.amblog-index-index {

  .breadcrumbs {
    margin-bottom: 0;
  }

  p {
    margin: 0;
  }
}

.blog-home-listing {

  .pagebuilder-column-group {
    margin-bottom: 6rem;
    margin-top: 3rem;
  }

  [data-content-type=row][data-appearance=contained] [data-element=inner] {

    > [data-content-type='text'] {
      display: flex;

      h2 {
        margin: 0 3rem 0 0;
        line-height: 1;
        font-weight: 700;
        font-size: 2.2rem;
      }
    }

    > .pagebuilder-column-group > .pagebuilder-column {
      padding: 0 !important;

      li:nth-child(3) ~ li {
        display: none;
      }

      &[style*='50%'] li:nth-child(2) ~ li {
        display: none;
      }

      &:first-child {

        [data-content-type='text'],
        [data-content-type='banner'] {
          margin-right: 15px !important;
        }
      }

      &:last-child {

        [data-content-type='text'],
        [data-content-type='banner'] {
          margin-left: 15px !important;
        }
      }

      &:only-child {

        [data-content-type='text'],
        [data-content-type='banner'] {
          margin-left: 0 !important;
          margin-right: 0 !important;
        }
      }

      [data-content-type='banner'] {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        background-color: $primary;
        border-radius: 3px;
        font-size: 1.8rem;
        font-weight: 700;
        text-align: center;
        font-family: $headings-font-family;

        a {
          display: inline-block;
          margin: 2rem 0 0;
          padding: #{$btn-padding-y-sm + $btn-border-width} #{$btn-padding-x-sm + $btn-border-width};
          background: $gradient-dark;
          color: $white;
          font-weight: 600;
          font-size: 12px;
          border-radius: 0.3rem;
          text-decoration: none;

          &:focus,
          &:active {
            border: none;
          }

          &:hover {
            padding: $btn-padding-y-sm $btn-padding-x-sm;
            border: 2px solid $black;
            background: $white;
            color: $black;
          }
        }

        p {
          max-width: 190px;
          margin-left: auto;
          margin-right: auto;
        }
      }

      ul,
      li {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
}

.blog-post {
  flex-wrap: wrap;

  &.desktop {
    display: flex;
  }

  &.one-column {
    margin-top: 1.5rem;
  }

  .amblog-post-item-container {

    &.amblog-post-container {
      margin-bottom: 2rem;
      box-shadow: none;
      background-color: transparent;
    }

    .amblog-post-item {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 100%;
      background-color: $white;
      box-shadow: $box-shadow-xl;
      border-radius: 3px;
      overflow: hidden;
    }

    .amblog-image {
      border-radius: 0;
    }

    .amblog-date {
      margin-bottom: 0.5rem;
    }

    .amblog-headline.amblog-headline.amblog-headline.amblog-headline.amblog-headline {
      display: block;
      text-decoration: none;
      line-height: 1.25;
      font-weight: 700;
      font-size: 1.8rem;

      &:hover {
        text-decoration: underline;
        color: inherit;
      }
    }

    .amblog-tags {
      position: absolute;
      top: 1.5rem;
      left: 1.5rem;
      width: auto;
      padding: 0;
      margin: 0;
    }

    .amblog-tag {

      ~ .amblog-tag {
        display: none;
      }
    }

    .blog-thumbnail {
      display: block;
      height: 187px;
      margin-bottom: 0;
      background-image: url('../images/noimage-placeholder.png');
      background-size: 150px 150px;
      background-position: center;
      background-repeat: no-repeat;
      background-color: #f8f8f8;
    }
  }

  .amblog-container-list {
    list-style: none;
    padding-left: 0;
    width: calc(100% + 30px);
  }

  .amblog-recent-post-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
    padding: 2rem;
  }

  .blog-thumbnail {
    width: 100%;
    height: 200px;
    margin-bottom: 3rem;

    @include media-breakpoint-down(xs) {
      height: 220px;
      width: auto;
    }

    .amblog-image {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  .amblog-main-content.amblog-main-content.amblog-main-content {
    flex-grow: 1;

    @include media-breakpoint-down(sm) {
      padding-top: 25px;
    }

    @include media-breakpoint-down(md) {
      width: 100%;
      padding-left: 0;
    }
  }

  .amblog-tags {
    width: 100%;
    text-align: center;

    @include media-breakpoint-down(xs) {
      margin-top: 0;
    }
  }

  .amblog-post-container {
    border: none;

    @include media-breakpoint-down(xs) {

      .amblog-bottom {
        margin-top: 0;
      }
    }

    a.prev-post,
    a.next-post {
      text-decoration: none;
      position: relative;

      &:hover {
        text-decoration: underline !important;
      }
    }

    .next-post::after {
      content: ' ';
      position: absolute;
      right: 5px;
      display: block;
      top: 20px;
      width: 15px;
      height: 15px;
      background-image: url(../images/right.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }

    .prev-post::after {
      content: ' ';
      position: absolute;
      left: 5px;
      display: block;
      top: 20px;
      width: 15px;
      height: 15px;
      background-image: url(../images/right.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      transform: rotate(180deg);
    }
  }

  .amblog-content {
    margin-top: 0;
    display: flex;
    flex-direction: column;
    line-height: 1.75;

    h1,
    h2,
    h3,
    h4,
    h5 {
      font-weight: 700;
    }

    h2,
    h3 {
      font-size: 2.2rem;
    }

    h2 {
      margin: 2.5rem 0 3.3rem;
    }

    h3 {
      margin: 2rem 0 2.8rem;
    }

    img {
      margin-bottom: 2rem;
    }

    p {
      margin-bottom: 2.3rem;
    }

    .amblog-title {
      margin-bottom: 3.7rem;
      padding-bottom: 3.7rem;
      text-transform: none;
      font-weight: 700;
      color: inherit;
      border-bottom: 2px solid $light;

      @include media-breakpoint-down(xs) {
        margin-bottom: 2.5rem;
        padding-bottom: 2.5rem;
      }
    }

    .lead {
      font-family: $headings-font-family;
      font-weight: normal;
    }

    @include media-breakpoint-down(xs) {
      font-size: 1.5rem;

      h2 {
        margin: 0.5rem 0 2rem;
      }

      h3 {
        margin: 0.5rem 0 1.8rem;
      }
    }

    @include media-breakpoint-down(md) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .amblog-date {
    order: -1;
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    font-size: 1.2rem;
    color: $black;
  }

  .blog-date-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    margin-right: 1.2rem;
    border-radius: 99px;
    border: solid 4px rgba($primary, 0.4);
    background-color: $primary;
    background-clip: content-box;
  }

  .amblog-title {
    text-transform: none;
    font-weight: 700;
    color: inherit;
  }
}

.amblog-tags {

  &.amblog-tags.amblog-widget-container {
    box-shadow: none;
    background-color: transparent;
  }

  .amblog-tag.amblog-tag {
    margin-bottom: 6px;
    padding: 4px 10px;
    background-color: $primary;
    color: $black;
    text-decoration: none;
    font-size: 1.1rem;

    &:hover {
      background-color: $white;
      color: $black;
    }

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.amblog-element-block {
  border-radius: 0;
  box-shadow: none;
}

.blog-sidebar {

  .amblog-title {
    font-size: 1.3rem;
  }

  .amblog-list {
    padding-left: 0;
  }

  .amblog-category-count {
    display: none;
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.blog-categories-container {
  background-color: $primary;
  text-align: center;
  margin: 1.7rem 0 3.5rem;
  padding: 2.5rem;

  .h3 {
    margin-bottom: 2rem;
    font-weight: 700;
  }

  .form-control {
    max-width: 289px;
    margin-left: auto;
    margin-right: auto;
  }
}

.bluefoot-video iframe {
  height: 500px;
  width: 100%;
  max-width: 100% !important;
}

.bluefoot-wrapper.container.row {
  padding-left: 0;
  padding-right: 0;
}

.page-layout-cms-full-width {

  .column.main {
    overflow: hidden;
  }
}

.review-wrapper {
  border-radius: 10px;
  border: 10px solid $yellow;
  width: 375px;
  padding-left: 21px;
  padding-right: 21px;
  margin: 10px auto 60px auto;

  .review {
    text-align: center;
    display: flex;
    align-items: column;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 0.9rem;
    padding-top: 13px;
    text-transform: uppercase;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 16px;
      background: linear-gradient(to right, $yellow var(--percentage), $gray-300 var(--percentage));
      order: 3;
    }

    &::after {
      content: attr(data-review);
      position: relative;
    }

    &:first-child,
    &:last-child {
      font-weight: bold;
      font-size: 1.8rem;
      line-height: 2.2rem;

      &::before,
      &::after {
        display: none;
      }
    }

    &:first-child {
      justify-content: center;
      padding-top: 28px;
      padding-bottom: 12px;
    }

    &:last-child {
      padding-top: 0;
      border: 8px solid $yellow;
      width: 79px;
      height: 79px;
      margin: 0 auto -15px auto;
      border-radius: 100%;
      align-items: center;
      justify-content: center;
      bottom: -39.5px;
      position: relative;
      background-color: $white;
    }
  }
}

.amblog-widget-container.-post.blog-post {
  width: 100%;
}

.amblog-tags.amblog-widget-container.-tags {

  .amblog-tag {
    display: inline-block;
    margin-bottom: 10px;

    &:hover {
      background-color: $primary;
    }
  }

  .amblog-tags-holder:not(.show-all) {
    max-height: 65px;
    overflow: hidden;
  }
}

@include media-breakpoint-down(sm) {

  .blog-home-listing {

    .amblog-post-item-container.amblog-post-container {
      margin-bottom: 2rem !important;
    }

    [data-content-type=row][data-appearance=contained] [data-element=inner] > .pagebuilder-column-group > .pagebuilder-column:last-child {

      [data-content-type=banner],
      [data-content-type=text] {
        margin-left: 0 !important;
      }
    }
  }
}
