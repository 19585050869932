.minicart-wrapper .ui-dialog {
  display: block !important;
}

.nav-sections,
.block-minicart.block-minicart {
  position: fixed;
  top: 0;
  display: block;
  margin: 0;
  max-width: 414px;
  min-width: 0;
  width: 100%;
  height: 100%;
  flex-direction: column;
  overflow-y: auto;
  transition: transform 0.3s;
  left: auto;
  right: 0;
  transform: translateX(100%);
  z-index: 100;

  @include media-breakpoint-down(xs) {
    max-width: 100%;
    width: 100%;
  }
}

.nav-sections.active,
.minicart-wrapper.active .block-minicart.block-minicart {
  left: auto;
  transform: translateX(0);
  box-shadow: $box-shadow-xl;

  @include media-breakpoint-down(xs) {
    box-shadow: none;
  }
}

.nav-open .nav-toggle::after {

  @include media-breakpoint-down(xs) {
    display: none;
  }
}

.nav-sections {

  @include media-breakpoint-down(md) {
    top: auto;
    bottom: 0;
    height: calc(100% - 105px);
    padding-top: 10px;
    z-index: 1001 !important;
  }

  @include media-breakpoint-up(lg) {
    position: static;
    max-width: 100%;
    z-index: 90;
    overflow: visible;
  }
}

.sections.nav-sections {

  @include media-breakpoint-down(md) {

    &.hide {
      display: none !important;
    }
  }

  @include media-breakpoint-up(lg) {
    display: block !important;
  }
}

.minicart-wrapper {

  @include media-breakpoint-up(lg) {
    margin-right: 0.5rem;
  }

  &.active {

    .action.showcart,
    .action.showAccount,
    .action.showCompare,
    .action.showReviews {

      &::before {
        display: none;
      }

      &::after {
        content: '';
        background: rgba($black, 0.5);
        display: block;
        height: 100%;
        position: fixed;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 100;
      }
    }
  }

  .block-minicart {
    padding: 0;
    border: none;

    .sidebar-heading {
      flex-grow: 0;
      flex-shrink: 0;
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
}

.sidebar-heading {
  position: relative;
  height: 40px;
  padding-top: 12px;
  background: $secondary;
  text-align: center;
  color: $white;

  h2 {
    position: relative;
    font-weight: 700;
    font-size: 1.6rem;
  }

  .nav-back {
    position: absolute;
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
    margin-right: -10px;
    border: none;
    border-radius: 0;
    padding: 1rem 2.5rem 1rem 1.5rem;

    &:hover,
    &:focus,
    &:active {
      background: none;
    }

    @include media-breakpoint-down(xs) {
      margin-right: -5.5rem;
    }
  }

  .close,
  .back {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    border-radius: 0;
    opacity: 1;
  }

  .back {
    left: 0;
    right: auto;

    &:hover {
      opacity: 0.75;
    }

    svg {
      height: 20px;
      width: 20px;
    }
  }

  .clear {
    background: none !important;
    border-radius: 0;
    color: $white;
    position: absolute;
    top: 50%;
    right: 6rem;
    transform: translateY(-50%);
    font-size: 1.2rem;
  }

  @include media-breakpoint-down(md) {
    padding-left: 0;
    padding-right: 0;

    ul {
      max-width: 272px;
      margin: 0 auto;

      li {
        border-top-color: $gray-200;
      }
    }

    a.nav-link {
      color: $primary;
      font-size: 1.5rem;
    }
  }
}

.sidebar-info.sidebar-info.sidebar-info {
  margin-bottom: 3rem;

  ul {
    margin-bottom: 0;
    padding-left: 1.6rem;
    list-style: none;
  }

  a {
    font-size: 1.2rem;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .btn-link {
    font-size: 1.3rem;
  }
}

.sidebar-cta {
  position: relative;
  margin-top: 2rem;
  padding: 2.7rem;
  border-radius: 2px;
  overflow: hidden;
  text-align: center;
  color: $white;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-color: rgba($black, 0.74);
  }

  [data-content-type=row][data-appearance=contained] {
    max-width: 197px;
  }

  svg {
    margin-bottom: 2rem;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100% !important;
    z-index: -2;
  }

  .btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .btn-sm {
    padding: 5px 13px !important;
    display: block;
  }

  h4 {
    margin-bottom: 1.5rem;
    font-size: 2.2rem;
  }

  strong {
    color: $primary;
  }

  p strong {
    font-size: 1.8rem;
  }
}
