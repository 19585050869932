.gallery-placeholder {
  position: relative;
  width: 100%;
}

.fotorama__nav-wrap {
  position: absolute;
  bottom: 0;
  left: 0;

  @include media-breakpoint-up(lg) {
    left: 2.3rem;
  }

  @include media-breakpoint-down(sm) {
    bottom: -3rem;
  }
}

@include media-breakpoint-down(sm) {

  .fotorama__wrap {
    margin-bottom: 3rem;
  }
}

.fotorama__nav__shaft {

  @include media-breakpoint-down(md) {
    display: flex;
    margin: 0 auto;
    transform: none !important;
  }
}

.fotorama__nav__frame.fotorama__nav__frame {
  height: 5px;

  @include media-breakpoint-up(lg) {
    padding: 2px;
    height: 49px;
    margin-right: 0.9rem;
  }

  @include media-breakpoint-down(md) {
    width: 100%;

    .fotorama__dot {
      background-color: $light;
      width: 100%;
      height: 5px;
      border-radius: 0;
      border: none;
      left: 0;
      top: 0;

      &::before {
        content: '';
        position: absolute;
        top: -20px;
        bottom: -21px;
        left: 0;
        right: 0;
        z-index: 3;
      }
    }

    &.fotorama__active .fotorama__dot {
      background-color: $secondary;
      border-radius: 99px;
    }

    &.fotorama__nav__frame--thumb + .fotorama__nav__frame--dot .fotorama__dot {
      border-top-left-radius: 99px;
      border-bottom-left-radius: 99px;
    }

    &.fotorama__nav__frame--dot:last-child .fotorama__dot {
      border-top-right-radius: 99px;
      border-bottom-right-radius: 99px;
    }
  }
}

.fotorama__img {
  height: 100%;
  object-fit: cover;
}

.fotorama__thumb-border {
  border: 1px solid $light !important;
}

.magnifier-preview {
  top: 0;
  left: 80px !important;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.magnify-lens {
  border: none;
}

.fotorama__fullscreen-icon {
  background-image: url('../images/close.svg');
  background-size: 25px 25px;
  z-index: 1;
}

.fotorama__arr__arr {

  @include media-breakpoint-up(lg) {

    .fotorama__arr--prev &,
    .fotorama__arr--next & {
      background-image: url('../images/right.svg');
      background-size: 14px 24.5px;
      background-position: center;

      .fotorama__fullscreen & {
        background-image: url('../images/right-white.svg');
      }
    }

    .fotorama__arr--prev & {
      transform-origin: 20px 20px;
      transform: rotate(180deg);
    }
  }

  @include media-breakpoint-down(md) {

    .fotorama__arr & {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      background-size: 15px 15px;
      background-image: url('../images/down.svg');
      background-position: center;
    }

    .fotorama__arr--prev & {
      transform: rotate(90deg);
    }

    .fotorama__arr--next & {
      transform: rotate(-90deg);
    }
  }
}

.fotorama--fullscreen {
  background-color: rgba($dark, 0.9);

  .fotorama__wrap,
  .fotorama__stage.fotorama__stage {
    height: 100% !important;
  }

  .fotorama__nav-wrap,
  .fotorama__zoom-in,
  .fotorama__zoom-out {
    display: none !important;
  }

  .fotorama__arr {
    width: 80px;
    height: 80px;
    margin: auto 0;
  }

  .fotorama__fullscreen-icon {
    background-position: center !important;
  }

  .fotorama__arr,
  .fotorama__thumb__arr,
  .fotorama__fullscreen-icon {
    display: block !important;
    background-color: transparent !important;
    background-repeat: no-repeat;

    &:hover {
      background-color: rgba($white, 0.2) !important;
    }
  }
}

.fotorama {

  @include media-breakpoint-down(md) {
    margin-left: -15px;
    margin-right: -15px;
    overflow: hidden;
    contain: content;
  }
}

.fotorama__stage.fotorama__stage {

  @include media-breakpoint-up(lg) {
    height: 400px !important;
  }

  @include media-breakpoint-down(md) {
    height: 270px !important;
    overflow: visible;

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      background-color: rgba($white, 0.8);
      top: 0;
      width: calc((100vw - 100%) / 2);
      height: 100%;
    }

    &::before {
      left: 100%;
    }

    &::after {
      right: 100%;
    }
  }
}

.fotorama__arr {

  @include media-breakpoint-down(md) {
    display: block !important;
    background: none !important;

    &.fotorama__arr--prev {
      left: calc((100vw - 240px) / 2 * -1);
    }

    &.fotorama__arr--next {
      right: calc((100vw - 240px) / 2 * -1);
    }
  }
}

.fotorama__wrap {

  @include media-breakpoint-down(md) {
    margin-left: auto;
    margin-right: auto;
  }
}

.fotorama__nav--dots {

  @include media-breakpoint-down(md) {
    width: 310px !important;
    transform: translateX(-50%) !important;
    left: 120px;
  }
}
