.breadcrumbs {
  max-width: none;
  padding: 0;
  margin-bottom: $breadcrumb-margin-bottom;
  background-color: $breadcrumb-bg;
}

.breadcrumb {
  margin-bottom: 0;
  background-color: transparent;
  color: $black;

  a {
    font-weight: 700;
    color: inherit;
  }

  .breadcrumb-item {
    margin-bottom: 0;
  }
}
