body[class*=cms-events] .column.main {
  padding-bottom: 0;

  [data-content-type=row][data-appearance=contained] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.events-banner,
.academy-banner {
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center !important;
  max-width: none;
  height: 250px;
  background-position: center !important;

  @include media-breakpoint-down(xs) {
    flex-basis: calc(100% + 3.5rem);
    height: 220px;
    margin: -3.3rem -1.5rem 0 !important;

    .btn {
      display: none;
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($black, 0.4);
    z-index: -1;
  }

  [data-content-type='html'] {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .btn {
    margin-top: 3.4rem;
    width: 225px;
  }
}

.events-intro,
.academy-intro {
  padding: 34px 15px !important;
  text-align: center;

  @include media-breakpoint-down(xs) {
    position: relative;
    padding-left: 0 !important;
    padding-right: 0 !important;
    font-size: 1.5rem;
    text-align: left;
    max-height: 233px;
    overflow: hidden;
    margin-bottom: 5rem !important;

    .read-more-holder {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 2;
    }

    &.show-more {
      max-height: none;

      .read-more-holder .more {
        display: none;
      }
    }

    &:not(.show-more) {

      &::after {
        content: ' ';
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        right: 0;
        height: 120px;
        background: transparent linear-gradient(180deg, #eee0 0%, $white 100%) 0% 0% no-repeat padding-box;
      }

      .read-more-holder .less {
        display: none;
      }
    }
  }

  h1 {
    margin-bottom: 1.7rem;
    font-size: 2.4rem;
    font-weight: 700;
  }

  .lead {
    font-family: $headings-font-family;
    font-weight: 400;
  }

  p {
    max-width: 980px;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.7;
  }
}

.inner-prefooter.inner-prefooter.inner-prefooter {
  margin-bottom: 4rem !important;

  @include media-breakpoint-up(md) {
    margin: 0 -0.4rem 6.4rem !important;
  }
}

.inner-prefooter-text {
  margin: 3.8rem 1.9rem !important;
  padding: 34px 20px !important;
  border-style: solid !important;
  border-width: 10px !important;
  border-radius: 8px !important;
  text-align: center;
  font-family: $headings-font-family;
  font-weight: 700;
  font-size: 2.2rem;

  @include media-breakpoint-down(sm) {
    margin-bottom: 3rem !important;
    line-height: 1.25;
  }

  p {
    max-width: 830px;
    margin: 0 auto;
  }
}

.prefooter-block {
  position: relative;
  z-index: 0;
  justify-content: center !important;
  align-items: center;
  height: 345px;
  box-sizing: content-box;
  padding: 19px !important;
  text-align: center;
  color: $white;

  figure {
    position: absolute;
    top: 19px;
    left: 19px;
    right: 19px;
    bottom: 19px;
    z-index: -1;
    border-radius: 2px;
    overflow: hidden;

    @include media-breakpoint-down(xs) {
      top: 9px;
      bottom: 9px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($black, 0.66);
      z-index: 0;
    }

    img {
      width: 100%;
      height: 100% !important;
      object-fit: cover;
    }
  }

  > div {
    max-width: 271px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
  }

  p {

    &:first-child {
      line-height: 1.2;
      font-family: $headings-font-family;
      font-weight: 700;
      font-size: 2.2rem;
      color: $primary;
    }

    &:not(:last-child) {
      margin-bottom: 2.8rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    display: inline-block;
    color: $black;
    text-align: center;
    text-transform: none;
    text-decoration: none;
    line-height: 1.5;
    font-family: $headings-font-family;
    font-weight: 600;
    font-size: 1.2rem;
    @include border-radius($btn-border-radius-sm); /* stylelint-disable-line */
  }

  @include media-breakpoint-down(sm) {
    height: 345px;
    max-width: 408px;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.prefooter-masters,
.prefooter-academy {

  a {
    padding: #{$btn-padding-y-sm + $btn-border-width} #{$btn-padding-x-sm + $btn-border-width};
    background: $gradient-primary;

    &:hover {
      padding: $btn-padding-y-sm $btn-padding-x-sm;
      border: 2px solid $black;
      color: $black;
      background: $white;
    }
  }

  p:first-child {
    text-transform: uppercase;
  }
}

.prefooter-masters {

  svg {
    margin-bottom: 3rem;
  }
}

.prefooter-twi {

  svg {
    margin-bottom: 3.5rem;
  }

  a {
    padding: $btn-padding-y-sm $btn-padding-x-sm;
    background: $white;
    border: 2px solid $black;

    &:hover {
      padding: #{$btn-padding-y-sm + $btn-border-width} #{$btn-padding-x-sm + $btn-border-width};
      border: none;
      background: $gradient-primary;
    }
  }
}

.prefooter-academy {

  svg {
    margin-bottom: 2rem;
  }

  p:first-child {
    margin-bottom: 3.3rem;
  }
}

.event-search {
  margin-bottom: 4.7rem;
  padding: 2.8rem 2.6rem 2.6rem;
  background-color: $primary;
  text-align: center;

  @include media-breakpoint-down(xs) {
    margin: 0 -1.5rem 3.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  h2 {
    margin-bottom: 1.5rem;
    font-weight: 700;
    font-size: 2.2rem;
  }

  .form-inline {
    justify-content: center;
  }

  .form-group {

    @include media-breakpoint-down(sm) {
      width: 100%;
      margin-bottom: 1.2rem;
    }
  }

  .form-control {

    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    @include media-breakpoint-up(md) {
      width: 220px;
      margin-right: 1.4rem;
    }

    @include media-breakpoint-up(lg) {
      width: 330px;
    }
  }

  .btn-secondary {

    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    @include media-breakpoint-up(md) {
      padding: #{$btn-padding-y + $btn-border-width} #{$btn-padding-x + $btn-border-width - 1.5rem};

      &:hover {
        padding: $btn-padding-y #{$btn-padding-x - 1.5rem};
      }
    }
  }
}

.event-list {
  list-style: none;
  padding-left: 0;

  .event-list-item {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: $white;
    box-shadow: $box-shadow-xl;
    border-radius: 3px;
    overflow: hidden;
  }

  .event-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
    padding: 2rem;
  }

  .event-title {
    margin-bottom: 1.5rem;
    text-transform: none;
    font-weight: 700;
    color: inherit;
    min-height: 67px;
  }

  .event-headline.event-headline {
    display: block;
    text-decoration: none;
    line-height: 1.25;
    font-size: 1.8rem;

    &:hover {
      text-decoration: underline;
      color: inherit;
    }
  }

  .event-date {
    order: -1;
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    font-size: 1.2rem;
    color: $black;
  }

  .event-price {
    position: absolute;
    top: 2.4rem;
    right: 2.1rem;
    font-weight: 700;
    color: $white;
    pointer-events: none;
  }
}

.amlocator-location-main {

  .event-list {

    li.col-md-6 {
      max-width: 100% !important;
    }
  }
}

.event-thumbnail {
  position: relative;
  display: block;
  height: 187px;
  margin-bottom: 0;
  background-image: url('../images/noimage-placeholder.png');
  background-size: 150px 150px;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #f8f8f8;

  .event-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.event-tag {
  position: absolute;
  top: 2.1rem;
  left: 2.1rem;
  z-index: 1;
  padding: 0.4rem 1rem;
  background-color: $primary;
  color: $black;
  border-radius: $border-radius;
  font-weight: 700;
  font-size: 1.1rem;
  text-decoration: none !important;

  &:hover {
    background-color: $black;
    color: $white;
  }
}

.event-location {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: auto;
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
  color: $black;

  .event-location-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    margin-right: 1.2rem;
    border-radius: 99px;
    border: solid 4px rgba($primary, 0.4);
    background-color: $primary;
    background-clip: content-box;
  }

  p {
    margin-bottom: 0;
  }
}

@include media-breakpoint-down(sm) {

  .event-detail {

    .event-buy-container {
      position: sticky;
      top: 0;
      background-color: $white;
      padding-top: 10px;
      padding-bottom: 10px;
      max-width: none !important;
    }
  }
}
