
body.tracking-index-index {

  .page-title-wrapper {
    display: none;
  }
}

.tracking-wrapper {

  h2 {
    font-size: 2.4rem;
    font-weight: 700;
  }

  .tracking-number-display {

    .table-striped {
      border-collapse: separate;

      tbody tr:nth-of-type(even) {
        background-color: rgba($light, 0.2);
      }

      td,
      th {
        padding: 8px 15px;
        border: solid 1px $gray-100;
        height: 47px;
        vertical-align: middle;

        svg {
          max-width: 30px;
          max-height: 30px;
          margin-right: 15px;

          @include media-breakpoint-down(md) {
            display: block;
            margin: 0 auto 5px auto;
          }
        }
      }

      tr.completed {

        svg {
          color: $success;
        }
      }

      tr:first-child {

        th {
          border-top-left-radius: 5px;
        }

        td {
          border-top-right-radius: 5px;
        }
      }

      tr:not(:first-child) {

        th,
        td {
          border-top: none;
        }
      }

      td {
        border-left: none;
      }

      th {
        border-right: none;
        width: 20%;

        @include media-breakpoint-down(md) {
          text-align: center;
        }
      }
    }
  }

  .tracking-form {

    @include media-breakpoint-up(md) {
      border: solid 10px $primary;
      border-radius: 8px;
      padding: 45px 65px;
    }

    .fieldset {
      margin-bottom: 0;
    }
  }
}
