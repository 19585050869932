.checkout-index-index {

  .page-header {
    border: none;

    &::before,
    &::after {
      display: none !important;
    }
  }

  .page-wrapper {
    overflow-x: hidden;
  }

  .page-main {
    max-width: 980px;
  }

  .cart-pre-actions .container {
    max-width: 1004px;
    height: inherit;
  }

  .page-title-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    margin: auto;
    height: 40px;

    .page-title {
      position: relative;
      z-index: 1;
      margin-bottom: 0;

      @include media-breakpoint-down(md) {
        font-size: 1.6rem;
      }
    }

    @include media-breakpoint-up(lg) {
      height: 80px;
    }
  }
}

.checkout-container {
  max-width: 1013px;
  margin: 15px auto 0;

  @include media-breakpoint-up(lg) {
    display: flex;
    flex-wrap: wrap;
  }

  .authentication-wrapper {
    order: 1;
  }

  .messages {
    max-width: 100%;
    width: 100%;
  }

  > div {
    float: none;
    width: auto;
    flex-grow: 1;
    max-width: 541px;
    flex-basis: 541px;
    padding-left: 0;

    @include media-breakpoint-up(md) {
      min-width: 370px;
    }

    @include media-breakpoint-down(md) {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .addresses {
    margin: 1rem 0;
  }

  .address {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    .field {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      width: 100%;
      margin-bottom: 1.3rem;

      &[name='shippingAddress.country_id'] {
        @include sr-only; /* stylelint-disable-line */
      }

      @include media-breakpoint-up(sm) {

        &[name='shippingAddress.firstname'],
        &[name='shippingAddress.lastname'] {
          width: 50%;
        }

        &[name='shippingAddress.firstname'] {
          padding-right: 2%;
        }

        &[name='shippingAddress.lastname'] {
          padding-left: 2%;
        }
      }

      &[name='shippingAddress.region_id'],
      &[name='shippingAddress.postcode'] {
        width: 50%;
      }

      &[name='shippingAddress.region_id'] {
        padding-right: 2%;
      }

      &[name='shippingAddress.postcode'] {
        padding-left: 2%;
      }

      &.street .field .label {
        @include sr-only; /* stylelint-disable-line */
      }

      .field {
        margin-bottom: 0;
      }

      .label {
        position: relative;
        z-index: 1;
        display: block;
        width: auto;
        margin: 0 0.9rem;
        padding: 0 0.9rem;
        background: $white;
        font-family: $headings-font-family;
        font-weight: 600;
      }

      legend.label {
        line-height: 2.3;
      }

      &._required .label::after {
        content: '*';
        font-size: 1.2rem;
        margin: 0 0 0 0.3rem;
      }

      .form-control,
      .input-text,
      .select,
      .control {
        width: 100%;
        margin-top: -0.6rem;
        border-radius: $border-radius;
      }

      .select {
        padding-right: 3rem;
      }
    }
  }

  .field-error {
    margin-top: 0;
    font-size: 1.1rem;
    color: $red;
  }

  .form.form-giftcard-account {

    .action-check,
    .giftcard-account-info {
      margin-top: 2rem;
    }
  }
}

.mark {
  background: none;
}

.table {

  .price-including-tax,
  .price-excluding-tax {
    font-size: inherit;
  }
}

.opc-sidebar.opc-sidebar {
  flex-basis: 380px;
  float: none;
  width: auto;
  margin-top: 0;
  margin-left: auto;

  .minicart-items-wrapper {
    margin: 0;
    padding: 25px 25px 15px;
    border: none;
  }

  .minicart-items {

    .product-item {
      padding-bottom: 0;

      &:not(:first-child) {
        border-top-color: $light;
      }

      &:last-child {
        border-bottom: 1px solid $light;
        padding-bottom: 10px;
      }
    }

    .product-item-details {

      .details-qty {
        margin-top: 0;
      }
    }

    .label {
      font-weight: 700;
      font-size: 1.3rem;
    }
  }

  .table-totals-container {
    padding: 0 25px;

    .cart-totals.cart-totals {
      margin-bottom: 0.4rem;

      /* padding on third last row's children, as per weird design */
      tr:nth-last-of-type(2) {

        td,
        th {
          padding-bottom: 30px;
        }
      }
    }
  }

  @include media-breakpoint-between(sm, md) {

    .cart-summary,
    .checkout-placeorder {
      max-width: 374px;
      width: 100%;
      margin-right: auto;
      margin-left: 0;
      float: left;
    }
  }

  @include media-breakpoint-down(md) {

    .cart-summary {
      margin-top: 0;
    }

    .checkout-placeorder .btn {
      width: 100%;
    }
  }

  .checkout-placeorder {

    @include media-breakpoint-up(lg) {

      .btn {
        margin-left: 2.9rem;
        width: calc(100% - 2.9rem);
      }
    }
  }
}

.checkout-discount {
  clear: both;

  @include media-breakpoint-between(sm, lg) {
    max-width: 374px;
  }

  @include media-breakpoint-up(lg) {
    margin-left: 2.9rem;
    width: calc(100% - 2.9rem);
  }
}

.shippit-options {
  margin-top: 2rem;

  .control {
    margin-top: 0.5rem;
    margin-bottom: 2rem;
  }

  .label {
    font-weight: 700;
  }

  .choice {
    position: relative;
    z-index: 1;
    display: block;
    min-height: $font-size-base * $line-height-base;
    padding-left: $custom-control-gutter + $custom-control-indicator-size;
    color-adjust: exact;

    .label {
      position: relative;
      margin-bottom: 0;
      color: $custom-control-label-color;
      vertical-align: top;
      cursor: $custom-control-cursor;

      // Background-color and (when enabled) gradient
      &::before {
        position: absolute;
        top: ($font-size-base * $line-height-base - $custom-control-indicator-size) / 2;
        left: -($custom-control-gutter + $custom-control-indicator-size);
        display: block;
        width: $custom-control-indicator-size;
        height: $custom-control-indicator-size;
        pointer-events: none;
        content: '';
        background-color: $custom-control-indicator-bg;
        border: $custom-control-indicator-border-color solid $custom-control-indicator-border-width;

        @include box-shadow($custom-control-indicator-box-shadow);
      }

      // Foreground (icon)
      &::after {
        position: absolute;
        top: ($font-size-base * $line-height-base - $custom-control-indicator-size) / 2;
        left: -($custom-control-gutter + $custom-control-indicator-size);
        display: block;
        width: $custom-control-indicator-size;
        height: $custom-control-indicator-size;
        content: '';
        background: 50% / #{$custom-control-indicator-bg-size} no-repeat;
      }
    }

    .checkbox {
      position: absolute;
      left: 0;
      z-index: -1;
      width: $custom-control-indicator-size;
      height: ($font-size-base * $line-height-base + $custom-control-indicator-size) / 2;
      opacity: 0;

      &:checked ~ .label {

        &::before {
          color: $custom-control-indicator-checked-color;
          border-color: $custom-control-indicator-checked-border-color;

          @include gradient-bg($custom-control-indicator-checked-bg);
          @include box-shadow($custom-control-indicator-checked-box-shadow);
        }

        &::after {
          background-image: escape-svg($custom-checkbox-indicator-icon-checked);
        }
      }

      &:focus ~ .label::before {
        // the mixin is not used here to make sure there is feedback
        @if $enable-shadows {
          box-shadow: $input-box-shadow, $custom-control-indicator-focus-box-shadow;
        }
        @else {
          box-shadow: $custom-control-indicator-focus-box-shadow;
        }
      }

      &:focus:not(:checked) ~ .label::before {
        border-color: $custom-control-indicator-focus-border-color;
      }

      &:not(:disabled):active ~ .label::before {
        color: $custom-control-indicator-active-color;
        background-color: $custom-control-indicator-active-bg;
        border-color: $custom-control-indicator-active-border-color;

        @include box-shadow($custom-control-indicator-active-box-shadow);
      }

      &[disabled],
      &:disabled {

        ~ .label {
          color: $custom-control-label-disabled-color;

          &::before {
            background-color: $custom-control-indicator-disabled-bg;
          }
        }
      }
    }
  }

  .admin__control-textarea {
    display: block;
    width: 100%;
    padding: $input-padding-y $input-padding-x;
    font-family: $input-font-family;
    @include font-size($input-font-size); /* stylelint-disable-line */
    font-weight: $input-font-weight; /* stylelint-disable-line */
    line-height: $input-line-height;
    color: $input-color;
    background-color: $input-bg;
    background-clip: padding-box;
    border: $input-border-width solid $input-border-color;

    // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
    @include border-radius($input-border-radius, 0);
    @include box-shadow($input-box-shadow); /* stylelint-disable-line */
    @include transition($input-transition); /* stylelint-disable-line */

    // Unstyle the caret on `<select>`s in IE10+.
    &::-ms-expand {
      background-color: transparent;
      border: 0;
    }

    // Remove select outline from select box in FF
    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 $input-color;
    }

    // Customize the `:focus` state to imitate native WebKit styles.
    @include form-control-focus($ignore-warning: true);

    // Placeholder
    &::placeholder {
      color: $input-placeholder-color;
      opacity: 1;
    }

    &:disabled,
    &[readonly] {
      background-color: $input-disabled-bg;
      opacity: 1;
    }
  }
}

.opc-wrapper {

  @include media-breakpoint-down(md) {
    padding-right: 0;
  }

  .shipping-address-item {
    line-height: 1.5;

    &.selected-item {
      border-color: $primary;

      &::after {
        content: '✓';
        background-color: $primary;
        font-family: inherit;
        font-size: 1.9rem;
      }
    }

    a {
      text-decoration: none;

      &:hover {
        text-decoration: hover;
      }
    }
  }

  .accordion {
    counter-reset: section;

    .btn-collapse span::before {
      counter-increment: section;
      content: counter(section) '. ';
    }
  }

  .form-login {
    padding-bottom: 0;
    border: none;

    &,
    .fieldset {
      margin-bottom: 0;
    }
  }

  .form-shipping-address {
    margin-top: 0;

    .btn.continue {

      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
  }

  .tab-holder {

    @include media-breakpoint-up(sm) {
      font-size: 1.5rem;
    }

    .btn {
      font-size: 1.4rem;
    }

    .tab-headings {
      border-bottom: solid 4px $black;

      .tab-item {
        background-color: $light;
        color: $black;
        display: block;
        flex: 1 1 50%;
        margin: 0 3px;
        font-family: $headings-font-family;
        font-size: 1.3rem;
        line-height: 1.9rem;
        font-weight: 700;
        padding: 20px 10px;
        border: none;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        outline: none !important;

        @include media-breakpoint-up(md) {
          font-size: 1.6rem;
        }

        &:hover,
        &.active {
          background-color: $black;
          color: $white;
        }

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .social-login-buttons {

      @include media-breakpoint-down(xs) {
        flex-wrap: wrap;
      }

      .btn-social {
        flex: 0 0 100%;
        padding-left: 45px;
        padding-right: 8px;
        line-height: 1.6;

        @include media-breakpoint-up(sm) {
          flex: 1 1 50%;
          margin: 0 3px;
        }

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;

          @include media-breakpoint-down(xs) {
            margin-top: 0.5rem;
          }
        }
      }
    }
  }
}

.opc-progress-bar {
  position: relative;
  width: 100%;
  text-align: center;
  margin-top: 1.5rem;
  margin-bottom: 4.6rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid $light;

  @include media-breakpoint-down(xs) {
    width: auto;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 100%;
    border-bottom: 1px solid $light;
    width: 100vw;
  }

  &::before {
    left: 100%;
  }

  &::after {
    right: 100%;
  }

  .opc-progress-bar-item {

    @include media-breakpoint-down(sm) {
      width: 33.33%;
    }

    &::before {
      left: 50%;
      top: 10px;
      height: 4px;
      background-color: $light;
    }

    &.show-complete::before {
      background-color: $primary;
    }

    &:last-child::before {
      display: none;
    }

    span {
      font-size: 1.1rem;
      font-weight: 400;
      padding-top: 33px;
      color: $black;

      &::before {
        width: 24px;
        height: 24px;
        margin-left: -12px;
        background-color: $light;
      }

      &::after {
        content: counter(i);
        font-family: $headings-font-family;
        font-size: 1.5rem;
        line-height: 1.4rem;
        font-weight: 700;
        color: $white;
        background-color: transparent;
      }
    }

    &.show-complete {

      span {

        &::before {
          background-color: $primary;
        }

        &::after {
          color: $black;
        }
      }
    }
  }
}

.table-checkout-shipping-method {

  td span {
    font-family: $headings-font-family;
  }
}

.shipping-method.shipping-method {

  td {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    border: none !important;
  }

  .col-method {
    padding-top: 0.6rem;
  }
}

.payment-title {
  font-size: 1.5rem;

  @include media-breakpoint-up(sm) {
    padding-left: 1.5rem;
  }

  @include media-breakpoint-down(xs) {
    margin: -1rem 0 1.5rem;
  }

  .methods-shipping & {
    margin-top: 2rem;
    padding-left: 0;
  }
}

.methods-shipping {

  .actions-toolbar .btn {

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
}

.ampickup-store-container.ampickup-store-container.ampickup-store-container {
  margin-bottom: 1rem;

  .label {
    float: none;
    width: 100%;
    margin-bottom: 0.5rem;
    text-align: left;
  }

  .ampickup-store {
    height: $input-height;
    padding: $input-padding-y $input-padding-x $input-padding-y 1.8rem;

    @include border-radius($input-border-radius, 0);
  }

  .ampickup-separator,
  .ampickup-details-container {
    display: none;
  }
}

.ampickup-map-popup.ampickup-map-popup {
  flex: 0 0 414px;
  max-width: 414px;
  width: 100%;
  left: auto;
  right: 0;
  height: 100%;

  .ampickup-overlay {
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;

    &:hover {
      cursor: pointer;
    }
  }

  .ampickup-content {
    width: 100%;
    height: 100%;
    max-height: 100%;
    border-radius: 0;
    padding: 0;
  }

  .ampickup-title {
    flex-shrink: 0;
    height: 40px;
    margin-bottom: 0;
    background-color: $black;
    color: $white;
    font-family: $headings-font-family;
    font-size: 1.6rem;
    text-align: center;
    font-weight: 700;
    line-height: 40px;
  }

  .ampickup-close {
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    opacity: 1;

    &::before,
    &::after {
      top: 10px;
      left: 14px;
      height: 21px;
      background-color: $white;
    }
  }

  .amlocator-main-container {
    min-width: 0;

    .amlocator-store-desc {
      cursor: default;
      overflow: revert !important;

      &:not(:last-child) {
        border-bottom-color: $light;
      }
    }
  }

  .amlocator-map-container {
    flex-direction: column;
    min-height: 0;
    position: absolute;
    top: auto;
    right: 0;
    width: 100%;
    height: 100%;

    .amlocator-search-results {
      padding: 2rem 1.5rem 0;

      @include media-breakpoint-up(sm) {
        padding-left: 2rem;
        padding-right: 2rem;
      }
    }

    .amlocator-block.-storelist {
      flex: 1 1 0;
      width: 100%;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      overflow-y: auto;

      @include media-breakpoint-up(sm) {
        padding-left: 2.2rem;
        padding-right: 2.2rem;
      }
    }

    .btn-sm {

      @include media-breakpoint-down(xs) {
        padding: #{$btn-padding-y-sm + $btn-border-width} #{$btn-padding-x-sm + $btn-border-width - 0.7rem};

        &:hover {
          padding: $btn-padding-y-sm #{$btn-padding-x-sm - 0.7rem};
        }
      }
    }
  }

  .amlocator-store-information {
    flex: 1 0 auto;
    line-height: 1.5;
    font-size: 1.2rem;

    .amlocator-title {
      margin: 0.5rem 0;
    }
  }

  .ampickup-locations {
    height: calc(100% - 40px);
    padding: 2rem;
    padding-top: 40px;

    &::before {
      content: 'Click & Collect';
      top: 0;
      left: 0;
      right: 0;
      background: #000;
      color: #fff;
      position: absolute;
      z-index: -1;
      height: 40px;
      line-height: 40px;
      padding: 0 20px;
      font-size: 1.6rem;
      font-weight: bold;
      text-align: center;
    }

    h1 {
      display: none;

      & + hr {
        display: none;
      }
    }
  }
}

.checkout-payment-method {

  .accordion-payment {
    margin-bottom: 2rem;
  }

  .billing-address-same-as-shipping-block {
    margin-bottom: 0;
  }

  .payment-methods {
    margin: 0;
  }

  .payment-method {

    .braintree-credit-card-selected {
      height: 31px;
    }

    #braintree_expirationDate {
      width: 120px;
    }

    [id='braintree_cc_type_cvv_div'] {
      max-width: 100%;
    }

    [id='braintree_cc_cid'] {
      width: 100px;
    }

    [for='braintree_cc_number'] {

      @include media-breakpoint-up(md) {
        min-height: 58px;
      }
    }

    .hosted-control {
      padding-top: 7px;
      padding-bottom: 6px;
      border: $input-border-width solid $input-border-color;

      @include border-radius($input-border-radius, 0);

      &:focus-within {
        border-color: $black;
        box-shadow: $box-shadow-xl;
      }
    }

    @include media-breakpoint-up(sm) {
      max-width: 374px;
    }
  }

  .payment-method-title {
    border: none !important;
    padding: 8px 0 0;

    .label {
      position: relative;
      display: flex;
      align-items: center;
      height: 60px;
      width: 100%;
      padding: 0 2.3rem 0 6rem;
      border: 2px solid $light;
      border-radius: $border-radius;

      @include media-breakpoint-down(xs) {
        padding: 0 2rem 0 5.8rem;
      }

      &:hover {
        cursor: pointer;
      }

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        border-radius: 50%;
      }

      &::before {
        left: 2.3rem;
        height: 24px;
        width: 24px;
        border-color: inherit;
        border-width: 2px;
        border-style: solid;

        @include media-breakpoint-down(xs) {
          left: 2rem;
        }
      }

      span {
        margin-right: auto;
        line-height: 1;
        font-family: $headings-font-family;
        font-weight: 700;
        font-size: 1.5rem;
      }

      .payment-icon {
        order: 1;
        flex-shrink: 0;
        margin-left: auto;
        margin-right: 0;
      }

      svg {
        flex-shrink: 0;
        margin-left: 1rem;

        @include media-breakpoint-down(xs) {
          margin-left: 0.4rem;
        }
      }
    }

    .custom-control-input:checked ~ .label {
      border-color: $black;
      box-shadow: $box-shadow-xl;

      &::after {
        left: 3rem;
        width: 10px;
        height: 10px;
        background-color: $black;

        @include media-breakpoint-down(xs) {
          left: 2.7rem;
        }
      }
    }
  }

  .payment-method-content {
    padding-top: 20px;
    padding-left: 0;
    padding-right: 0;

    iframe#checkout-iframe {
      width: 100%;
      height: 268px !important;
    }
  }

  .actions-toolbar.actions-toolbar.actions-toolbar {
    margin-left: 0;

    .primary {
      float: none;
      width: 100%;

      .btn {
        width: 100%;
        margin: 0;
      }
    }
  }

  .billing-address-details.billing-address-details.billing-address-details {
    margin-bottom: 1.5rem;
    padding-left: 3rem;
    line-height: 2;
  }

  .field-tooltip-action {
    display: none;
  }
}

.form-shipping-address.form-shipping-address {

  @include media-breakpoint-down(xs) {

    ~ .d-flex .btn-primary {
      width: 100%;
    }
  }

  .field {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 1.3rem;

    .field {
      margin-bottom: 0;
    }

    .control {
      float: none;
      width: 100%;
      margin-top: -0.8rem;
    }

    .label {
      position: relative;
      z-index: 1;
      display: block;
      margin: 0 0.9rem;
      padding: 0 0.9rem;
      background: $white;
      font-family: $headings-font-family;
      font-weight: 600;
      width: auto;
      float: none;
      text-align: left;
    }

    &.street {

      label.label {
        height: 0;
      }

      .input-text {
        margin-top: 0.5rem;
      }
    }

    &.choice::before {
      width: 0;
      padding-right: 2rem;
    }
  }
}

.checkout-onepage-success .page-title-wrapper {
  text-align: center;
  margin-top: 40px !important;
}

.checkout-onepage-success {

  .action.print {
    display: none;
  }

  .checkout-success {
    max-width: 500px;
    margin: 30px auto 0 auto !important;
    text-align: center;

    .success-card {
      position: relative;
      top: 20px;
      border: 10px solid $yellow;
      border-radius: 8px;
      padding: 25px;
      text-align: center;
      margin-bottom: 70px;

      hr {
        margin-top: 0;
        margin-bottom: 3rem;
      }

      p:first-child {
        font-weight: bold;
        font-size: 1.8rem;

        a {
          color: $yellow;
          text-decoration: none !important;
        }
      }

      hr + p,
      hr + p + p {
        font-size: 1.5rem;
        margin-bottom: 5px;
      }
    }
  }
}
