.productList {

  .klevuProduct {

    [data-role='add-to-links'] {
      display: flex;
      margin-top: 1rem;
      margin-right: 3rem;
      justify-content: flex-end;
      gap: 1rem;

      .btn-action-secondary {

        .icon-label {
          display: none;
        }
      }

      &.in-wishlist .action.wishlist .add_wish_list {
        background: $orange;

        svg path {
          stroke: $white;
        }
      }
    }
  }
}

.kuSearchResultsPageContainer.kuSearchResultsPageContainer {

  .kuInfiniteScrollContainer .kuLoadMoreBtn {
    padding: 0.6rem 2.4rem;
    font-size: 1.2rem;
    line-height: 1.5;
    border-radius: 0.3rem;
    color: $black;
    border: 2px solid $black;
    background-color: $white;
    display: inline-block;
    width: auto;
    margin-top: 4rem;

    &:hover {
      background-color: $black;
      color: $white;
    }
  }
}
