.header-content-container {

  .block.block-search {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .search-wrapper {
      position: relative;

      .search-icon {
        right: 1rem;
        top: 2.2rem;
      }
    }
  }
}
