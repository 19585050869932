body[class*=cms-event-detail],
body[class*=cms-course-detail] {

  .columns {
    display: none;
  }
}

.event-detail {

  .event-thumbnail {
    height: 187px;
    border-radius: 3px;
    overflow: hidden;

    @include media-breakpoint-up(lg) {
      height: 274px;
    }
  }

  .event-title {
    font-weight: 700;
    font-size: 2.2rem;

    @include media-breakpoint-up(md) {
      font-size: 2.4rem;
    }
  }

  .event-location {
    align-items: flex-start;
    margin-bottom: 2rem;

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .step-content {
    padding: 2rem 0;
    line-height: 1.75;

    @include media-breakpoint-down(sm) {
      font-size: 1.5rem;
    }
  }

  .event-buy-container {
    display: flex;
    align-items: center;
    max-width: 434px;

    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .event-price {
    font-weight: 700;
    font-size: 2.2rem;

    @include media-breakpoint-up(md) {
      font-size: 2.4rem;
    }
  }
}
