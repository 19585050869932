@font-face {
  font-family: 'Inter';
  src:
    local('Inter Bold'),
    local('Inter-Bold'),
    url('../fonts/subset-Inter-Bold.woff2') format('woff2'),
    url('../fonts/subset-Inter-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src:
    local('Inter Regular'),
    local('Inter-Regular'),
    url('../fonts/subset-Inter-Regular.woff2') format('woff2'),
    url('../fonts/subset-Inter-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat Bold'),
    local('Montserrat-Bold'),
    url('../fonts/subset-Montserrat-Bold.woff2') format('woff2'),
    url('../fonts/subset-Montserrat-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat Semibold'),
    local('Montserrat-Semibold'),
    url('../fonts/subset-Montserrat-Semibold.woff2') format('woff2'),
    url('../fonts/subset-Montserrat-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat Light'),
    local('Montserrat-Light'),
    url('../fonts/subset-Montserrat-Light.woff2') format('woff2'),
    url('../fonts/subset-Montserrat-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
