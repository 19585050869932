.amlocator-index-index,
.amlocator-location-view {

  .breadcrumbs {

    @include media-breakpoint-down(sm) {
      margin-bottom: 0;
    }

    @include media-breakpoint-up(md) {
      margin-bottom: 1.2rem;
    }
  }
}

.amlocator-location-view {

  .page-wrapper {
    overflow-x: hidden;
  }
}

.amlocator-main-container {

  .amlocator-map-container {
    flex-direction: row;
    max-height: none;
    height: auto;
  }

  .amlocator-search-container {
    width: 100%;
    background-color: $primary;
    text-align: center;
    border-radius: $border-radius;

    @include media-breakpoint-down(xs) {
      width: auto;
      margin-left: -1.5rem;
      margin-right: -1.5rem;
      border-radius: 0;
    }

    h1 {
      font-weight: 700;
      font-size: 2.4rem;
    }
  }

  .amlocator-search-block {
    display: flex;
    align-items: center;
    max-width: 752px;

    @include media-breakpoint-down(xs) {
      flex-wrap: wrap;

      .amlocator-block,
      .btn {
        flex: 0 0 100%;
      }

      .amlocator-block {
        margin-bottom: 1rem;
      }
    }

    @include media-breakpoint-up(sm) {

      .amlocator-block {
        margin-bottom: 0;
      }

      .btn {
        flex: 0 0 118px;
        padding-left: 0;
        padding-right: 0;
      }
    }

    .search-icon {
      top: 50%;
      right: 1rem;
      transform: translateY(-50%);

      @include media-breakpoint-up(md) {
        right: 2rem;
      }
    }

    select.form-control {

      @include media-breakpoint-down(sm) {
        background-position: right 1rem center;
      }
    }
  }

  .amlocator-wrapper {
    margin-top: 0;

    @include media-breakpoint-down(sm) {
      max-height: none;
    }
  }

  .amlocator-results {
    display: flex;
    width: 100%;
    height: 840px;

    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
      height: auto;
    }

    .amlocator-block.-map {
      max-height: none;
      height: 100%;
      padding-left: 0;

      @include media-breakpoint-up(md) {
        width: 60%;
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
        order: 2;
        margin-top: 1rem;
      }
    }

    .amlocator-block.-storelist {

      @include media-breakpoint-up(md) {
        padding-left: 2.8rem;
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
  }

  .amlocator-store-desc {
    background: none;
    color: inherit;

    &:not(:last-child) {
      border-bottom: 1px solid $light;
    }

    .amlocator-block {
      margin: 0.2rem 0 2rem;
    }

    .amlocator-link.amlocator-link {
      text-decoration: none !important;
      color: inherit;

      &:hover {
        text-decoration: underline !important;
      }
    }
  }

  .store-marker {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 99px;
    border: solid 4px rgba($primary, 0.4);
    background-color: $primary;
    background-clip: content-box;
  }
}

.amlocator-location-main {

  .amlocator-block.-map {
    height: 333px !important;
  }

  .amlocator-location-info {
    display: flex;
    align-items: flex-start;
    max-width: 805px;
    margin: 0 auto 3rem;
  }

  .amlocator-info-container {
    padding-top: 5.7rem;

    .page-title-wrapper {
      margin-bottom: 3rem;
    }

    .amlocator-block {
      display: flex;
      align-items: center;

      .icon-container {
        margin: 0 1rem 0 0;
        width: 38px;
        height: 38px;
        background-color: $primary;
        background-clip: content-box;
        border: solid 3px rgba($primary, 0.4);
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .amlocator-text {
        display: block;
        line-height: 1;
      }
    }

    .am-phone,
    .am-email {

      a {
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .amlocator-store-hours {
    position: relative;
    z-index: 1;
    flex: 0 0 375px;
    margin-top: -7rem;
    margin-left: auto;

    .amlocator-store-hours-inner {
      padding: 3rem 2rem;
      background: $white;
    }

    @include media-breakpoint-down(sm) {
      margin-top: 1.5rem;
      flex: 0 0 100%;
      max-width: 100%;
    }

    h2 {
      margin-bottom: 1.5rem;
    }

    .amlocator-schedule-table {

      .amlocator-row {
        margin: 0;
        padding: 1rem 0;
        border-bottom: 1px solid $light;

        &:last-child {
          border-bottom: none;
        }

        .-day {
          text-align: left;
          font-weight: 700;
        }
      }
    }
  }

  .amlocator-location-gallery {
    flex-grow: 1;
    flex-basis: calc(100% - 375px);
    max-width: calc(100% - 375px);

    .amlocator-wrapper {
      height: auto;
      padding: 0;

      @include media-breakpoint-down(sm) {
        max-height: none;
      }
    }

    .slick-list {
      margin-bottom: 1.5rem;
      border-radius: 2px;
    }

    .slick-dots {
      max-width: 319px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    }

    .amlocator-image {
      height: auto;
    }
  }

  .amlocator-collapse-title {

    @include media-breakpoint-up(md) {
      padding-top: 1.6rem;
    }
  }

  .amlocator-location-reviews {
    overflow: visible;
    display: block !important;
    height: auto !important;
  }

  .amlocator-reviews-wrapper {
    flex: 0 0 50%;
    margin-left: auto;
    text-align: center;
    max-width: 375px;

    .rating-summary {
      margin-bottom: 1.7rem;
    }

    .amlocator-name {
      margin-bottom: 1rem;
      font-size: 1.8rem;
    }

    .slick-prev {
      left: -2rem;
    }

    .slick-next {
      right: -2rem;
    }
  }

  .amlocator-location-review {

    .amlocator-wrapper {
      padding: 0 30px;
    }

    h3 {
      margin-bottom: 1.1rem;
      font-weight: 700;
      font-size: 1.8rem;
    }

    .amlocator-message {
      font-size: 1.5rem;
    }
  }

  .amlocator-description {
    position: relative;
    max-width: 600px;
    margin: 5rem auto;
    padding: 6rem $spacer;
    text-align: center;
    line-height: 1.6;
    font-size: 1.5rem;

    p:last-child {
      margin-bottom: 0;
    }

    @include media-breakpoint-up(md) {

      &,
      &::before,
      &::after {
        background-color: $lighter;
      }

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        width: 100vw;
        height: 100%;
      }

      &::before {
        right: 100%;
      }

      &::after {
        left: 100%;
      }

      h2 {
        margin-bottom: 3.2rem;
      }
    }

    @include media-breakpoint-down(sm) {
      margin: 0 auto 5rem;
      padding: 0;
      text-align: left;
    }
  }

  @include media-breakpoint-down(md) {

    .amlocator-location-info {
      flex-wrap: wrap;
      max-width: 100%;
      margin-bottom: 1.5rem;
      padding-top: 2.1rem;
    }

    .amlocator-location-gallery,
    .amlocator-reviews-wrapper {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .amlocator-reviews-wrapper {
      margin-bottom: 3rem;
    }

    .amlocator-location-gallery {
      order: 1;
      padding-left: 0;
      padding-right: 0;
    }
  }

  @include media-breakpoint-down(sm) {
    display: flex;
    flex-direction: column;

    .amlocator-info-container {
      padding-top: 3.5rem;
    }
  }
}

.teds-events-banner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 250px;
  margin-bottom: 1rem;
  border-radius: 3px;
  overflow: hidden;
  text-align: center;
  font-family: $headings-font-family;
  font-weight: 700;
  font-size: 2.4rem;
  color: $primary;

  img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
  }

  a::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $black;
    z-index: -1;
    opacity: 0.4;
  }

  p {
    margin-bottom: 1.2rem;

    &:first-child::before {
      content: '';
      display: block;
      margin: 0 auto 1.7rem;
      background-image: url('../images/teds-events.svg');
      background-size: 157.07px 80.99px;
      background-repeat: no-repeat;
      background-position: center;
      width: 157.07px;
      height: 80.99px;
    }
  }

  @include media-breakpoint-down(sm) {
    height: 333px !important;

    p {
      margin-bottom: 2.2rem;
      max-width: 280px;
      line-height: 1.3;

      &:first-child::before {
        margin-bottom: 2.7rem;
      }
    }
  }
}

.am-mobbanner {
  order: -2;
}

.am-content {

  @include media-breakpoint-down(sm) {
    order: -1;
  }
}

.amlocator-info-popup {

  .amlocator-name {
    margin-bottom: 1.5rem;
    font-weight: 700;

    .amlocator-link {
      text-decoration: none !important;

      &:hover {
        text-decoration: underline !important;
      }
    }
  }

  .amlocator-image {
    display: none;
  }
}

.holiday-hours {
  padding-top: 2.5rem;
  border-top: 1px solid $light;
  font-weight: 700;
  color: $orange;

  h3 {
    margin-bottom: 1rem;
    color: $secondary;
  }
}

.holiday-link {
  margin-top: 2.9rem;

  @include media-breakpoint-up(md) {
    margin-top: 2rem;
  }

  a {
    display: block;
  }

  img {
    width: 100%;
  }
}
