.review-form-wrapper {

  .review-form {
    padding: 20px;
  }

  .review-legend.legend {
    font-weight: 700;

    strong {
      color: $teal;
      display: inline;
    }
  }

  .review-intro {
    font-size: 15px;
  }

  fieldset legend h4 {
    font-weight: 700;
  }

  .field small {
    font-size: 1.1rem;
  }

  .review-field-ratings .review-field-rating {

    .label {
      font-size: 1.5rem;
      font-weight: 700;
    }

    .control {
      padding-top: 8px;
    }
  }

  .review-field-pros,
  .review-field-cons {

    label {
      width: 100%;
    }
  }

  .review-field-recommendation {

    > label {
      font-size: 1.3rem;
    }

    .control > label {
      margin-right: 30px;
    }
  }

  @include media-breakpoint-down(md) {

    button.showReviews {
      padding: 6px 66px;
      font-size: 1.2rem;
    }
  }
}

.review-list {

  .review-title {
    margin-bottom: 5px;
    font-weight: bold;
    font-family: $headings-font-family;
  }

  .review-ratings ~ .review-content,
  .review-ratings ~ .review-details {
    margin-left: 0;
  }

  .review-by,
  .review-date {
    font-size: 1.1rem;
  }

  .review-pros,
  .review-cons,
  .review-recommendation,
  .review-content {
    font-size: 1.5rem;
  }

  .review-pros,
  .review-cons {

    strong {
      min-width: 70px;
      display: inline-block;
      font-family: $headings-font-family;
    }
  }

  .review-recommendation strong {
    display: inline-block;
    padding-right: 12px;
    font-family: $headings-font-family;
  }

  .review-ratings .rating-label {
    font-weight: 700;
    font-size: 1.5rem;
    font-family: $headings-font-family;
  }

  .review-items {
    padding-left: 0;
    padding-right: 0;

    .review-ratings-holder {
      padding-left: 0;
    }

    .review-details {
      padding-right: 0;
    }
  }

  @include media-breakpoint-down(md) {

    .review-items {

      .review-ratings-holder,
      .review-details {
        padding-left: 0;
        padding-right: 0;
      }

      .review-details {
        margin-bottom: 8px;
      }
    }
  }
}

.rating-result.rating-result {
  width: 122px;
  margin-left: 0;
  height: 17.122px;

  &,
  span {

    &::before {
      content: '' !important;
      display: block;
      letter-spacing: 0;
      font-size: 2.4rem;
      height: 17.122px;
      padding: 2px 0;
      white-space: nowrap;
      background-image: url(../images/star-fill.svg);
      background-size: contain;
      background-repeat: repeat-x;
      background-position: left center;
    }
  }

  &::before {
    z-index: 0;
    width: 100%;
    opacity: 0.5;
    background-image: url(../images/star-no-fill.svg);
  }

  span::before {
    z-index: 1;
  }
}

.review-control-vote {

  &,
  label {

    &::before {
      content: '' !important;
      display: block;
      font-size: 2.4rem;
      height: 17.122px;
      padding: 2px 0;
      line-height: 24.5px;
      letter-spacing: 9px;
      text-indent: 2.4px;
      white-space: nowrap;
    }
  }

  &::before {
    content: '';
    background-image: url(../images/star-no-fill.svg);
    background-size: 25px 17.122px;
    background-repeat: repeat-x;
    background-position: left center;
    width: 125px;
  }

  label::before {
    background-image: url(../images/star-fill.svg);
    background-size: 25px 17.122px;
    background-repeat: repeat-x;
    background-position: left center;
    color: $white;
  }

  .rating-1::before { width: 25px; }
  .rating-2::before { width: 50px; }
  .rating-3::before { width: 75px; }
  .rating-4::before { width: 100px; }
  .rating-5::before { width: 125px; }
}

.rating-summary .rating-result > span,
.review-control-vote label {

  &::before {
    font-size: 2.4rem;
  }
}
