.product.data.items {
  z-index: auto;

  &::before,
  &::after {
    display: none;
  }

  p {
    width: 100% !important;
  }

  iframe {
    max-width: 100%;
  }

  @include media-breakpoint-up(md) {

    > .item.title {
      width: 20%;
      margin: 0;
      padding: 0 2px;

      > .switch,
      > .switch:visited {
        height: 60px;
        line-height: 44px;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        color: $black;
        background-color: $light;
        width: 100%;
        border: none;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        padding: 8px 20px;
        z-index: auto;
        text-decoration: none !important;
      }

      &.active > .switch,
      &:not(.disabled) > .switch:hover,
      &:not(.disabled) > .switch:focus {
        background-color: $black;
        color: $white;
      }

      &:first-child {
        padding-left: 0;
      }

      &:nth-last-child(2) {
        padding-right: 0;
      }
    }

    > .item.content {
      margin-top: 60px;
      border-top: 3px solid $black;
    }
  }

  @include media-breakpoint-down(sm) {

    > .item.title {
      margin-left: -$grid-gutter-width / 2;
      margin-right: -$grid-gutter-width / 2;
      width: auto;

      > .switch,
      > .switch:visited {
        background: $white;
        border: none;
        border-bottom: solid 1px $light;
        color: $black;
        padding: 8px 20px;
        position: relative;
        height: auto;
        font-size: 1.5rem;
        line-height: 2rem;
        text-decoration: none !important;

        &:hover {
          text-decoration: underline !important;
        }

        &::after {
          position: absolute;
          content: ' ';
          right: 30px;
          top: 9px;
          background-image: url('../images/plus.svg');
          width: 10px;
          height: 10px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
        }
      }

      &.active > .switch,
      &:not(.disabled) > .switch:hover,
      &:not(.disabled) > .switch:focus {
        padding: 8px 20px;
        color: $black;

        &::after {
          background-image: url('../images/minus.svg');
        }
      }
    }

    > .item.content {
      padding: 15px 5px 15px;
      border: none;

      &[id='reviews'] {
        overflow-x: hidden;
        margin-left: -1.5rem;
        margin-right: -1.5rem;
        padding-left: 2rem;
        padding-right: 2rem;
      }
    }
  }
}

.product-info-main {

  @include media-breakpoint-down(sm) {
    margin-top: 1rem;
  }

  .page-title {
    font-size: 1.8rem;

    @include media-breakpoint-up(lg) {
      font-size: 3.4rem;
    }
  }

  .price-box {

    @include media-breakpoint-down(xs) {
      margin-top: 0;
    }
  }

  .product-reviews-summary {

    @include media-breakpoint-down(xs) {
      position: relative;
      z-index: 1;
      display: block;
      margin: 0 -1.5rem 0;
      padding: 0 1.8rem;
      background: $white;
    }
  }

  .box-tocart {
    display: block;
    margin-top: 0;

    .fieldset {
      margin: 0;

      &,
      .product-addto-links {
        display: flex;
      }

      .product-addto-links {
        margin-top: 0;
        margin-bottom: 0;

        @include media-breakpoint-up(lg) {
          margin-right: 1.8rem;
        }
      }
    }

    .learn-more {
      margin-top: 1rem;
    }

    .actions {
      flex-grow: 1;
      padding-top: 0;
    }

    .btn-primary {

      @include media-breakpoint-only(xs) {
        padding-left: 1.3rem;
        padding-right: 1.3rem;
      }
    }

    .btn-action-secondary {
      margin-left: 1.5rem !important;

      @include media-breakpoint-up(sm) {
        margin-left: 1.8rem !important;
      }

      @include media-breakpoint-up(lg) {
        margin-left: 2.5rem !important;
      }
    }
  }

  .special-price {
    margin: 0;
  }

  .product-info-price {

    @include media-breakpoint-down(xs) {
      position: sticky;
      top: 0;
      margin-left: -1.5rem;
      margin-right: -1.5rem;
      padding: 0 1.5rem 0.1rem;
      background: $white;
      box-shadow: 0 7px 7px rgba($black, 0.12);
      z-index: 30;
    }
  }

  .product-payment-methods {
    display: flex;
    flex-wrap: wrap;

    @include media-breakpoint-down(xs) {
      margin: 0 -1.5rem;
    }

    @include media-breakpoint-up(sm) {
      margin: 1rem 0 0;
      padding: 1rem 0;
      border-top: 1px solid $light;
      border-bottom: 1px solid $light;
      text-align: center;
    }

    .product-payment-method {
      flex: 0 0 100%;
      display: flex;

      p button {
        display: inline;
        font-size: inherit;
        font-family: inherit;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }

      &:empty {
        display: none;
      }

      @include media-breakpoint-down(xs) {
        align-items: center;
        justify-content: center;
        padding: 8px;
        border-top: 1px solid $light;

        &:last-child {
          border-bottom: 1px solid $light;
        }

        svg {
          flex-shrink: 0;
          margin-right: 1rem;
        }

        p {
          margin-bottom: 0;

          ~ a {
            margin-left: 0.5rem;
          }
        }
      }

      @include media-breakpoint-up(sm) {
        flex: 0 0 50%;
        flex-direction: column;
        align-items: center;

        &:first-child {
          border-right: 1px solid $light;
        }

        p {
          max-width: 180px;
          margin: auto auto 0;
          padding-top: 10px;
        }
      }

      span span:first-child {
        font-weight: 700;
      }
    }
  }
}

.product.info.detailed {

  &::before {

    @include media-breakpoint-down(xs) {
      content: 'Product information';
      display: block;
      text-align: center;
      background: $black;
      color: $white;
      height: 40px;
      line-height: 40px;
      font-family: $headings-font-family;
      font-weight: 700;
      font-size: 16px;
      margin-left: -1.5rem;
      margin-right: -1.5rem;
      margin-bottom: 0.5rem;
    }
  }

  &::after {
    content: '';
    display: table;
    clear: both;
  }

  .additional-attributes {
    width: 100%;

    > tbody > tr > th,
    > tbody > tr > td {
      border: solid 1px $gray-200;
      font-size: 13px;
      padding: 8px 15px;
    }

    > tbody > tr > th {
      border-right: none;
    }

    > tbody > tr > td {
      border-left: none;
    }

    tr:nth-child(odd) {

      th,
      td {
        background-color: rgba($light, 0.2);
      }
    }
  }
}

.catalog-product-view .product.short-description {

  @include media-breakpoint-down(sm) {
    order: 0;
  }
}

.header-after.product-advertlist {
  border-bottom: none;

  @include media-breakpoint-down(md) {
    margin: 1.5rem 0;
    padding: 1.5rem;
    border-top: 1px solid $light;
    border-bottom: 1px solid $light;
  }

  @include media-breakpoint-down(xs) {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  ul {

    @include media-breakpoint-down(lg) {
      flex-wrap: wrap;
    }

    li {
      display: block;
      text-align: center;

      @include media-breakpoint-up(xl) {
        flex: 0 0 25%;
      }

      @include media-breakpoint-down(lg) {
        width: 50%;
        margin-top: 10px;
        margin-bottom: 10px;
        padding-left: 20px;
        padding-right: 20px;
        line-height: 1.45;
        font-size: 1.5rem;
      }

      a::before {
        display: block;
        margin: 5px auto;
      }

      a {
        text-decoration: none !important;
        flex-direction: column;

        &:hover {
          text-decoration: underline !important;
        }
      }
    }
  }
}

.catalog-product-view .block.related {
  clear: both;
}

.request-price-match-holder {

  a:hover {
    cursor: pointer;
  }

  @include media-breakpoint-down(sm) {
    border-bottom: 1px solid $light;
  }

  @include media-breakpoint-down(xs) {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
}

.grid-relateds {

  @include media-breakpoint-down(sm) {
    margin-top: 3rem;
    margin-bottom: 2rem;
  }

  .block-title {
    background-color: $black;
    color: $white;
    text-align: center;

    @include media-breakpoint-down(xs) {
      margin-left: -1.5rem;
      margin-right: -1.5rem;
    }

    h2 {
      padding: 1.05rem;
      font-weight: 700;
      font-size: 1.6rem;
    }
  }

  .products {
    max-width: 780px;
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-down(xs) {
      margin-bottom: 1.5rem;

      .products {
        margin-bottom: 0;
      }
    }
  }

  .product-item-info {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .product-item-details {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .product-item-actions {
    margin-top: auto;
    margin-bottom: 2rem;

    @include media-breakpoint-down(xs) {
      margin-bottom: 1rem;
    }
  }

  .price-box {
    margin-bottom: 0;
  }

  .price {
    display: block;
    text-align: center;
  }

  .pricing.pricing {
    flex-grow: 1;
    padding-right: 0;
  }

  .actions-secondary {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }

  .product-item,
  .product-item-info {
    height: 100%;
  }

  @include media-breakpoint-down(xs) {

    .product-item-photo {
      margin-top: 5rem;
    }
  }
}

.bundle-options-container .product-add-form {
  display: block;

  .bundle-options-wrapper {
    float: none;
    width: 100%;

    .fieldset {
      outline: none !important;
      margin-bottom: 0;
    }

    .custom-control.custom-radio {

      .custom-control-label {
        font-weight: 700;
      }

      .custom-control-label::after {
        content: '';
        margin: 0;
      }

      .custom-control-label::before,
      .custom-control-label::after {
        top: 10px;
      }
    }
  }

  .product-options-wrapper {
    float: none;
    width: 100%;
    outline: none !important;
    margin-bottom: 0;

    .field.option {

      .qty-holder {
        display: none;
      }
    }

    p.required {
      display: none;
    }

    .custom-control-label {

      &::after,
      &::before {
        top: 0;
      }
    }
  }

  .block-bundle-summary {
    float: none;
    width: 100%;
    top: 0 !important;
    background-color: transparent;
    padding: 0;

    & > .title {
      display: none;
    }

    .product-image-container {
      display: none;
    }

    .product-details .product.name {
      display: none;
    }

    .bundle-summary {
      display: none;
    }

    .available.stock {
      display: none;
    }
  }
}

.bundle-actions {
  display: none;

  #bundle-slide {
    display: none;
  }
}

.product-options-wrapper {

  .field.configurable {

    label.label {
      margin: 0 0.9rem !important;
      padding: 0 0.9rem !important;
    }

    .control {
      width: 100% !important;
    }
  }
}

// Overrides for the view page (see categories styling for the product recommendations)
.catalog-product-view {

  .products-wrapper.products-grid {

    .simple-product-wrapper {
      width: 100%;
    }
  }
}

.product-promo-container,
.product.attribute.overview {

  .bluefoot-row.row.bluefoot-entity,
  .bluefoot-wrapper.container.row {
    margin-left: 0;
    margin-right: 0;
  }

  .bluefoot-wrapper {
    margin-bottom: 1.5rem;
  }
}

.cash-back-offer-wrapper {

  img {
    display: block;
  }
}

.ampickupmsi-main-container {
  background: $white !important;
  border: 10px solid $yellow;
  border-radius: 8px;
  padding-left: 0;
  padding-right: 0;

  .ampickupmsi-location {
    border-bottom: 1px solid $gray-300;

    &:hover {
      background: $gray-500 !important;
    }

    &:last-child {
      border-bottom: 0;
    }
  }
}

@include media-breakpoint-up(xl) {

  .product-info-main.product-sticky {

    .page-title-wrapper.product,
    .product-info-price {
      position: fixed;
      top: 0;
      z-index: 99;
      // background-color: $white;
      // border-bottom: 1px solid #00000029;
      min-height: 52px;
    }

    .page-title-wrapper.product::before {
      content: ' ';
      display: block;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100vw;
      position: absolute;
      background-color: $white;
      border-bottom: 1px solid #00000029;
    }

    .page-title-wrapper.product {
      left: 0;
      width: 50vw;
      padding-left: calc((100vw - 1190px) / 2);

      .page-title {
        margin: 2px 0;
        font-size: 3rem;
        position: relative;
        z-index: 1;
      }
    }

    .product-info-price {
      right: 0;
      width: 50vw;
      display: flex;
      justify-content: flex-end;
      padding-right: calc((100vw - 1190px) / 2);

      body.page-product-bundle & {
        justify-content: flex-start;
      }

      .price-box {
        margin: 2px 0;

        .price-label {
          display: none;
        }

        [data-price-type='oldPrice'] {
          display: none;
        }

        .standard-price:not(.strikethrough-price) [data-price-type='oldPrice'] {
          display: inline;
        }

        .price-from,
        .price-to {
          margin: 3px 0;

          .price-label {
            display: inline;
          }
        }
      }

      .product-add-form .box-tocart {
        display: flex;
        flex-direction: row;
        margin: 2px 0;

        .input-text.qty {
          height: auto;
        }

        .field.qty {
          display: flex;
        }

        .learn-more {
          display: none;
        }

        .action-buttons {
          display: flex;

          .product-addto-links {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 0;

            .action {

              span {
                display: none;
              }
            }
          }
        }
      }
    }

    body.page-product-bundle & {

      .product-info-price .price-box {
        display: none;
      }

      .price-box.price-configured_price {
        position: fixed;
        top: 0;
        z-index: 99;
        min-height: 52px;
        right: 0;
        width: 50vw;
        display: flex;
        justify-content: flex-start;
        padding-right: calc((100vw - 1190px) / 2);
        margin: 0;

        .price {
          font-size: 3rem;
          line-height: 1.5;
          vertical-align: middle;
        }
      }

      .box-tocart {
        position: fixed;
        top: 0;
        z-index: 99;
        min-height: 52px;
        right: 0;
        width: 30vw;
        display: flex;
        justify-content: flex-end;
        padding-right: calc((100vw - 1190px) / 2);
        padding-top: 2px;
        padding-bottom: 2px;
        margin: 0;

        .field.qty .input-text {
          height: 48px;
        }

        .actions {
          margin-bottom: 0;
        }

        .action-buttons {
          display: flex;

          .product-addto-links {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 0;

            .action {

              span {
                display: none;
              }
            }
          }
        }
      }
    }

    body.page-product-configurable & {

      .product-info-price .price-box {

        .price-label {
          display: none !important;
        }

        .price {
          margin-right: 20px;
        }
      }

      .product-options-wrapper {
        height: 0;
        overflow: hidden;
      }
    }
  }
}

@include media-breakpoint-up(xl) {

  body.page-product-bundle {

    .product-info-main:not(.product-sticky) {

      .price-box.price-configured_price {
        display: none !important;
      }
    }
  }
}

@include media-breakpoint-down(lg) {

  body.page-product-bundle {

    .price-box.price-configured_price {
      display: none !important;
    }
  }
}

@include media-breakpoint-down(xs) {

  body.page-product-bundle {

    .product-info-price {
      position: static;
      box-shadow: none;
    }
  }
}

.discontinued-product-holder {

  .disc-title {
    font-weight: bold;
    font-size: 1.4rem;
    margin-left: auto;
    padding: 1rem;
    border-radius: 15px;
    background-color: $light;
    z-index: 1;
    position: relative;

    @include media-breakpoint-up(lg) {
      text-align: right;
      margin-top: -7rem;
      width: 65%;
      font-size: 1.8rem;
    }
  }

  .img-holder {
    text-align: center;

    img {
      max-width: 300px;
    }
  }

  .rep-title {
    font-weight: bold;

    a {
      text-decoration: none;
    }
  }

  .pricing {
    font-weight: bold;
    font-size: 1.8rem;

    @include media-breakpoint-up(lg) {
      font-size: 2.4rem;
    }
  }
}

@include media-breakpoint-up(xl) {

  .stick-tab-title-holder {
    position: fixed;
    top: 52px;
    z-index: 300;
    padding-top: 10px;
    left: 0;
    right: 0;
    background-color: $white;
    box-shadow: 0 3px 6px #00000029;

    a.tab-link {
      font-size: 1.6rem;
      line-height: 40px;
      font-weight: 700;
      display: inline-block;
      width: 232px;
      height: 40px;
      border-radius: 3px 3px 0 0;
      margin-right: 5px;
      text-decoration: none !important;
      text-align: center;

      &:hover,
      &.active {
        background-color: $black;
        color: $white;
      }
    }
  }
}

@include media-breakpoint-down(lg) {

  .stick-tab-title-holder {
    display: none !important;
  }
}

.product_recommendations_below-main-content {
  display: block;

  .grid-relateds {
    display: block;

    .products {
      justify-content: center !important;
      max-width: 1000px;

      > div {
        width: 25%;

        @include media-breakpoint-down(lg) {
          width: 50%;
        }

        @include media-breakpoint-down(xs) {
          width: 70%;
        }

        .simple-product-wrapper {
          width: 100%;
        }
      }
    }
  }
}

.humm-price-info-widget .humm-description .humm-more-info.humm-more-info {

  @include media-breakpoint-only(xs) {
    font-size: 1.3rem;
  }
}

.studio19-wrapper div {
  font-size: inherit !important;
}
