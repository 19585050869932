.size-container {
  width: 100%;
  border: 0;
  margin: auto;
  border-radius: 4px;

  .text-bold {
    font-weight: 500;
  }

  h3 {
    font-size: 30px;
    font-weight: 700;
    color: #21285e;
    margin: 0;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  @include media-breakpoint-only(xs) {

    iframe {
      margin: 0 -3rem;
      width: calc(100% + 6rem);
    }
  }
}

.top-logo-container {
  text-align: center;
  width: 100%;

  img {
    width: 170px;
  }
}

.usp-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 60px;
  padding-bottom: 80px;

  .info-snippet-container {
    display: flex;
    flex-direction: column;
    max-width: 200px;
    align-items: flex-start;
    margin-right: 25px;
    margin-left: 25px;
  }

  @include media-breakpoint-down(sm) {
    flex-wrap: wrap;

    .info-snippet-container {
      max-width: 100%;
      width: 100%;
      margin-top: 2rem;
    }
  }
}

.usp-text-container,
.usp-img-container {
  width: 100%;
  text-align: center;
}

.usp-img-container img {
  width: 38px;
}

.zm-icon {
  margin-right: 6px;
}

.usp-text {
  font-size: 17px;
  font-weight: 400;
}

.flex-row-centered {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.margin-right-1 {
  margin-right: 24px;
}

.footer-img-container {
  height: 20px;

  img {
    height: 100%;
  }
}

.terms-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
}

.terms {
  font-size: 12px !important;
  text-align: center;
  max-width: 600px;
}
