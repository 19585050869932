.box-tocart .qty .control {
  display: flex;
  align-items: center;

  @include media-breakpoint-down(xs) {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
}

input.qty.qty.qty.qty {
  height: auto;
  width: 30px;
  margin: 0 0.3rem;
  padding-left: 0;
  padding-right: 0;
  border: none;
  text-align: center;
  font-weight: 700;
  font-size: 1.3rem;
  color: $secondary;

  @include media-breakpoint-down(xs) {
    margin: 0 1rem;
  }

  &:focus {
    box-shadow: $box-shadow;
  }
}

.block-minicart {

  &.block-minicart,
  > div,
  .block-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;

    // I know I know, I hate this selector too....
    &[style='display: block;']:not([hidden]) {
      display: flex !important;
    }
  }

  .minicart-items-wrapper {
    flex-grow: 1;
    margin: 0;
    padding: 1.5rem 0;
    border: none;
  }

  .minicart-items {
    padding: 0 2rem;

    .product-item {
      flex-wrap: wrap;

      > .product {
        width: 100%;
      }

      &:not(:first-child) {
        border-top-color: $light;
      }

      .product-image-photo {
        margin-left: 0;
      }

      .message {
        width: 100%;
        margin-bottom: 0;
      }
    }

    .product-item-details {
      position: relative;
      padding-left: 78px;

      .minicart-price:hover {
        cursor: default;
      }

      .price {
        font-size: 1.3rem;
        white-space: nowrap;
      }

      .details-qty {
        display: flex;
      }

      .product.options .content {
        display: block !important;
      }
    }
  }

  .minicart-footer {
    padding: 0 2rem 2rem;
    line-height: 1.6;

    a:not(.btn) {
      text-transform: capitalize;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .subtotal {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 2rem 1rem;
    padding: 3rem 0 0;
    border-top: 1px solid $light;
    font-family: $headings-font-family;
    font-weight: 700;
    font-size: 2.2rem;

    .label,
    .price.price.price {
      font-size: inherit;
    }

    .label::after {
      display: none;
    }

    .price {
      margin-left: 0.5rem;
    }
  }

  .action.delete {
    position: absolute;
    top: 54px;
    right: 0;

    @include media-breakpoint-down(xs) {
      top: 55px;
    }
  }
}

.product-item-name.product-item-name {
  margin-top: 0.5rem;
  margin-bottom: 0;

  a {
    padding-left: 0;
    color: inherit;
    font-size: 1.3rem;
    text-decoration: none !important;

    &:hover {
      text-decoration: underline !important;
    }
  }
}

.checkout-cart-index {

  .page-header {
    border: none;

    &::before,
    &::after {
      display: none !important;
    }
  }

  .page-title-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    margin-bottom: 4.3rem;

    h1 {
      position: relative;
      z-index: 1;
      font-weight: 700;
      font-size: 2.4rem;
    }

    @include media-breakpoint-down(md) {
      height: 40px;
      margin: 0.4rem auto 2rem;

      h1 {
        font-size: 1.6rem;
      }
    }
  }

  .cart-summary,
  .form-cart {
    position: static;
    float: none;
    width: 100%;
  }

  .message.message {
    margin-bottom: 2rem;
  }
}

.cart-pre-actions {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  max-width: 1920px;
  width: 100%;
  height: 80px;
  margin: 0 auto;
  padding: 0 2rem;
  align-items: center;
  background-color: $primary;

  @include media-breakpoint-down(md) {
    height: 40px;

    svg {
      margin-top: -0.1rem;
      margin-left: -1.5rem;
      width: 28px;
      height: 28px;
    }
  }

  .btn-link {
    display: flex;
    align-items: center;
    color: inherit;
    font-family: $font-family-base;
    font-size: 1.3rem;

    &:hover {
      color: $secondary;
    }
  }

  .btn-primary {
    width: 100%;
    max-width: 221px;
  }
}

.cart-container {
  display: flex;

  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
  }

  .form-cart {

    @include media-breakpoint-up(md) {
      width: auto;
      flex-grow: 1;
    }
  }

  .cart {

    &.item.item.item {
      border: none;
    }

    tbody tr {

      @include media-breakpoint-down(sm) {
        border-bottom: 1px solid $light;
      }
    }

    thead + tbody td {
      border-top: none;
    }

    tr:first-child td {

      @include media-breakpoint-down(sm) {
        min-height: 0 !important;
      }
    }

    .product-item-photo {
      max-width: 60px;

      @include media-breakpoint-up(md) {
        max-width: 80px;
      }
    }

    .product-item-name {

      @include media-breakpoint-down(sm) {
        display: block;
        margin-top: 0.5rem;
      }
    }

    .item-options.item-options {
      margin-top: 0;
      margin-bottom: 0;
    }

    .col {

      @include media-breakpoint-down(sm) {
        border: none;
      }

      &[data-th]::before {
        display: none !important;
      }

      &:not(.item) {
        width: 1px;
        padding-left: 3rem;
      }

      &.qty {

        @include media-breakpoint-down(sm) {
          padding-top: 1rem;
          padding-bottom: 1.5rem;
          padding-left: 7.5rem;
        }

        .control {

          @include media-breakpoint-up(md) {
            padding-top: 2.5rem;
          }
        }
      }

      &.price {

        @include media-breakpoint-down(sm) {
          padding: 0;
        }
      }

      &.item-actions {

        @include media-breakpoint-down(sm) {
          float: right;
          display: flex;
          margin-top: 1rem;
          padding-top: 0;
          padding-right: 0;
          padding-left: 0 !important;
          width: auto;
          align-items: center;

          .price-excluding-tax {
            padding-top: 1rem;
            padding-right: 3.5rem;
          }
        }
      }
    }

    .product-item-details {

      @include media-breakpoint-up(md) {
        padding-top: 2rem;
      }
    }

    .price-excluding-tax {

      @include media-breakpoint-up(md) {
        padding-top: 3.1rem;
      }
    }

    .action-delete {

      @include media-breakpoint-up(md) {
        margin: 2.5rem auto 0;
      }

      @include media-breakpoint-down(sm) {
        margin: 0 auto;
        float: right;
      }
    }

    .price,
    .subtotal {

      .price {
        font-size: 1.3rem;
      }
    }

    .price .price {
      font-weight: normal;
    }

    .qty.qty.input-text {
      height: 24px;
      width: 30px;
      margin-top: 0;
    }

    .price-including-tax,
    .price-excluding-tax {

      @include media-breakpoint-up(md) {
        padding-top: 3.1rem;
      }
    }
  }
}

.cart-summary.cart-summary {
  order: 1;
  width: 100%;
  background: none;
  padding-right: 1rem;

  @include media-breakpoint-down(md) {
    margin-top: 3rem;
    padding-left: 1rem;
  }

  @include media-breakpoint-up(lg) {
    width: 380px;
    margin-left: 2.4rem;
  }

  .opc-sidebar & {
    padding: 0;
  }

  .block .title {
    padding: 0;

    &::after {
      display: none;
    }
  }

  .block.shipping {
    display: none;
  }

  .summary.title,
  .cart-totals {
    position: relative;
    margin: 0;

    &::before {
      content: '';
      position: absolute;
      background-color: $primary;
      left: -10px;
      right: -10px;
      z-index: -1;

      .opc-sidebar & {
        display: none;
      }
    }
  }

  .summary.title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    background-color: $black;
    color: $white;
    font-weight: 700;
    font-size: 1.3rem;

    &::before {
      top: -10px;
      bottom: 0;
      border-radius: 8px 8px 0 0;
    }
  }

  .cart-totals {
    margin-bottom: 2.4rem;
    border-top: none;
    background-color: $white;

    &::before {
      top: 0;
      bottom: -10px;
      border-radius: 0 0 8px 8px;
    }

    .table {
      width: calc(100% - 40px);
      margin: 0 20px;
    }

    .mark {
      background: none;
    }

    tr:nth-last-of-type(2) {

      td,
      th {
        padding-bottom: 20px;
      }
    }

    th,
    td {
      border: none;
      padding: 0.65rem 0;
      font-size: 1.5rem;
    }

    .sub {

      th,
      td {
        padding-bottom: 0;
      }
    }

    .totals-tax {

      th,
      td {
        padding-bottom: 1.6rem;
      }
    }

    .totals.discount {
      color: $orange;
    }

    .grand {
      font-family: $headings-font-family;

      th,
      td {
        border-top: 1px solid $light;
        padding-bottom: 1.6rem;
        padding-top: 1.6rem;
        font-size: 1.8rem;
      }

      strong {
        font-weight: 700;
      }

      small {
        display: block;
        line-height: 1;
        font-family: $font-family-base;
        font-size: 1.2rem;
        color: $dark;
      }
    }
  }
}

.cart-summary-container {
  list-style: none;
  padding-left: 0;

  li {
    margin-bottom: 0;
  }

  .content {
    display: block !important;
  }

  .btn-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.3rem;

    svg {

      &:last-child {
        display: none;
      }
    }

    &:not(.collapsed) svg {
      display: none;

      &:last-child {
        display: block;
      }
    }

    @include media-breakpoint-down(md) {
      font-size: 1.5rem;
    }

    @include media-breakpoint-down(xs) {
      width: calc(100% + 3rem);
      margin-left: -1.5rem;
      margin-right: -1.5rem;
      padding-left: 1.8rem !important;
      padding-right: 1.8rem !important;
    }
  }
}

.cart-empty {
  text-align: center;

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.action {

  &.delete,
  &.action-delete {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;

    svg {
      transform: rotate(45deg);
    }
  }
}

.coupon {
  margin-left: 0 !important;

  .input-text {
    height: 47px;
  }

  div.mage-error {
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 0 !important;
  }

  .btn-secondary {
    width: 134px;
  }
}

.giftcard .delete {
  width: auto;
  justify-content: flex-start;
}

.add-to-cart-popup.add-to-cart-popup {

  .modal-header {
    padding: 0;
  }

  .action-close {
    position: absolute;
    top: 0.4rem;
    right: 0.4rem;
    z-index: 1;
    outline: 0;

    &::before {
      width: 28.28px;
      height: 28.28px;
      background-size: contain;
      transform: none;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28.284' height='28.284'%3E%3Cg fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'%3E%3Cpath d='M7.072 21.213 21.214 7.071M7.071 7.07l14.142 14.142'/%3E%3C/g%3E%3C/svg%3E");
    }
  }

  .modal-inner-wrap {
    border-radius: 1rem;
    height: auto;
    max-height: calc(100% - 10rem);
    max-width: 1150px;
    width: 90%;
    transform: translateY(-50%);
    top: 50%;
    margin: auto;
  }

  .modal-content {
    position: relative;
    padding: 0;
    overflow-y: auto;
    border-radius: 1rem;
  }
}

.recommended-items {
  padding: 0 1rem;

  @include media-breakpoint-down(sm) {
    margin-bottom: 14rem;
  }

  .block.upsell {
    margin-bottom: 2rem;
  }

  .grid-relateds .products {
    max-width: 1024px;
    margin-top: 2rem;
  }

  .products-upsell {
    width: 100%;

    .product-item {
      padding: 1rem;
      background: #eee;
      margin-bottom: 0;
    }

    .product-item-info {
      width: 100%;
    }

    .product-item-details {
      padding: 1rem 0;
    }

    .product-item-actions {
      margin-bottom: 0;
    }

    .product-item-name.product-item-name {
      min-height: 38px;
    }
  }
}

.recommended-items .grid-relateds .slick-slider {
  padding: 0 8rem;
  max-width: 1024px;

  @include media-breakpoint-down(sm) {
    padding: 0 6rem;
  }

  .slick-track .slick-slide {
    padding: 0 1rem;
    flex: 0 0 25%;

    @include media-breakpoint-down(md) {
      flex: 0 0 33.33%;
    }

    @include media-breakpoint-down(sm) {
      flex: 0 0 50%;
    }

    @include media-breakpoint-down(xs) {
      flex: 0 0 100%;
    }

    >div {
      max-width: 160px;
    }
  }

  .slick-arrow {

    &.slick-prev {
      left: 1rem;
    }

    &.slick-next {
      right: 1rem;
    }

    &::before {
      background-size: 30px;
    }
  }
}

.recommended-popup .slick-track {
  margin-left: 0;
  margin-right: 0;
  width: 100% !important;
}

.grid-relateds .product-image-container {
  display: block;
  max-width: 100%;
  width: 140px !important;
  margin: 0 auto !important;
}

div.recommended-popup {
  font-family: $headings-font-family;

  div.block-title.title {
    margin: 0;

    h2#block-upsell-heading {
      display: none;
    }
  }

  .header-panel {
    text-align: center;
    background: #E5EFE6;
    color: #317C3A;
    padding: 1.5rem 4rem;
    font-size: 1.4rem;

    svg {
      margin-right: 1rem;
      margin-top: -0.3rem;
    }

    .added-to-cart-header {
      font-weight: 700;
    }

    @include media-breakpoint-down(md) {
      font-size: 1.4rem;
      padding: 1rem 4rem;
      line-height: 1.2;

      .dash {
        display: none;
      }

      .added-to-cart-header {
        display: block;
      }

      svg {
        display: none;
      }
    }
  }

  .proceed-to-checkout {
    position: absolute;
    top: 2.7rem;
    right: 4.6rem;

    @include media-breakpoint-down(md) {
      position: static;
      order: 1;
      margin-top: 1.6rem;
    }
  }

  .proceed-to-checkout-link-wrapper {
    width: 100%;

    .proceed-to-checkout-link {
      color: $white;
      border-color: $white;
      width: 100%;
      padding: 1rem;
      margin-top: 1rem;
      font-size: 1.6rem;
    }

    @include media-breakpoint-down(sm) {
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 99;
      width: 100%;
      background: $white;
      padding: 1rem;

      .proceed-to-checkout-link {
        color: $black;
        border-color: $black;
      }
    }
  }

  .product-thumbnail {
    margin-right: 6rem;

    img {
      width: auto;
      height: auto;
      object-fit: contain;
      font-size: 0;
    }
  }

  .product-panel {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .package-panel {
    background: $black;
    color: $white;
    font-weight: bold;
    text-align: center;
    padding: 3rem;
    gap: 0 3rem;
    display: flex;
    justify-content: space-between;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      padding: 2rem;
    }
  }

  .package-ticks {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    flex: 1 0 28%;
    padding-top: 1.5rem;

    @include media-breakpoint-down(sm) {
      border-top: 2px solid #808080;
    }

    .tick-item {
      flex: 0 1 115px;
      margin-bottom: 1rem;

      .tick-icon {
        margin: auto;
        width: 37px;
        height: 37px;
        background-image: url(../images/icon-tick.svg);
        background-size: cover;
      }

      .tick-text {
        padding-top: 1rem;
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 1.25;
      }

      @include media-breakpoint-down(sm) {
        flex-basis: 100%;
        display: flex;
        align-items: center;
        padding-left: 1rem;

        .tick-icon {
          margin: 1rem;
          width: 24px;
          height: 24px;
        }

        .tick-text {
          padding-top: 0;
        }
      }
    }
  }

  .package-header {
    padding-top: 3.3rem;
    margin-left: auto;
    margin-right: auto;
    font-size: 2.5rem;
    font-weight: bold;
    max-width: 43.7rem;
    text-align: center;

    @include media-breakpoint-down(md) {
      font-size: 2.4rem;

      br {
        display: none;
      }
    }
  }

  .package-highlight {
    color: $yellow;
  }

  .package-product {
    border: 0.5rem solid $yellow;
    border-radius: 2rem;
    background-color: $white;
    flex: 1 1 68%;
    padding: 2rem;
    color: $black;
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;

    @include media-breakpoint-down(sm) {
      border: 0;
      padding: 0;
      flex-direction: column;
      background: $black;
      color: $white;
      text-align: left;
    }
  }

  .package-thumbnail {
    flex: 1 1 40%;
    text-align: center;
  }

  .package-title {
    flex: 1 1 40%;
    font-size: 1.8rem;
    font-weight: normal;
    text-align: left;

    strong {
      text-transform: uppercase;
    }

    .package-product-name {
      padding: 1rem 0;
    }
  }

  .package-add-to-cart-button {
    display: flex;
    flex-grow: 1;

    @include media-breakpoint-down(sm) {
      position: fixed;
      bottom: 57px;
      left: 0;
      z-index: 100;
      width: 100%;
      background: $white;
      padding: 1rem;
      font-size: 1.6rem;
      box-shadow: 0 -7px 7px rgba($black, 0.12);

      .button-margin {
        margin: 0;
      }
    }
  }

  .button-margin {
    margin-top: 2rem;
    flex-grow: 1;
  }

  .package-price {
    font-size: 2rem;
    font-weight: bold;
  }

  .recommended-title {
    padding-top: 3.8rem;
    padding-left: 11rem;
    font-size: 1.8rem;
    max-width: 1024px;
    margin: auto;

    @include media-breakpoint-down(md) {
      padding-left: 3rem;
    }

    @include media-breakpoint-down(sm) {
      padding-left: 2rem;
    }
  }

  .package-add-to-cart-form {
    width: 100%;
  }

  .package-thumbnail-text {
    line-height: 1;
  }
}

.page-products .products-grid .product-item .photo {
  max-width: 140px;
  margin-left: auto;
  margin-right: auto;
}

.product.price.product-item-details {
  font-weight: bold;
  font-size: 16px;
}

.form.giftcard {

  .action-check,
  .giftcard.info {
    margin-top: 2rem;
  }
}
