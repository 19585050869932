.percentage-wrapper {
  text-align: center;

  .percentage-holder {
    display: block;
    max-width: 323px;
    width: 100%;
    height: 5px;
    margin: 1rem auto 0;
    background-color: #b1b1b1;
    position: relative;
    border-radius: 5px;
    overflow: hidden;

    .percentage-display {
      background-color: #000;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      display: block;
      border-radius: 5px;
    }
  }
}

.category-view {

  .category-image {

    .image {
      margin: 0 auto;
    }
  }

  .amslider {
    margin-top: 2rem;

    @include media-breakpoint-down(sm) {
      margin-top: 2.8rem;
    }
  }

  .category-description {
    max-height: 90px;
    position: relative;
    overflow: hidden;
    margin: 0 auto;

    @include media-breakpoint-up(lg) {
      max-width: 760px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 1.5rem;
    }

    & + .read-more-holder {
      position: relative;
      z-index: 2;
    }

    &.show-more {
      max-height: none;

      & + .read-more-holder .more {
        display: none;
      }
    }

    &:not(.show-more) {

      & + .read-more-holder .less {
        display: none;
      }

      &::after {
        content: ' ';
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        right: 0;
        height: 40px;
        background: transparent linear-gradient(180deg, #eee0 0%, $white 100%) 0% 0% no-repeat padding-box;

        @include media-breakpoint-down(sm) {
          height: 80px;
        }
      }
    }

    p {
      margin-bottom: 0.5rem;
    }
  }

  .page-title-wrapper {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-bottom: none;

    @include media-breakpoint-up(sm) {
      text-align: center;
    }
  }
}

.children-category-container {
  position: relative;

  @include media-breakpoint-down(xs) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .wrapper {
    flex-wrap: nowrap;
    overflow-x: auto;
    contain: content;
    justify-content: flex-start;
    scrollbar-color: $black $light;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      height: 5px;
      border-radius: 100px;
    }

    &::-webkit-scrollbar-track {
      background-color: $light;
      height: 5px;
      border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $black;
      border-radius: 100px;
      height: 5px;
    }

    &::-webkit-scrollbar-button {

      @include media-breakpoint-up(md) {
        width: 120px;
      }

      @include media-breakpoint-up(lg) {
        width: 170px;
      }

      @include media-breakpoint-up(xl) {
        width: 210px;
      }
    }
  }

  .child-cats-button {
    position: absolute;
    top: 40%;
    width: 15px;
    height: 25px;
    background-position: center;
    background-color: transparent;
    background-repeat: no-repeat;
    background-image: url('../images/right.svg');
    border: none;

    &.cats-left {
      transform-origin: 7.5px 12.5px;
      transform: rotate(180deg);
      left: -5px;

      @include media-breakpoint-down(xs) {
        left: 5px;
      }
    }

    &.cats-right {
      right: -5px;

      @include media-breakpoint-down(xs) {
        right: 5px;
      }
    }
  }

  a.slide {
    border: solid 2px transparent;
    border-radius: 2px;
    width: 125px;
    padding-bottom: 14px;
    margin-right: 10px;
    margin-left: 10px;
    flex-shrink: 0;

    &:hover {
      border-color: $primary;
    }

    .category-item {
      border-color: transparent;
      padding: 0 !important;

      &:hover {
        box-shadow: none;
        border-color: transparent;
      }

      .category-item-title {
        font-size: 1.2rem;
        line-height: 1.6rem;
        max-width: 115px !important;
      }

      &.category-item-label img {
        margin-bottom: 0;
      }
    }
  }
}

.toolbar-products .pages {
  display: none !important;
}

.ias-noneleft {

  & + .ias-noneleft {
    display: none;
  }
}

.category-default-category.ambrand-index-index,
.catalog-category-view {

  .columns {

    .column.main {

      @include media-breakpoint-up(xl) {
        flex-basis: 844px;
      }
    }
  }
}

body {

  .products-wrapper {
    position: relative;
    flex-direction: row;
    flex-wrap: wrap;

    .simple-product-wrapper {

      .product-item-wrap {
        height: 100%;
        display: flex;
        flex-direction: column;

        .info-wrapper {
          position: relative;

          .product-image {

            .product-tag {
              top: 3px;
              position: absolute;
            }

            .klevuImgWrap,
            .image-container {
              margin-top: 3.2rem;
              width: 100%;
              text-align: center;

              @include media-breakpoint-down(xs) {
                display: inline-block;
                margin-top: 4rem;
              }

              .kuProdImg,
              .product-image-container {
                width: 145px !important;
                max-width: 100%;
                display: block;
                margin: auto;

                @include media-breakpoint-down(xs) {
                  margin-top: 0;
                }
              }
            }
          }

          .product-info {
            position: relative;

            .brand {
              position: relative;
              height: 100%;
              min-height: 20px;
              margin-bottom: 3px;

              img.brand-image {
                max-width: 100px;
                max-height: 25px;
              }
            }

            .title {
              font-family: $font-family-base;
              font-size: 1.3rem;
              font-weight: normal;

              @include media-breakpoint-only(xs) {
                font-size: 1.4rem;
              }

              a.title-link {
                text-decoration: none;
              }
            }

            > .pricing {

              .price-box.price-final_price .pricing {
                width: 100%;

                &.standard-price {

                  .price {
                    font-family: $font-family-base;
                    font-size: 16px;
                  }

                  &.strikethrough-price .price {
                    font-size: 13px;
                  }
                }

                &.special-price .price {
                  font-size: 16px !important;
                  font-family: $font-family-base;
                  color: $orange;
                  font-weight: 700;
                }

                .price-label {
                  display: none;
                }

                .price-wrapper {
                  text-align: center;
                }

                &.config-pricing:not(.strikethrough-price),
                &.bundle-pricing:not(.strikethrough-price) {

                  .price-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  }

                  .price-label {
                    margin-right: 0.8rem;
                    display: block;
                  }
                }
              }
            }

            .reviews {
              height: 30px;

              .product-reviews-summary.short {
                margin: 0 auto;

                .rating-summary {
                  margin: 0 auto;
                  left: 0;
                }

                .reviews-actions {
                  display: none;
                }
              }
            }
          }

          .product-actions {
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            z-index: 1;

            &.in-wishlist .action.wishlist a {
              background: $orange;

              svg path {
                stroke: $white;
              }
            }

            .action {
              display: inline-block;

              a.btn-action-secondary.btn-action-secondary.btn-action-secondary {
                height: 30px;
                width: 30px;

                > span {
                  display: none;
                }
              }
            }

            .wishlist {
              order: 1;

              @include media-breakpoint-up(xl) {
                margin-left: 0.5rem;
              }
            }

            @include media-breakpoint-down(xs) {

              .action.compare {
                margin-right: 1rem;
              }
            }
          }
        }

        .actions {

          .product-specs {
            display: none;
          }

          .rented {
            height: 23px;
            margin-top: 0.5rem;
            font-size: 1.1rem;
            vertical-align: middle;

            @include media-breakpoint-down(xs) {
              margin-top: 2.2rem;
              font-size: 1.4rem;
            }

            img {
              max-width: 64px;
            }

            span,
            b {
              font-size: 1.1rem;
            }
          }
        }
      }
    }
  }

  .products-wrapper.products-grid {

    .simple-product-wrapper {
      padding: 0 2.3rem 1.4rem 2.3rem;
    }
  }

  // List specifics
  .products-list.products-wrapper,
  .productList[data-result-view='productList'] .products-wrapper,
  .productList[data-result-view='grid'] .products-wrapper,
  .productList[data-result-view='list'] .products-wrapper {
    position: relative;
    flex-direction: row;
    flex-wrap: wrap;

    @include media-breakpoint-only(md) {
      width: auto !important;
      margin-left: -20px;
      margin-right: -20px;
    }

    .simple-product-wrapper {
      border-bottom: 1px solid $light;

      @include media-breakpoint-up(md) {
        width: 50%;
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
      }

      &:nth-last-child(-n + 2):nth-child(2n + 1) {

        &,
        ~ div {
          border-bottom: none;
        }
      }

      .product-item-wrap {

        .product-content {
          padding: 2rem;
          height: 100%;
          display: flex;
          flex-direction: column;

          @include media-breakpoint-down(sm) {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }

      .info-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        text-align: left;

        .product-image {
          flex: 0 0 90px;

          .image-container {

            .product-image-container {
              max-width: 95px;

              .product-image-wrapper {

                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }

        .product-info {
          flex: 1 1 auto;
          padding-left: 1rem;

          .brand {
            max-height: 20px;
          }

          .title {
            padding-right: 1.5rem;
          }

          > .pricing {
            margin-top: 0.3rem;

            .price-box.price-final_price .pricing {
              width: auto;
            }
          }

          .reviews {

            .product-reviews-summary.short {
              margin: 0;
            }
          }
        }

        .product-actions {
          position: relative;
          flex: 0 0 42px;
          flex-wrap: wrap;
          align-self: start;
          margin: 2px auto;

          @include media-breakpoint-only(xs) {
            flex-basis: 34px;
          }

          .action {
            margin-bottom: 2rem;
            display: block;

            a.btn-action-secondary.btn-action-secondary.btn-action-secondary {
              margin: 0 auto;

              > span {
                display: inline-block;
                left: -3px;

                @include media-breakpoint-only(xs) {
                  right: auto;
                  left: 50%;
                  transform: translateX(-50%);
                  font-size: 1rem;
                }
              }
            }
          }
        }
      }

      .actions {

        .product-specs {
          display: block;
          margin: 0.5rem 0;
        }

        .kuLandingAddToCartBtn,
        form button[type=submit] {
          height: 48px;
          font-size: 1.2rem;
          font-weight: 600;
          line-height: 2.6;

          @include media-breakpoint-only(xs) {
            font-size: 1.4rem;
            line-height: 2;
          }
        }
      }

      .rented {
        text-align: center;

        img {
          max-width: 84px !important;
        }
      }
    }
  }

  // Grid Specifics for desktop only
  @media (min-width: 1240px) {

    .products-grid.products-wrapper,
    .productList[data-result-view='productList'] .products-wrapper.products-wrapper,
    .productList[data-result-view='grid'] .products-wrapper.products-wrapper {

      .simple-product-wrapper {
        border-bottom: 1px solid $light;
        border-right: 1px solid $light;
        text-align: center;
        padding: 0 2.3rem 1.4rem 2.3rem;
        width: 25%;

        &:nth-child(4) ~ .simple-product-wrapper {
          padding-top: 2.6rem;
        }

        &:nth-child(4n+0),
        &:last-child {
          border-right: none;
        }

        &:nth-last-child(-n + 4):nth-child(4n + 1) {

          &,
          ~ div {
            border-bottom: none;
          }
        }

        .product-item-wrap {
          height: 100%;
          display: flex;
          flex-direction: column;

          .product-content {
            height: 100%;
            display: flex;
            flex-direction: column;
            padding: 0;

            .actions {

              .product-specs {
                display: none;
              }

              form button[type=submit] {
                height: 34px;
                line-height: inherit;
              }

              .kuLandingAddToCartBtn {
                height: auto;
                line-height: 1.3;
                margin-top: 3rem;
              }
            }

            .info-wrapper {
              display: block;

              .btn-action-secondary .icon-label.icon-label {
                display: none;
              }

              .klevuImgWrap {

                a {
                  height: 145px;
                  display: flex;
                  justify-content: center;
                }
              }

              .product-info {
                text-align: center;
                padding: 0;

                .title {
                  padding: 0;
                }

                .kuPrice {
                  align-items: center;
                }
              }

              .product-actions {
                position: absolute;
              }
            }
          }
        }
      }

      // for additional grids loaded by ajax load more
      & + .products-grid {

        .simple-product-wrapper {
          border-top: 1px solid $light;
          padding-top: 2.6rem;
        }
      }
    }
  }
}

.ias-trigger.ias-trigger-next {
  margin-top: 5.5rem;
}

[data-content-type='products'][data-appearance='carousel'] {

  .slick-dots {
    margin-top: 2rem;
  }

  .slick-slide > div {
    flex-grow: 1;
  }

  .brand img,
  .rented img {
    display: inline-block;
  }
}

body .products-wrapper.products-grid.widget-product-grid {

  .simple-product-wrapper {

    @include media-breakpoint-down(lg) {
      width: 50%;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
}
