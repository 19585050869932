#sidebar-account {
  overflow: hidden;

  .account-panels {
    transition: ease 0.3s;
    position: absolute;
    width: 100%;
    height: 100%;

    &.panel-sub {
      display: block;
      left: 100%;

      &.panel-active {
        left: 0;
      }
    }

    &.panel-main {
      right: 100%;

      &.panel-active {
        right: 0;
      }
    }

    &.panel-login {
      background: $yellow;

      .block-content {
        padding: 0;
        overflow-y: auto;

        .form-login,
        .social-login-wrapper {
          padding-left: 2rem;
          padding-right: 2rem;
        }

        .form-login {
          padding-left: 2rem;
          padding-right: 2rem;
          background: $white;

          .announcement {
            display: none;
          }
        }

        .social-login-wrapper {
          position: relative;
          font-size: 15px;

          > div {
            max-width: 100%;
          }
        }
      }
    }

    &.panel-forgot {

      .block-content {

        p {
          padding: 0 3rem;
        }

        fieldset.fieldset.forgot {
          margin-bottom: 0;
        }
      }

      .captcha .nested {
        margin-top: 1rem;
      }
    }

    &.panel-register {

      .control {
        padding-bottom: 1rem;
      }

      input[name='captcha[user_create]'] + .mage-error {
        margin-bottom: 1rem;
      }
    }

    .block-content {
      height: 100%;
      overflow-y: auto;
      padding: 1rem 1rem 0 1rem;
      text-align: left;

      a:not(.btn) {
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .link-left {
      padding-left: 5rem;
      padding-right: 5rem;
    }

    a.remind {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    a.register {
      text-transform: capitalize;
    }
  }
}

.customer-account-forgotpassword {

  .password-forget {
    margin-top: 20px;

    fieldset {
      margin-bottom: 0;

      .nested {
        margin-top: 15px;
      }
    }

    .mobile-back {
      padding-top: 10px !important;
    }
  }
}

.customer-account-login {

  .social-login-wrapper {
    margin: 0 auto;
    padding-top: 0;

    @include media-breakpoint-up(lg) {
      padding-left: 3rem;
    }

    @include media-breakpoint-down(md) {
      text-align: center;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .login-container .fieldset::after {
    margin-top: 0;
  }
}

.customer-account-create {

  .fieldset {

    .control,
    .custom-control {
      margin-bottom: 15px;
    }
  }

  .mobile-back {
    padding-top: 15px;
  }
}

span.required {
  color: $danger;
  font-size: 1.2rem;
  margin: 0 0 0 0.3rem;
}
