@charset "UTF-8";
/*!
 * Bootstrap v4.5.3 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
*/
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #d60000;
  --orange: #eb5050;
  --yellow: #fcd900;
  --green: #28a745;
  --teal: #46db8b;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #aaa;
  --gray-dark: #59595a;
  --gray-light: #ddd;
  --primary: #fcd900;
  --secondary: #000;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #fcd900;
  --danger: #d10000;
  --light: #ddd;
  --dark: #59595a;
  --notice: #17a2b8;
  --error: #d10000;
  --white: #fff;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 1024px;
  --breakpoint-xl: 1240px;
  --font-family-sans-serif: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 2rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #000;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #262626;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: "Montserrat", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 3.2rem; }

h2, .h2 {
  font-size: 2.6rem; }

h3, .h3 {
  font-size: 2.2rem; }

h4, .h4 {
  font-size: 1.8rem; }

h5, .h5 {
  font-size: 1.5rem; }

h6, .h6 {
  font-size: 1.3rem; }

.lead {
  font-size: 1.6rem;
  font-weight: 300; }

.display-1 {
  font-size: 4.2rem;
  font-weight: 700;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0.3rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

.container, .navigation, .breadcrumb, .page-header .header.panel, .header.content, .footer-container, .page-wrapper > .widget, .page-wrapper > .page-bottom, .block.category.event, .top-container, .page-main, .cms-home .page-main .page.messages,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .navigation, .breadcrumb, .page-header .header.panel, .header.content, .footer-container, .page-wrapper > .widget, .page-wrapper > .page-bottom, .block.category.event, .top-container, .page-main, .cms-home .page-main .page.messages, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .navigation, .breadcrumb, .page-header .header.panel, .header.content, .footer-container, .page-wrapper > .widget, .page-wrapper > .page-bottom, .block.category.event, .top-container, .page-main, .cms-home .page-main .page.messages, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 1024px) {
  .container, .navigation, .breadcrumb, .page-header .header.panel, .header.content, .footer-container, .page-wrapper > .widget, .page-wrapper > .page-bottom, .block.category.event, .top-container, .page-main, .cms-home .page-main .page.messages, .container-sm, .container-md, .container-lg {
    max-width: 976px; } }

@media (min-width: 1240px) {
  .container, .navigation, .breadcrumb, .page-header .header.panel, .header.content, .footer-container, .page-wrapper > .widget, .page-wrapper > .page-bottom, .block.category.event, .top-container, .page-main, .cms-home .page-main .page.messages, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1190px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1024px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1240px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #000; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #ddd; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #ddd; }
  .table tbody + tbody {
    border-top: 2px solid #ddd; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #ddd; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(even) {
  background-color: rgba(221, 221, 221, 0.46); }

.table-hover tbody tr:hover {
  color: #000;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #fef4b8; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #fdeb7a; }

.table-hover .table-primary:hover {
  background-color: #fef09f; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #fef09f; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #b8b8b8; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #7a7a7a; }

.table-hover .table-secondary:hover {
  background-color: #ababab; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #ababab; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fef4b8; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #fdeb7a; }

.table-hover .table-warning:hover {
  background-color: #fef09f; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #fef09f; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f2b8b8; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #e77a7a; }

.table-hover .table-danger:hover {
  background-color: #eea2a2; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #eea2a2; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: whitesmoke; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #ededed; }

.table-hover .table-light:hover {
  background-color: #e8e8e8; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #e8e8e8; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #d1d1d1; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: darkgray; }

.table-hover .table-dark:hover {
  background-color: #c4c4c4; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #c4c4c4; }

.table-notice,
.table-notice > th,
.table-notice > td {
  background-color: #bee5eb; }

.table-notice th,
.table-notice td,
.table-notice thead th,
.table-notice tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-notice:hover {
  background-color: #abdde5; }
  .table-hover .table-notice:hover > td,
  .table-hover .table-notice:hover > th {
    background-color: #abdde5; }

.table-error,
.table-error > th,
.table-error > td {
  background-color: #f2b8b8; }

.table-error th,
.table-error td,
.table-error thead th,
.table-error tbody + tbody {
  border-color: #e77a7a; }

.table-hover .table-error:hover {
  background-color: #eea2a2; }
  .table-hover .table-error:hover > td,
  .table-hover .table-error:hover > th {
    background-color: #eea2a2; }

.table-white,
.table-white > th,
.table-white > td {
  background-color: white; }

.table-white th,
.table-white td,
.table-white thead th,
.table-white tbody + tbody {
  border-color: white; }

.table-hover .table-white:hover {
  background-color: #f2f2f2; }
  .table-hover .table-white:hover > td,
  .table-hover .table-white:hover > th {
    background-color: #f2f2f2; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #3c3c3c;
  border-color: #4f4f4f; }

.table .thead-light th {
  color: #59595a;
  background-color: #e5e5e5;
  border-color: #ddd; }

.table-dark {
  color: #fff;
  background-color: #3c3c3c; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #4f4f4f; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(even) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 1023.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1239.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(calc(1.5em + 2.2rem + 4px) + 2.5px);
  padding: 1.1rem 1.7rem;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.5;
  color: #59595a;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid #aaa;
  border-radius: 0.3rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #59595a; }
  .form-control:focus {
    color: #59595a;
    background-color: #fff;
    border-color: #fcd900;
    outline: 0;
    box-shadow: 0 3px 10px #00000026; }
  .form-control::placeholder {
    color: #aaa;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e5e5e5;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none; }

select.form-control:focus::-ms-value {
  color: #59595a;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(1.1rem + 2px);
  padding-bottom: calc(1.1rem + 2px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(1.9rem + 2px);
  padding-bottom: calc(1.9rem + 2px);
  font-size: 1.8rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.6rem + 2px);
  padding-bottom: calc(0.6rem + 2px);
  font-size: 1.2rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 1.1rem 0;
  margin-bottom: 0;
  font-size: 1.3rem;
  line-height: 1.5;
  color: #000;
  background-color: transparent;
  border: solid transparent;
  border-width: 2px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 1.2rem + 4px);
  padding: 0.6rem 2.4rem;
  font-size: 1.2rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.form-control-lg {
  height: calc(1.5em + 3.8rem + 4px);
  padding: 1.9rem 5rem;
  font-size: 1.8rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1.2rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.3rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 2.2rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.55rem) center;
  background-size: calc(0.75em + 1.1rem) calc(0.75em + 1.1rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 2.2rem);
  background-position: top calc(0.375em + 0.55rem) right calc(0.375em + 0.55rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 4.35rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%233c3c3c' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1.7rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 2.7rem/calc(0.75em + 1.1rem) calc(0.75em + 1.1rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #d10000; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(209, 0, 0, 0.9);
  border-radius: 0.3rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #d10000;
  padding-right: calc(1.5em + 2.2rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23d10000' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23d10000' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.55rem) center;
  background-size: calc(0.75em + 1.1rem) calc(0.75em + 1.1rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #d10000;
    box-shadow: 0 0 0 0.2rem rgba(209, 0, 0, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 2.2rem);
  background-position: top calc(0.375em + 0.55rem) right calc(0.375em + 0.55rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #d10000;
  padding-right: calc(0.75em + 4.35rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%233c3c3c' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1.7rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23d10000' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23d10000' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 2.7rem/calc(0.75em + 1.1rem) calc(0.75em + 1.1rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #d10000;
    box-shadow: 0 0 0 0.2rem rgba(209, 0, 0, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #d10000; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #d10000; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #d10000; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #ff0505;
  background-color: #ff0505; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(209, 0, 0, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #d10000; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #d10000; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #d10000;
  box-shadow: 0 0 0 0.2rem rgba(209, 0, 0, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn, a.pagebuilder-button-primary,
.pagebuilder-button-primary, a.pagebuilder-button-secondary,
.pagebuilder-button-secondary, .account-nav .item.item a[href*='logout'] {
  display: inline-block;
  font-family: "Montserrat", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  color: #000;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 0.2rem solid transparent;
  padding: 1.1rem 4rem;
  font-size: 1.4rem;
  line-height: 1.5;
  border-radius: 0.3rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn, a.pagebuilder-button-primary,
    .pagebuilder-button-primary, a.pagebuilder-button-secondary,
    .pagebuilder-button-secondary, .account-nav .item.item a[href*='logout'] {
      transition: none; } }
  .btn:hover, a.pagebuilder-button-primary:hover,
  .pagebuilder-button-primary:hover, a.pagebuilder-button-secondary:hover,
  .pagebuilder-button-secondary:hover, .account-nav .item.item a:hover[href*='logout'] {
    color: #000;
    text-decoration: none; }
  .btn:focus, a.pagebuilder-button-primary:focus,
  .pagebuilder-button-primary:focus, a.pagebuilder-button-secondary:focus,
  .pagebuilder-button-secondary:focus, .account-nav .item.item a:focus[href*='logout'], .btn.focus, a.focus.pagebuilder-button-primary,
  .focus.pagebuilder-button-primary, a.focus.pagebuilder-button-secondary,
  .focus.pagebuilder-button-secondary, .account-nav .item.item a.focus[href*='logout'] {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(252, 217, 0, 0.25); }
  .btn.disabled, a.disabled.pagebuilder-button-primary,
  .disabled.pagebuilder-button-primary, a.disabled.pagebuilder-button-secondary,
  .disabled.pagebuilder-button-secondary, .account-nav .item.item a.disabled[href*='logout'], .btn:disabled, a.pagebuilder-button-primary:disabled,
  .pagebuilder-button-primary:disabled, a.pagebuilder-button-secondary:disabled,
  .pagebuilder-button-secondary:disabled, .account-nav .item.item a:disabled[href*='logout'] {
    opacity: 1; }
  .btn:not(:disabled):not(.disabled),
  .pagebuilder-button-primary:not(:disabled):not(.disabled),
  .pagebuilder-button-secondary:not(:disabled):not(.disabled), .account-nav .item.item a:not(:disabled):not(.disabled)[href*='logout'] {
    cursor: pointer; }

a.btn.disabled, a.disabled.pagebuilder-button-primary, a.disabled.pagebuilder-button-secondary, .account-nav .item.item a.disabled[href*='logout'],
fieldset:disabled a.btn,
fieldset:disabled a.pagebuilder-button-primary,
fieldset:disabled a.pagebuilder-button-secondary,
fieldset:disabled .account-nav .item.item a[href*='logout'],
.account-nav .item.item fieldset:disabled a[href*='logout'] {
  pointer-events: none; }

.btn-primary, a.pagebuilder-button-primary,
.pagebuilder-button-primary {
  color: #292929;
  background-color: #fcd900;
  border-color: #fcd900; }
  .btn-primary:hover, a.pagebuilder-button-primary:hover,
  .pagebuilder-button-primary:hover {
    color: #292929;
    background-color: #d6b800;
    border-color: #c9ad00; }
  .btn-primary:focus, a.pagebuilder-button-primary:focus,
  .pagebuilder-button-primary:focus, .btn-primary.focus, a.focus.pagebuilder-button-primary,
  .focus.pagebuilder-button-primary {
    color: #292929;
    background-color: #d6b800;
    border-color: #c9ad00;
    box-shadow: 0 0 0 0.2rem rgba(220, 191, 6, 0.5); }
  .btn-primary.disabled, a.disabled.pagebuilder-button-primary,
  .disabled.pagebuilder-button-primary, .btn-primary:disabled, a.pagebuilder-button-primary:disabled,
  .pagebuilder-button-primary:disabled {
    color: #292929;
    background-color: #fcd900;
    border-color: #fcd900; }
  .btn-primary:not(:disabled):not(.disabled):active,
  .pagebuilder-button-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .pagebuilder-button-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle,
  .show > .dropdown-toggle.pagebuilder-button-primary {
    color: #292929;
    background-color: #c9ad00;
    border-color: #bca200; }
    .btn-primary:not(:disabled):not(.disabled):active:focus,
    .pagebuilder-button-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .pagebuilder-button-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus,
    .show > .dropdown-toggle.pagebuilder-button-primary:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 191, 6, 0.5); }

.btn-secondary, a.pagebuilder-button-secondary,
.pagebuilder-button-secondary {
  color: #fff;
  background-color: #000;
  border-color: #000; }
  .btn-secondary:hover, a.pagebuilder-button-secondary:hover,
  .pagebuilder-button-secondary:hover {
    color: #fff;
    background-color: black;
    border-color: black; }
  .btn-secondary:focus, a.pagebuilder-button-secondary:focus,
  .pagebuilder-button-secondary:focus, .btn-secondary.focus, a.focus.pagebuilder-button-secondary,
  .focus.pagebuilder-button-secondary {
    color: #fff;
    background-color: black;
    border-color: black;
    box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5); }
  .btn-secondary.disabled, a.disabled.pagebuilder-button-secondary,
  .disabled.pagebuilder-button-secondary, .btn-secondary:disabled, a.pagebuilder-button-secondary:disabled,
  .pagebuilder-button-secondary:disabled {
    color: #fff;
    background-color: #000;
    border-color: #000; }
  .btn-secondary:not(:disabled):not(.disabled):active,
  .pagebuilder-button-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .pagebuilder-button-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle,
  .show > .dropdown-toggle.pagebuilder-button-secondary {
    color: #fff;
    background-color: black;
    border-color: black; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus,
    .pagebuilder-button-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .pagebuilder-button-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus,
    .show > .dropdown-toggle.pagebuilder-button-secondary:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #292929;
  background-color: #fcd900;
  border-color: #fcd900; }
  .btn-warning:hover {
    color: #292929;
    background-color: #d6b800;
    border-color: #c9ad00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #292929;
    background-color: #d6b800;
    border-color: #c9ad00;
    box-shadow: 0 0 0 0.2rem rgba(220, 191, 6, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #292929;
    background-color: #fcd900;
    border-color: #fcd900; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #292929;
    background-color: #c9ad00;
    border-color: #bca200; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 191, 6, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #d10000;
  border-color: #d10000; }
  .btn-danger:hover {
    color: #fff;
    background-color: #ab0000;
    border-color: #9e0000; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #ab0000;
    border-color: #9e0000;
    box-shadow: 0 0 0 0.2rem rgba(216, 38, 38, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #d10000;
    border-color: #d10000; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #9e0000;
    border-color: #910000; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 38, 38, 0.5); }

.btn-light {
  color: #292929;
  background-color: #ddd;
  border-color: #ddd; }
  .btn-light:hover {
    color: #292929;
    background-color: #cacaca;
    border-color: #c4c4c4; }
  .btn-light:focus, .btn-light.focus {
    color: #292929;
    background-color: #cacaca;
    border-color: #c4c4c4;
    box-shadow: 0 0 0 0.2rem rgba(194, 194, 194, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #292929;
    background-color: #ddd;
    border-color: #ddd; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #292929;
    background-color: #c4c4c4;
    border-color: #bdbdbd; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(194, 194, 194, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #59595a;
  border-color: #59595a; }
  .btn-dark:hover {
    color: #fff;
    background-color: #464647;
    border-color: #404040; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #464647;
    border-color: #404040;
    box-shadow: 0 0 0 0.2rem rgba(114, 114, 115, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #59595a;
    border-color: #59595a; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #404040;
    border-color: #39393a; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(114, 114, 115, 0.5); }

.btn-notice {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-notice:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-notice:focus, .btn-notice.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-notice.disabled, .btn-notice:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-notice:not(:disabled):not(.disabled):active, .btn-notice:not(:disabled):not(.disabled).active,
  .show > .btn-notice.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-notice:not(:disabled):not(.disabled):active:focus, .btn-notice:not(:disabled):not(.disabled).active:focus,
    .show > .btn-notice.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-error {
  color: #fff;
  background-color: #d10000;
  border-color: #d10000; }
  .btn-error:hover {
    color: #fff;
    background-color: #ab0000;
    border-color: #9e0000; }
  .btn-error:focus, .btn-error.focus {
    color: #fff;
    background-color: #ab0000;
    border-color: #9e0000;
    box-shadow: 0 0 0 0.2rem rgba(216, 38, 38, 0.5); }
  .btn-error.disabled, .btn-error:disabled {
    color: #fff;
    background-color: #d10000;
    border-color: #d10000; }
  .btn-error:not(:disabled):not(.disabled):active, .btn-error:not(:disabled):not(.disabled).active,
  .show > .btn-error.dropdown-toggle {
    color: #fff;
    background-color: #9e0000;
    border-color: #910000; }
    .btn-error:not(:disabled):not(.disabled):active:focus, .btn-error:not(:disabled):not(.disabled).active:focus,
    .show > .btn-error.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 38, 38, 0.5); }

.btn-white {
  color: #292929;
  background-color: #fff;
  border-color: #fff; }
  .btn-white:hover {
    color: #292929;
    background-color: #ececec;
    border-color: #e6e6e6; }
  .btn-white:focus, .btn-white.focus {
    color: #292929;
    background-color: #ececec;
    border-color: #e6e6e6;
    box-shadow: 0 0 0 0.2rem rgba(223, 223, 223, 0.5); }
  .btn-white.disabled, .btn-white:disabled {
    color: #292929;
    background-color: #fff;
    border-color: #fff; }
  .btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active,
  .show > .btn-white.dropdown-toggle {
    color: #292929;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-white:not(:disabled):not(.disabled):active:focus, .btn-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(223, 223, 223, 0.5); }

.btn-outline-primary {
  color: #fcd900;
  border-color: #fcd900; }
  .btn-outline-primary:hover {
    color: #292929;
    background-color: #fcd900;
    border-color: #fcd900; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(252, 217, 0, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #fcd900;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #292929;
    background-color: #fcd900;
    border-color: #fcd900; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(252, 217, 0, 0.5); }

.btn-outline-secondary, .account-nav .item.item a[href*='logout'] {
  color: #000;
  border-color: #000; }
  .btn-outline-secondary:hover, .account-nav .item.item a:hover[href*='logout'] {
    color: #fff;
    background-color: #000;
    border-color: #000; }
  .btn-outline-secondary:focus, .account-nav .item.item a:focus[href*='logout'], .btn-outline-secondary.focus, .account-nav .item.item a.focus[href*='logout'] {
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }
  .btn-outline-secondary.disabled, .account-nav .item.item a.disabled[href*='logout'], .btn-outline-secondary:disabled, .account-nav .item.item a:disabled[href*='logout'] {
    color: #000;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .account-nav .item.item a:not(:disabled):not(.disabled):active[href*='logout'], .btn-outline-secondary:not(:disabled):not(.disabled).active, .account-nav .item.item a:not(:disabled):not(.disabled).active[href*='logout'],
  .show > .btn-outline-secondary.dropdown-toggle,
  .account-nav .item.item .show > a.dropdown-toggle[href*='logout'] {
    color: #fff;
    background-color: #000;
    border-color: #000; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .account-nav .item.item a:not(:disabled):not(.disabled):active:focus[href*='logout'], .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .account-nav .item.item a:not(:disabled):not(.disabled).active:focus[href*='logout'],
    .show > .btn-outline-secondary.dropdown-toggle:focus,
    .account-nav .item.item .show > a.dropdown-toggle:focus[href*='logout'] {
      box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #fcd900;
  border-color: #fcd900; }
  .btn-outline-warning:hover {
    color: #292929;
    background-color: #fcd900;
    border-color: #fcd900; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(252, 217, 0, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #fcd900;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #292929;
    background-color: #fcd900;
    border-color: #fcd900; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(252, 217, 0, 0.5); }

.btn-outline-danger {
  color: #d10000;
  border-color: #d10000; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #d10000;
    border-color: #d10000; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(209, 0, 0, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #d10000;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #d10000;
    border-color: #d10000; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(209, 0, 0, 0.5); }

.btn-outline-light {
  color: #ddd;
  border-color: #ddd; }
  .btn-outline-light:hover {
    color: #292929;
    background-color: #ddd;
    border-color: #ddd; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(221, 221, 221, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #ddd;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #292929;
    background-color: #ddd;
    border-color: #ddd; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(221, 221, 221, 0.5); }

.btn-outline-dark {
  color: #59595a;
  border-color: #59595a; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #59595a;
    border-color: #59595a; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(89, 89, 90, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #59595a;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #59595a;
    border-color: #59595a; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(89, 89, 90, 0.5); }

.btn-outline-notice {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-notice:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-notice:focus, .btn-outline-notice.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-notice.disabled, .btn-outline-notice:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-notice:not(:disabled):not(.disabled):active, .btn-outline-notice:not(:disabled):not(.disabled).active,
  .show > .btn-outline-notice.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-notice:not(:disabled):not(.disabled):active:focus, .btn-outline-notice:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-notice.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-error {
  color: #d10000;
  border-color: #d10000; }
  .btn-outline-error:hover {
    color: #fff;
    background-color: #d10000;
    border-color: #d10000; }
  .btn-outline-error:focus, .btn-outline-error.focus {
    box-shadow: 0 0 0 0.2rem rgba(209, 0, 0, 0.5); }
  .btn-outline-error.disabled, .btn-outline-error:disabled {
    color: #d10000;
    background-color: transparent; }
  .btn-outline-error:not(:disabled):not(.disabled):active, .btn-outline-error:not(:disabled):not(.disabled).active,
  .show > .btn-outline-error.dropdown-toggle {
    color: #fff;
    background-color: #d10000;
    border-color: #d10000; }
    .btn-outline-error:not(:disabled):not(.disabled):active:focus, .btn-outline-error:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-error.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(209, 0, 0, 0.5); }

.btn-outline-white {
  color: #fff;
  border-color: #fff; }
  .btn-outline-white:hover {
    color: #292929;
    background-color: #fff;
    border-color: #fff; }
  .btn-outline-white:focus, .btn-outline-white.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-outline-white.disabled, .btn-outline-white:disabled {
    color: #fff;
    background-color: transparent; }
  .btn-outline-white:not(:disabled):not(.disabled):active, .btn-outline-white:not(:disabled):not(.disabled).active,
  .show > .btn-outline-white.dropdown-toggle {
    color: #292929;
    background-color: #fff;
    border-color: #fff; }
    .btn-outline-white:not(:disabled):not(.disabled):active:focus, .btn-outline-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-link {
  font-weight: 400;
  color: #000;
  text-decoration: none; }
  .btn-link:hover {
    color: #262626;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #aaa;
    pointer-events: none; }

.btn-lg {
  padding: 1.9rem 5rem;
  font-size: 1.8rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .account-nav .item.item a[href*='logout'] {
  padding: 0.6rem 2.4rem;
  font-size: 1.2rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -2px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn, .input-group-prepend a.pagebuilder-button-primary,
  .input-group-prepend .pagebuilder-button-primary, .input-group-prepend a.pagebuilder-button-secondary,
  .input-group-prepend .pagebuilder-button-secondary, .input-group-prepend .account-nav .item.item a[href*='logout'], .account-nav .item.item .input-group-prepend a[href*='logout'],
  .input-group-append .btn,
  .input-group-append a.pagebuilder-button-primary,
  .input-group-append .pagebuilder-button-primary,
  .input-group-append a.pagebuilder-button-secondary,
  .input-group-append .pagebuilder-button-secondary,
  .input-group-append .account-nav .item.item a[href*='logout'],
  .account-nav .item.item .input-group-append a[href*='logout'] {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-prepend .pagebuilder-button-primary:focus,
    .input-group-prepend .pagebuilder-button-secondary:focus, .input-group-prepend .account-nav .item.item a:focus[href*='logout'], .account-nav .item.item .input-group-prepend a:focus[href*='logout'],
    .input-group-append .btn:focus,
    .input-group-append .pagebuilder-button-primary:focus,
    .input-group-append .pagebuilder-button-secondary:focus,
    .input-group-append .account-nav .item.item a:focus[href*='logout'],
    .account-nav .item.item .input-group-append a:focus[href*='logout'] {
      z-index: 3; }
  .input-group-prepend .btn + .btn, .input-group-prepend .pagebuilder-button-primary + .btn, .input-group-prepend .pagebuilder-button-secondary + .btn, .input-group-prepend .account-nav .item.item a[href*='logout'] + .btn, .account-nav .item.item .input-group-prepend a[href*='logout'] + .btn,
  .input-group-prepend .btn + .pagebuilder-button-primary,
  .input-group-prepend .pagebuilder-button-primary + .pagebuilder-button-primary,
  .input-group-prepend .pagebuilder-button-secondary + .pagebuilder-button-primary,
  .input-group-prepend .account-nav .item.item a[href*='logout'] + .pagebuilder-button-primary, .account-nav .item.item .input-group-prepend a[href*='logout'] + .pagebuilder-button-primary,
  .input-group-prepend .btn + .pagebuilder-button-secondary,
  .input-group-prepend .pagebuilder-button-primary + .pagebuilder-button-secondary,
  .input-group-prepend .pagebuilder-button-secondary + .pagebuilder-button-secondary,
  .input-group-prepend .account-nav .item.item a[href*='logout'] + .pagebuilder-button-secondary, .account-nav .item.item .input-group-prepend a[href*='logout'] + .pagebuilder-button-secondary, .input-group-prepend .account-nav .item.item .btn + a[href*='logout'], .account-nav .item.item .input-group-prepend .btn + a[href*='logout'], .input-group-prepend .account-nav .item.item .pagebuilder-button-primary + a[href*='logout'], .account-nav .item.item .input-group-prepend .pagebuilder-button-primary + a[href*='logout'], .input-group-prepend .account-nav .item.item .pagebuilder-button-secondary + a[href*='logout'], .account-nav .item.item .input-group-prepend .pagebuilder-button-secondary + a[href*='logout'], .input-group-prepend .account-nav .item.item a[href*='logout'] + a[href*='logout'], .account-nav .item.item .input-group-prepend a[href*='logout'] + a[href*='logout'],
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .pagebuilder-button-primary + .input-group-text,
  .input-group-prepend .pagebuilder-button-secondary + .input-group-text,
  .input-group-prepend .account-nav .item.item a[href*='logout'] + .input-group-text,
  .account-nav .item.item .input-group-prepend a[href*='logout'] + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-prepend .input-group-text + .pagebuilder-button-primary,
  .input-group-prepend .input-group-text + .pagebuilder-button-secondary,
  .input-group-prepend .account-nav .item.item .input-group-text + a[href*='logout'],
  .account-nav .item.item .input-group-prepend .input-group-text + a[href*='logout'],
  .input-group-append .btn + .btn,
  .input-group-append .pagebuilder-button-primary + .btn,
  .input-group-append .pagebuilder-button-secondary + .btn,
  .input-group-append .account-nav .item.item a[href*='logout'] + .btn,
  .account-nav .item.item .input-group-append a[href*='logout'] + .btn,
  .input-group-append .btn + .pagebuilder-button-primary,
  .input-group-append .pagebuilder-button-primary + .pagebuilder-button-primary,
  .input-group-append .pagebuilder-button-secondary + .pagebuilder-button-primary,
  .input-group-append .account-nav .item.item a[href*='logout'] + .pagebuilder-button-primary,
  .account-nav .item.item .input-group-append a[href*='logout'] + .pagebuilder-button-primary,
  .input-group-append .btn + .pagebuilder-button-secondary,
  .input-group-append .pagebuilder-button-primary + .pagebuilder-button-secondary,
  .input-group-append .pagebuilder-button-secondary + .pagebuilder-button-secondary,
  .input-group-append .account-nav .item.item a[href*='logout'] + .pagebuilder-button-secondary,
  .account-nav .item.item .input-group-append a[href*='logout'] + .pagebuilder-button-secondary,
  .input-group-append .account-nav .item.item .btn + a[href*='logout'],
  .account-nav .item.item .input-group-append .btn + a[href*='logout'],
  .input-group-append .account-nav .item.item .pagebuilder-button-primary + a[href*='logout'],
  .account-nav .item.item .input-group-append .pagebuilder-button-primary + a[href*='logout'],
  .input-group-append .account-nav .item.item .pagebuilder-button-secondary + a[href*='logout'],
  .account-nav .item.item .input-group-append .pagebuilder-button-secondary + a[href*='logout'],
  .input-group-append .account-nav .item.item a[href*='logout'] + a[href*='logout'],
  .account-nav .item.item .input-group-append a[href*='logout'] + a[href*='logout'],
  .input-group-append .btn + .input-group-text,
  .input-group-append .pagebuilder-button-primary + .input-group-text,
  .input-group-append .pagebuilder-button-secondary + .input-group-text,
  .input-group-append .account-nav .item.item a[href*='logout'] + .input-group-text,
  .account-nav .item.item .input-group-append a[href*='logout'] + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn,
  .input-group-append .input-group-text + .pagebuilder-button-primary,
  .input-group-append .input-group-text + .pagebuilder-button-secondary,
  .input-group-append .account-nav .item.item .input-group-text + a[href*='logout'],
  .account-nav .item.item .input-group-append .input-group-text + a[href*='logout'] {
    margin-left: -2px; }

.input-group-prepend {
  margin-right: -2px; }

.input-group-append {
  margin-left: -2px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 1.1rem 1.7rem;
  margin-bottom: 0;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.5;
  color: #59595a;
  text-align: center;
  white-space: nowrap;
  background-color: #fff;
  border: 2px solid #aaa;
  border-radius: 0.3rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 3.8rem + 4px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-prepend > .pagebuilder-button-primary,
.input-group-lg > .input-group-prepend > .pagebuilder-button-secondary,
.account-nav .item.item .input-group-lg > .input-group-prepend > a[href*='logout'],
.input-group-lg > .input-group-append > .btn,
.input-group-lg > .input-group-append > .pagebuilder-button-primary,
.input-group-lg > .input-group-append > .pagebuilder-button-secondary,
.account-nav .item.item .input-group-lg > .input-group-append > a[href*='logout'] {
  padding: 1.9rem 5rem;
  font-size: 1.8rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 1.2rem + 4px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-prepend > .pagebuilder-button-primary,
.input-group-sm > .input-group-prepend > .pagebuilder-button-secondary,
.account-nav .item.item .input-group-sm > .input-group-prepend > a[href*='logout'],
.input-group-sm > .input-group-append > .btn,
.input-group-sm > .input-group-append > .pagebuilder-button-primary,
.input-group-sm > .input-group-append > .pagebuilder-button-secondary,
.account-nav .item.item .input-group-sm > .input-group-append > a[href*='logout'] {
  padding: 0.6rem 2.4rem;
  font-size: 1.2rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 2.7rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .pagebuilder-button-primary,
.input-group > .input-group-prepend > .pagebuilder-button-secondary, .account-nav .item.item .input-group > .input-group-prepend > a[href*='logout'],
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .pagebuilder-button-primary,
.input-group > .input-group-append:not(:last-child) > .pagebuilder-button-secondary,
.account-nav .item.item .input-group > .input-group-append:not(:last-child) > a[href*='logout'],
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .pagebuilder-button-primary:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .pagebuilder-button-secondary:not(:last-child):not(.dropdown-toggle),
.account-nav .item.item .input-group > .input-group-append:last-child > a:not(:last-child):not(.dropdown-toggle)[href*='logout'],
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .pagebuilder-button-primary,
.input-group > .input-group-append > .pagebuilder-button-secondary, .account-nav .item.item .input-group > .input-group-append > a[href*='logout'],
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .pagebuilder-button-primary,
.input-group > .input-group-prepend:not(:first-child) > .pagebuilder-button-secondary,
.account-nav .item.item .input-group > .input-group-prepend:not(:first-child) > a[href*='logout'],
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .pagebuilder-button-primary:not(:first-child),
.input-group > .input-group-prepend:first-child > .pagebuilder-button-secondary:not(:first-child),
.account-nav .item.item .input-group > .input-group-prepend:first-child > a:not(:first-child)[href*='logout'],
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 2.9rem;
  color-adjust: exact; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 2.4rem;
  height: 1.95rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #000;
    border-color: #000;
    background-color: #fff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 3px 10px #00000026; }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #000; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #fff;
    border-color: #000; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #ddd; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #fff; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: -0.45rem;
    left: -2.9rem;
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #ddd solid 2px; }
  .custom-control-label::after {
    position: absolute;
    top: -0.45rem;
    left: -2.9rem;
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.3rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #fcd900;
  background-color: #fcd900; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23000' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(252, 217, 0, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(252, 217, 0, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23000'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(252, 217, 0, 0.5); }

.custom-switch {
  padding-left: 4.7rem; }
  .custom-switch .custom-control-label::before {
    left: -4.7rem;
    width: 4.2rem;
    pointer-events: all;
    border-radius: 1.2rem; }
  .custom-switch .custom-control-label::after {
    top: calc(-0.45rem + 4px);
    left: calc(-4.7rem + 4px);
    width: calc(2.4rem - 8px);
    height: calc(2.4rem - 8px);
    background-color: #ddd;
    border-radius: 1.2rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(1.8rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(252, 217, 0, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(calc(1.5em + 2.2rem + 4px) + 2.5px);
  padding: 1.1rem 2.7rem 1.1rem 1.7rem;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.5;
  color: #59595a;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%233c3c3c' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1.7rem center/8px 10px;
  border: 2px solid #aaa;
  border-radius: 0.3rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #fcd900;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(252, 217, 0, 0.25); }
    .custom-select:focus::-ms-value {
      color: #59595a;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 1.7rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e5e5e5; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #59595a; }

.custom-select-sm {
  height: calc(1.5em + 1.2rem + 4px);
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  padding-left: 2.4rem;
  font-size: 1.2rem; }

.custom-select-lg {
  height: calc(1.5em + 3.8rem + 4px);
  padding-top: 1.9rem;
  padding-bottom: 1.9rem;
  padding-left: 5rem;
  font-size: 1.8rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(calc(1.5em + 2.2rem + 4px) + 2.5px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(calc(1.5em + 2.2rem + 4px) + 2.5px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: transparent;
    box-shadow: 0 3px 10px #00000026; }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e5e5e5; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(calc(1.5em + 2.2rem + 4px) + 2.5px);
  padding: 1.1rem 1.7rem;
  font-weight: 600;
  line-height: 1.5;
  color: #000;
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 0.3rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 2.2rem);
    padding: 1.1rem 1.7rem;
    line-height: 1.5;
    color: #000;
    content: "Browse";
    background-color: #fff;
    border-left: inherit;
    border-radius: 0 0.3rem 0.3rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 3px 10px #00000026; }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 3px 10px #00000026; }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 3px 10px #00000026; }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0rem;
    background-color: #fcd900;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #fcd900; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 1rem;
    color: transparent;
    cursor: pointer;
    background-color: #000;
    border-color: transparent;
    border-radius: 99px; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #fcd900;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #fcd900; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 1rem;
    color: transparent;
    cursor: pointer;
    background-color: #000;
    border-color: transparent;
    border-radius: 99px; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #fcd900;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #fcd900; }
  .custom-range::-ms-track {
    width: 100%;
    height: 1rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #000;
    border-radius: 99px; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #000;
    border-radius: 99px; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #aaa; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #aaa; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #aaa; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #ddd; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e5e5e5 #e5e5e5 #ddd; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #59595a;
    background-color: #fff;
    border-color: #ddd #ddd #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.3rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #fcd900; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 2px solid #ddd;
  border-radius: 0.3rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.3rem - 2px);
      border-top-right-radius: calc(0.3rem - 2px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.3rem - 2px);
      border-bottom-left-radius: calc(0.3rem - 2px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 2rem; }

.card-title {
  margin-bottom: 2rem; }

.card-subtitle {
  margin-top: -1rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 2rem; }

.card-header {
  padding: 2rem 2rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 2px solid #ddd; }
  .card-header:first-child {
    border-radius: calc(0.3rem - 2px) calc(0.3rem - 2px) 0 0; }

.card-footer {
  padding: 2rem 2rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 2px solid #ddd; }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.3rem - 2px) calc(0.3rem - 2px); }

.card-header-tabs {
  margin-right: -1rem;
  margin-bottom: -2rem;
  margin-left: -1rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -1rem;
  margin-left: -1rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.3rem - 2px); }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.3rem - 2px);
  border-top-right-radius: calc(0.3rem - 2px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.3rem - 2px);
  border-bottom-left-radius: calc(0.3rem - 2px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 2rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -2px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 1.1rem 1.5rem;
  margin-bottom: 3.4rem;
  font-size: 1.1rem;
  list-style: none;
  background-color: rgba(221, 221, 221, 0.31);
  border-radius: 0; }

.breadcrumb-item {
  display: flex; }
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem; }
    .breadcrumb-item + .breadcrumb-item::before {
      display: inline-block;
      padding-right: 0.5rem;
      color: #000;
      content: "/"; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none; }
  .breadcrumb-item.active {
    color: #000; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.3rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #000;
  background-color: #fff;
  border: 1px solid #ddd; }
  .page-link:hover {
    z-index: 2;
    color: #262626;
    text-decoration: none;
    background-color: #e5e5e5;
    border-color: #ddd; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(252, 217, 0, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #fcd900;
  border-color: #fcd900; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #ddd; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.alert {
  position: relative;
  padding: 1.3rem 1.4rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.3rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4.3rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1.3rem 1.4rem;
    color: inherit; }

.alert-primary {
  color: #837100;
  background-color: #fef7cc;
  border-color: #fef4b8; }
  .alert-primary hr {
    border-top-color: #fef09f; }
  .alert-primary .alert-link {
    color: #504500; }

.alert-secondary {
  color: black;
  background-color: #cccccc;
  border-color: #b8b8b8; }
  .alert-secondary hr {
    border-top-color: #ababab; }
  .alert-secondary .alert-link {
    color: black; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #837100;
  background-color: #fef7cc;
  border-color: #fef4b8; }
  .alert-warning hr {
    border-top-color: #fef09f; }
  .alert-warning .alert-link {
    color: #504500; }

.alert-danger {
  color: #6d0000;
  background-color: #f6cccc;
  border-color: #f2b8b8; }
  .alert-danger hr {
    border-top-color: #eea2a2; }
  .alert-danger .alert-link {
    color: #3a0000; }

.alert-light {
  color: #737373;
  background-color: #f8f8f8;
  border-color: whitesmoke; }
  .alert-light hr {
    border-top-color: #e8e8e8; }
  .alert-light .alert-link {
    color: #5a5a5a; }

.alert-dark {
  color: #2e2e2f;
  background-color: #dedede;
  border-color: #d1d1d1; }
  .alert-dark hr {
    border-top-color: #c4c4c4; }
  .alert-dark .alert-link {
    color: #151515; }

.alert-notice {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-notice hr {
    border-top-color: #abdde5; }
  .alert-notice .alert-link {
    color: #062c33; }

.alert-error {
  color: #6d0000;
  background-color: #f6cccc;
  border-color: #f2b8b8; }
  .alert-error hr {
    border-top-color: #eea2a2; }
  .alert-error .alert-link {
    color: #3a0000; }

.alert-white {
  color: #858585;
  background-color: white;
  border-color: white; }
  .alert-white hr {
    border-top-color: #f2f2f2; }
  .alert-white .alert-link {
    color: #6c6c6c; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.3rem; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #fcd900 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #c9ad00 !important; }

.bg-secondary {
  background-color: #000 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: black !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #fcd900 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #c9ad00 !important; }

.bg-danger {
  background-color: #d10000 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #9e0000 !important; }

.bg-light {
  background-color: #ddd !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #c4c4c4 !important; }

.bg-dark {
  background-color: #59595a !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #404040 !important; }

.bg-notice {
  background-color: #17a2b8 !important; }

a.bg-notice:hover, a.bg-notice:focus,
button.bg-notice:hover,
button.bg-notice:focus {
  background-color: #117a8b !important; }

.bg-error {
  background-color: #d10000 !important; }

a.bg-error:hover, a.bg-error:focus,
button.bg-error:hover,
button.bg-error:focus {
  background-color: #9e0000 !important; }

.bg-white {
  background-color: #fff !important; }

a.bg-white:hover, a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e6e6 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #ddd !important; }

.border-top {
  border-top: 1px solid #ddd !important; }

.border-right {
  border-right: 1px solid #ddd !important; }

.border-bottom {
  border-bottom: 1px solid #ddd !important; }

.border-left {
  border-left: 1px solid #ddd !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #fcd900 !important; }

.border-secondary {
  border-color: #000 !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #fcd900 !important; }

.border-danger {
  border-color: #d10000 !important; }

.border-light {
  border-color: #ddd !important; }

.border-dark {
  border-color: #59595a !important; }

.border-notice {
  border-color: #17a2b8 !important; }

.border-error {
  border-color: #d10000 !important; }

.border-white {
  border-color: #fff !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.3rem !important; }

.rounded {
  border-radius: 0.3rem !important; }

.rounded-top {
  border-top-left-radius: 0.3rem !important;
  border-top-right-radius: 0.3rem !important; }

.rounded-right {
  border-top-right-radius: 0.3rem !important;
  border-bottom-right-radius: 0.3rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.3rem !important;
  border-bottom-left-radius: 0.3rem !important; }

.rounded-left {
  border-top-left-radius: 0.3rem !important;
  border-bottom-left-radius: 0.3rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1024px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1240px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 1024px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1240px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 1024px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1240px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.1875rem 0.375rem rgba(89, 89, 90, 0.16) !important; }

.shadow-lg {
  box-shadow: 0 3px 6px #00000029 !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 1024px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1240px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 1024px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1240px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #fcd900 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #b09700 !important; }

.text-secondary {
  color: #000 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: black !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #fcd900 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #b09700 !important; }

.text-danger {
  color: #d10000 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #850000 !important; }

.text-light {
  color: #ddd !important; }

a.text-light:hover, a.text-light:focus {
  color: #b7b7b7 !important; }

.text-dark {
  color: #59595a !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #333334 !important; }

.text-notice {
  color: #17a2b8 !important; }

a.text-notice:hover, a.text-notice:focus {
  color: #0f6674 !important; }

.text-error {
  color: #d10000 !important; }

a.text-error:hover, a.text-error:focus {
  color: #850000 !important; }

.text-white {
  color: #fff !important; }

a.text-white:hover, a.text-white:focus {
  color: #d9d9d9 !important; }

.text-body {
  color: #000 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@font-face {
  font-family: 'Inter';
  src: local("Inter Bold"), local("Inter-Bold"), url("../fonts/subset-Inter-Bold.woff2") format("woff2"), url("../fonts/subset-Inter-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Inter';
  src: local("Inter Regular"), local("Inter-Regular"), url("../fonts/subset-Inter-Regular.woff2") format("woff2"), url("../fonts/subset-Inter-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Montserrat';
  src: local("Montserrat Bold"), local("Montserrat-Bold"), url("../fonts/subset-Montserrat-Bold.woff2") format("woff2"), url("../fonts/subset-Montserrat-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Montserrat';
  src: local("Montserrat Semibold"), local("Montserrat-Semibold"), url("../fonts/subset-Montserrat-Semibold.woff2") format("woff2"), url("../fonts/subset-Montserrat-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Montserrat';
  src: local("Montserrat Light"), local("Montserrat-Light"), url("../fonts/subset-Montserrat-Light.woff2") format("woff2"), url("../fonts/subset-Montserrat-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

.page-title {
  font-weight: 700;
  font-size: 2.4rem; }

.text-normal {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }

.text-headings {
  font-family: "Montserrat", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }

/*** iPhone and iOS Form Input Zoom Fixes ***/
@media screen and (device-aspect-ratio: 2 / 3) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'] {
    font-size: 1.6rem; } }

/* Fix Input Zoom on iPhone 5,
5C,
5S,
iPod Touch 5g */
@media screen and (device-aspect-ratio: 40 / 71) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'] {
    font-size: 1.6rem; } }

/* Fix Input Zoom on iPhone 6,
iPhone 6s,
iPhone 7  */
@media screen and (device-aspect-ratio: 375 / 667) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='tel'],
  input[type='url'] {
    font-size: 1.6rem; } }

/* Fix Input Zoom on iPhone 6 Plus,
iPhone 6s Plus,
iPhone 7 Plus,
iPhone 8,
iPhone X,
XS,
XS Max  */
@media screen and (device-aspect-ratio: 9 / 16) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='tel'],
  input[type='url'] {
    font-size: 1.6rem; } }

[data-content-type='faq'] {
  border-bottom: none !important;
  margin-bottom: 3rem !important; }
  [data-content-type='faq'],
  [data-content-type='faq'] [data-content-type='faq-item'] {
    border-color: #ddd; }
  [data-content-type='faq'] [data-content-type='faq-item'] {
    border-left: none !important;
    border-right: none !important;
    padding-left: 0 !important; }
    [data-content-type='faq'] [data-content-type='faq-item']:first-child {
      border-top: none !important; }
  [data-content-type='faq'] h3 {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ddd !important;
    padding: 0 !important;
    height: 40px; }
    [data-content-type='faq'] h3::before, [data-content-type='faq'] h3::after {
      content: '';
      position: absolute;
      right: 2.5rem;
      width: 10px;
      height: 10px;
      background-image: url("../images/minus.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain; }
    [data-content-type='faq'] h3::after {
      transform: rotate(90deg);
      transform-origin: center; }
    [data-content-type='faq'] h3:hover {
      cursor: pointer; }
    [data-content-type='faq'] h3:focus {
      outline: none; }
    [data-content-type='faq'] h3[aria-expanded='true'] {
      background-color: #fff; }
      [data-content-type='faq'] h3[aria-expanded='true']::after {
        display: none; }
  [data-content-type='faq'] [data-element='answer'] {
    padding: 2rem 0 2rem !important; }

#sidebar-account {
  overflow: hidden; }
  #sidebar-account .account-panels {
    transition: ease 0.3s;
    position: absolute;
    width: 100%;
    height: 100%; }
    #sidebar-account .account-panels.panel-sub {
      display: block;
      left: 100%; }
      #sidebar-account .account-panels.panel-sub.panel-active {
        left: 0; }
    #sidebar-account .account-panels.panel-main {
      right: 100%; }
      #sidebar-account .account-panels.panel-main.panel-active {
        right: 0; }
    #sidebar-account .account-panels.panel-login {
      background: #fcd900; }
      #sidebar-account .account-panels.panel-login .block-content {
        padding: 0;
        overflow-y: auto; }
        #sidebar-account .account-panels.panel-login .block-content .form-login,
        #sidebar-account .account-panels.panel-login .block-content .social-login-wrapper {
          padding-left: 2rem;
          padding-right: 2rem; }
        #sidebar-account .account-panels.panel-login .block-content .form-login {
          padding-left: 2rem;
          padding-right: 2rem;
          background: #fff; }
          #sidebar-account .account-panels.panel-login .block-content .form-login .announcement {
            display: none; }
        #sidebar-account .account-panels.panel-login .block-content .social-login-wrapper {
          position: relative;
          font-size: 15px; }
          #sidebar-account .account-panels.panel-login .block-content .social-login-wrapper > div {
            max-width: 100%; }
    #sidebar-account .account-panels.panel-forgot .block-content p {
      padding: 0 3rem; }
    #sidebar-account .account-panels.panel-forgot .block-content fieldset.fieldset.forgot {
      margin-bottom: 0; }
    #sidebar-account .account-panels.panel-forgot .captcha .nested {
      margin-top: 1rem; }
    #sidebar-account .account-panels.panel-register .control {
      padding-bottom: 1rem; }
    #sidebar-account .account-panels.panel-register input[name='captcha[user_create]'] + .mage-error {
      margin-bottom: 1rem; }
    #sidebar-account .account-panels .block-content {
      height: 100%;
      overflow-y: auto;
      padding: 1rem 1rem 0 1rem;
      text-align: left; }
      #sidebar-account .account-panels .block-content a:not(.btn):not(a.pagebuilder-button-primary):not(
      .pagebuilder-button-primary):not(a.pagebuilder-button-secondary):not(
      .pagebuilder-button-secondary) {
        text-decoration: underline; }
        #sidebar-account .account-panels .block-content a:not(.btn):not(a.pagebuilder-button-primary):not(
        .pagebuilder-button-primary):not(a.pagebuilder-button-secondary):not(
        .pagebuilder-button-secondary):hover {
          text-decoration: none; }
    #sidebar-account .account-panels .link-left {
      padding-left: 5rem;
      padding-right: 5rem; }
    #sidebar-account .account-panels a.remind {
      text-decoration: underline; }
      #sidebar-account .account-panels a.remind:hover {
        text-decoration: none; }
    #sidebar-account .account-panels a.register {
      text-transform: capitalize; }

.customer-account-forgotpassword .password-forget {
  margin-top: 20px; }
  .customer-account-forgotpassword .password-forget fieldset {
    margin-bottom: 0; }
    .customer-account-forgotpassword .password-forget fieldset .nested {
      margin-top: 15px; }
  .customer-account-forgotpassword .password-forget .mobile-back {
    padding-top: 10px !important; }

.customer-account-login .social-login-wrapper {
  margin: 0 auto;
  padding-top: 0; }
  @media (min-width: 1024px) {
    .customer-account-login .social-login-wrapper {
      padding-left: 3rem; } }
  @media (max-width: 1023.98px) {
    .customer-account-login .social-login-wrapper {
      text-align: center;
      padding-left: 0;
      padding-right: 0; } }

.customer-account-login .login-container .fieldset::after {
  margin-top: 0; }

.customer-account-create .fieldset .control,
.customer-account-create .fieldset .custom-control {
  margin-bottom: 15px; }

.customer-account-create .mobile-back {
  padding-top: 15px; }

span.required {
  color: #d10000;
  font-size: 1.2rem;
  margin: 0 0 0 0.3rem; }

.message.message {
  margin-bottom: 1.3rem;
  padding: 1.3rem 1.4rem;
  border-radius: 0.3rem;
  color: #fff;
  text-align: left;
  font-size: 1.2rem; }
  .message.message span::before,
  .message.message div::before {
    display: none !important; }
  .product-info-main .message.message div {
    width: 100%; }
  .message.message a {
    text-decoration: underline;
    color: inherit !important; }
    .message.message a:hover {
      text-decoration: none; }
  .message.message.primary {
    color: #837100;
    background-color: #fef7cc;
    border-color: #fef4b8; }
    .message.message.primary hr {
      border-top-color: #fef09f; }
    .message.message.primary .alert-link {
      color: #504500; }
  .message.message.secondary {
    color: black;
    background-color: #cccccc;
    border-color: #b8b8b8; }
    .message.message.secondary hr {
      border-top-color: #ababab; }
    .message.message.secondary .alert-link {
      color: black; }
  .message.message.success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb; }
    .message.message.success hr {
      border-top-color: #b1dfbb; }
    .message.message.success .alert-link {
      color: #0b2e13; }
  .message.message.info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb; }
    .message.message.info hr {
      border-top-color: #abdde5; }
    .message.message.info .alert-link {
      color: #062c33; }
  .message.message.warning {
    color: #837100;
    background-color: #fef7cc;
    border-color: #fef4b8; }
    .message.message.warning hr {
      border-top-color: #fef09f; }
    .message.message.warning .alert-link {
      color: #504500; }
  .message.message.danger {
    color: #6d0000;
    background-color: #f6cccc;
    border-color: #f2b8b8; }
    .message.message.danger hr {
      border-top-color: #eea2a2; }
    .message.message.danger .alert-link {
      color: #3a0000; }
  .message.message.light {
    color: #737373;
    background-color: #f8f8f8;
    border-color: whitesmoke; }
    .message.message.light hr {
      border-top-color: #e8e8e8; }
    .message.message.light .alert-link {
      color: #5a5a5a; }
  .message.message.dark {
    color: #2e2e2f;
    background-color: #dedede;
    border-color: #d1d1d1; }
    .message.message.dark hr {
      border-top-color: #c4c4c4; }
    .message.message.dark .alert-link {
      color: #151515; }
  .message.message.notice {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb; }
    .message.message.notice hr {
      border-top-color: #abdde5; }
    .message.message.notice .alert-link {
      color: #062c33; }
  .message.message.error {
    color: #6d0000;
    background-color: #f6cccc;
    border-color: #f2b8b8; }
    .message.message.error hr {
      border-top-color: #eea2a2; }
    .message.message.error .alert-link {
      color: #3a0000; }
  .message.message.white {
    color: #858585;
    background-color: white;
    border-color: white; }
    .message.message.white hr {
      border-top-color: #f2f2f2; }
    .message.message.white .alert-link {
      color: #6c6c6c; }
  .message.message.message-primary {
    background-color: #feec80;
    color: #000; }

.alert {
  font-size: 1.2rem; }
  .alert:empty {
    display: none; }
  .alert.help-text {
    position: relative;
    text-align: center; }
    .alert.help-text::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      width: 0;
      height: 0;
      transform: translateX(-50%);
      border-top-width: 8px;
      border-top-style: solid;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent; }
    .alert.help-text.arrow-top::after {
      border-bottom-width: 8px;
      border-bottom-style: solid;
      border-top-width: 0;
      bottom: 100%;
      top: auto; }
    .alert.help-text.arrow-bottom::after {
      border-top-width: 8px;
      border-top-style: solid;
      border-bottom-width: 0;
      top: 100%;
      bottom: auto; }
  .alert.alert-primary {
    background-color: #feec80;
    color: #000; }
    .alert.alert-primary.help-text::after {
      border-top-color: #feec80; }
    .alert.alert-primary.arrow-top::after {
      border-top-color: transparent;
      border-bottom-color: #feec80; }
    .alert.alert-primary.arrow-bottom::after {
      border-bottom-color: transparent;
      border-top-color: #feec80; }
  .alert.alert-secondary, .alert.alert-dark {
    background-color: #000;
    color: #fff; }
    .alert.alert-secondary.help-text::after, .alert.alert-dark.help-text::after {
      border-top-color: #000; }
    .alert.alert-secondary.arrow-top::after, .alert.alert-dark.arrow-top::after {
      border-top-color: transparent;
      border-bottom-color: #000; }
    .alert.alert-secondary.arrow-bottom::after, .alert.alert-dark.arrow-bottom::after {
      border-bottom-color: transparent;
      border-top-color: #000; }
  .alert p {
    margin-bottom: 0; }

.brand-brand-slider .slick-track {
  align-items: center; }

.brand-brand-slider .am-image {
  max-width: 260px;
  max-height: 140px;
  padding: 1rem; }

.brands-filters .letters {
  margin-top: 1.2rem; }

.brands-filters .-letter-all {
  margin-right: 1rem; }

.brands-filters .letter.letter {
  text-decoration: none;
  font-weight: 700;
  font-size: 1.2rem;
  background-color: #fcd900;
  border-color: #fcd900;
  color: #000; }
  .brands-filters .letter.letter:hover, .brands-filters .letter.letter.-active {
    background-color: #000;
    border-color: #000;
    color: #fff; }

@media (min-width: 768px) {
  .brand-images.brand-images {
    margin-right: -3.5rem; } }

@media (min-width: 768px) {
  .brand-images.brand-images .brands-letter {
    width: 33.333%; } }

.brand-images.brand-images .brands-letter .title {
  padding: 0 4px 1px;
  background-color: #fcd900;
  border-color: #fcd900;
  color: #000;
  font-weight: 700; }

.brand-images.brand-images .brands-letter .brand-item .item {
  text-decoration: none; }

.brand-images.brand-images .brands-letter .brand-item .label {
  font-weight: 700; }

.ambrands-search-wrapper #livesearch {
  border-top-color: #fcd900; }
  .ambrands-search-wrapper #livesearch a {
    font-weight: 700;
    text-decoration: none; }
    .ambrands-search-wrapper #livesearch a:hover {
      background-color: #000;
      color: #fff; }

.breadcrumbs {
  max-width: none;
  padding: 0;
  margin-bottom: 3.4rem;
  background-color: rgba(221, 221, 221, 0.31); }

.breadcrumb {
  margin-bottom: 0;
  background-color: transparent;
  color: #000; }
  .breadcrumb a {
    font-weight: 700;
    color: inherit; }
  .breadcrumb .breadcrumb-item {
    margin-bottom: 0; }

.btn-primary, a.pagebuilder-button-primary,
.pagebuilder-button-primary,
.btn.action-accept,
a.action-accept.pagebuilder-button-primary,
.action-accept.pagebuilder-button-primary,
a.action-accept.pagebuilder-button-secondary,
.action-accept.pagebuilder-button-secondary,
.account-nav .item.item a.action-accept[href*='logout'] {
  color: #000; }
  .btn-primary, a.pagebuilder-button-primary,
  .pagebuilder-button-primary, .btn-primary:focus, a.pagebuilder-button-primary:focus,
  .pagebuilder-button-primary:focus,
  .btn.action-accept,
  a.action-accept.pagebuilder-button-primary,
  .action-accept.pagebuilder-button-primary,
  a.action-accept.pagebuilder-button-secondary,
  .action-accept.pagebuilder-button-secondary,
  .account-nav .item.item a.action-accept[href*='logout'],
  .btn.action-accept:focus,
  .action-accept.pagebuilder-button-primary:focus,
  .action-accept.pagebuilder-button-secondary:focus,
  .account-nav .item.item a.action-accept:focus[href*='logout'] {
    background: linear-gradient(180deg, #fcd900 50%, #ffba00 100%); }

.btn-secondary, a.pagebuilder-button-secondary,
.pagebuilder-button-secondary,
.btn-dark {
  color: #fff; }
  .btn-secondary, a.pagebuilder-button-secondary,
  .pagebuilder-button-secondary, .btn-secondary:focus, a.pagebuilder-button-secondary:focus,
  .pagebuilder-button-secondary:focus,
  .btn-dark,
  .btn-dark:focus {
    background: linear-gradient(180deg, #59595a 0%, black 100%); }

.btn-white {
  color: #000; }

.btn-fb,
.btn-goo {
  color: #fff; }
  .btn-fb:focus,
  .btn-goo:focus {
    border-width: 2px; }

.btn-fb {
  background-color: #3b5998;
  border: 2px solid #3b5998; }

.btn-goo {
  background-color: #dd4b39;
  border: 2px solid #dd4b39; }

.btn-primary, a.pagebuilder-button-primary,
.pagebuilder-button-primary, .btn-secondary, a.pagebuilder-button-secondary,
.pagebuilder-button-secondary,
.btn-dark,
.btn-white,
.btn.action-accept,
a.action-accept.pagebuilder-button-primary,
.action-accept.pagebuilder-button-primary,
a.action-accept.pagebuilder-button-secondary,
.action-accept.pagebuilder-button-secondary,
.account-nav .item.item a.action-accept[href*='logout'] {
  border: none;
  padding: 1.3rem 4.2rem; }
  .btn-primary:focus, a.pagebuilder-button-primary:focus,
  .pagebuilder-button-primary:focus, .btn-primary:active, a.pagebuilder-button-primary:active,
  .pagebuilder-button-primary:active, .btn-secondary:focus, a.pagebuilder-button-secondary:focus,
  .pagebuilder-button-secondary:focus,
  .btn-secondary:active,
  a.pagebuilder-button-secondary:active,
  .pagebuilder-button-secondary:active,
  .btn-dark:focus,
  .btn-dark:active,
  .btn-white:focus,
  .btn-white:active,
  .btn.action-accept:focus,
  .action-accept.pagebuilder-button-primary:focus,
  .action-accept.pagebuilder-button-secondary:focus,
  .account-nav .item.item a.action-accept:focus[href*='logout'],
  .btn.action-accept:active,
  .action-accept.pagebuilder-button-primary:active,
  .action-accept.pagebuilder-button-secondary:active,
  .account-nav .item.item a.action-accept:active[href*='logout'] {
    border: none; }
  .btn-primary:hover, a.pagebuilder-button-primary:hover,
  .pagebuilder-button-primary:hover, .btn-secondary:hover, a.pagebuilder-button-secondary:hover,
  .pagebuilder-button-secondary:hover,
  .btn-dark:hover,
  .btn-white:hover,
  .btn.action-accept:hover,
  .action-accept.pagebuilder-button-primary:hover,
  .action-accept.pagebuilder-button-secondary:hover,
  .account-nav .item.item a.action-accept:hover[href*='logout'] {
    padding: 1.1rem 4rem;
    border: 2px solid #000;
    background: #fff;
    color: #000; }
    .page-footer.page-footer .btn-primary:hover,
    .page-footer.page-footer .pagebuilder-button-primary:hover, .page-footer.page-footer .btn-secondary:hover,
    .page-footer.page-footer .pagebuilder-button-secondary:hover, .page-footer.page-footer
    .btn-dark:hover, .page-footer.page-footer
    .btn-white:hover, .page-footer.page-footer .btn.action-accept:hover,
    .page-footer.page-footer .action-accept.pagebuilder-button-primary:hover,
    .page-footer.page-footer .action-accept.pagebuilder-button-secondary:hover, .page-footer.page-footer .account-nav .item.item a.action-accept:hover[href*='logout'], .account-nav .item.item .page-footer.page-footer a.action-accept:hover[href*='logout'] {
      background: none;
      border-color: #fff;
      color: #fff; }
  .btn-primary.btn-sm, a.btn-sm.pagebuilder-button-primary, .account-nav .item.item a.pagebuilder-button-primary[href*='logout'],
  .btn-sm.pagebuilder-button-primary, .account-nav .item.item a.btn-primary[href*='logout'],
  .btn-secondary.btn-sm,
  a.btn-sm.pagebuilder-button-secondary,
  .account-nav .item.item a.pagebuilder-button-secondary[href*='logout'],
  .btn-sm.pagebuilder-button-secondary,
  .account-nav .item.item a.btn-secondary[href*='logout'],
  .btn-dark.btn-sm,
  .account-nav .item.item a.btn-dark[href*='logout'],
  .btn-white.btn-sm,
  .account-nav .item.item a.btn-white[href*='logout'],
  .btn.action-accept.btn-sm,
  .action-accept.btn-sm.pagebuilder-button-primary,
  .action-accept.btn-sm.pagebuilder-button-secondary,
  .account-nav .item.item a.action-accept[href*='logout'] {
    padding: 0.8rem 2.6rem; }
    .btn-primary.btn-sm:hover, .account-nav .item.item a.pagebuilder-button-primary:hover[href*='logout'],
    .btn-sm.pagebuilder-button-primary:hover, .account-nav .item.item a.btn-primary:hover[href*='logout'],
    .btn-secondary.btn-sm:hover,
    .account-nav .item.item a.pagebuilder-button-secondary:hover[href*='logout'],
    .btn-sm.pagebuilder-button-secondary:hover,
    .account-nav .item.item a.btn-secondary:hover[href*='logout'],
    .btn-dark.btn-sm:hover,
    .account-nav .item.item a.btn-dark:hover[href*='logout'],
    .btn-white.btn-sm:hover,
    .account-nav .item.item a.btn-white:hover[href*='logout'],
    .btn.action-accept.btn-sm:hover,
    .action-accept.btn-sm.pagebuilder-button-primary:hover,
    .action-accept.btn-sm.pagebuilder-button-secondary:hover,
    .account-nav .item.item a.action-accept:hover[href*='logout'] {
      padding: 0.6rem 2.4rem; }
  .btn-primary.btn-lg, a.btn-lg.pagebuilder-button-primary,
  .btn-lg.pagebuilder-button-primary,
  .btn-secondary.btn-lg,
  a.btn-lg.pagebuilder-button-secondary,
  .btn-lg.pagebuilder-button-secondary,
  .btn-dark.btn-lg,
  .btn-white.btn-lg,
  .btn.action-accept.btn-lg,
  .action-accept.btn-lg.pagebuilder-button-primary,
  .action-accept.btn-lg.pagebuilder-button-secondary,
  .account-nav .item.item a.action-accept.btn-lg[href*='logout'] {
    padding: 2.1rem 5.2rem; }
    .btn-primary.btn-lg:hover,
    .btn-lg.pagebuilder-button-primary:hover,
    .btn-secondary.btn-lg:hover,
    .btn-lg.pagebuilder-button-secondary:hover,
    .btn-dark.btn-lg:hover,
    .btn-white.btn-lg:hover,
    .btn.action-accept.btn-lg:hover,
    .action-accept.btn-lg.pagebuilder-button-primary:hover,
    .action-accept.btn-lg.pagebuilder-button-secondary:hover,
    .account-nav .item.item a.action-accept.btn-lg:hover[href*='logout'] {
      padding: 1.9rem 5rem; }

.btn-sm, .account-nav .item.item a[href*='logout'] {
  font-weight: 600; }

.btn-outline-secondary, .account-nav .item.item a[href*='logout'],
.btn.action-dismiss,
a.action-dismiss.pagebuilder-button-primary,
.action-dismiss.pagebuilder-button-primary,
a.action-dismiss.pagebuilder-button-secondary,
.action-dismiss.pagebuilder-button-secondary,
.account-nav .item.item a.action-dismiss[href*='logout'] {
  position: relative;
  background-color: #fff; }
  .btn-outline-secondary:hover, .account-nav .item.item a:hover[href*='logout'], .btn-outline-secondary:focus, .account-nav .item.item a:focus[href*='logout'],
  .btn.action-dismiss:hover,
  .action-dismiss.pagebuilder-button-primary:hover,
  .action-dismiss.pagebuilder-button-secondary:hover,
  .account-nav .item.item a.action-dismiss:hover[href*='logout'],
  .btn.action-dismiss:focus,
  .action-dismiss.pagebuilder-button-primary:focus,
  .action-dismiss.pagebuilder-button-secondary:focus,
  .account-nav .item.item a.action-dismiss:focus[href*='logout'] {
    border-width: 2px; }
  .btn-outline-secondary.active, .account-nav .item.item a.active[href*='logout'],
  .btn.action-dismiss.active,
  .action-dismiss.active.pagebuilder-button-primary,
  .action-dismiss.active.pagebuilder-button-secondary,
  .account-nav .item.item a.action-dismiss.active[href*='logout'] {
    box-shadow: 0 1px 8px #0003; }


.btn.action-dismiss,
a.action-dismiss.pagebuilder-button-primary,
.action-dismiss.pagebuilder-button-primary,
a.action-dismiss.pagebuilder-button-secondary,
.action-dismiss.pagebuilder-button-secondary,
.account-nav .item.item a.action-dismiss[href*='logout'] {
  color: #000;
  border-color: #000; }
  
  .btn.action-dismiss:hover,
  .action-dismiss.pagebuilder-button-primary:hover,
  .action-dismiss.pagebuilder-button-secondary:hover,
  .account-nav .item.item a.action-dismiss:hover[href*='logout'] {
    color: #fff;
    background-color: #000;
    border-color: #000; }
  
  .btn.action-dismiss:focus,
  .action-dismiss.pagebuilder-button-primary:focus,
  .action-dismiss.pagebuilder-button-secondary:focus,
  .account-nav .item.item a.action-dismiss:focus[href*='logout'], .btn.action-dismiss.focus,
  .action-dismiss.focus.pagebuilder-button-primary,
  .action-dismiss.focus.pagebuilder-button-secondary, .account-nav .item.item a.action-dismiss.focus[href*='logout'] {
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }
  .btn.action-dismiss.disabled,
  .action-dismiss.disabled.pagebuilder-button-primary,
  .action-dismiss.disabled.pagebuilder-button-secondary, .account-nav .item.item a.action-dismiss.disabled[href*='logout'], .btn.action-dismiss:disabled,
  .action-dismiss.pagebuilder-button-primary:disabled,
  .action-dismiss.pagebuilder-button-secondary:disabled, .account-nav .item.item a.action-dismiss:disabled[href*='logout'] {
    color: #000;
    background-color: transparent; }
  .btn.action-dismiss:not(:disabled):not(.disabled):active,
  .action-dismiss.pagebuilder-button-primary:not(:disabled):not(.disabled):active,
  .action-dismiss.pagebuilder-button-secondary:not(:disabled):not(.disabled):active, .account-nav .item.item a.action-dismiss:not(:disabled):not(.disabled):active[href*='logout'], .btn.action-dismiss:not(:disabled):not(.disabled).active,
  .action-dismiss.pagebuilder-button-primary:not(:disabled):not(.disabled).active,
  .action-dismiss.pagebuilder-button-secondary:not(:disabled):not(.disabled).active, .account-nav .item.item a.action-dismiss:not(:disabled):not(.disabled).active[href*='logout'],
  .show > .btn.action-dismiss.dropdown-toggle,
  .show > .action-dismiss.dropdown-toggle.pagebuilder-button-primary,
  .show > .action-dismiss.dropdown-toggle.pagebuilder-button-secondary,
  .account-nav .item.item .show > a.action-dismiss.dropdown-toggle[href*='logout'] {
    color: #fff;
    background-color: #000;
    border-color: #000; }
    .btn.action-dismiss:not(:disabled):not(.disabled):active:focus,
    .action-dismiss.pagebuilder-button-primary:not(:disabled):not(.disabled):active:focus,
    .action-dismiss.pagebuilder-button-secondary:not(:disabled):not(.disabled):active:focus, .account-nav .item.item a.action-dismiss:not(:disabled):not(.disabled):active:focus[href*='logout'], .btn.action-dismiss:not(:disabled):not(.disabled).active:focus,
    .action-dismiss.pagebuilder-button-primary:not(:disabled):not(.disabled).active:focus,
    .action-dismiss.pagebuilder-button-secondary:not(:disabled):not(.disabled).active:focus, .account-nav .item.item a.action-dismiss:not(:disabled):not(.disabled).active:focus[href*='logout'],
    .show > .btn.action-dismiss.dropdown-toggle:focus,
    .show > .action-dismiss.dropdown-toggle.pagebuilder-button-primary:focus,
    .show > .action-dismiss.dropdown-toggle.pagebuilder-button-secondary:focus,
    .account-nav .item.item .show > a.action-dismiss.dropdown-toggle:focus[href*='logout'] {
      box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }

.btn-social {
  position: relative;
  text-align: left;
  padding-left: 22%;
  padding-right: 3rem; }
  .btn-social .svg-container {
    position: absolute;
    top: 50%;
    left: 0.75rem;
    bottom: auto;
    transform: translateY(-50%);
    width: 30px;
    text-align: center;
    border: none; }
    .btn-social .svg-container svg {
      height: 23px; }

.btn-action-secondary.btn-action-secondary.btn-action-secondary {
  position: relative;
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 37px;
  height: 37px;
  padding: 0;
  border: none !important;
  border-radius: 99px !important;
  background-color: #f0f0f0;
  color: #000; }
  .btn-action-secondary.btn-action-secondary.btn-action-secondary span:not(.sr-only) {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin: 3px auto 0;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.8rem; }
    @media (max-width: 575.98px) {
      .btn-action-secondary.btn-action-secondary.btn-action-secondary span:not(.sr-only) {
        font-size: 0.9rem; } }
  .btn-action-secondary.btn-action-secondary.btn-action-secondary:hover {
    background-color: #d7d7d7; }
  .btn-action-secondary.btn-action-secondary.btn-action-secondary.btn-white {
    background-color: #fff;
    box-shadow: 0 3px 6px #00000029; }
    .btn-action-secondary.btn-action-secondary.btn-action-secondary.btn-white:hover {
      background-color: #e6e6e6; }

.btn, a.pagebuilder-button-primary,
.pagebuilder-button-primary, a.pagebuilder-button-secondary,
.pagebuilder-button-secondary, .account-nav .item.item a[href*='logout'] {
  white-space: nowrap; }
  .btn:disabled, a.pagebuilder-button-primary:disabled,
  .pagebuilder-button-primary:disabled, a.pagebuilder-button-secondary:disabled,
  .pagebuilder-button-secondary:disabled, .account-nav .item.item a:disabled[href*='logout'] {
    background: #e5e5e5;
    border: none;
    color: #aaa; }

.btn-primary-flat {
  background-color: #fcd900;
  border-color: #fcd900; }
  .btn-primary-flat:hover {
    background-color: #000;
    color: #fff; }

.btn-qty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 2.4rem;
  height: 2.4rem;
  padding: 0;
  border-radius: 50%;
  border-width: 1px; }
  @media (max-width: 575.98px) {
    .btn-qty {
      width: 3rem;
      height: 3rem; } }

.btn-link {
  border: none; }
  .btn-link:hover, .btn-link:focus, .btn-link:active {
    border: none;
    background: none;
    box-shadow: none; }

.btn-collapse {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .btn-collapse svg:last-child {
    display: none; }
  .btn-collapse:not(.collapsed) svg {
    display: none; }
    .btn-collapse:not(.collapsed) svg:last-child {
      display: block; }

.compare-container {
  position: sticky;
  bottom: 0;
  left: 0;
  z-index: 50; }

.link.compare {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  background-color: #000;
  color: #fff; }
  @media (min-width: 1024px) {
    .link.compare {
      justify-content: center; } }
  .link.compare .btn-link {
    font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: normal;
    font-size: 1.1rem;
    color: inherit; }
  .link.compare p {
    margin-bottom: 0; }
  .link.compare small {
    font-size: 1.1rem; }
  .link.compare .btn-primary:hover,
  .link.compare .pagebuilder-button-primary:hover {
    background: none;
    border-color: #fff;
    color: #fff; }

.sidebar-compare .block-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  width: 100%; }

.input-text,
.select {
  display: block;
  width: 100%;
  height: calc(calc(1.5em + 2.2rem + 4px) + 2.5px);
  padding: 1.1rem 1.7rem 1.1rem 1.7rem;
  font-weight: 400;
  line-height: 1.5;
  color: #59595a;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid #aaa;
  font-size: 1.3rem;
  border-radius: 0.3rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .input-text,
    .select {
      transition: none; } }
  .input-text::-ms-expand,
  .select::-ms-expand {
    background-color: transparent;
    border: 0; }
  .input-text:-moz-focusring,
  .select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #59595a; }
  .input-text:focus,
  .select:focus {
    color: #59595a;
    background-color: #fff;
    border-color: #fcd900;
    outline: 0;
    box-shadow: 0 3px 10px #00000026; }
  .input-text::placeholder,
  .select::placeholder {
    color: #aaa;
    opacity: 1; }
  .input-text:disabled, .input-text[readonly],
  .select:disabled,
  .select[readonly] {
    background-color: #e5e5e5;
    opacity: 1; }
  .input-text:not(:first-child),
  .select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
    label + .input-text:not(:first-child), label +
    .select:not(:first-child) {
      border-top-left-radius: 0.3rem;
      border-bottom-left-radius: 0.3rem; }
  .input-text:not(:last-child),
  .select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  @media (max-width: 575.98px) {
    .input-text,
    .select {
      background-position: right 1.5rem center; } }

textarea.input-text {
  height: auto; }

.label {
  font-size: 1.2rem; }

select {
  appearance: none;
  background-image: url("../images/down.svg");
  background-size: 14px 7px;
  background-position: right 2rem center;
  background-repeat: no-repeat; }

.floating-label {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .floating-label label {
    position: relative;
    z-index: 1;
    display: block;
    margin: 0 0.9rem;
    padding: 0 0.9rem;
    background: #fff;
    font-family: "Montserrat", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 600; }
  .floating-label .form-control,
  .floating-label .input-text,
  .floating-label .control {
    width: 100%;
    margin-top: -0.8rem; }
  .floating-label .input-group .form-control,
  .floating-label .input-group .input-text {
    flex-grow: 1;
    width: auto;
    min-width: 0; }
    .floating-label .input-group .form-control ~ div,
    .floating-label .input-group .input-text ~ div {
      margin-top: -0.8rem; }
  .floating-label .control .form-control,
  .floating-label .control .input-text {
    margin-top: 0; }

input.mage-error,
select.mage-error,
textarea.mage-error {
  border-color: #d10000; }

div.mage-error {
  width: 100%;
  margin-top: 0;
  padding-left: 0.2rem;
  font-size: 1.1rem; }

.custom-control {
  min-height: 3rem; }
  .custom-control input[disabled] ~ .custom-control-label::before, .custom-control input:disabled ~ .custom-control-label::before {
    border-color: #ddd; }

.email .control,
.password .control {
  position: relative; }
  .email .control::before,
  .password .control::before {
    content: '';
    position: absolute;
    top: 1rem;
    left: 1.7rem;
    width: 24px;
    height: 24px;
    background-position: center;
    background-repeat: no-repeat;
    pointer-events: none; }

.email .form-control,
.email .input-text,
.password .form-control,
.password .input-text {
  padding-left: 5.5rem; }

.email .control::before {
  background-image: url("../images/email.svg");
  background-size: 21.95px 21.95px; }

.password .control::before {
  background-image: url("../images/password.svg");
  background-size: 24px 24px; }

.required .label.label.label::after {
  content: '*';
  font-size: 1.2rem;
  margin: 0 0 0 0.3rem;
  color: #d10000; }

.review-field-ratings .label.label.label:not(#Overall_rating_label)::after {
  display: none; }

.amcform-toolbar {
  width: 100%; }

.amcform-page-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .amcform-page-wrap .form-group {
    width: 100%;
    margin-bottom: 1.9rem; }
  .amcform-page-wrap .amform-layout-two.amform-layout-two.amform-layout-two {
    display: flex;
    margin-right: 0;
    max-width: 100%; }
    @media (min-width: 768px) {
      .amcform-page-wrap .amform-layout-two.amform-layout-two.amform-layout-two {
        max-width: 48%; } }
  .amcform-page-wrap .amform-layout-three.amform-layout-three.amform-layout-three {
    display: flex; }
    .amcform-page-wrap .amform-layout-three.amform-layout-three.amform-layout-three + .amform-layout-three + .amform-layout-three {
      margin-right: 0; }
    @media (max-width: 767.98px) {
      .amcform-page-wrap .amform-layout-three.amform-layout-three.amform-layout-three {
        max-width: 100%;
        margin-right: 0; } }

.field._error .control input,
.field._error .control select,
.field._error .control textarea {
  border-color: #d60000; }

.range-container {
  position: relative;
  line-height: 0; }

.custom-range, .custom-range::-webkit-slider-runnable-track {
  background-color: transparent; }

.custom-range, .custom-range::-webkit-slider-thumb, .custom-range::-webkit-slider-runnable-track {
  height: 1rem; }

.range-value {
  position: absolute;
  bottom: 24px;
  display: block;
  width: 30px;
  height: 22px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-color: #fcd900;
  text-align: center;
  font-weight: 700;
  line-height: 2.1;
  transform: translateX(-10px);
  pointer-events: none; }
  .range-value::before {
    content: '';
    position: absolute;
    top: 100%;
    right: 0;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 10px solid #fcd900;
    margin-bottom: 2px; }

.custom-range-list {
  position: absolute;
  top: 100%;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 0;
  margin-top: 1rem;
  margin-bottom: 0;
  list-style: none; }
  .custom-range-list li {
    flex-shrink: 1;
    text-align: center;
    font-weight: 700;
    font-family: "Montserrat", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }
    .custom-range-list li:hover {
      cursor: pointer; }
  @media (max-width: 575.98px) {
    .custom-range-list {
      margin-left: -1rem;
      margin-right: -1rem;
      width: calc(100% + 2rem); }
      .custom-range-list li {
        font-weight: normal;
        font-size: 1rem; } }

.range-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 1rem;
  background-color: #000;
  border-radius: 99px;
  overflow: hidden; }
  .range-bg .range-active {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: #fcd900;
    border-radius: 99px; }

.custom-file.custom-file .custom-file-label {
  padding-left: calc(125px + 1.7rem);
  border: none;
  line-height: 1.9;
  font-family: "Montserrat", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.2rem;
  box-shadow: none !important; }
  .custom-file.custom-file .custom-file-label::after {
    content: 'Choose File';
    right: auto;
    left: 0;
    width: 125px;
    height: 33px;
    margin: auto;
    text-align: center;
    border: 2px solid #000;
    border-radius: 0.3rem;
    line-height: 0.7; }

.custom-file.custom-file .custom-file-input:hover {
  cursor: pointer; }
  .custom-file.custom-file .custom-file-input:hover + .custom-file-label::after {
    background: linear-gradient(180deg, #59595a 0%, black 100%);
    color: #fff; }

.custom-file.custom-file .custom-file-input:focus + .custom-file-label::after {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }

/* Used for Jobs at Teds form */
.cms-jobs-at-teds .fb-file label.custom-file-label::before {
  position: absolute;
  display: block;
  margin: 0 0.9rem;
  padding: 0 0.9rem;
  background: #fff;
  font-weight: 600;
  left: 0;
  z-index: 10;
  top: -15px; }

.cms-jobs-at-teds .fb-file label.custom-file-label[for=cv]::before {
  text-transform: uppercase;
  content: attr(for); }

.cms-jobs-at-teds .fb-file label.custom-file-label[for=cover-letter]::before {
  text-transform: capitalize;
  content: 'Cover Letter'; }

.cms-jobs-at-teds .fb-file label.custom-file-label::after {
  left: 3px !important;
  width: 102px;
  padding: 11px 0; }

/* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40 / 71) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'] {
    font-size: 1.6rem !important; } }

/* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
@media screen and (device-aspect-ratio: 375 / 667) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='tel'],
  input[type='url'] {
    font-size: 1.6rem !important; } }

/* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
@media screen and (device-aspect-ratio: 9 / 16) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='tel'],
  input[type='url'] {
    font-size: 1.6rem !important; } }

.custom-checkbox .custom-control-label {
  cursor: pointer; }

.gallery-placeholder {
  position: relative;
  width: 100%; }

.fotorama__nav-wrap {
  position: absolute;
  bottom: 0;
  left: 0; }
  @media (min-width: 1024px) {
    .fotorama__nav-wrap {
      left: 2.3rem; } }
  @media (max-width: 767.98px) {
    .fotorama__nav-wrap {
      bottom: -3rem; } }

@media (max-width: 767.98px) {
  .fotorama__wrap {
    margin-bottom: 3rem; } }

@media (max-width: 1023.98px) {
  .fotorama__nav__shaft {
    display: flex;
    margin: 0 auto;
    transform: none !important; } }

.fotorama__nav__frame.fotorama__nav__frame {
  height: 5px; }
  @media (min-width: 1024px) {
    .fotorama__nav__frame.fotorama__nav__frame {
      padding: 2px;
      height: 49px;
      margin-right: 0.9rem; } }
  @media (max-width: 1023.98px) {
    .fotorama__nav__frame.fotorama__nav__frame {
      width: 100%; }
      .fotorama__nav__frame.fotorama__nav__frame .fotorama__dot {
        background-color: #ddd;
        width: 100%;
        height: 5px;
        border-radius: 0;
        border: none;
        left: 0;
        top: 0; }
        .fotorama__nav__frame.fotorama__nav__frame .fotorama__dot::before {
          content: '';
          position: absolute;
          top: -20px;
          bottom: -21px;
          left: 0;
          right: 0;
          z-index: 3; }
      .fotorama__nav__frame.fotorama__nav__frame.fotorama__active .fotorama__dot {
        background-color: #000;
        border-radius: 99px; }
      .fotorama__nav__frame.fotorama__nav__frame.fotorama__nav__frame--thumb + .fotorama__nav__frame--dot .fotorama__dot {
        border-top-left-radius: 99px;
        border-bottom-left-radius: 99px; }
      .fotorama__nav__frame.fotorama__nav__frame.fotorama__nav__frame--dot:last-child .fotorama__dot {
        border-top-right-radius: 99px;
        border-bottom-right-radius: 99px; } }

.fotorama__img {
  height: 100%;
  object-fit: cover; }

.fotorama__thumb-border {
  border: 1px solid #ddd !important; }

.magnifier-preview {
  top: 0;
  left: 80px !important;
  width: 100%;
  height: 100%;
  pointer-events: none; }

.magnify-lens {
  border: none; }

.fotorama__fullscreen-icon {
  background-image: url("../images/close.svg");
  background-size: 25px 25px;
  z-index: 1; }

@media (min-width: 1024px) {
  .fotorama__arr--prev .fotorama__arr__arr,
  .fotorama__arr--next .fotorama__arr__arr {
    background-image: url("../images/right.svg");
    background-size: 14px 24.5px;
    background-position: center; }
    .fotorama__fullscreen .fotorama__arr--prev .fotorama__arr__arr, .fotorama__fullscreen
    .fotorama__arr--next .fotorama__arr__arr {
      background-image: url("../images/right-white.svg"); }
  .fotorama__arr--prev .fotorama__arr__arr {
    transform-origin: 20px 20px;
    transform: rotate(180deg); } }

@media (max-width: 1023.98px) {
  .fotorama__arr .fotorama__arr__arr {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-size: 15px 15px;
    background-image: url("../images/down.svg");
    background-position: center; }
  .fotorama__arr--prev .fotorama__arr__arr {
    transform: rotate(90deg); }
  .fotorama__arr--next .fotorama__arr__arr {
    transform: rotate(-90deg); } }

.fotorama--fullscreen {
  background-color: rgba(89, 89, 90, 0.9); }
  .fotorama--fullscreen .fotorama__wrap,
  .fotorama--fullscreen .fotorama__stage.fotorama__stage {
    height: 100% !important; }
  .fotorama--fullscreen .fotorama__nav-wrap,
  .fotorama--fullscreen .fotorama__zoom-in,
  .fotorama--fullscreen .fotorama__zoom-out {
    display: none !important; }
  .fotorama--fullscreen .fotorama__arr {
    width: 80px;
    height: 80px;
    margin: auto 0; }
  .fotorama--fullscreen .fotorama__fullscreen-icon {
    background-position: center !important; }
  .fotorama--fullscreen .fotorama__arr,
  .fotorama--fullscreen .fotorama__thumb__arr,
  .fotorama--fullscreen .fotorama__fullscreen-icon {
    display: block !important;
    background-color: transparent !important;
    background-repeat: no-repeat; }
    .fotorama--fullscreen .fotorama__arr:hover,
    .fotorama--fullscreen .fotorama__thumb__arr:hover,
    .fotorama--fullscreen .fotorama__fullscreen-icon:hover {
      background-color: rgba(255, 255, 255, 0.2) !important; }

@media (max-width: 1023.98px) {
  .fotorama {
    margin-left: -15px;
    margin-right: -15px;
    overflow: hidden;
    contain: content; } }

@media (min-width: 1024px) {
  .fotorama__stage.fotorama__stage {
    height: 400px !important; } }

@media (max-width: 1023.98px) {
  .fotorama__stage.fotorama__stage {
    height: 270px !important;
    overflow: visible; }
    .fotorama__stage.fotorama__stage::before, .fotorama__stage.fotorama__stage::after {
      content: '';
      display: block;
      position: absolute;
      background-color: rgba(255, 255, 255, 0.8);
      top: 0;
      width: calc((100vw - 100%) / 2);
      height: 100%; }
    .fotorama__stage.fotorama__stage::before {
      left: 100%; }
    .fotorama__stage.fotorama__stage::after {
      right: 100%; } }

@media (max-width: 1023.98px) {
  .fotorama__arr {
    display: block !important;
    background: none !important; }
    .fotorama__arr.fotorama__arr--prev {
      left: calc((100vw - 240px) / 2 * -1); }
    .fotorama__arr.fotorama__arr--next {
      right: calc((100vw - 240px) / 2 * -1); } }

@media (max-width: 1023.98px) {
  .fotorama__wrap {
    margin-left: auto;
    margin-right: auto; } }

@media (max-width: 1023.98px) {
  .fotorama__nav--dots {
    width: 310px !important;
    transform: translateX(-50%) !important;
    left: 120px; } }

.modal-popup .action-close::before {
  content: '';
  width: 24px;
  height: 24px;
  background-image: url("../images/plus.svg");
  background-size: 14px 14px;
  background-position: center;
  background-repeat: no-repeat;
  transform: rotate(45deg); }

.modal-popup.confirm .modal-inner-wrap {
  height: auto;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%); }

.modal-popup .modal-title {
  font-weight: 700;
  font-size: 2.4rem;
  text-align: center; }

.modal-popup .modal-footer {
  display: flex;
  justify-content: flex-end; }
  .modal-popup .modal-footer .btn,
  .modal-popup .modal-footer .pagebuilder-button-primary,
  .modal-popup .modal-footer .pagebuilder-button-secondary, .modal-popup .modal-footer .account-nav .item.item a[href*='logout'], .account-nav .item.item .modal-popup .modal-footer a[href*='logout'] {
    margin-left: 1rem; }
  .modal-popup .modal-footer .primary {
    background-color: #fcd900;
    border-color: #fcd900;
    color: #000;
    font-family: "Montserrat", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1.2rem; }
    .modal-popup .modal-footer .primary:hover {
      background-color: #000;
      color: #fff; }

.modal-popup .modal-inner-wrap {
  height: calc(100% - 10rem); }

.modals-overlay {
  cursor: pointer; }

.subscribe-modal.subscribe-modal.subscribe-modal {
  left: 0;
  overflow: hidden;
  contain: content; }
  .subscribe-modal.subscribe-modal.subscribe-modal .modal-inner-wrap {
    overflow: visible;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    max-width: 360px;
    width: 100%;
    margin: auto;
    transform: translate(-50%, -50%);
    border: 10px solid #fcd900;
    border-radius: 8px; }
    @media (max-width: 575.98px) {
      .subscribe-modal.subscribe-modal.subscribe-modal .modal-inner-wrap {
        max-width: 330px; } }
    @media (max-width: 359px) {
      .subscribe-modal.subscribe-modal.subscribe-modal .modal-inner-wrap {
        max-width: 264px; } }
    .subscribe-modal.subscribe-modal.subscribe-modal .modal-inner-wrap::before {
      content: '';
      position: absolute;
      bottom: -60px;
      left: -75px;
      z-index: 1;
      width: 156px;
      height: 274px;
      background-image: url("../images/ted.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      pointer-events: none; }
      @media (max-width: 575.98px) {
        .subscribe-modal.subscribe-modal.subscribe-modal .modal-inner-wrap::before {
          bottom: -53px;
          left: -56px;
          width: 130px;
          height: 250px; } }
      @media (max-width: 359px) {
        .subscribe-modal.subscribe-modal.subscribe-modal .modal-inner-wrap::before {
          bottom: -60px;
          left: -66px; } }
    .subscribe-modal.subscribe-modal.subscribe-modal .modal-inner-wrap .modal-content {
      padding: 2.9rem 3.5rem 1.5rem; }
      @media (max-width: 575.98px) {
        .subscribe-modal.subscribe-modal.subscribe-modal .modal-inner-wrap .modal-content {
          padding: 2.4rem 3rem 1.3rem; } }
      @media (max-width: 359px) {
        .subscribe-modal.subscribe-modal.subscribe-modal .modal-inner-wrap .modal-content {
          padding: 1.9rem 2.5rem 0.5rem; } }
    .subscribe-modal.subscribe-modal.subscribe-modal .modal-inner-wrap header {
      padding: 0; }
    .subscribe-modal.subscribe-modal.subscribe-modal .modal-inner-wrap footer {
      display: none; }
    .subscribe-modal.subscribe-modal.subscribe-modal .modal-inner-wrap .insert-container {
      padding: 0 !important; }
    .subscribe-modal.subscribe-modal.subscribe-modal .modal-inner-wrap .amform {
      margin-top: 3rem; }
  .subscribe-modal.subscribe-modal.subscribe-modal h3 {
    line-height: 1.1;
    font-weight: 800;
    font-size: 5.9rem; }
    .subscribe-modal.subscribe-modal.subscribe-modal h3 small {
      vertical-align: text-top;
      line-height: 1.5;
      font-weight: 700;
      font-size: 1.8rem; }
    @media (max-width: 575.98px) {
      .subscribe-modal.subscribe-modal.subscribe-modal h3 {
        font-size: 5.3rem; } }
    @media (max-width: 359px) {
      .subscribe-modal.subscribe-modal.subscribe-modal h3 {
        font-size: 4.1rem; }
        .subscribe-modal.subscribe-modal.subscribe-modal h3 small {
          line-height: 1.25; } }
  .subscribe-modal.subscribe-modal.subscribe-modal .h4 {
    display: block;
    font-weight: 700;
    font-size: 1.6rem; }
    @media (max-width: 575.98px) {
      .subscribe-modal.subscribe-modal.subscribe-modal .h4 {
        font-size: 1.45rem; } }
    @media (max-width: 359px) {
      .subscribe-modal.subscribe-modal.subscribe-modal .h4 {
        font-size: 1.13rem; } }
  .subscribe-modal.subscribe-modal.subscribe-modal .teds-border {
    border: none;
    border-radius: 0;
    padding: 0 !important; }
  .subscribe-modal.subscribe-modal.subscribe-modal .btn,
  .subscribe-modal.subscribe-modal.subscribe-modal .pagebuilder-button-primary,
  .subscribe-modal.subscribe-modal.subscribe-modal .pagebuilder-button-secondary, .subscribe-modal.subscribe-modal.subscribe-modal .account-nav .item.item a[href*='logout'], .account-nav .item.item .subscribe-modal.subscribe-modal.subscribe-modal a[href*='logout'] {
    width: 100%; }
  .subscribe-modal.subscribe-modal.subscribe-modal .action-close {
    position: absolute;
    top: -56px;
    right: 0;
    z-index: 1;
    padding: 1rem;
    margin-right: -1.5rem; }
    .subscribe-modal.subscribe-modal.subscribe-modal .action-close::before {
      width: 28.28px;
      height: 28.28px;
      background-image: url("../images/close-thin.svg");
      background-size: contain;
      transform: none; }
  .subscribe-modal.subscribe-modal.subscribe-modal p {
    margin: 0; }

.navigation {
  font-family: "Montserrat", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  z-index: 0; }
  .navigation .category-item {
    margin-right: 0;
    padding-top: 0;
    padding-bottom: 0; }
    .navigation .category-item:hover, .navigation .category-item:focus-within {
      background-color: #fff;
      color: #000; }
      .navigation .category-item:hover > a, .navigation .category-item:focus-within > a {
        background-color: #fff; }
  @media (min-width: 1024px) {
    .navigation ul {
      display: flex;
      justify-content: space-between;
      margin: 0 -0.5rem 0 -1.5rem;
      padding: 0; }
    .navigation a.nav-link {
      padding: 1.3rem 0.5rem;
      font-size: 1.4rem; } }
  @media (min-width: 1240px) {
    .navigation ul {
      margin: 0 -1rem 0 -2rem; }
    .navigation a.nav-link {
      padding-left: 1rem;
      padding-right: 1rem;
      font-size: 1.6rem; } }

.nav-sections-item-title {
  display: none; }

.nav-sections-item-content {
  margin-top: 0;
  padding: 0; }
  @media (max-width: 1023.98px) {
    .nav-sections-item-content {
      display: flex;
      flex-direction: column;
      height: 100%;
      flex-grow: 1;
      margin-left: 0; } }
  @media (min-width: 1024px) {
    .nav-sections-item-content {
      position: relative; } }

.nav-sections {
  background-color: #fff;
  color: #000; }
  .nav-sections a:not(.btn):not(a.pagebuilder-button-primary):not(
  .pagebuilder-button-primary):not(a.pagebuilder-button-secondary):not(
  .pagebuilder-button-secondary) {
    color: inherit !important; }
  .nav-sections .navigation {
    background: none; }
    @media (max-width: 1023.98px) {
      .nav-sections .navigation {
        padding: 0; } }
  @media (max-width: 1023.98px) {
    .nav-sections {
      background-color: #fcd900; }
      .nav-sections::before {
        content: '';
        background: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 10px;
        z-index: 2; }
      .nav-sections::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        width: 100vw;
        height: 10px;
        background-image: url("../images/nav-curve.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-color: #000; } }
  @media (max-width: 575.98px) {
    .nav-sections::before {
      display: none; } }
  @media (min-width: 1024px) {
    .nav-sections {
      background-color: #000;
      color: #fff;
      transform: none !important; } }

.category-item {
  display: block;
  padding: 0; }
  @media (min-width: 1024px) {
    .category-item {
      position: static !important; } }
  .category-item.level0::after {
    width: auto; }
  @media (min-width: 1024px) {
    .category-item.level0:not(.parent)::after {
      display: none; }
    .category-item.level0.parent::after {
      content: '';
      display: none;
      background: rgba(0, 0, 0, 0.5);
      height: 100%;
      position: fixed;
      right: 0;
      top: 0;
      width: 100%;
      z-index: -1;
      pointer-events: none;
      left: auto; }
    .category-item.level0.parent.active::after, .category-item.level0.parent:hover::after, .category-item.level0.parent:focus-within::after {
      display: block; }
    .category-item.level0.parent.active .submenu-wrapper, .category-item.level0.parent:hover .submenu-wrapper, .category-item.level0.parent:focus-within .submenu-wrapper {
      display: block;
      color: #000; }
    .category-item.level0.parent.active > .nav-link::after, .category-item.level0.parent:hover > .nav-link::after, .category-item.level0.parent:focus-within > .nav-link::after {
      content: ' ';
      width: 0;
      height: 0;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 10px solid #fff;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%); }
    .category-item.level0.parent > .nav-link {
      position: relative;
      height: 100%;
      background-color: #000; }
      .category-item.level0.parent > .nav-link span {
        color: #fff; }
      .category-item.level0.parent > .nav-link::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: -1rem;
        right: -1rem; } }

@media (min-width: 1024px) and (min-width: 1240px) {
  .category-item.level0 > .nav-link::before {
    left: -1.25rem;
    right: -1.25rem; } }
  @media (min-width: 1024px) {
    .category-item.level0 > .nav-link::before {
      left: -1.5rem;
      right: -1.5rem; }
    .category-item.level0 > .nav-link::before {
      left: -3.5rem;
      right: -3.5rem; } }

@media (min-width: 1024px) {
  .nav-sections-items .navigation {
    position: static; }
    .nav-sections-items .navigation ul {
      position: static;
      display: flex;
      justify-content: space-between;
      margin: 0 -1.3rem;
      padding: 0; } }

@media (max-width: 1023.98px) {
  .nav-sections-items .navigation {
    padding-left: 0;
    padding-right: 0; }
    .nav-sections-items .navigation ul,
    .nav-sections-items .navigation li {
      border: none !important;
      padding: 0; }
    .nav-sections-items .navigation li > a {
      position: relative;
      margin-bottom: 0;
      padding: 0.8rem 2rem;
      border-bottom: 1px solid #f0f0f0;
      border-left: none !important;
      line-height: 1.75;
      font-size: 1.6rem;
      background-color: #fff;
      font-weight: 700; }
      .nav-sections-items .navigation li > a span {
        margin-left: 0 !important; }
    .nav-sections-items .navigation li:first-child > a {
      border-top: none;
      padding-top: 1.6rem !important; }
    .nav-sections-items .navigation li:last-child a {
      padding-bottom: 1.6rem !important; }
    .nav-sections-items .navigation .level0.parent > a {
      background-image: url("../images/arrow-right.svg");
      background-position: right 1.2rem center;
      background-repeat: no-repeat;
      background-size: 32px 32px; }
    .nav-sections-items .navigation .parent {
      position: relative; }
      .nav-sections-items .navigation .parent::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 50px;
        height: 100%;
        background-image: url(../img/caret.svg);
        background-position: right 8px center;
        background-repeat: no-repeat;
        background-size: 6px 12px; }
    .nav-sections-items .navigation.inner .parent::before {
      display: none; }
    .nav-sections-items .navigation.inner .level0 > .nav-link {
      display: none; }
      .nav-sections-items .navigation.inner .level0 > .nav-link.show + .submenu-wrapper {
        display: flex !important; }
        .nav-sections-items .navigation.inner .level0 > .nav-link.show + .submenu-wrapper .submenu {
          display: block !important; } }

.submenu-wrapper {
  display: none;
  background: #fff; }
  .submenu-wrapper .submenu.submenu {
    position: static;
    box-shadow: none;
    border: none;
    min-width: 0;
    margin-top: 0;
    font-weight: 400;
    font-size: 1.5rem; }
    .submenu-wrapper .submenu.submenu::before {
      display: none !important; }
  @media (min-width: 1024px) {
    .submenu-wrapper {
      position: absolute;
      z-index: 0;
      top: 100%;
      left: 0;
      right: 0;
      max-width: none !important;
      box-shadow: 0 3px 10px #00000026; }
      .submenu-wrapper .submenu-wrapper-inner {
        display: flex;
        max-width: 1010px;
        width: 100%;
        margin: auto;
        flex-wrap: wrap; }
      .submenu-wrapper .submenu-items.submenu-items .submenu-heading {
        display: flex;
        justify-content: space-between;
        align-items: center; }
      .submenu-wrapper .submenu-items.submenu-items .submenu {
        margin: 17px -1px -2px -1px !important; }
        .submenu-wrapper .submenu-items.submenu-items .submenu .category-item.active > a {
          border-color: #fcd900;
          border-width: 2px; }
        .submenu-wrapper .submenu-items.submenu-items .submenu a {
          padding: 0;
          background: none !important;
          color: #fcd900;
          text-decoration: none; }
          .submenu-wrapper .submenu-items.submenu-items .submenu a:hover {
            text-decoration: underline; }
      .submenu-wrapper .brands-wrapper,
      .submenu-wrapper .submenu-items {
        padding: 1.5rem 2.5rem 2.5rem 2.6rem; }
        .submenu-wrapper .brands-wrapper h3,
        .submenu-wrapper .submenu-items h3 {
          font-size: 1.3rem;
          font-weight: 700; }
        .submenu-wrapper .brands-wrapper .btn-outline-secondary:hover, .submenu-wrapper .brands-wrapper .account-nav .item.item a:hover[href*='logout'], .account-nav .item.item .submenu-wrapper .brands-wrapper a:hover[href*='logout'],
        .submenu-wrapper .submenu-items .btn-outline-secondary:hover,
        .submenu-wrapper .submenu-items .account-nav .item.item a:hover[href*='logout'],
        .account-nav .item.item .submenu-wrapper .submenu-items a:hover[href*='logout'] {
          background-color: #000; }
      .submenu-wrapper .brands-wrapper {
        flex: 0 0 100%;
        padding-top: 2.5rem;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #f0f0f0;
        margin-bottom: 1rem; }
        .submenu-wrapper .brands-wrapper .brands-inner {
          display: flex;
          width: calc(100% - 300px);
          align-items: center; }
          .submenu-wrapper .brands-wrapper .brands-inner > div:nth-child(n+6) {
            display: none; }
          .submenu-wrapper .brands-wrapper .brands-inner a {
            display: block;
            padding: 0 2rem; }
            .submenu-wrapper .brands-wrapper .brands-inner a img {
              transition: all 0.3s ease;
              width: 100%;
              max-width: 130px;
              max-height: 40px;
              margin-bottom: 0; }
            .submenu-wrapper .brands-wrapper .brands-inner a span {
              display: none; }
            .submenu-wrapper .brands-wrapper .brands-inner a:hover img {
              transform: scale(1.05); }
      .submenu-wrapper .submenu-items {
        position: relative;
        flex: 0 0 100%; }
        .submenu-wrapper .submenu-items::before {
          content: '';
          position: absolute;
          top: 1.5rem;
          right: 0;
          bottom: 3rem; }
        .submenu-wrapper .submenu-items h3 {
          margin-right: auto; }
      .submenu-wrapper .submenu {
        display: flex !important;
        justify-content: flex-start !important;
        flex-wrap: wrap;
        margin-left: -5px !important;
        margin-right: -5px !important; }
        .submenu-wrapper .submenu li {
          width: 25%;
          margin: 0;
          font-size: 1.2rem; }
          .submenu-wrapper .submenu li a {
            text-align: center;
            display: block;
            border: solid 2px transparent;
            border-radius: 2px;
            min-height: 120px;
            padding: 5px;
            text-align: left;
            min-height: initial;
            margin-bottom: 10px;
            border: none;
            transition: all 0.3s ease; }
            .submenu-wrapper .submenu li a:hover {
              border-color: #fcd900;
              text-decoration: none !important;
              font-weight: 600; }
      .submenu-wrapper .nav-back {
        display: none; } }
  @media (min-width: 1240px) {
    .submenu-wrapper .submenu-wrapper-inner {
      max-width: 1220px; } }
  @media (max-width: 1023.98px) {
    .submenu-wrapper {
      flex-wrap: wrap; }
      .submenu-wrapper .submenu-wrapper-inner {
        display: flex;
        flex-direction: column;
        width: 100%; }
      .submenu-wrapper .brands-wrapper,
      .submenu-wrapper .submenu-items {
        width: 100%; }
        .submenu-wrapper .brands-wrapper h3,
        .submenu-wrapper .submenu-items h3 {
          position: relative;
          margin-bottom: 0;
          padding: 1rem;
          background-color: #000;
          color: #fff;
          text-align: center;
          font-weight: 700;
          font-size: 1.6rem;
          line-height: 1.3; }
          .submenu-wrapper .brands-wrapper h3 button.nav-back,
          .submenu-wrapper .submenu-items h3 button.nav-back {
            position: absolute;
            left: 0;
            top: 0;
            padding-left: 2.6rem;
            padding-right: 2.6rem; }
          .submenu-wrapper .brands-wrapper h3 .see-all,
          .submenu-wrapper .submenu-items h3 .see-all {
            padding: 0 !important;
            border: none !important;
            text-decoration: underline;
            position: absolute;
            right: 1.9rem;
            top: 1.2rem;
            font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
            font-weight: normal;
            font-size: 1.3rem; }
        .submenu-wrapper .brands-wrapper .btn-outline-secondary, .submenu-wrapper .brands-wrapper .account-nav .item.item a[href*='logout'], .account-nav .item.item .submenu-wrapper .brands-wrapper a[href*='logout'],
        .submenu-wrapper .submenu-items .btn-outline-secondary,
        .submenu-wrapper .submenu-items .account-nav .item.item a[href*='logout'],
        .account-nav .item.item .submenu-wrapper .submenu-items a[href*='logout'] {
          display: none; }
        .submenu-wrapper .brands-wrapper a img,
        .submenu-wrapper .submenu-items a img {
          display: none; }
      .submenu-wrapper .brands-wrapper .brands-inner a,
      .submenu-wrapper .submenu-items .submenu li a {
        background-color: #fcd900;
        font-size: 1.5rem;
        font-weight: 700;
        padding: 0.8rem 2rem;
        border-bottom: 1px solid #fff; }
      .submenu-wrapper .brands-wrapper {
        order: 0; }
        .submenu-wrapper .brands-wrapper .brands-inner div:first-child > a {
          border-top: none;
          padding-top: 1.6rem; }
        .submenu-wrapper .brands-wrapper .brands-inner div:last-child a {
          padding-bottom: 1.6rem; } }
  @media (min-width: 1024px) and (max-width: 1239.98px) {
    .submenu-wrapper {
      max-width: 1024px; } }
  @media (min-width: 1240px) {
    .submenu-wrapper {
      max-width: 1240px; } }

@media (max-width: 1023.98px) {
  .submenu-extra ul {
    padding-left: 0;
    margin-bottom: 0; }
  .submenu-extra a {
    display: block;
    font-size: 1.5rem;
    font-weight: 700;
    padding: 0.8rem 2rem;
    border: none !important; }
  .submenu-extra li {
    margin-bottom: 0; }
    .submenu-extra li a {
      border-bottom: 1px solid #f0f0f0 !important; }
    .submenu-extra li:first-child > a {
      border-top: none;
      padding-top: 1.6rem; }
    .submenu-extra li:last-child a {
      padding-bottom: 1.6rem; } }

.price-box.price-final_price .pricing {
  text-align: center;
  position: relative;
  margin-top: 0;
  padding-left: 0;
  padding-right: 1rem; }
  .price-box.price-final_price .pricing.strikethrough-price .price-wrapper .price {
    text-decoration: line-through; }
    .product-info-main .price-box.price-final_price .pricing.strikethrough-price .price-wrapper .price {
      text-decoration: none; }
      .product-info-main .price-box.price-final_price .pricing.strikethrough-price .price-wrapper .price::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        transform: translateY(-50%);
        height: 3px;
        margin-top: -1px;
        background-color: #000; }
  .price-box.price-final_price .pricing.cashback-price .price-wrapper .price {
    color: #eb5050; }
  .price-box.price-final_price .pricing.tradein-price .price-wrapper .price {
    color: #eb5050; }
  .price-box.price-final_price .pricing .price-label {
    display: block;
    font-size: 1.1rem;
    line-height: 1.5; }
    @media (min-width: 768px) {
      .price-box.price-final_price .pricing .price-label {
        text-align: left; } }
    @media (max-width: 575.98px) {
      .price-box.price-final_price .pricing .price-label {
        line-height: 1.4;
        font-size: 1.3rem; } }
    .price-box.price-final_price .pricing .price-label::after {
      content: ''; }
  .price-box.price-final_price .pricing .price-wrapper {
    display: block; }
    @media (min-width: 768px) {
      .price-box.price-final_price .pricing .price-wrapper {
        text-align: left; } }
    .price-box.price-final_price .pricing .price-wrapper .price {
      position: relative;
      line-height: 1.5em;
      font-family: "Montserrat", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 700; }
      .product-info-main .price-box.price-final_price .pricing .price-wrapper .price {
        font-size: 2.2rem; }
        @media (min-width: 1024px) {
          .product-info-main .price-box.price-final_price .pricing .price-wrapper .price {
            font-size: 3rem; } }

.tags-wrapper {
  position: absolute;
  text-align: center;
  width: 91px;
  height: 24px;
  z-index: 30;
  font-weight: bold; }
  .tags-wrapper .tag {
    padding: 4px 3px;
    border-radius: 4px;
    font-size: 11px;
    background: #ffba00;
    color: #fff; }
    .tags-wrapper .tag.tag-cash-back {
      background: #eb5050; }
    .tags-wrapper .tag.tag-bonus {
      background: #28a745; }
    .tags-wrapper .tag.tag-clearance {
      background: #b1b1e0; }
    .tags-wrapper .tag.tag-online-only {
      background: #499ef4; }

.product.media {
  position: relative; }

.product.media .tags-wrapper {
  width: auto;
  height: auto; }
  @media (min-width: 1024px) {
    .product.media .tags-wrapper {
      left: 100px; } }
  .product.media .tags-wrapper .tag {
    padding: 8px 31px;
    font-size: 1.3rem; }

.review-form-wrapper .review-form {
  padding: 20px; }

.review-form-wrapper .review-legend.legend {
  font-weight: 700; }
  .review-form-wrapper .review-legend.legend strong {
    color: #46db8b;
    display: inline; }

.review-form-wrapper .review-intro {
  font-size: 15px; }

.review-form-wrapper fieldset legend h4 {
  font-weight: 700; }

.review-form-wrapper .field small {
  font-size: 1.1rem; }

.review-form-wrapper .review-field-ratings .review-field-rating .label {
  font-size: 1.5rem;
  font-weight: 700; }

.review-form-wrapper .review-field-ratings .review-field-rating .control {
  padding-top: 8px; }

.review-form-wrapper .review-field-pros label,
.review-form-wrapper .review-field-cons label {
  width: 100%; }

.review-form-wrapper .review-field-recommendation > label {
  font-size: 1.3rem; }

.review-form-wrapper .review-field-recommendation .control > label {
  margin-right: 30px; }

@media (max-width: 1023.98px) {
  .review-form-wrapper button.showReviews {
    padding: 6px 66px;
    font-size: 1.2rem; } }

.review-list .review-title {
  margin-bottom: 5px;
  font-weight: bold;
  font-family: "Montserrat", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }

.review-list .review-ratings ~ .review-content,
.review-list .review-ratings ~ .review-details {
  margin-left: 0; }

.review-list .review-by,
.review-list .review-date {
  font-size: 1.1rem; }

.review-list .review-pros,
.review-list .review-cons,
.review-list .review-recommendation,
.review-list .review-content {
  font-size: 1.5rem; }

.review-list .review-pros strong,
.review-list .review-cons strong {
  min-width: 70px;
  display: inline-block;
  font-family: "Montserrat", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }

.review-list .review-recommendation strong {
  display: inline-block;
  padding-right: 12px;
  font-family: "Montserrat", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }

.review-list .review-ratings .rating-label {
  font-weight: 700;
  font-size: 1.5rem;
  font-family: "Montserrat", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }

.review-list .review-items {
  padding-left: 0;
  padding-right: 0; }
  .review-list .review-items .review-ratings-holder {
    padding-left: 0; }
  .review-list .review-items .review-details {
    padding-right: 0; }

@media (max-width: 1023.98px) {
  .review-list .review-items .review-ratings-holder,
  .review-list .review-items .review-details {
    padding-left: 0;
    padding-right: 0; }
  .review-list .review-items .review-details {
    margin-bottom: 8px; } }

.rating-result.rating-result {
  width: 122px;
  margin-left: 0;
  height: 17.122px; }
  .rating-result.rating-result::before,
  .rating-result.rating-result span::before {
    content: '' !important;
    display: block;
    letter-spacing: 0;
    font-size: 2.4rem;
    height: 17.122px;
    padding: 2px 0;
    white-space: nowrap;
    background-image: url(../images/star-fill.svg);
    background-size: contain;
    background-repeat: repeat-x;
    background-position: left center; }
  .rating-result.rating-result::before {
    z-index: 0;
    width: 100%;
    opacity: 0.5;
    background-image: url(../images/star-no-fill.svg); }
  .rating-result.rating-result span::before {
    z-index: 1; }

.review-control-vote::before,
.review-control-vote label::before {
  content: '' !important;
  display: block;
  font-size: 2.4rem;
  height: 17.122px;
  padding: 2px 0;
  line-height: 24.5px;
  letter-spacing: 9px;
  text-indent: 2.4px;
  white-space: nowrap; }

.review-control-vote::before {
  content: '';
  background-image: url(../images/star-no-fill.svg);
  background-size: 25px 17.122px;
  background-repeat: repeat-x;
  background-position: left center;
  width: 125px; }

.review-control-vote label::before {
  background-image: url(../images/star-fill.svg);
  background-size: 25px 17.122px;
  background-repeat: repeat-x;
  background-position: left center;
  color: #fff; }

.review-control-vote .rating-1::before {
  width: 25px; }

.review-control-vote .rating-2::before {
  width: 50px; }

.review-control-vote .rating-3::before {
  width: 75px; }

.review-control-vote .rating-4::before {
  width: 100px; }

.review-control-vote .rating-5::before {
  width: 125px; }

.rating-summary .rating-result > span::before,
.review-control-vote label::before {
  font-size: 2.4rem; }

.aa-Panel {
  flex-direction: row;
  left: 0 !important;
  right: 0 !important;
  width: 100% !important;
  box-shadow: 0 3px 6px #00000029;
  border: none;
  background: #fff; }
  @media (min-width: 768px) {
    .aa-Panel {
      overflow: hidden; }
      .aa-Panel::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(221, 221, 221, 0.3);
        z-index: -1; } }
  .aa-Panel .aa-PanelLayout {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    padding-bottom: 0; }
    @media (max-width: 767.98px) {
      .aa-Panel .aa-PanelLayout {
        display: block;
        padding-top: 1rem; } }
    @media (min-width: 576px) {
      .aa-Panel .aa-PanelLayout {
        max-width: 540px; } }
    @media (min-width: 768px) {
      .aa-Panel .aa-PanelLayout {
        max-width: 100%; }
        .aa-Panel .aa-PanelLayout section:nth-child(1) {
          position: relative;
          max-width: 669px;
          background: #fff;
          border: none !important;
          padding: 3rem 1.5rem 8.6rem 7rem; } }
    @media (min-width: 768px) and (min-width: 768px) and (max-width: 1023.98px) {
      .aa-Panel .aa-PanelLayout section:nth-child(1) {
        padding-left: 3rem; } }
    @media (min-width: 768px) {
          .aa-Panel .aa-PanelLayout section:nth-child(1)::before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 100%;
            width: 100vw;
            background: inherit; } }
    @media (min-width: 1024px) {
      .aa-Panel .aa-PanelLayout {
        max-width: 976px; } }
    @media (min-width: 1240px) {
      .aa-Panel .aa-PanelLayout {
        max-width: 1190px;
        grid-template-columns: 1fr 782px; } }
    .aa-Panel .aa-PanelLayout section:nth-child(1) .aa-List li {
      background: none; }
      .aa-Panel .aa-PanelLayout section:nth-child(1) .aa-List li a:hover {
        text-decoration: none; }
    .aa-Panel .aa-PanelLayout .autocomplete-products-footer {
      position: relative;
      width: auto;
      left: auto; }
    .aa-Panel .aa-PanelLayout .aa-SourceHeader {
      background-color: #fcd900;
      border-radius: 4px;
      color: #000 !important;
      font: normal normal normal 1.1rem "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
      text-transform: capitalize !important;
      display: inline-block;
      padding: 0.5rem 1rem !important; }
    @media (min-width: 768px) and (max-width: 1023.98px) {
      .aa-Panel .aa-PanelLayout .aa-Source.aa-Source[data-autocomplete-source-id='categories'],
      .aa-Panel .aa-PanelLayout .aa-Source.aa-Source[data-autocomplete-source-id='pages'] {
        max-width: 190px; } }
    .aa-Panel .aa-PanelLayout .aa-Source.aa-Source[data-autocomplete-source-id='categories'] .aa-List,
    .aa-Panel .aa-PanelLayout .aa-Source.aa-Source[data-autocomplete-source-id='pages'] .aa-List {
      padding-top: 0.6rem; }
    @media (min-width: 768px) {
      .aa-Panel .aa-PanelLayout .aa-Source.aa-Source[data-autocomplete-source-id='categories'],
      .aa-Panel .aa-PanelLayout .aa-Source.aa-Source[data-autocomplete-source-id='pages'] {
        width: 100% !important;
        padding-top: 2.9rem;
        padding-right: 0.6rem; } }
    .aa-Panel .aa-PanelLayout .aa-Source.aa-Source[data-autocomplete-source-id='categories'] .algoliasearch-autocomplete-hit.algoliasearch-autocomplete-hit,
    .aa-Panel .aa-PanelLayout .aa-Source.aa-Source[data-autocomplete-source-id='pages'] .algoliasearch-autocomplete-hit.algoliasearch-autocomplete-hit {
      padding: 1rem 0;
      background: none;
      border-bottom: 1px solid #ddd; }
      .aa-Panel .aa-PanelLayout .aa-Source.aa-Source[data-autocomplete-source-id='categories'] .algoliasearch-autocomplete-hit.algoliasearch-autocomplete-hit:hover .info-title,
      .aa-Panel .aa-PanelLayout .aa-Source.aa-Source[data-autocomplete-source-id='pages'] .algoliasearch-autocomplete-hit.algoliasearch-autocomplete-hit:hover .info-title {
        text-decoration: underline; }
      .aa-Panel .aa-PanelLayout .aa-Source.aa-Source[data-autocomplete-source-id='categories'] .algoliasearch-autocomplete-hit.algoliasearch-autocomplete-hit em,
      .aa-Panel .aa-PanelLayout .aa-Source.aa-Source[data-autocomplete-source-id='pages'] .algoliasearch-autocomplete-hit.algoliasearch-autocomplete-hit em {
        font-weight: normal; }
    .aa-Panel .aa-PanelLayout .aa-Source.aa-Source[data-autocomplete-source-id='categories'] .aa-no-results.aa-no-results,
    .aa-Panel .aa-PanelLayout .aa-Source.aa-Source[data-autocomplete-source-id='pages'] .aa-no-results.aa-no-results {
      padding: 1rem 0; }
    .aa-Panel .aa-PanelLayout .aa-Source.aa-Source[data-autocomplete-source-id='pages'] {
      padding-top: 2rem; }
      @media (max-width: 1023.98px) {
        .aa-Panel .aa-PanelLayout .aa-Source.aa-Source[data-autocomplete-source-id='pages'] {
          padding-bottom: 1.5rem; } }
    .aa-Panel .aa-PanelLayout .info {
      position: relative;
      padding-top: 2.2rem; }
      .aa-Panel .aa-PanelLayout .info .algoliasearch-autocomplete-category {
        display: none; }
      .aa-Panel .aa-PanelLayout .info .after_special.promotion {
        padding-right: 0.4rem; }
      .aa-Panel .aa-PanelLayout .info .algoliasearch-autocomplete-price {
        position: absolute;
        top: 0;
        left: 0;
        font-weight: 700;
        color: #000; }
        @media (max-width: 1023.98px) {
          .aa-Panel .aa-PanelLayout .info .algoliasearch-autocomplete-price {
            left: 1.5rem; } }
    .aa-Panel .aa-PanelLayout .aa-Item:hover {
      background: none !important; }
    .aa-Panel .aa-PanelLayout .aa-Item a {
      padding-left: 0 !important;
      padding-right: 0 !important; }
      .aa-Panel .aa-PanelLayout .aa-Item a:hover {
        background: none !important;
        text-decoration: underline !important; }
    .aa-Panel .aa-PanelLayout #autocomplete-products-footer {
      left: 7rem;
      right: 1rem;
      width: auto;
      bottom: 3rem;
      border-style: solid;
      border-width: 2px;
      font-family: "Montserrat", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 700;
      padding: 0.6rem 2.4rem;
      font-size: 1.2rem;
      line-height: 1.5;
      border-radius: 0.3rem;
      color: #000;
      border-color: #000; }
      .aa-Panel .aa-PanelLayout #autocomplete-products-footer:hover {
        color: #fff;
        background-color: #000;
        border-color: #000; }
      .aa-Panel .aa-PanelLayout #autocomplete-products-footer:focus, .aa-Panel .aa-PanelLayout #autocomplete-products-footer.focus {
        box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }
      .aa-Panel .aa-PanelLayout #autocomplete-products-footer.disabled, .aa-Panel .aa-PanelLayout #autocomplete-products-footer:disabled {
        color: #000;
        background-color: transparent; }
      .aa-Panel .aa-PanelLayout #autocomplete-products-footer:not(:disabled):not(.disabled):active, .aa-Panel .aa-PanelLayout #autocomplete-products-footer:not(:disabled):not(.disabled).active,
      .show > .aa-Panel .aa-PanelLayout #autocomplete-products-footer.dropdown-toggle {
        color: #fff;
        background-color: #000;
        border-color: #000; }
        .aa-Panel .aa-PanelLayout #autocomplete-products-footer:not(:disabled):not(.disabled):active:focus, .aa-Panel .aa-PanelLayout #autocomplete-products-footer:not(:disabled):not(.disabled).active:focus,
        .show > .aa-Panel .aa-PanelLayout #autocomplete-products-footer.dropdown-toggle:focus {
          box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }
      @media (min-width: 768px) and (max-width: 1023.98px) {
        .aa-Panel .aa-PanelLayout #autocomplete-products-footer {
          left: 3rem; } }
      .aa-Panel .aa-PanelLayout #autocomplete-products-footer span {
        font-weight: inherit;
        color: inherit; }
      .aa-Panel .aa-PanelLayout #autocomplete-products-footer a {
        color: inherit;
        text-decoration: none; }
        .aa-Panel .aa-PanelLayout #autocomplete-products-footer a::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0; }

.block-search {
  float: none;
  z-index: 1;
  flex: 0 1 470px;
  margin: -3px auto 0;
  padding: 0 20px; }
  @media (max-width: 1023.98px) {
    .block-search {
      margin-bottom: 0 !important; } }
  @media (min-width: 768px) {
    .block-search,
    .block-search .aa-Autocomplete,
    .block-search .aa-Form {
      height: 100%; } }
  .block-search .aa-Label {
    display: inline;
    margin-bottom: 0; }
  .block-search .aa-InputWrapper {
    align-self: center;
    width: 100%; }
  .block-search .aa-Form {
    display: flex;
    position: relative; }
    @media (max-width: 1023.98px) {
      .block-search .aa-Form {
        padding: 1rem 1.5rem 1.5rem; } }
    .block-search .aa-Form .aa-Input {
      height: 44px;
      padding: 1.1rem 3.5rem 1.1rem 1.7rem;
      border-radius: 0.7rem;
      border: 2px #000 solid;
      font-family: "Montserrat", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 700;
      font-size: 1.3rem;
      width: 100%;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
      @media (prefers-reduced-motion: reduce) {
        .block-search .aa-Form .aa-Input {
          transition: none; } }
      .block-search .aa-Form .aa-Input:focus {
        outline: 0;
        border-color: #fcd900;
        color: #000;
        box-shadow: 0 3px 6px #00000029; }
    .block-search .aa-Form .aa-SubmitButton {
      right: 0.7rem !important;
      top: 50%;
      transform: translateY(-50%);
      background-image: url("../images/search.svg") !important;
      background-repeat: no-repeat !important;
      background-position: center !important;
      background-size: 24px 24px !important; }
      @media (max-width: 1023.98px) {
        .block-search .aa-Form .aa-SubmitButton {
          right: 2.2rem !important;
          margin-top: -0.25rem; } }
      .block-search .aa-Form .aa-SubmitButton:focus {
        outline: none; }
      .block-search .aa-Form .aa-SubmitButton svg {
        display: none; }
  @media (max-width: 1023.98px) {
    .block-search {
      padding: 0; } }
  .block-search .search-curve {
    display: none; }
  @media (max-width: 1023.98px) {
    .block-search {
      position: relative;
      flex-basis: 100%;
      order: 4;
      background-color: #292929; }
      .block-search .search-curve {
        display: block;
        position: absolute;
        bottom: 100%;
        left: 0;
        right: 0;
        color: #fcd900;
        background-color: #292929; } }
  @media (max-width: 575.98px) {
    .nav-open .block-search {
      height: 0;
      padding: 0;
      overflow: hidden; } }
  .block-search .input-group {
    width: 100%; }
  .block-search .form-control {
    left: auto;
    height: 44px;
    padding-right: 3.5rem;
    border-radius: 0.7rem;
    border-color: #000;
    font-family: "Montserrat", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 700;
    font-size: 1.3rem; }
    .block-search .form-control:focus {
      border-width: 2px !important;
      border-color: #fcd900 !important;
      background-color: #fff !important;
      color: #000 !important;
      box-shadow: 0 3px 6px #00000029 !important; }
    @media (max-width: 1023.98px) {
      .block-search .form-control {
        margin: 0;
        border-radius: 0.5rem;
        border-width: 3px; } }
  .block-search .magnifying-glass {
    background: none !important; }

.form-search .btn-search-close,
.form-search .search-spinner {
  display: none; }

.form-search .btn-search-close-overlay {
  position: absolute;
  border: none; }

.form-search.hide .search-autocomplete {
  transform: translateY(-100%); }

@media (max-width: 767.98px) {
  .form-search .algolia-search-input {
    font-size: 1.6rem; }
    .form-search .algolia-search-input::placeholder {
      font-size: 1.3rem; } }

.search-wrapper .search-icon {
  display: block;
  right: 1rem;
  top: 50%;
  z-index: 99;
  transform: translateY(-50%); }
  @media (max-width: 1023.98px) {
    .search-wrapper .search-icon {
      right: 1.5rem; } }

#algolia-autocomplete-container .other-sections {
  max-width: 600px;
  margin: 0;
  padding-top: 25px;
  opacity: 1; }
  @media (min-width: 576px) {
    #algolia-autocomplete-container .other-sections {
      padding-top: 38px; } }

.aa-dataset-products {
  width: 100%;
  display: flex;
  flex-direction: column; }
  @media (min-width: 576px) {
    .aa-dataset-products {
      padding-bottom: 20px; } }
  @media (max-width: 575.98px) {
    .aa-dataset-products {
      min-height: 0 !important; } }

#algolia-autocomplete-container .section-sidebar {
  position: relative; }
  @media (min-width: 768px) {
    #algolia-autocomplete-container .section-sidebar {
      padding-right: 4.5rem; } }
  @media (min-width: 576px) {
    #algolia-autocomplete-container .section-sidebar::before {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 100vw;
      background-color: rgba(221, 221, 221, 0.3);
      z-index: -1; } }
  @media (max-width: 575.98px) {
    #algolia-autocomplete-container .section-sidebar {
      order: 1;
      margin-bottom: 2rem; } }

@media (min-width: 768px) {
  #algolia-autocomplete-container .section-content {
    padding-left: 7rem; } }

#algolia-autocomplete-container .aa-dataset-0 ~ div:not(:empty) {
  padding-top: 1rem; }

#algolia-autocomplete-container #autocomplete-products-footer {
  position: static;
  margin-top: auto;
  background: none;
  width: 100%; }
  #algolia-autocomplete-container #autocomplete-products-footer span {
    font-weight: inherit;
    color: inherit; }
  @media (max-width: 767.98px) {
    #algolia-autocomplete-container #autocomplete-products-footer#autocomplete-products-footer {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: auto;
      left: auto;
      height: auto;
      bottom: auto; }
    #algolia-autocomplete-container #autocomplete-products-footer .btn-seeall {
      display: inline-block;
      border: none;
      background: none;
      width: auto;
      font-size: 0;
      padding: 0;
      position: absolute;
      top: 4rem;
      right: 0;
      text-decoration: underline; }
      #algolia-autocomplete-container #autocomplete-products-footer .btn-seeall:hover {
        text-decoration: none;
        color: inherit; }
      #algolia-autocomplete-container #autocomplete-products-footer .btn-seeall .autocomplete-seeall {
        font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-weight: normal;
        font-size: 1.3rem; } }
  @media (max-width: 575.98px) {
    #algolia-autocomplete-container #autocomplete-products-footer .btn-seeall {
      top: 3rem;
      right: 1.5rem; } }

#algolia-autocomplete-container .algoliasearch-autocomplete-price {
  text-align: left;
  font: normal normal bold 13px/22px "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  letter-spacing: 0;
  color: #000 !important;
  opacity: 1;
  font-size: 1em !important; }

#algolia-autocomplete-container .aa-dropdown-menu {
  top: 100% !important;
  left: 0 !important;
  right: 0 !important;
  z-index: 99 !important;
  width: auto;
  margin-top: 0;
  border: none;
  box-shadow: 0 3px 6px #00000029; }
  #algolia-autocomplete-container .aa-dropdown-menu .category {
    background-color: #fcd900;
    border-radius: 4px;
    color: #000 !important;
    text-align: left;
    font: normal normal normal 11px "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    opacity: 1;
    text-transform: none;
    display: inline-block;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px; }
  #algolia-autocomplete-container .aa-dropdown-menu .algoliasearch-autocomplete-hit.algoliasearch-autocomplete-hit {
    padding: 1rem 0;
    background: none;
    border-bottom: 1px solid #ddd; }
    #algolia-autocomplete-container .aa-dropdown-menu .algoliasearch-autocomplete-hit.algoliasearch-autocomplete-hit:hover .info-title {
      text-decoration: underline; }
    #algolia-autocomplete-container .aa-dropdown-menu .algoliasearch-autocomplete-hit.algoliasearch-autocomplete-hit em {
      font-weight: normal; }
  #algolia-autocomplete-container .aa-dropdown-menu .aa-no-results.aa-no-results {
    padding: 1rem 0; }

#algolia-autocomplete-container .other-sections .aa-suggestions {
  margin: 0; }
  @media (max-width: 575.98px) {
    #algolia-autocomplete-container .other-sections .aa-suggestions {
      margin-bottom: 0 !important; } }

#algolia-autocomplete-container .aa-dropdown-menu .algoliasearch-autocomplete-hit .info {
  margin: 0;
  padding-left: 20px;
  padding-bottom: 17px;
  white-space: normal; }

#algolia-autocomplete-container .aa-suggestions {
  margin-bottom: 20px !important;
  padding-top: 23px; }

#algolia-autocomplete-container .aa-dropdown-menu .other-sections .aa-dataset-products .aa-suggestions .aa-suggestion {
  padding: 0; }
  #algolia-autocomplete-container .aa-dropdown-menu .other-sections .aa-dataset-products .aa-suggestions .aa-suggestion:nth-child(8) ~ .aa-suggestion {
    display: none; }
  #algolia-autocomplete-container .aa-dropdown-menu .other-sections .aa-dataset-products .aa-suggestions .aa-suggestion .algoliasearch-autocomplete-hit {
    border: none;
    padding: 0; }

.search-see-all {
  float: right; }
  @media (max-width: 575.98px) {
    .search-see-all {
      text-decoration: underline; }
      .search-see-all:hover {
        text-decoration: none; } }

.minicart-wrapper .ui-dialog {
  display: block !important; }

.nav-sections,
.block-minicart.block-minicart {
  position: fixed;
  top: 0;
  display: block;
  margin: 0;
  max-width: 414px;
  min-width: 0;
  width: 100%;
  height: 100%;
  flex-direction: column;
  overflow-y: auto;
  transition: transform 0.3s;
  left: auto;
  right: 0;
  transform: translateX(100%);
  z-index: 100; }
  @media (max-width: 575.98px) {
    .nav-sections,
    .block-minicart.block-minicart {
      max-width: 100%;
      width: 100%; } }

.nav-sections.active,
.minicart-wrapper.active .block-minicart.block-minicart {
  left: auto;
  transform: translateX(0);
  box-shadow: 0 3px 10px #00000026; }
  @media (max-width: 575.98px) {
    .nav-sections.active,
    .minicart-wrapper.active .block-minicart.block-minicart {
      box-shadow: none; } }

@media (max-width: 575.98px) {
  .nav-open .nav-toggle::after {
    display: none; } }

@media (max-width: 1023.98px) {
  .nav-sections {
    top: auto;
    bottom: 0;
    height: calc(100% - 105px);
    padding-top: 10px;
    z-index: 1001 !important; } }

@media (min-width: 1024px) {
  .nav-sections {
    position: static;
    max-width: 100%;
    z-index: 90;
    overflow: visible; } }

@media (max-width: 1023.98px) {
  .sections.nav-sections.hide {
    display: none !important; } }

@media (min-width: 1024px) {
  .sections.nav-sections {
    display: block !important; } }

@media (min-width: 1024px) {
  .minicart-wrapper {
    margin-right: 0.5rem; } }

.minicart-wrapper.active .action.showcart::before,
.minicart-wrapper.active .action.showAccount::before,
.minicart-wrapper.active .action.showCompare::before,
.minicart-wrapper.active .action.showReviews::before {
  display: none; }

.minicart-wrapper.active .action.showcart::after,
.minicart-wrapper.active .action.showAccount::after,
.minicart-wrapper.active .action.showCompare::after,
.minicart-wrapper.active .action.showReviews::after {
  content: '';
  background: rgba(0, 0, 0, 0.5);
  display: block;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 100; }

.minicart-wrapper .block-minicart {
  padding: 0;
  border: none; }
  .minicart-wrapper .block-minicart .sidebar-heading {
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 2rem;
    padding-right: 2rem; }

.sidebar-heading {
  position: relative;
  height: 40px;
  padding-top: 12px;
  background: #000;
  text-align: center;
  color: #fff; }
  .sidebar-heading h2 {
    position: relative;
    font-weight: 700;
    font-size: 1.6rem; }
  .sidebar-heading .nav-back {
    position: absolute;
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
    margin-right: -10px;
    border: none;
    border-radius: 0;
    padding: 1rem 2.5rem 1rem 1.5rem; }
    .sidebar-heading .nav-back:hover, .sidebar-heading .nav-back:focus, .sidebar-heading .nav-back:active {
      background: none; }
    @media (max-width: 575.98px) {
      .sidebar-heading .nav-back {
        margin-right: -5.5rem; } }
  .sidebar-heading .close,
  .sidebar-heading .back {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    border-radius: 0;
    opacity: 1; }
  .sidebar-heading .back {
    left: 0;
    right: auto; }
    .sidebar-heading .back:hover {
      opacity: 0.75; }
    .sidebar-heading .back svg {
      height: 20px;
      width: 20px; }
  .sidebar-heading .clear {
    background: none !important;
    border-radius: 0;
    color: #fff;
    position: absolute;
    top: 50%;
    right: 6rem;
    transform: translateY(-50%);
    font-size: 1.2rem; }
  @media (max-width: 1023.98px) {
    .sidebar-heading {
      padding-left: 0;
      padding-right: 0; }
      .sidebar-heading ul {
        max-width: 272px;
        margin: 0 auto; }
        .sidebar-heading ul li {
          border-top-color: #e5e5e5; }
      .sidebar-heading a.nav-link {
        color: #fcd900;
        font-size: 1.5rem; } }

.sidebar-info.sidebar-info.sidebar-info {
  margin-bottom: 3rem; }
  .sidebar-info.sidebar-info.sidebar-info ul {
    margin-bottom: 0;
    padding-left: 1.6rem;
    list-style: none; }
  .sidebar-info.sidebar-info.sidebar-info a {
    font-size: 1.2rem;
    text-decoration: none; }
    .sidebar-info.sidebar-info.sidebar-info a:hover {
      text-decoration: underline; }
  .sidebar-info.sidebar-info.sidebar-info .btn-link {
    font-size: 1.3rem; }

.sidebar-cta {
  position: relative;
  margin-top: 2rem;
  padding: 2.7rem;
  border-radius: 2px;
  overflow: hidden;
  text-align: center;
  color: #fff; }
  .sidebar-cta::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.74); }
  .sidebar-cta [data-content-type=row][data-appearance=contained] {
    max-width: 197px; }
  .sidebar-cta svg {
    margin-bottom: 2rem; }
  .sidebar-cta img {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100% !important;
    z-index: -2; }
  .sidebar-cta .btn::before,
  .sidebar-cta .pagebuilder-button-primary::before,
  .sidebar-cta .pagebuilder-button-secondary::before, .sidebar-cta .account-nav .item.item a[href*='logout']::before, .account-nav .item.item .sidebar-cta a[href*='logout']::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .sidebar-cta .btn-sm, .sidebar-cta .account-nav .item.item a[href*='logout'], .account-nav .item.item .sidebar-cta a[href*='logout'] {
    padding: 5px 13px !important;
    display: block; }
  .sidebar-cta h4 {
    margin-bottom: 1.5rem;
    font-size: 2.2rem; }
  .sidebar-cta strong {
    color: #fcd900; }
  .sidebar-cta p strong {
    font-size: 1.8rem; }

.slick-prev,
.slick-next {
  background: none !important;
  transition: all 0.2s ease-in-out;
  z-index: 50; }
  .slick-prev::before,
  .slick-next::before {
    content: '';
    display: block;
    padding: 10px;
    background-image: url("../images/down.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 15px 15px;
    line-height: 1; }
  .slick-prev:hover,
  .slick-next:hover {
    opacity: 0.7; }
  .slick-prev:focus,
  .slick-next:focus {
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(223, 223, 223, 0.5); }

.slick-prev::before {
  transform: rotate(90deg); }

.slick-next::before {
  transform: rotate(-90deg); }

.slick-dots {
  display: flex !important;
  max-width: 490px;
  margin: 0 auto;
  padding: 0;
  border-radius: 99px;
  overflow: hidden; }
  .slick-dots li {
    margin: 0;
    height: 5px;
    flex-grow: 1;
    width: auto;
    display: block; }
    .slick-dots li button {
      border-radius: 0;
      width: 100%;
      height: 100%;
      border: none !important;
      padding: 0;
      background: #ddd;
      outline: none; }
      .slick-dots li button::before {
        content: '';
        position: absolute;
        top: -20px;
        bottom: -21px;
        left: 0;
        right: 0;
        z-index: 3; }
    .slick-dots li.slick-active button {
      background: #000 !important;
      border-radius: 99px; }
  @media (max-width: 767.98px) {
    .slick-dots {
      max-width: calc(100% - 6rem);
      margin: 2rem 3rem; } }

.slick-initialized .slick-track {
  display: flex; }
  .slick-initialized .slick-track .slick-slide {
    display: flex;
    height: auto;
    align-items: stretch;
    justify-content: center; }

.widget-product-carousel .product-item {
  display: block !important;
  visibility: visible !important; }

.widget-product-carousel .product-item-wrap .info-wrapper .brand {
  min-height: 45px !important; }

.widget-product-carousel .product-item-wrap .info-wrapper .title {
  min-height: 70px; }

@media (max-width: 767.98px) {
  .widget-product-carousel.products-wrapper.products-grid .simple-product-wrapper {
    width: 100%;
    border-right: none; } }

.spinner-lg {
  width: 4rem;
  height: 4rem; }

.table .col {
  width: auto; }

.zendesk-button.zendesk-button {
  position: fixed;
  margin: 10px 25px 30px 25px;
  bottom: 0;
  right: 0;
  z-index: 88;
  width: 66px;
  height: 66px;
  background: #fcd900;
  background-clip: padding-box;
  border: solid 7px rgba(252, 217, 0, 0.4);
  border-radius: 100%;
  text-align: center;
  vertical-align: middle; }
  .zendesk-button.zendesk-button:hover {
    background-clip: border-box; }
  .zendesk-button.zendesk-button:focus {
    outline: 2px solid rgba(252, 217, 0, 0.4); }
  .zendesk-button.zendesk-button svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.zendesk-chat.zendesk-chat:hover {
  cursor: pointer; }

.size-container {
  width: 100%;
  border: 0;
  margin: auto;
  border-radius: 4px; }
  .size-container .text-bold {
    font-weight: 500; }
  .size-container h3 {
    font-size: 30px;
    font-weight: 700;
    color: #21285e;
    margin: 0;
    padding-top: 10px;
    padding-bottom: 10px; }
  @media (max-width: 575.98px) {
    .size-container iframe {
      margin: 0 -3rem;
      width: calc(100% + 6rem); } }

.top-logo-container {
  text-align: center;
  width: 100%; }
  .top-logo-container img {
    width: 170px; }

.usp-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 60px;
  padding-bottom: 80px; }
  .usp-container .info-snippet-container {
    display: flex;
    flex-direction: column;
    max-width: 200px;
    align-items: flex-start;
    margin-right: 25px;
    margin-left: 25px; }
  @media (max-width: 767.98px) {
    .usp-container {
      flex-wrap: wrap; }
      .usp-container .info-snippet-container {
        max-width: 100%;
        width: 100%;
        margin-top: 2rem; } }

.usp-text-container,
.usp-img-container {
  width: 100%;
  text-align: center; }

.usp-img-container img {
  width: 38px; }

.zm-icon {
  margin-right: 6px; }

.usp-text {
  font-size: 17px;
  font-weight: 400; }

.flex-row-centered {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }

.margin-right-1 {
  margin-right: 24px; }

.footer-img-container {
  height: 20px; }
  .footer-img-container img {
    height: 100%; }

.terms-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px; }

.terms {
  font-size: 12px !important;
  text-align: center;
  max-width: 600px; }

.kuSearchResultsPageContainer .filter-sidebar.filter-sidebar.filter-no-options,
.layered-filter-block.filter-no-options {
  display: none; }

.kuSearchResultsPageContainer .filter-sidebar.filter-sidebar .filter-clear,
.layered-filter-block .filter-clear {
  text-decoration: underline;
  font-size: 1.2rem; }
  .kuSearchResultsPageContainer .filter-sidebar.filter-sidebar .filter-clear:hover,
  .layered-filter-block .filter-clear:hover {
    text-decoration: none; }

.kuSearchResultsPageContainer .filter-sidebar.filter-sidebar .filter-title.filter-title,
.layered-filter-block .filter-title.filter-title {
  display: flex;
  align-items: center;
  background-color: #000;
  font-weight: 700;
  color: #fff;
  padding: 8px; }
  @media (min-width: 1024px) {
    .kuSearchResultsPageContainer .filter-sidebar.filter-sidebar .filter-title.filter-title svg,
    .layered-filter-block .filter-title.filter-title svg {
      margin-right: 0.8rem; } }
  .kuSearchResultsPageContainer .filter-sidebar.filter-sidebar .filter-title.filter-title strong,
  .layered-filter-block .filter-title.filter-title strong {
    font-size: 1.3rem;
    line-height: 24px;
    display: inline-block;
    outline: none; }
    @media (max-width: 1023.98px) {
      .kuSearchResultsPageContainer .filter-sidebar.filter-sidebar .filter-title.filter-title strong,
      .layered-filter-block .filter-title.filter-title strong {
        margin: auto;
        text-align: center;
        font-family: "Montserrat", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-size: 1.6rem; } }

.kuSearchResultsPageContainer .filter-sidebar.filter-sidebar .active .am-show-more.-active,
.layered-filter-block .active .am-show-more.-active {
  padding: 0.5rem 1.5rem;
  margin-right: 2.28rem; }

.kuSearchResultsPageContainer .filter-sidebar.filter-sidebar .filter-options-item .am-labels-folding .item,
.layered-filter-block .filter-options-item .am-labels-folding .item {
  align-items: normal; }

.kuSearchResultsPageContainer .filter-sidebar.filter-sidebar .filter-options-item .filter-options-title,
.layered-filter-block .filter-options-item .filter-options-title {
  position: relative;
  padding: 8.5px 13px;
  background: none;
  border: none;
  border-bottom: solid 1px #ddd;
  box-shadow: none;
  font-size: 1.3rem;
  line-height: 22px;
  margin: 0;
  outline: none;
  text-align: left;
  width: 100%;
  color: #000; }
  .kuSearchResultsPageContainer .filter-sidebar.filter-sidebar .filter-options-item .filter-options-title::after,
  .layered-filter-block .filter-options-item .filter-options-title::after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
    background-image: url("../images/plus.svg");
    display: block;
    width: 8px;
    height: 8px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    content: ' '; }
  @media (max-width: 1023.98px) {
    .kuSearchResultsPageContainer .filter-sidebar.filter-sidebar .filter-options-item .filter-options-title,
    .layered-filter-block .filter-options-item .filter-options-title {
      padding-left: 28px; }
      .kuSearchResultsPageContainer .filter-sidebar.filter-sidebar .filter-options-item .filter-options-title::after,
      .layered-filter-block .filter-options-item .filter-options-title::after {
        padding-right: 28px; } }

.kuSearchResultsPageContainer .filter-sidebar.filter-sidebar .filter-options-item.active .filter-options-title::after,
.layered-filter-block .filter-options-item.active .filter-options-title::after {
  background-image: url("../images/minus.svg"); }

.kuSearchResultsPageContainer .filter-sidebar.filter-sidebar .filter-options-item .filter-options-content,
.layered-filter-block .filter-options-item .filter-options-content {
  margin-bottom: 8px;
  padding-left: 13px; }
  .kuSearchResultsPageContainer .filter-sidebar.filter-sidebar .filter-options-item .filter-options-content .am-filter-price,
  .layered-filter-block .filter-options-item .filter-options-content .am-filter-price {
    max-width: 65px;
    padding-left: 1.1rem;
    padding-right: 1.1rem; }
  .kuSearchResultsPageContainer .filter-sidebar.filter-sidebar .filter-options-item .filter-options-content ul.items.items,
  .kuSearchResultsPageContainer .filter-sidebar.filter-sidebar .filter-options-item .filter-options-content ol.items.items,
  .layered-filter-block .filter-options-item .filter-options-content ul.items.items,
  .layered-filter-block .filter-options-item .filter-options-content ol.items.items {
    margin: 0;
    padding-top: 12px; }
  .kuSearchResultsPageContainer .filter-sidebar.filter-sidebar .filter-options-item .filter-options-content .am_shopby_link_selected,
  .layered-filter-block .filter-options-item .filter-options-content .am_shopby_link_selected {
    font-weight: 400; }
  .kuSearchResultsPageContainer .filter-sidebar.filter-sidebar .filter-options-item .filter-options-content .count,
  .layered-filter-block .filter-options-item .filter-options-content .count {
    color: #000; }
  .kuSearchResultsPageContainer .filter-sidebar.filter-sidebar .filter-options-item .filter-options-content .item.item,
  .layered-filter-block .filter-options-item .filter-options-content .item.item {
    display: flex;
    margin-left: 0 !important;
    padding-left: 2.9rem;
    font-size: 1.2rem; }
    @media (max-width: 1023.98px) {
      .kuSearchResultsPageContainer .filter-sidebar.filter-sidebar .filter-options-item .filter-options-content .item.item,
      .layered-filter-block .filter-options-item .filter-options-content .item.item {
        padding-left: 5.4rem; }
        .kuSearchResultsPageContainer .filter-sidebar.filter-sidebar .filter-options-item .filter-options-content .item.item .custom-control-label,
        .layered-filter-block .filter-options-item .filter-options-content .item.item .custom-control-label {
          margin-top: 0.8rem; } }
    .kuSearchResultsPageContainer .filter-sidebar.filter-sidebar .filter-options-item .filter-options-content .item.item a,
    .layered-filter-block .filter-options-item .filter-options-content .item.item a {
      margin-left: 8px;
      color: inherit; }
      .kuSearchResultsPageContainer .filter-sidebar.filter-sidebar .filter-options-item .filter-options-content .item.item a,
      .kuSearchResultsPageContainer .filter-sidebar.filter-sidebar .filter-options-item .filter-options-content .item.item a .count,
      .layered-filter-block .filter-options-item .filter-options-content .item.item a,
      .layered-filter-block .filter-options-item .filter-options-content .item.item a .count {
        color: inherit; }

.am_shopby_apply_filters .am-show-button {
  position: static;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: 14px 8px 16px rgba(0, 0, 0, 0.16);
  padding: 10px; }
  .am_shopby_apply_filters .am-show-button, .am_shopby_apply_filters .am-show-button::before {
    background-color: #fff; }
  .am_shopby_apply_filters .am-show-button::before {
    position: absolute;
    top: 8px;
    left: -20px;
    z-index: -1;
    width: 40px;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 0 0 0 3px;
    box-shadow: 7px 6px 16px rgba(0, 0, 0, 0.16);
    transform: rotate(45deg);
    transform-origin: 50%; }

.am_shopby_apply_filters:not(.-fixed) .am-show-button {
  border-left: none;
  border-radius: 0 3px 3px 0; }

.am_shopby_apply_filters.-fixed .am-show-button {
  justify-content: center; }
  .am_shopby_apply_filters.-fixed .am-show-button .am-items {
    margin: 0; }

@media (max-width: 1239.98px) {
  #layered-filter-block {
    display: block !important;
    margin-bottom: 0; }
  .toolbar.toolbar-products:first-child {
    padding: 0;
    background-color: #fff;
    border: solid 1px #000;
    justify-content: space-between; } }
  @media (max-width: 1239.98px) and (max-width: 1023.98px) {
    .toolbar.toolbar-products:first-child {
      display: flex !important; } }
  @media (max-width: 1239.98px) and (max-width: 575.98px) {
    .toolbar.toolbar-products:first-child {
      box-sizing: content-box;
      margin: 0; } }

@media (max-width: 1239.98px) {
    .toolbar.toolbar-products:first-child .filter-mobile-controls {
      flex: 1 1 50%;
      background-color: #000; }
      .toolbar.toolbar-products:first-child .filter-mobile-controls button.refine-control {
        background-color: #000;
        color: #fff;
        border: none;
        width: 100%;
        height: 40px;
        outline: none;
        font-size: 1.3rem;
        display: flex;
        align-items: center;
        justify-content: center; }
        .toolbar.toolbar-products:first-child .filter-mobile-controls button.refine-control.active::before {
          content: '';
          background: rgba(0, 0, 0, 0.5);
          display: block;
          height: 100%;
          position: fixed;
          right: 0;
          top: 0;
          width: 100%;
          z-index: 100; }
        .toolbar.toolbar-products:first-child .filter-mobile-controls button.refine-control svg {
          margin-right: 0.5rem; }
    .toolbar.toolbar-products:first-child .sorter {
      flex: 1 1 50%;
      padding: 4px;
      height: 40px;
      font-size: 1.2rem;
      text-align: center;
      justify-content: center !important;
      margin: 0 0 0 6px; } }
    @media (max-width: 1239.98px) and (max-width: 1239.98px) {
      .toolbar.toolbar-products:first-child .sorter {
        margin-top: -1px;
        margin-bottom: -1px; } }

@media (max-width: 1239.98px) {
      .toolbar.toolbar-products:first-child .sorter .sorter-options {
        background-color: #fff;
        margin: 0;
        padding: 0 1.8rem 0 1rem; }
  .toolbar.toolbar-products:last-child .filter-mobile-controls {
    display: none !important; } }

.attr-icons-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  @media (max-width: 767.98px) {
    .attr-icons-wrapper {
      margin-top: 1rem;
      margin-bottom: 1rem; } }
  .attr-icons-wrapper svg.attr-icon {
    padding: 0; }
  .attr-icons-wrapper .attr-icon {
    width: 44px;
    height: 44px;
    border-radius: 3px;
    background-color: #ddd;
    margin-right: 4px;
    margin-bottom: 4px;
    padding: 4px 0;
    text-align: center;
    overflow: hidden; }
    .attr-icons-wrapper .attr-icon .attr-value {
      font-size: 1.6rem;
      line-height: 1.9rem;
      text-align: center;
      font-weight: 800; }
    .attr-icons-wrapper .attr-icon .attr-text {
      font-size: 0.7rem;
      line-height: 0.8rem;
      text-align: center;
      text-transform: uppercase;
      font-weight: 600; }
    .attr-icons-wrapper .attr-icon.small-val .attr-value {
      font-size: 1.2rem;
      line-height: 1.4rem;
      min-height: 19px; }
    .attr-icons-wrapper .attr-icon.extra-small-val .attr-value {
      font-size: 0.9rem;
      line-height: 1.2rem;
      min-height: 19px; }
    .attr-icons-wrapper .attr-icon.small-label .attr-text {
      font-size: 0.6rem; }
    .attr-icons-wrapper .attr-icon.lcd-size .attr-value {
      border: solid 0.5px #000;
      padding: 3px 5px;
      margin-bottom: 1px;
      display: inline-block;
      border-radius: 2px; }

@media (min-width: 768px) {
  body.catalog-product-view .attr-icons-wrapper svg.attr-icon {
    padding: 0; }
  body.catalog-product-view .attr-icons-wrapper .attr-icon {
    width: 64px;
    height: 64px;
    padding: 7px 0; }
    body.catalog-product-view .attr-icons-wrapper .attr-icon .attr-value {
      font-size: 2.3rem;
      line-height: 2.7rem;
      text-align: center;
      font-weight: 800; }
    body.catalog-product-view .attr-icons-wrapper .attr-icon .attr-text {
      font-size: 1rem;
      line-height: 1.1rem;
      text-align: center;
      text-transform: uppercase; }
    body.catalog-product-view .attr-icons-wrapper .attr-icon.small-val .attr-value {
      font-size: 1.6rem;
      line-height: 1.8rem;
      min-height: 27px; }
    body.catalog-product-view .attr-icons-wrapper .attr-icon.extra-small-val .attr-value {
      font-size: 1.2rem;
      line-height: 1.5rem;
      min-height: 27px; }
    body.catalog-product-view .attr-icons-wrapper .attr-icon.small-label .attr-text {
      font-size: 0.9rem; } }

body .amasty-catalog-topnav {
  display: block; }
  @media (min-width: 1024px) {
    body .amasty-catalog-topnav {
      display: none; } }

body .toolbar {
  position: relative;
  background: #f0f0f0;
  padding: 4px 20px;
  display: flex !important;
  flex-direction: row;
  height: 100%;
  max-height: 40px; }
  body .toolbar .toolbar-sorter {
    display: flex;
    justify-content: left;
    align-items: center; }
    body .toolbar .toolbar-sorter .sorter-label {
      margin: 0; }
    body .toolbar .toolbar-sorter .sorter-action {
      top: 0;
      text-decoration: none !important; }
      body .toolbar .toolbar-sorter .sorter-action:hover {
        text-decoration: underline !important;
        cursor: pointer; }
      body .toolbar .toolbar-sorter .sorter-action::before {
        display: none; }
      body .toolbar .toolbar-sorter .sorter-action > span {
        width: auto;
        position: relative;
        height: auto;
        margin: 0; }
  @media (max-width: 1239.98px) {
    body .toolbar.toolbar-products:last-child {
      display: block;
      background: none; }
      body .toolbar.toolbar-products:last-child .sorter {
        display: none; }
      body .toolbar.toolbar-products:last-child .toolbar-amount {
        display: block; } }
  ~ body .toolbar.toolbar-products.toolbar-products .filter-mobile-controls {
    display: none !important; }
  body .toolbar .modes,
  body .toolbar .sorter,
  body .toolbar .toolbar-amount {
    position: relative;
    float: none;
    flex: 1 1 33%;
    margin: 0;
    padding: 0;
    height: auto;
    vertical-align: middle; }
  body .toolbar .sorter {
    text-align: left; }
    body .toolbar .sorter .sorter-label {
      font-weight: 700;
      text-transform: capitalize; }
      @media (max-width: 767.98px) {
        body .toolbar .sorter .sorter-label {
          margin-right: -0.3rem; } }
    body .toolbar .sorter .sorter-options {
      background-color: #f0f0f0;
      border: none;
      outline: none;
      padding-right: 40px; }
      @media (max-width: 767.98px) {
        body .toolbar .sorter .sorter-options {
          max-width: 70%; } }
  body .toolbar .toolbar-amount {
    font-size: 12px;
    line-height: 3.4rem; }
    @media (max-width: 1239.98px) {
      body .toolbar .toolbar-amount {
        display: none; } }
  body .toolbar .modes {
    width: 30px;
    height: 30px;
    display: inline-block;
    text-align: right; }
    @media (max-width: 1239.98px) {
      body .toolbar .modes {
        display: none; } }
    body .toolbar .modes .modes-mode {
      border: none;
      text-align: center;
      float: none;
      cursor: pointer; }
      body .toolbar .modes .modes-mode::before {
        content: none;
        display: none; }
      body .toolbar .modes .modes-mode svg {
        height: 20px; }
        body .toolbar .modes .modes-mode svg path {
          fill: #aaa; }
      body .toolbar .modes .modes-mode.kuCurrent, body .toolbar .modes .modes-mode.active {
        cursor: default; }
        body .toolbar .modes .modes-mode.kuCurrent svg path, body .toolbar .modes .modes-mode.active svg path {
          fill: #000; }

.page-products .columns,
#amasty-shopby-product-list {
  z-index: initial; }

#amasty-shopby-product-list {
  display: flex;
  flex-direction: column; }
  #amasty-shopby-product-list .toolbar.toolbar-products:first-child {
    order: 0; }
    #amasty-shopby-product-list .toolbar.toolbar-products:first-child .m-auto.w-100,
    #amasty-shopby-product-list .toolbar.toolbar-products:first-child .percentage-wrapper {
      display: none; }
  #amasty-shopby-product-list .products-wrapper {
    order: 1; }
  #amasty-shopby-product-list .ias-spinner {
    order: 9998;
    display: inline-block;
    width: 3rem;
    height: 3rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: 0.75s linear infinite spinner-border;
    margin: auto;
    color: #000; }
    #amasty-shopby-product-list .ias-spinner img {
      display: none !important; }
    #amasty-shopby-product-list .ias-spinner em {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border: 0; }
    #amasty-shopby-product-list .ias-spinner + .toolbar-products .percentage-wrapper {
      display: none; }
  #amasty-shopby-product-list .ias-noneleft,
  #amasty-shopby-product-list .ias-trigger-next {
    order: 9999; }
  #amasty-shopby-product-list .toolbar.toolbar-products ~ .toolbar-products {
    order: 2;
    background: none;
    margin: 0; }
    #amasty-shopby-product-list .toolbar.toolbar-products ~ .toolbar-products .filter-mobile-controls {
      display: none; }
    #amasty-shopby-product-list .toolbar.toolbar-products ~ .toolbar-products .sorter,
    #amasty-shopby-product-list .toolbar.toolbar-products ~ .toolbar-products .modes {
      display: none; }

@media (min-width: 1024px) {
  .kuSearchResultsPageContainer .filter-sidebar.filter-sidebar {
    display: table-cell;
    width: 315px;
    max-width: 315px;
    padding-right: 4rem; }
    .kuSearchResultsPageContainer .filter-sidebar.filter-sidebar .amshopby-items {
      padding-left: 0; } }

@media (max-width: 1023.98px) {
  .kuSearchResultsPageContainer .filter-sidebar.filter-sidebar {
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    margin: 0;
    max-width: 414px;
    min-width: 0;
    overflow-y: auto;
    transition: transform 0.3s;
    transform: translateX(100%);
    z-index: 101;
    background: #fff; }
    .kuSearchResultsPageContainer .filter-sidebar.filter-sidebar.active {
      transform: translateX(0);
      box-shadow: 0 3px 10px #00000026;
      width: 100%; }
    .kuSearchResultsPageContainer .filter-sidebar.filter-sidebar .filter-action-holder,
    .kuSearchResultsPageContainer .filter-sidebar.filter-sidebar .amshopby-items {
      padding-left: 1.9rem;
      padding-right: 1.9rem; } }

.header-content-container .block.block-search {
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .header-content-container .block.block-search .search-wrapper {
    position: relative; }
    .header-content-container .block.block-search .search-wrapper .search-icon {
      right: 1rem;
      top: 2.2rem; }

.klevuTarget.klevu-fluid.klevuTarget > div {
  left: 0 !important;
  right: 0 !important;
  width: 100% !important;
  max-width: none !important;
  top: 141px !important; }
  @media (min-width: 1024px) and (max-width: 1239.98px) {
    .klevuTarget.klevu-fluid.klevuTarget > div {
      top: 190px !important; } }
  @media (min-width: 1240px) {
    .klevuTarget.klevu-fluid.klevuTarget > div {
      top: 169px !important; } }

.klevuQuickSearchingArea.klevuQuickSearchingArea {
  box-shadow: 0 3px 6px #00000029; }
  .klevuQuickSearchingArea.klevuQuickSearchingArea [data-container-role='main'] {
    max-width: 1190px;
    width: 100%; }
    @media (min-width: 768px) {
      .klevuQuickSearchingArea.klevuQuickSearchingArea [data-container-role='main'] {
        padding-right: 1.5rem;
        padding-left: 1.5rem; } }
  .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea .kuContainer {
    display: none; }
  .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea .kuQuickResultsListContainer.kuQuickResultsListContainer {
    height: 330px;
    overflow: visible; }
    @media (max-width: 767.98px) {
      .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea .kuQuickResultsListContainer.kuQuickResultsListContainer {
        height: auto; } }
    .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea .kuQuickResultsListContainer.kuQuickResultsListContainer .klevu-desc-l2 {
      display: none !important; }
  .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea [data-block-id='ku_quick_left_facets'] {
    max-height: 370px !important;
    background: none !important; }
    @media (max-width: 767.98px) {
      .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea [data-block-id='ku_quick_left_facets'] {
        max-height: none !important;
        max-width: 540px;
        margin-right: auto;
        margin-left: auto; } }
  .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea [ku-container][data-container-role='left'] {
    background-color: rgba(221, 221, 221, 0.3);
    position: relative; }
    @media (max-width: 767.98px) {
      .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea [ku-container][data-container-role='left'] {
        background-color: #fff; } }
    .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea [ku-container][data-container-role='left']::before {
      content: '';
      position: absolute;
      top: 0;
      right: 100%;
      width: 100%;
      height: 100%;
      background-color: rgba(221, 221, 221, 0.3);
      z-index: -1; }
    .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea [ku-container][data-container-role='left'] .kuFilters {
      padding: 3rem 2.2rem 1rem; }
      @media (min-width: 768px) {
        .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea [ku-container][data-container-role='left'] .kuFilters {
          padding: 3rem 1.5rem 1rem 1.5rem; } }
      @media (min-width: 1240px) {
        .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea [ku-container][data-container-role='left'] .kuFilters {
          padding: 3rem 4.6rem 1rem 1rem; } }
    .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea [ku-container][data-container-role='left'] .kuFilterIcon {
      display: none; }
    @media (max-width: 767.98px) {
      .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea [ku-container][data-container-role='left'] .klevu-cms {
        padding: 0 2.2rem 3rem 2.2rem; } }
    @media (min-width: 768px) {
      .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea [ku-container][data-container-role='left'] .klevu-cms {
        padding: 0 1.5rem 0 1.5rem; } }
    @media (min-width: 1240px) {
      .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea [ku-container][data-container-role='left'] .klevu-cms {
        padding: 0 4.6rem 0 1rem; } }
    .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea [ku-container][data-container-role='left'] .klevu-cms .kuFilterNames.kuFilterNames .klevuFilterOption {
      padding-bottom: 0; }
    .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea [ku-container][data-container-role='left'] .klevu-cms .kuFilters {
      padding: 0; }
      .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea [ku-container][data-container-role='left'] .klevu-cms .kuFilters .kuFilterBox > .kuFilterHead {
        display: none; }
    .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea [ku-container][data-container-role='left'] .klevu-cms [data-content='cmsCompressed'] {
      display: block !important; }
      .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea [ku-container][data-container-role='left'] .klevu-cms [data-content='cmsCompressed'] li {
        border-bottom: none; }
        .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea [ku-container][data-container-role='left'] .klevu-cms [data-content='cmsCompressed'] li:nth-child(2) ~ li {
          display: none; }
      .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea [ku-container][data-container-role='left'] .klevu-cms [data-content='cmsCompressed'] .klevuProductItemTop,
      .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea [ku-container][data-container-role='left'] .klevu-cms [data-content='cmsCompressed'] [data-block-id='ku_quick_other_items'] {
        display: none; }
      .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea [ku-container][data-container-role='left'] .klevu-cms [data-content='cmsCompressed'] .klevuQuickProductInnerBlock {
        padding: 1rem 0;
        border-bottom: 1px solid #ddd; }
        .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea [ku-container][data-container-role='left'] .klevu-cms [data-content='cmsCompressed'] .klevuQuickProductInnerBlock:hover {
          text-decoration: underline; }
      .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea [ku-container][data-container-role='left'] .klevu-cms [data-content='cmsCompressed'] .klevuQuickProductDescBlock {
        margin-left: 0;
        text-decoration: inherit; }
      .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea [ku-container][data-container-role='left'] .klevu-cms [data-content='cmsCompressed'] .klevuProductItemBottom {
        min-height: 0;
        padding: 0;
        text-decoration: inherit; }
        .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea [ku-container][data-container-role='left'] .klevu-cms [data-content='cmsCompressed'] .klevuProductItemBottom .klevuQuickProductName {
          font-weight: 400;
          color: #000; }
        .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea [ku-container][data-container-role='left'] .klevu-cms [data-content='cmsCompressed'] .klevuProductItemBottom .klevu-desc-l2 {
          font-size: 1rem; }
    .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea [ku-container][data-container-role='left'] [data-container-role='left'] {
      background: none;
      width: 100%;
      max-width: 100%;
      display: block; }
      .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea [ku-container][data-container-role='left'] [data-container-role='left']::before {
        display: none; }
      .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea [ku-container][data-container-role='left'] [data-container-role='left'] .kuFilterHead.kuCollapse {
        display: none; }
      .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea [ku-container][data-container-role='left'] [data-container-role='left'] .kuFilterIcon {
        display: none; }
      .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea [ku-container][data-container-role='left'] [data-container-role='left'] .kuMulticheck {
        margin: 0;
        padding: 0; }
      .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea [ku-container][data-container-role='left'] [data-container-role='left'] .kufacet-text {
        background-color: #fcd900;
        border-radius: 4px;
        color: #000 !important;
        font: normal normal normal 1.1rem "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
        text-transform: capitalize !important;
        display: inline-block;
        padding: 0.5rem 1rem !important;
        width: auto;
        text-decoration: none !important; }
      .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea [ku-container][data-container-role='left'] [data-container-role='left'] .kuFilterTotal,
      .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea [ku-container][data-container-role='left'] [data-container-role='left'] .kuFilterCancel {
        display: none; }
  .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea [data-result-view='grid'].klevuQuickSearchResults ul {
    display: flex;
    flex-wrap: wrap; }
  .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea [data-result-view='grid'].klevuQuickSearchResults .klevuQuickImgWrap {
    width: auto !important;
    height: 60px !important; }
  .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea [data-result-view='grid'].klevuQuickSearchResults img {
    width: 50px;
    height: 50px;
    object-fit: contain; }
  .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea [data-result-view='grid'].klevuQuickSearchResults .klevuQuickAddtoCart.klevuQuickAddtoCart {
    display: none; }
  .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea [data-result-view='grid'].klevuQuickSearchResults .klevuProductItemBottom {
    position: static;
    width: auto !important;
    text-decoration: inherit; }
    .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea [data-result-view='grid'].klevuQuickSearchResults .klevuProductItemBottom > a {
      position: absolute;
      top: 0;
      left: 0;
      padding: 0;
      right: 0;
      bottom: 0; }
    .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea [data-result-view='grid'].klevuQuickSearchResults .klevuProductItemBottom .klevuQuickProductName {
      font-weight: 400; }
  .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea [data-result-view='grid'].klevuQuickSearchResults .klevuProductItemTop {
    width: auto !important;
    flex-shrink: 0; }
  .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea [data-result-view='grid'] .klevuProduct.klevuProduct {
    display: flex;
    align-items: flex-start;
    position: relative;
    min-height: 60px;
    padding: 0.5rem 1rem;
    border: none !important;
    float: none;
    width: 50% !important;
    background: none !important; }
    .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea [data-result-view='grid'] .klevuProduct.klevuProduct:hover {
      text-decoration: underline; }
    @media (max-width: 767.98px) {
      .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea [data-result-view='grid'] .klevuProduct.klevuProduct {
        width: 100% !important;
        padding: 0.5rem 0; } }
    .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea [data-result-view='grid'] .klevuProduct.klevuProduct:nth-child(6) ~ .klevuProduct {
      display: none; }
    .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea [data-result-view='grid'] .klevuProduct.klevuProduct > a {
      display: flex;
      flex-shrink: 0; }
    .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea [data-result-view='grid'] .klevuProduct.klevuProduct .klevuQuickProductDescBlock {
      display: flex;
      flex-direction: column;
      text-decoration: inherit; }
    .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea [data-result-view='grid'] .klevuProduct.klevuProduct a.klevuQuickProductInnerBlock {
      max-width: 100%;
      padding: 0; }
      .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea [data-result-view='grid'] .klevuProduct.klevuProduct a.klevuQuickProductInnerBlock:hover {
        text-decoration: underline; }
    .kuQuickSearchFacetedLayout .klevuQuickSearchingArea.klevuQuickSearchingArea [data-result-view='grid'] .klevuProduct.klevuProduct .klevuQuickProductPrice.kuClippedOne {
      order: -1;
      margin-bottom: 0.3rem; }
  .klevuQuickSearchingArea.klevuQuickSearchingArea .kuFilterBox .kuFilterNames.kuFilterNames {
    height: auto !important; }
    .klevuQuickSearchingArea.klevuQuickSearchingArea .kuFilterBox .kuFilterNames.kuFilterNames ul li {
      border-bottom: 1px solid #ddd; }
      .klevuQuickSearchingArea.klevuQuickSearchingArea .kuFilterBox .kuFilterNames.kuFilterNames ul li:nth-child(2) ~ li {
        display: none !important; }
      .klevuQuickSearchingArea.klevuQuickSearchingArea .kuFilterBox .kuFilterNames.kuFilterNames ul li > a {
        padding: 1rem 0; }
        .klevuQuickSearchingArea.klevuQuickSearchingArea .kuFilterBox .kuFilterNames.kuFilterNames ul li > a:hover {
          text-decoration: underline; }
      .klevuQuickSearchingArea.klevuQuickSearchingArea .kuFilterBox .kuFilterNames.kuFilterNames ul li .kufacet-text.kufacet-text {
        width: auto;
        max-width: none;
        text-decoration: inherit; }
      .klevuQuickSearchingArea.klevuQuickSearchingArea .kuFilterBox .kuFilterNames.kuFilterNames ul li .kuFilterTotal {
        float: none;
        padding-left: 5px; }
        .klevuQuickSearchingArea.klevuQuickSearchingArea .kuFilterBox .kuFilterNames.kuFilterNames ul li .kuFilterTotal::before {
          content: '('; }
        .klevuQuickSearchingArea.klevuQuickSearchingArea .kuFilterBox .kuFilterNames.kuFilterNames ul li .kuFilterTotal::after {
          content: ')'; }
    .klevuQuickSearchingArea.klevuQuickSearchingArea .kuFilterBox .kuFilterNames.kuFilterNames a {
      color: #000; }
  .klevuQuickSearchingArea.klevuQuickSearchingArea .kuFilterBox .kuShowOpt {
    display: none; }
  .klevuQuickSearchingArea.klevuQuickSearchingArea .kuFilterBox.klevuFilter.kuMulticheck:not([data-filter='category']), .klevuQuickSearchingArea.klevuQuickSearchingArea .kuFilterBox.klevuFilter[data-filter='klevu_price'] {
    display: none; }
  .klevuQuickSearchingArea.klevuQuickSearchingArea .kuFilterBox.klevuFilter.kuMulticheck {
    margin-bottom: 0; }
  .klevuQuickSearchingArea.klevuQuickSearchingArea .kuFilterBox .kuFilterHead.kuFilterHead {
    background-color: #fcd900;
    border-radius: 4px;
    color: #000 !important;
    font: normal normal normal 0 "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
    text-transform: capitalize !important;
    display: inline-block;
    margin-bottom: 0 !important;
    padding: 0.5rem 1rem !important; }
    .klevuQuickSearchingArea.klevuQuickSearchingArea .kuFilterBox .kuFilterHead.kuFilterHead::before {
      content: 'Categories';
      font-size: 1.1rem; }
    .klevuQuickSearchingArea.klevuQuickSearchingArea .kuFilterBox .kuFilterHead.kuFilterHead::after {
      display: none !important; }
  @media (max-width: 767.98px) {
    .klevuQuickSearchingArea.klevuQuickSearchingArea [data-container-id='ku_quick_main_content_container'] {
      display: flex !important;
      flex-direction: column; } }
  .klevuQuickSearchingArea.klevuQuickSearchingArea [data-container-id='ku_quick_main_content_center'] .klevuResultsBlock .klevuQuickSearchResults.productList {
    padding: 2rem; }
    @media (min-width: 1240px) {
      .klevuQuickSearchingArea.klevuQuickSearchingArea [data-container-id='ku_quick_main_content_center'] .klevuResultsBlock .klevuQuickSearchResults.productList {
        padding: 2rem 5.6rem; } }
  @media (max-width: 767.98px) {
    .klevuQuickSearchingArea.klevuQuickSearchingArea [data-container-id='ku_quick_main_content_center'] {
      order: -1;
      max-width: 540px !important;
      margin-right: auto !important;
      margin-left: auto !important;
      margin-bottom: 2rem !important; } }
  .klevuQuickSearchingArea.klevuQuickSearchingArea .klevu-all {
    position: absolute;
    bottom: 2rem;
    left: 2rem;
    right: 2rem;
    z-index: 1; }
    @media (max-width: 767.98px) {
      .klevuQuickSearchingArea.klevuQuickSearchingArea .klevu-all {
        bottom: -1rem; } }
    @media (min-width: 1240px) {
      .klevuQuickSearchingArea.klevuQuickSearchingArea .klevu-all {
        bottom: 3rem;
        left: 7rem;
        right: 7rem; } }
    .klevuQuickSearchingArea.klevuQuickSearchingArea .klevu-all a {
      font-weight: 700 !important; }

.kuSearchPersonalizationsContainer {
  display: none; }

.kuQuickResultsListHeader {
  display: none; }

.searchRequestLoading .klevuQuickSearchingArea {
  display: none !important; }

.klevuRestyle > [data-container-role='main'] {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  position: relative;
  display: flex;
  width: 100%; }
  @media (max-width: 767.98px) {
    .klevuRestyle > [data-container-role='main'] {
      flex-direction: column; } }

.klevuRestyle [data-container-role='header'] {
  width: 33%;
  background-color: rgba(221, 221, 221, 0.3);
  position: relative; }
  @media (max-width: 767.98px) {
    .klevuRestyle [data-container-role='header'] {
      width: 100%;
      background-color: #fff; } }
  .klevuRestyle [data-container-role='header']::before {
    content: '';
    position: absolute;
    top: 0;
    right: 100%;
    width: 100vw;
    height: 100%;
    background-color: rgba(221, 221, 221, 0.3);
    z-index: -1; }
  .klevuRestyle [data-container-role='header'] .kuFilters {
    padding: 3rem 2.2rem 1rem; }
    @media (min-width: 768px) {
      .klevuRestyle [data-container-role='header'] .kuFilters {
        padding: 3rem 1.5rem 1rem 1.5rem; } }
    @media (min-width: 1240px) {
      .klevuRestyle [data-container-role='header'] .kuFilters {
        padding: 3rem 4.6rem 1rem 1rem; } }
  .klevuRestyle [data-container-role='header'] .kuFilterIcon {
    display: none; }

.klevuRestyle .klevuAutoSuggestionsWrap {
  padding: 3rem 4.6rem 1rem 1rem;
  border: none; }
  .klevuRestyle .klevuAutoSuggestionsWrap ul li {
    border-bottom: 1px solid #ddd; }
    .klevuRestyle .klevuAutoSuggestionsWrap ul li:nth-child(2) ~ li {
      display: none !important; }
    .klevuRestyle .klevuAutoSuggestionsWrap ul li a {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin: 0.2rem 0.5rem 0.2rem 0;
      padding: 1rem 0; }
      .klevuRestyle .klevuAutoSuggestionsWrap ul li a * {
        vertical-align: middle; }

.klevuRestyle .klevuSuggestionHeading {
  background-color: #fcd900;
  border-radius: 4px;
  color: #000 !important;
  font: normal normal normal 1.1rem "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  text-transform: capitalize !important;
  display: inline-block;
  padding: 0.5rem 1rem !important;
  width: auto;
  text-decoration: none !important; }

.klevuRestyle .klevuQuickSearchResults ul {
  margin: 0;
  padding-left: 0;
  text-align: left;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  .klevuRestyle .klevuQuickSearchResults ul li {
    margin-bottom: auto;
    display: flex;
    align-items: flex-start;
    position: relative;
    min-height: 60px;
    padding: 0.5rem 1rem;
    border: none !important;
    float: none;
    width: 50% !important;
    background: none !important; }
    @media (max-width: 767.98px) {
      .klevuRestyle .klevuQuickSearchResults ul li {
        width: 100% !important;
        padding: 0.5rem 0; } }
    .klevuRestyle .klevuQuickSearchResults ul li:hover {
      text-decoration: underline; }

.klevuRestyle .klevuQuickSearchResults .klevuQuickImgWrap.klevuQuickImgWrap {
  float: none;
  overflow: hidden;
  width: 100% !important;
  height: 50px !important;
  text-align: center; }

.klevuRestyle [ku-container] {
  text-decoration: inherit; }

.klevuRestyle [data-container-id='ku_quick_main_content_center'] {
  position: static; }
  .klevuRestyle [data-container-id='ku_quick_main_content_center'] .klevuResultsBlock .klevuQuickSearchResults.productList.productList {
    padding: 2rem 5.6rem 7rem; }
    @media (max-width: 767.98px) {
      .klevuRestyle [data-container-id='ku_quick_main_content_center'] .klevuResultsBlock .klevuQuickSearchResults.productList.productList {
        padding: 2rem; } }

@media (max-width: 767.98px) {
  .klevuRestyle [data-container-id='ku_quick_main_content_container'] {
    order: -1;
    max-width: 540px !important;
    margin-right: auto !important;
    margin-left: auto !important;
    margin-bottom: 2rem !important; } }

.productList .klevuProduct [data-role='add-to-links'] {
  display: flex;
  margin-top: 1rem;
  margin-right: 3rem;
  justify-content: flex-end;
  gap: 1rem; }
  .productList .klevuProduct [data-role='add-to-links'] .btn-action-secondary .icon-label {
    display: none; }
  .productList .klevuProduct [data-role='add-to-links'].in-wishlist .action.wishlist .add_wish_list {
    background: #eb5050; }
    .productList .klevuProduct [data-role='add-to-links'].in-wishlist .action.wishlist .add_wish_list svg path {
      stroke: #fff; }

.kuSearchResultsPageContainer.kuSearchResultsPageContainer .kuInfiniteScrollContainer .kuLoadMoreBtn {
  padding: 0.6rem 2.4rem;
  font-size: 1.2rem;
  line-height: 1.5;
  border-radius: 0.3rem;
  color: #000;
  border: 2px solid #000;
  background-color: #fff;
  display: inline-block;
  width: auto;
  margin-top: 4rem; }
  .kuSearchResultsPageContainer.kuSearchResultsPageContainer .kuInfiniteScrollContainer .kuLoadMoreBtn:hover {
    background-color: #000;
    color: #fff; }

.kuFilters.kuFilters.kuFilters[role='navigation'] .block-title.filter-title.sidebar-heading {
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  margin-bottom: 2rem;
  font-weight: 700; }
  .kuFilters.kuFilters.kuFilters[role='navigation'] .block-title.filter-title.sidebar-heading svg {
    margin-right: 0.5rem; }

.kuFilters.kuFilters.kuFilters[role='navigation'] .kuFilterBox .kuFilterHead {
  font-size: 1.3rem;
  font-weight: 700;
  text-transform: capitalize; }
  .kuFilters.kuFilters.kuFilters[role='navigation'] .kuFilterBox .kuFilterHead::after {
    position: absolute;
    top: 30%;
    border: none;
    transform: none;
    display: block;
    width: 8px;
    height: 8px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain; }
  .kuFilters.kuFilters.kuFilters[role='navigation'] .kuFilterBox .kuFilterHead.kuExpand::after {
    background-image: url("../images/plus.svg"); }
  .kuFilters.kuFilters.kuFilters[role='navigation'] .kuFilterBox .kuFilterHead.kuCollapse::after {
    background-image: url("../images/minus.svg"); }

.kuFilters.kuFilters.kuFilters[role='navigation'] .kuFilterBox .kuFilterNames a.klevuFilterOption {
  display: flex;
  align-items: center;
  color: #000; }
  .kuFilters.kuFilters.kuFilters[role='navigation'] .kuFilterBox .kuFilterNames a.klevuFilterOption .kuFilterCancel,
  .kuFilters.kuFilters.kuFilters[role='navigation'] .kuFilterBox .kuFilterNames a.klevuFilterOption .kuFilterTotal {
    margin-left: auto;
    margin-right: 0.8rem; }

.kuFilters.kuFilters.kuFilters[role='navigation'] .kuFilterBox .kuFilterNames .kufacet-text {
  font-size: 1.2rem; }

.kuFilters.kuFilters.kuFilters[role='navigation'] .kuFilterBox .kuFilterNames ul li span.kuFilterIcon::before {
  width: 2.4rem;
  height: 2.4rem;
  border: 2px solid #ddd; }

.kuFilters.kuFilters.kuFilters[role='navigation'] .kuFilterBox .kuFilterNames ul li span.kuFilterIcon::after {
  width: 12px;
  height: 7px;
  top: 6px;
  left: 6px;
  border-width: 3px; }

.kuFilters.kuFilters.kuFilters[role='navigation'] .kuFilterBox .kuFilterNames ul li:hover a, .kuFilters.kuFilters.kuFilters[role='navigation'] .kuFilterBox .kuFilterNames ul li.kuSelected a {
  font-weight: 700; }

.kuFilters.kuFilters.kuFilters[role='navigation'] .kuFilterBox .kuFilterNames ul li:hover span.kuFilterIcon::before, .kuFilters.kuFilters.kuFilters[role='navigation'] .kuFilterBox .kuFilterNames ul li.kuSelected span.kuFilterIcon::before {
  border-color: #000; }

.kuFilters.kuFilters.kuFilters[role='navigation'] .kuFilterBox .kuFilterNames ul li:hover span.kuFilterIcon::after, .kuFilters.kuFilters.kuFilters[role='navigation'] .kuFilterBox .kuFilterNames ul li.kuSelected span.kuFilterIcon::after {
  border-color: #000; }

.kuFilters.kuFilters.kuFilters[role='navigation'] .kuFilterBox .kuFilterNames ul li:not(.quick).level-0 > a {
  font-weight: 700; }

.kuFilters.kuFilters.kuFilters[role='navigation'] .kuFilterBox .kuFilterNames ul li:not(.quick).level-1 {
  padding-left: 3rem; }

.kuFilters.kuFilters.kuFilters[role='navigation'] .kuPriceSlider .noUi-connect {
  background: #000; }

.kuFilters.kuFilters.kuFilters[role='navigation'] .kuPriceSlider .noUi-tooltip {
  border: 0;
  font-weight: 700; }

.page-layout-2columns-left .columns {
  display: flex; }

.page-layout-2columns-left .page-title-wrapper {
  border-bottom: 1px solid #ddd;
  margin-bottom: 3rem; }

.page-layout-2columns-left [data-content-type='faq'] h3,
.page-layout-2columns-left [data-content-type='faq'] [data-element='answer'] {
  padding-left: 1.8rem !important; }

.page-layout-2columns-left [data-content-type='faq'] h3 {
  font-size: 1.3rem; }

.page-layout-2columns-left .column.main {
  flex: 0 1 100%;
  width: auto;
  padding-left: 0; }
  @media (min-width: 1024px) {
    .page-layout-2columns-left .column.main {
      flex-basis: 650px;
      margin-left: auto; } }
  @media (min-width: 1240px) {
    .page-layout-2columns-left .column.main {
      flex-basis: 681px;
      margin-right: auto; } }

@media (min-width: 1024px) {
  .page-layout-2columns-left .sidebar-main {
    order: -1;
    flex: 0 1 275.38px;
    margin-right: 2rem;
    padding-right: 0; } }

@media (min-width: 1240px) {
  .page-layout-2columns-left .sidebar-main {
    margin-right: 4rem; } }

.page-footer .footer.content {
  max-width: none;
  margin: 0;
  padding: 0; }

.page-footer .footer-bg {
  padding: 1.4rem 0 0;
  background: linear-gradient(0deg, #3c3c3c 0%, black 100%);
  color: #fff; }
  @media (min-width: 1024px) {
    .page-footer .footer-bg {
      padding: 6.4rem 0; } }

.page-footer .footer-container {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem; }

.page-footer .footer {
  border-top: none; }

.page-footer a:not(.btn):not(a.pagebuilder-button-primary):not(
.pagebuilder-button-primary):not(a.pagebuilder-button-secondary):not(
.pagebuilder-button-secondary) {
  color: inherit; }

.page-footer .footer-logo img {
  filter: brightness(0) invert(1); }

.page-footer .accordion .btn-link {
  border: none;
  font-weight: 700;
  font-size: 1.3rem; }
  .page-footer .accordion .btn-link:hover, .page-footer .accordion .btn-link:focus, .page-footer .accordion .btn-link:active {
    background: none;
    border: none;
    box-shadow: none; }

@media (min-width: 1024px) {
  .page-footer .accordion .collapse,
  .page-footer .accordion .collapsing {
    display: block !important;
    height: auto !important; }
  .page-footer .accordion .btn-link {
    margin-bottom: 1rem;
    padding: 0.6rem;
    color: #fcd900; }
    .page-footer .accordion .btn-link:hover, .page-footer .accordion .btn-link:focus, .page-footer .accordion .btn-link:active {
      text-decoration: none;
      cursor: default;
      color: #fcd900; }
    .page-footer .accordion .btn-link svg {
      display: none; } }

@media (max-width: 1023.98px) {
  .page-footer .accordion {
    max-width: 283px;
    margin: 0 auto; }
    .page-footer .accordion .btn-link {
      padding-top: 0.6rem;
      padding-bottom: 0.6rem; }
      .page-footer .accordion .btn-link, .page-footer .accordion .btn-link:hover, .page-footer .accordion .btn-link:focus, .page-footer .accordion .btn-link:active {
        color: #fff; }
    .page-footer .accordion ul {
      padding-bottom: 1.5rem; } }

.page-footer .phone {
  font-weight: 700;
  font-size: 2.4rem; }

.page-footer .footer-bordered {
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.17); }
  @media (max-width: 1023.98px) {
    .page-footer .footer-bordered {
      border-width: 1px 0; }
      .page-footer .footer-bordered .footer-bordered-inner {
        max-width: 208px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 3.2rem;
        padding-bottom: 6rem; }
      .page-footer .footer-bordered .btn,
      .page-footer .footer-bordered .pagebuilder-button-primary,
      .page-footer .footer-bordered .pagebuilder-button-secondary, .page-footer .footer-bordered .account-nav .item.item a[href*='logout'], .account-nav .item.item .page-footer .footer-bordered a[href*='logout'] {
        width: 166px; } }
  @media (min-width: 1024px) {
    .page-footer .footer-bordered {
      border-width: 0 1px; } }

.page-footer .footer-link > div:not(.collapse) {
  margin-bottom: 1rem; }

.page-footer .footer-link a {
  margin-bottom: 1rem;
  display: block; }

.page-footer .social-links.social-links {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  list-style: none; }
  @media (max-width: 1023.98px) {
    .page-footer .social-links.social-links {
      justify-content: center;
      margin: -6.6rem 0 0; }
      .page-footer .social-links.social-links li {
        margin: 0 0.6rem; } }
  @media (min-width: 1024px) {
    .page-footer .social-links.social-links {
      width: 150px;
      margin-top: -5rem; }
      .page-footer .social-links.social-links li {
        margin-right: 1rem; } }

.page-header {
  position: relative;
  z-index: 100;
  margin-bottom: 0;
  background-color: #fcd900;
  border-bottom: 1px solid #fff;
  color: #000; }
  @media (max-width: 1023.98px) {
    .page-header {
      border-bottom: none; } }
  @media (max-width: 575.98px) {
    .page-header {
      z-index: 101;
      background: none; }
      .page-header::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 108px;
        background-color: #fcd900; }
      .page-header::after {
        content: '';
        position: absolute;
        top: 108px;
        right: 0;
        z-index: 1;
        width: 100vw;
        background-image: url(../images/nav-curve.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-color: #000;
        transition: background-color 0s 0.3s; }
      .nav-open .page-header::after {
        height: 10px;
        background-color: #000; } }
  .page-header a:not(.btn):not(a.pagebuilder-button-primary):not(
  .pagebuilder-button-primary):not(a.pagebuilder-button-secondary):not(
  .pagebuilder-button-secondary) {
    color: inherit; }
  @media (max-width: 1023.98px) {
    .page-header .panel.wrapper {
      display: none; } }
  @media (min-width: 1024px) {
    .page-header .panel.wrapper {
      border-bottom: 1px solid #fff; } }
  .page-header .panel.header {
    padding: 0 1rem 0 0; }
  .page-header .header-content-container {
    background-color: #fcd900; }
    @media (min-width: 1024px) {
      .page-header .header-content-container {
        background: linear-gradient(180deg, #fcd900 50%, #ffba00 100%); } }
    @media (max-width: 1023.98px) {
      .page-header .header-content-container {
        position: relative;
        padding-top: 4rem; } }
    @media (max-width: 767.98px) {
      .page-header .header-content-container {
        background: none; } }
  .page-header .action.showcart::before {
    display: none !important; }
  @media (max-width: 1023.98px) {
    .page-header .action.showcart {
      margin-left: 0;
      margin-right: 2.5rem; }
      .page-header .action.showcart .header-links-label {
        display: none; } }
  .page-header .header.content {
    position: static;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 120px;
    padding-top: 0; }
    @media (max-width: 1023.98px) {
      .page-header .header.content {
        flex-wrap: wrap;
        max-width: 100%;
        height: auto;
        padding: 0; } }
    .page-header .header.content::before, .page-header .header.content::after {
      display: none; }

.top-bar-container {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.top-bar {
  font-family: "Montserrat", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }
  .top-bar ul {
    display: flex;
    align-items: center;
    list-style: none;
    margin-bottom: 0;
    padding: 0; }
  .top-bar li {
    position: relative;
    margin-bottom: 0; }
    .top-bar li ul {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 4;
      min-width: 100%;
      width: max-content;
      padding: 1rem 0;
      background-color: #fff;
      box-shadow: 0 3px 6px #00000029; }
      .top-bar li ul a {
        padding: 1rem 1.8rem; }
        .top-bar li ul a:hover {
          text-decoration: underline; }
      .top-bar li ul ul {
        top: 0;
        left: 100%;
        box-shadow: 5px 3px 6px #00000029; }
    .top-bar li:hover > ul, .top-bar li:focus-within > ul {
      display: block; }
  .top-bar a {
    display: block;
    padding: 1.5rem 1.2rem;
    font-weight: 700;
    font-size: 1.3rem; }
    .top-bar a:not(:only-child)::after {
      content: '';
      display: inline-block;
      margin-left: 5px;
      width: 14px;
      height: 7px;
      background-image: url("../images/down.svg");
      background-size: 8.03px 5px;
      background-position: center;
      background-repeat: no-repeat; }
    @media (min-width: 1240px) {
      .top-bar a {
        padding-left: 2rem;
        padding-right: 2rem; } }
  .top-bar ul ul a:not(:only-child)::after {
    transform: rotate(270deg); }

.top-left li:hover a, .top-left li:focus-within a {
  text-decoration: none;
  background-color: #fff; }

@media (min-width: 1240px) {
  .top-left {
    margin-left: -0.6rem; } }

.top-right li:not(:last-child) a {
  padding-left: 1.3rem;
  padding-right: 1.3rem; }

.top-right li:last-child {
  margin-left: 1rem; }

.top-right .widget.block {
  margin: 0; }

.top-right .pagebuilder-button-link.pagebuilder-button-link {
  margin: 0;
  background: linear-gradient(180deg, #59595a 0%, black 100%);
  color: #fff !important;
  font-weight: 600;
  padding: 0.6rem 2.4rem;
  font-size: 1.2rem;
  line-height: 1.5;
  border-radius: 0.3rem;
  /* stylelint-disable-line */ }
  .top-right .pagebuilder-button-link.pagebuilder-button-link:active {
    color: #fff; }
  .top-right .pagebuilder-button-link.pagebuilder-button-link:hover, .top-right .pagebuilder-button-link.pagebuilder-button-link:focus {
    text-decoration: none;
    border: 2px solid #000;
    color: #000 !important;
    background: none;
    padding: 0.4rem 2.2rem;
    font-size: 1.2rem;
    line-height: 1.5;
    border-radius: 0.3rem;
    /* stylelint-disable-line */ }

.logo {
  z-index: 0;
  margin-bottom: 0; }
  @media (max-width: 1023.98px) {
    .logo {
      flex-shrink: 1;
      max-width: 178px;
      width: auto;
      margin: 0.8rem auto;
      z-index: 2;
      padding-bottom: 2px; } }

@media (min-width: 768px) {
  .header .logo img {
    width: auto;
    height: auto; } }

.nav-toggle {
  display: none; }
  @media (max-width: 1023.98px) {
    .nav-toggle {
      position: static;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      width: 60px;
      margin-left: 0.5rem;
      padding: 0;
      border-radius: 0; }
      .nav-toggle::before {
        display: none; }
      .nav-toggle svg:last-child {
        display: none; }
      .nav-open .nav-toggle svg {
        display: none; }
        .nav-open .nav-toggle svg:last-child {
          display: block; } }

.header-links,
.header-links .links,
.header-links li {
  display: flex !important;
  align-items: center;
  height: 100%; }

.header-links .header-link {
  display: block;
  margin-left: 3.4rem;
  font-family: "Montserrat", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  font-size: 1.3rem;
  text-align: center; }
  @media (min-width: 1024px) and (max-width: 1239.98px) {
    .header-links .header-link {
      margin-left: 2.5rem; } }
  .header-links .header-link .expert-advice ul {
    list-style: none;
    font-weight: 600;
    font-size: 1rem;
    align-items: center; }
  .header-links .header-link .expert-advice li.online {
    padding-right: 3.7rem; }
  .header-links .header-link .expert-advice li.online::before {
    content: '';
    border-radius: 0.375rem;
    height: 0.75rem;
    width: 0.75rem;
    margin-right: 0.5rem;
    background-color: #28a745; }
  .header-links .header-link .svg-container {
    width: 40px;
    height: 40px;
    margin: 0 auto 0.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center; }
  .header-links .header-link:hover {
    text-decoration: none; }
    .header-links .header-link:hover .svg-container {
      background-color: #feec80; }

.header-links .links {
  list-style: none;
  margin: 0;
  padding: 0; }
  .header-links .links .header-link-chat.online {
    padding-top: 1.5rem; }
  .header-links .links li {
    position: relative;
    margin: 0; }
    .header-links .links li:first-child .header-link {
      margin-left: 0; }
  .header-links .links .wishlist .counter.qty.qty.qty {
    right: -0.5rem; }
  @media (max-width: 1023.98px) {
    .header-links .links .header-link-account,
    .header-links .links .header-link-store {
      position: absolute;
      top: 0;
      width: 50%;
      height: 40px;
      border-bottom: 1px solid #fff;
      text-align: center; }
      .header-links .links .header-link-account .header-link,
      .header-links .links .header-link-store .header-link {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 0;
        font-size: 1.1rem; }
        .header-links .links .header-link-account .header-link .svg-container,
        .header-links .links .header-link-store .header-link .svg-container {
          width: auto;
          height: auto;
          margin: 0 1rem 0 0;
          background: none; }
    .header-links .links .header-link-store {
      left: 0; }
      .header-links .links .header-link-store:only-child {
        width: 100%; }
      .header-links .links .header-link-store svg {
        width: 18px;
        height: 18px;
        stroke-width: 2px; }
    .header-links .links .header-link-account {
      right: 0;
      border-left: 1px solid #fff; }
      .header-links .links .header-link-account .header-link > .svg-container > svg {
        width: 16.08px;
        height: 15px; } }

.header-links .counter.qty.qty.qty:not(:empty) {
  position: absolute;
  top: 1rem;
  right: 1.8rem;
  width: 21px;
  height: 21px;
  margin: -8px 0 0;
  padding: 0;
  border-radius: 50%;
  background-color: #d60000;
  color: #fff;
  line-height: 22px;
  text-align: center;
  font-weight: 600;
  font-size: 1.2rem; }
  @media (min-width: 1024px) {
    .header-links .counter.qty.qty.qty:not(:empty) {
      right: -0.9rem; } }

@media (max-width: 1023.98px) {
  .header-links .wishlist {
    display: none !important; } }

@media (max-width: 1023.98px) {
  .header-links .header-link-chat {
    display: none !important; } }

.header-after {
  border-bottom: 1px solid #ddd; }
  .header-after ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none; }
    @media (max-width: 1023.98px) {
      .header-after ul {
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-evenly; } }
    .header-after ul li {
      display: flex;
      align-items: center;
      margin: 0.7rem 0;
      font-weight: 700;
      font-size: 1.3rem; }
      @media (max-width: 767.98px) {
        .header-after ul li {
          width: 50%; } }
      .header-after ul li a {
        display: flex;
        align-items: center; }
        @media (max-width: 1023.98px) {
          .header-after ul li a {
            max-width: 145px;
            flex-direction: column;
            margin: 0 auto;
            text-align: center;
            text-decoration: none !important; } }
      .header-after ul li a::before {
        content: '';
        flex-shrink: 0;
        width: 37px;
        height: 37px;
        margin-right: 1.1rem;
        background-image: url("../images/header-after-2.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain; }
        @media (max-width: 1023.98px) {
          .header-after ul li a::before {
            margin-right: 0;
            margin-bottom: 1.1rem; } }
      .header-after ul li:nth-child(1) a::before {
        background-image: url("../images/header-after-1.svg"); }
      .header-after ul li:nth-child(3) a::before {
        background-image: url("../images/header-after-3.svg"); }
      .header-after ul li:nth-child(4) a::before {
        background-image: url("../images/header-after-4.svg"); }

body {
  font-size: 1.3rem; }

.navigation, .breadcrumb, .page-header .header.panel, .header.content, .footer-container, .page-wrapper > .widget, .page-wrapper > .page-bottom, .block.category.event, .top-container, .page-main {
  /* stylelint-disable-line */ }

.small-payment-logos {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-around;
  align-items: baseline;
  justify-content: space-around !important;
  max-width: 400px; }
  @media (max-width: 1023.98px) {
    .small-payment-logos {
      align-items: center;
      justify-content: center !important;
      margin: auto; }
      .small-payment-logos .logo {
        margin: 0.5rem 1rem !important; } }

[data-content-type='row'][data-appearance='contained'] {
  max-width: 1190px;
  width: 100%; }

.page-wrapper {
  left: auto !important; }

[data-content-type='row'][data-appearance='contained'] [data-element='inner'] {
  margin: 0 !important;
  padding: 0 !important; }

.main a:not(.btn):not(a.pagebuilder-button-primary):not(
.pagebuilder-button-primary):not(a.pagebuilder-button-secondary):not(
.pagebuilder-button-secondary) {
  text-decoration: underline; }
  .main a:not(.btn):not(a.pagebuilder-button-primary):not(
  .pagebuilder-button-primary):not(a.pagebuilder-button-secondary):not(
  .pagebuilder-button-secondary):hover {
    text-decoration: none; }

.teds-border {
  border: 10px solid #fcd900;
  border-radius: 8px; }

.loading-mask {
  background: none; }

.loader-container {
  bottom: 0;
  left: 0;
  margin: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3); }

body[class*=cms-academy] .page-wrapper {
  overflow-x: hidden; }

body[class*=cms-academy] .column.main {
  padding-bottom: 0; }
  body[class*=cms-academy] .column.main [data-content-type=row][data-appearance=contained] {
    padding-left: 15px !important;
    padding-right: 15px !important; }

body[class*=cms-academy] .home-blog.home-blog.home-blog {
  background: none; }
  @media (max-width: 767.98px) {
    body[class*=cms-academy] .home-blog.home-blog.home-blog {
      margin-bottom: 0.5rem;
      text-align: left; } }
  body[class*=cms-academy] .home-blog.home-blog.home-blog::before, body[class*=cms-academy] .home-blog.home-blog.home-blog::after {
    display: none; }
  body[class*=cms-academy] .home-blog.home-blog.home-blog .amblog-element-block {
    padding: 0; }
  body[class*=cms-academy] .home-blog.home-blog.home-blog h2 {
    margin-bottom: 2rem; }
    @media (min-width: 768px) {
      body[class*=cms-academy] .home-blog.home-blog.home-blog h2 {
        margin-bottom: 3.2rem; } }
    @media (max-width: 767.98px) {
      body[class*=cms-academy] .home-blog.home-blog.home-blog h2 {
        text-align: left; } }

body[class*=cms-academy] .event-list li {
  width: 100%;
  max-width: 100%; }

body[class*=cms-academy] .slick-list {
  width: 100%; }

@media (min-width: 1024px) {
  body[class*=cms-academy] h2 {
    margin-bottom: 3.2rem; } }

.academy-banner.academy-banner {
  height: 200px; }

.academy-events.academy-events.academy-events {
  position: relative;
  padding: 3rem 0 !important; }
  @media (min-width: 1024px) {
    .academy-events.academy-events.academy-events {
      padding: 4rem 0 !important; } }
  .academy-events.academy-events.academy-events h2 {
    font-size: 2.4rem; }
  .academy-events.academy-events.academy-events, .academy-events.academy-events.academy-events::before, .academy-events.academy-events.academy-events::after {
    background-color: rgba(221, 221, 221, 0.51); }
  .academy-events.academy-events.academy-events::before, .academy-events.academy-events.academy-events::after {
    content: '';
    position: absolute;
    top: 0;
    z-index: -1;
    width: 100vw;
    height: 100%; }
  .academy-events.academy-events.academy-events::before {
    left: 100%; }
  .academy-events.academy-events.academy-events::after {
    right: 100%; }
  .academy-events.academy-events.academy-events .pagebuilder-column {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .academy-events.academy-events.academy-events [data-content-type='text'] {
    max-width: 506px;
    margin-bottom: 1.3rem !important; }
    @media (min-width: 1024px) {
      .academy-events.academy-events.academy-events [data-content-type='text'] {
        margin: 0 auto 1.3rem !important;
        text-align: center; } }
    .academy-events.academy-events.academy-events [data-content-type='text'] h2 {
      font-weight: 700; }
  .academy-events.academy-events.academy-events .container, .academy-events.academy-events.academy-events .navigation, .academy-events.academy-events.academy-events .breadcrumb, .academy-events.academy-events.academy-events .page-header .header.panel, .page-header .academy-events.academy-events.academy-events .header.panel, .academy-events.academy-events.academy-events .header.content, .academy-events.academy-events.academy-events .footer-container, .academy-events.academy-events.academy-events .page-wrapper > .widget, .academy-events.academy-events.academy-events .page-wrapper > .page-bottom, .academy-events.academy-events.academy-events .block.category.event, .academy-events.academy-events.academy-events .top-container, .academy-events.academy-events.academy-events .page-main, .academy-events.academy-events.academy-events .cms-home .page-main .page.messages, .cms-home .page-main .academy-events.academy-events.academy-events .page.messages {
    padding-left: 0;
    padding-right: 0;
    max-width: 100% !important; }

.academy-courses.academy-courses.academy-courses {
  background: none; }
  .academy-courses.academy-courses.academy-courses::before, .academy-courses.academy-courses.academy-courses::after {
    display: none; }

.account-nav .content {
  background: none;
  padding: 0; }

.account-nav .item.item {
  width: 100%; }
  .account-nav .item.item ~ .item {
    margin-top: 1.5rem; }
  .account-nav .item.item strong,
  .account-nav .item.item a {
    color: #000;
    border: none;
    padding: 0;
    font-weight: 400; }
  .account-nav .item.item a:not([href*='logout']) {
    color: inherit; }
    .account-nav .item.item a:not([href*='logout']):hover {
      background: none;
      color: #000;
      text-decoration: underline; }

@media (max-width: 767.98px) {
  .account .page-main {
    padding-top: 0; } }

.account .table th {
  border-bottom: none !important; }

.account .limiter-options {
  min-width: 80px; }

.account .additional-addresses th:first-child,
.account .additional-addresses td:first-child {
  padding-left: 0; }

.account .additional-addresses th:last-child,
.account .additional-addresses td:last-child {
  padding-right: 0; }

.account .additional-addresses td {
  font-size: 1.2rem; }

.account .additional-addresses .delete {
  display: inline-block; }

.page-title-wrapper.dashboard-title {
  border: none;
  margin-bottom: 0; }

.form-address-edit .country select {
  height: calc(calc(1.5em + 2.2rem + 4px) + 2.5px);
  padding: 1.1rem 1.7rem;
  color: #59595a;
  border: 2px solid #aaa;
  border-radius: 0.3rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-address-edit .country select {
      transition: none; } }
  .form-address-edit .country select::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-address-edit .country select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #59595a; }
  .form-address-edit .country select:focus {
    color: #59595a;
    background-color: #fff;
    border-color: #fcd900;
    outline: 0;
    box-shadow: 0 3px 10px #00000026; }
  .form-address-edit .country select:disabled, .form-address-edit .country select[readonly] {
    background-color: #e5e5e5;
    opacity: 1; }

.customer-account-login .block-minicart .block-customer-login .block-content {
  margin-top: 0; }

.customer-account-login .block-customer-login {
  width: 100%; }
  .customer-account-login .block-customer-login .block-content {
    width: 100%; }
    .customer-account-login .block-customer-login .block-content form.form-login .register {
      max-width: 350px;
      margin: 0 auto; }
      @media (max-width: 575.98px) {
        .customer-account-login .block-customer-login .block-content form.form-login .register {
          padding-left: 0;
          padding-right: 0; } }
    .customer-account-login .block-customer-login .block-content .social-login-wrapper > div {
      max-width: 300px; }
      @media (max-width: 1023.98px) {
        .customer-account-login .block-customer-login .block-content .social-login-wrapper > div {
          margin-left: auto;
          margin-right: auto; } }
      .customer-account-login .block-customer-login .block-content .social-login-wrapper > div .svg-container {
        bottom: auto;
        border: none; }

.customer-account-edit .fieldset > .field,
.customer-address-form .fieldset > .field {
  margin-bottom: 0; }

.customer-account-edit .fieldset .floating-label,
.customer-address-form .fieldset .floating-label {
  margin-bottom: 1.5rem; }

.customer-account-logoutsuccess .page-main {
  text-align: center;
  margin-top: 3rem; }

.sales-order-history .pager {
  display: flex;
  justify-content: space-between;
  width: 100%; }
  .sales-order-history .pager .toolbar-amount {
    float: none;
    width: auto;
    flex: none; }
  .sales-order-history .pager .pages {
    position: relative;
    width: auto; }
    .sales-order-history .pager .pages .action.next,
    .sales-order-history .pager .pages .action.previous {
      background-image: url("../images/arrow-right.svg");
      background-position: right 1.2rem center;
      background-repeat: no-repeat;
      background-size: 24px 24px;
      border: none; }
      .sales-order-history .pager .pages .action.next.previous,
      .sales-order-history .pager .pages .action.previous.previous {
        transform: rotate(180deg); }
      .sales-order-history .pager .pages .action.next::before,
      .sales-order-history .pager .pages .action.previous::before {
        content: ' '; }

.amblog-index-index .breadcrumbs {
  margin-bottom: 0; }

.amblog-index-index p {
  margin: 0; }

.blog-home-listing .pagebuilder-column-group {
  margin-bottom: 6rem;
  margin-top: 3rem; }

.blog-home-listing [data-content-type=row][data-appearance=contained] [data-element=inner] > [data-content-type='text'] {
  display: flex; }
  .blog-home-listing [data-content-type=row][data-appearance=contained] [data-element=inner] > [data-content-type='text'] h2 {
    margin: 0 3rem 0 0;
    line-height: 1;
    font-weight: 700;
    font-size: 2.2rem; }

.blog-home-listing [data-content-type=row][data-appearance=contained] [data-element=inner] > .pagebuilder-column-group > .pagebuilder-column {
  padding: 0 !important; }
  .blog-home-listing [data-content-type=row][data-appearance=contained] [data-element=inner] > .pagebuilder-column-group > .pagebuilder-column li:nth-child(3) ~ li {
    display: none; }
  .blog-home-listing [data-content-type=row][data-appearance=contained] [data-element=inner] > .pagebuilder-column-group > .pagebuilder-column[style*='50%'] li:nth-child(2) ~ li {
    display: none; }
  .blog-home-listing [data-content-type=row][data-appearance=contained] [data-element=inner] > .pagebuilder-column-group > .pagebuilder-column:first-child [data-content-type='text'],
  .blog-home-listing [data-content-type=row][data-appearance=contained] [data-element=inner] > .pagebuilder-column-group > .pagebuilder-column:first-child [data-content-type='banner'] {
    margin-right: 15px !important; }
  .blog-home-listing [data-content-type=row][data-appearance=contained] [data-element=inner] > .pagebuilder-column-group > .pagebuilder-column:last-child [data-content-type='text'],
  .blog-home-listing [data-content-type=row][data-appearance=contained] [data-element=inner] > .pagebuilder-column-group > .pagebuilder-column:last-child [data-content-type='banner'] {
    margin-left: 15px !important; }
  .blog-home-listing [data-content-type=row][data-appearance=contained] [data-element=inner] > .pagebuilder-column-group > .pagebuilder-column:only-child [data-content-type='text'],
  .blog-home-listing [data-content-type=row][data-appearance=contained] [data-element=inner] > .pagebuilder-column-group > .pagebuilder-column:only-child [data-content-type='banner'] {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .blog-home-listing [data-content-type=row][data-appearance=contained] [data-element=inner] > .pagebuilder-column-group > .pagebuilder-column [data-content-type='banner'] {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    background-color: #fcd900;
    border-radius: 3px;
    font-size: 1.8rem;
    font-weight: 700;
    text-align: center;
    font-family: "Montserrat", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }
    .blog-home-listing [data-content-type=row][data-appearance=contained] [data-element=inner] > .pagebuilder-column-group > .pagebuilder-column [data-content-type='banner'] a {
      display: inline-block;
      margin: 2rem 0 0;
      padding: 0.8rem 2.6rem;
      background: linear-gradient(180deg, #59595a 0%, black 100%);
      color: #fff;
      font-weight: 600;
      font-size: 12px;
      border-radius: 0.3rem;
      text-decoration: none; }
      .blog-home-listing [data-content-type=row][data-appearance=contained] [data-element=inner] > .pagebuilder-column-group > .pagebuilder-column [data-content-type='banner'] a:focus, .blog-home-listing [data-content-type=row][data-appearance=contained] [data-element=inner] > .pagebuilder-column-group > .pagebuilder-column [data-content-type='banner'] a:active {
        border: none; }
      .blog-home-listing [data-content-type=row][data-appearance=contained] [data-element=inner] > .pagebuilder-column-group > .pagebuilder-column [data-content-type='banner'] a:hover {
        padding: 0.6rem 2.4rem;
        border: 2px solid #000;
        background: #fff;
        color: #000; }
    .blog-home-listing [data-content-type=row][data-appearance=contained] [data-element=inner] > .pagebuilder-column-group > .pagebuilder-column [data-content-type='banner'] p {
      max-width: 190px;
      margin-left: auto;
      margin-right: auto; }
  .blog-home-listing [data-content-type=row][data-appearance=contained] [data-element=inner] > .pagebuilder-column-group > .pagebuilder-column ul,
  .blog-home-listing [data-content-type=row][data-appearance=contained] [data-element=inner] > .pagebuilder-column-group > .pagebuilder-column li {
    margin-bottom: 0;
    padding-bottom: 0; }

.blog-post {
  flex-wrap: wrap; }
  .blog-post.desktop {
    display: flex; }
  .blog-post.one-column {
    margin-top: 1.5rem; }
  .blog-post .amblog-post-item-container.amblog-post-container {
    margin-bottom: 2rem;
    box-shadow: none;
    background-color: transparent; }
  .blog-post .amblog-post-item-container .amblog-post-item {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #fff;
    box-shadow: 0 3px 10px #00000026;
    border-radius: 3px;
    overflow: hidden; }
  .blog-post .amblog-post-item-container .amblog-image {
    border-radius: 0; }
  .blog-post .amblog-post-item-container .amblog-date {
    margin-bottom: 0.5rem; }
  .blog-post .amblog-post-item-container .amblog-headline.amblog-headline.amblog-headline.amblog-headline.amblog-headline {
    display: block;
    text-decoration: none;
    line-height: 1.25;
    font-weight: 700;
    font-size: 1.8rem; }
    .blog-post .amblog-post-item-container .amblog-headline.amblog-headline.amblog-headline.amblog-headline.amblog-headline:hover {
      text-decoration: underline;
      color: inherit; }
  .blog-post .amblog-post-item-container .amblog-tags {
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
    width: auto;
    padding: 0;
    margin: 0; }
  .blog-post .amblog-post-item-container .amblog-tag ~ .amblog-tag {
    display: none; }
  .blog-post .amblog-post-item-container .blog-thumbnail {
    display: block;
    height: 187px;
    margin-bottom: 0;
    background-image: url("../images/noimage-placeholder.png");
    background-size: 150px 150px;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #f8f8f8; }
  .blog-post .amblog-container-list {
    list-style: none;
    padding-left: 0;
    width: calc(100% + 30px); }
  .blog-post .amblog-recent-post-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
    padding: 2rem; }
  .blog-post .blog-thumbnail {
    width: 100%;
    height: 200px;
    margin-bottom: 3rem; }
    @media (max-width: 575.98px) {
      .blog-post .blog-thumbnail {
        height: 220px;
        width: auto; } }
    .blog-post .blog-thumbnail .amblog-image {
      object-fit: cover;
      height: 100%;
      width: 100%; }
  .blog-post .amblog-main-content.amblog-main-content.amblog-main-content {
    flex-grow: 1; }
    @media (max-width: 767.98px) {
      .blog-post .amblog-main-content.amblog-main-content.amblog-main-content {
        padding-top: 25px; } }
    @media (max-width: 1023.98px) {
      .blog-post .amblog-main-content.amblog-main-content.amblog-main-content {
        width: 100%;
        padding-left: 0; } }
  .blog-post .amblog-tags {
    width: 100%;
    text-align: center; }
    @media (max-width: 575.98px) {
      .blog-post .amblog-tags {
        margin-top: 0; } }
  .blog-post .amblog-post-container {
    border: none; }
    @media (max-width: 575.98px) {
      .blog-post .amblog-post-container .amblog-bottom {
        margin-top: 0; } }
    .blog-post .amblog-post-container a.prev-post,
    .blog-post .amblog-post-container a.next-post {
      text-decoration: none;
      position: relative; }
      .blog-post .amblog-post-container a.prev-post:hover,
      .blog-post .amblog-post-container a.next-post:hover {
        text-decoration: underline !important; }
    .blog-post .amblog-post-container .next-post::after {
      content: ' ';
      position: absolute;
      right: 5px;
      display: block;
      top: 20px;
      width: 15px;
      height: 15px;
      background-image: url(../images/right.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center; }
    .blog-post .amblog-post-container .prev-post::after {
      content: ' ';
      position: absolute;
      left: 5px;
      display: block;
      top: 20px;
      width: 15px;
      height: 15px;
      background-image: url(../images/right.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      transform: rotate(180deg); }
  .blog-post .amblog-content {
    margin-top: 0;
    display: flex;
    flex-direction: column;
    line-height: 1.75; }
    .blog-post .amblog-content h1,
    .blog-post .amblog-content h2,
    .blog-post .amblog-content h3,
    .blog-post .amblog-content h4,
    .blog-post .amblog-content h5 {
      font-weight: 700; }
    .blog-post .amblog-content h2,
    .blog-post .amblog-content h3 {
      font-size: 2.2rem; }
    .blog-post .amblog-content h2 {
      margin: 2.5rem 0 3.3rem; }
    .blog-post .amblog-content h3 {
      margin: 2rem 0 2.8rem; }
    .blog-post .amblog-content img {
      margin-bottom: 2rem; }
    .blog-post .amblog-content p {
      margin-bottom: 2.3rem; }
    .blog-post .amblog-content .amblog-title {
      margin-bottom: 3.7rem;
      padding-bottom: 3.7rem;
      text-transform: none;
      font-weight: 700;
      color: inherit;
      border-bottom: 2px solid #ddd; }
      @media (max-width: 575.98px) {
        .blog-post .amblog-content .amblog-title {
          margin-bottom: 2.5rem;
          padding-bottom: 2.5rem; } }
    .blog-post .amblog-content .lead {
      font-family: "Montserrat", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: normal; }
    @media (max-width: 575.98px) {
      .blog-post .amblog-content {
        font-size: 1.5rem; }
        .blog-post .amblog-content h2 {
          margin: 0.5rem 0 2rem; }
        .blog-post .amblog-content h3 {
          margin: 0.5rem 0 1.8rem; } }
    @media (max-width: 1023.98px) {
      .blog-post .amblog-content {
        padding-left: 0;
        padding-right: 0; } }
  .blog-post .amblog-date {
    order: -1;
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    font-size: 1.2rem;
    color: #000; }
  .blog-post .blog-date-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    margin-right: 1.2rem;
    border-radius: 99px;
    border: solid 4px rgba(252, 217, 0, 0.4);
    background-color: #fcd900;
    background-clip: content-box; }
  .blog-post .amblog-title {
    text-transform: none;
    font-weight: 700;
    color: inherit; }

.amblog-tags.amblog-tags.amblog-widget-container {
  box-shadow: none;
  background-color: transparent; }

.amblog-tags .amblog-tag.amblog-tag {
  margin-bottom: 6px;
  padding: 4px 10px;
  background-color: #fcd900;
  color: #000;
  text-decoration: none;
  font-size: 1.1rem; }
  .amblog-tags .amblog-tag.amblog-tag:hover {
    background-color: #fff;
    color: #000; }
  .amblog-tags .amblog-tag.amblog-tag:not(:last-child) {
    margin-right: 10px; }

.amblog-element-block {
  border-radius: 0;
  box-shadow: none; }

.blog-sidebar .amblog-title {
  font-size: 1.3rem; }

.blog-sidebar .amblog-list {
  padding-left: 0; }

.blog-sidebar .amblog-category-count {
  display: none; }

@media (max-width: 767.98px) {
  .blog-sidebar {
    width: 100%; } }

.blog-categories-container {
  background-color: #fcd900;
  text-align: center;
  margin: 1.7rem 0 3.5rem;
  padding: 2.5rem; }
  .blog-categories-container .h3 {
    margin-bottom: 2rem;
    font-weight: 700; }
  .blog-categories-container .form-control {
    max-width: 289px;
    margin-left: auto;
    margin-right: auto; }

.bluefoot-video iframe {
  height: 500px;
  width: 100%;
  max-width: 100% !important; }

.bluefoot-wrapper.container.row, .bluefoot-wrapper.row.navigation, .bluefoot-wrapper.row.breadcrumb, .page-header .bluefoot-wrapper.row.header.panel, .bluefoot-wrapper.row.header.content, .bluefoot-wrapper.row.footer-container, .page-wrapper > .bluefoot-wrapper.row.widget, .page-wrapper > .bluefoot-wrapper.row.page-bottom, .bluefoot-wrapper.row.block.category.event, .bluefoot-wrapper.row.top-container, .bluefoot-wrapper.row.page-main, .cms-home .page-main .bluefoot-wrapper.row.page.messages {
  padding-left: 0;
  padding-right: 0; }

.page-layout-cms-full-width .column.main {
  overflow: hidden; }

.review-wrapper {
  border-radius: 10px;
  border: 10px solid #fcd900;
  width: 375px;
  padding-left: 21px;
  padding-right: 21px;
  margin: 10px auto 60px auto; }
  .review-wrapper .review {
    text-align: center;
    display: flex;
    align-items: column;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 0.9rem;
    padding-top: 13px;
    text-transform: uppercase; }
    .review-wrapper .review::before {
      content: '';
      display: block;
      width: 100%;
      height: 16px;
      background: linear-gradient(to right, #fcd900 var(--percentage), #ddd var(--percentage));
      order: 3; }
    .review-wrapper .review::after {
      content: attr(data-review);
      position: relative; }
    .review-wrapper .review:first-child, .review-wrapper .review:last-child {
      font-weight: bold;
      font-size: 1.8rem;
      line-height: 2.2rem; }
      .review-wrapper .review:first-child::before, .review-wrapper .review:first-child::after, .review-wrapper .review:last-child::before, .review-wrapper .review:last-child::after {
        display: none; }
    .review-wrapper .review:first-child {
      justify-content: center;
      padding-top: 28px;
      padding-bottom: 12px; }
    .review-wrapper .review:last-child {
      padding-top: 0;
      border: 8px solid #fcd900;
      width: 79px;
      height: 79px;
      margin: 0 auto -15px auto;
      border-radius: 100%;
      align-items: center;
      justify-content: center;
      bottom: -39.5px;
      position: relative;
      background-color: #fff; }

.amblog-widget-container.-post.blog-post {
  width: 100%; }

.amblog-tags.amblog-widget-container.-tags .amblog-tag {
  display: inline-block;
  margin-bottom: 10px; }
  .amblog-tags.amblog-widget-container.-tags .amblog-tag:hover {
    background-color: #fcd900; }

.amblog-tags.amblog-widget-container.-tags .amblog-tags-holder:not(.show-all) {
  max-height: 65px;
  overflow: hidden; }

@media (max-width: 767.98px) {
  .blog-home-listing .amblog-post-item-container.amblog-post-container {
    margin-bottom: 2rem !important; }
  .blog-home-listing [data-content-type=row][data-appearance=contained] [data-element=inner] > .pagebuilder-column-group > .pagebuilder-column:last-child [data-content-type=banner],
  .blog-home-listing [data-content-type=row][data-appearance=contained] [data-element=inner] > .pagebuilder-column-group > .pagebuilder-column:last-child [data-content-type=text] {
    margin-left: 0 !important; } }

.percentage-wrapper {
  text-align: center; }
  .percentage-wrapper .percentage-holder {
    display: block;
    max-width: 323px;
    width: 100%;
    height: 5px;
    margin: 1rem auto 0;
    background-color: #b1b1b1;
    position: relative;
    border-radius: 5px;
    overflow: hidden; }
    .percentage-wrapper .percentage-holder .percentage-display {
      background-color: #000;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      display: block;
      border-radius: 5px; }

.category-view .category-image .image {
  margin: 0 auto; }

.category-view .amslider {
  margin-top: 2rem; }
  @media (max-width: 767.98px) {
    .category-view .amslider {
      margin-top: 2.8rem; } }

.category-view .category-description {
  max-height: 90px;
  position: relative;
  overflow: hidden;
  margin: 0 auto; }
  @media (min-width: 1024px) {
    .category-view .category-description {
      max-width: 760px; } }
  @media (max-width: 767.98px) {
    .category-view .category-description {
      font-size: 1.5rem; } }
  .category-view .category-description + .read-more-holder {
    position: relative;
    z-index: 2; }
  .category-view .category-description.show-more {
    max-height: none; }
    .category-view .category-description.show-more + .read-more-holder .more {
      display: none; }
  .category-view .category-description:not(.show-more) + .read-more-holder .less {
    display: none; }
  .category-view .category-description:not(.show-more)::after {
    content: ' ';
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    right: 0;
    height: 40px;
    background: transparent linear-gradient(180deg, #eee0 0%, #fff 100%) 0% 0% no-repeat padding-box; }
    @media (max-width: 767.98px) {
      .category-view .category-description:not(.show-more)::after {
        height: 80px; } }
  .category-view .category-description p {
    margin-bottom: 0.5rem; }

.category-view .page-title-wrapper {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-bottom: none; }
  @media (min-width: 576px) {
    .category-view .page-title-wrapper {
      text-align: center; } }

.children-category-container {
  position: relative; }
  @media (max-width: 575.98px) {
    .children-category-container {
      padding-left: 0.5rem;
      padding-right: 0.5rem; } }
  .children-category-container .wrapper {
    flex-wrap: nowrap;
    overflow-x: auto;
    contain: content;
    justify-content: flex-start;
    scrollbar-color: #000 #ddd;
    scrollbar-width: thin; }
    .children-category-container .wrapper::-webkit-scrollbar {
      height: 5px;
      border-radius: 100px; }
    .children-category-container .wrapper::-webkit-scrollbar-track {
      background-color: #ddd;
      height: 5px;
      border-radius: 100px; }
    .children-category-container .wrapper::-webkit-scrollbar-thumb {
      background-color: #000;
      border-radius: 100px;
      height: 5px; }
    @media (min-width: 768px) {
      .children-category-container .wrapper::-webkit-scrollbar-button {
        width: 120px; } }
    @media (min-width: 1024px) {
      .children-category-container .wrapper::-webkit-scrollbar-button {
        width: 170px; } }
    @media (min-width: 1240px) {
      .children-category-container .wrapper::-webkit-scrollbar-button {
        width: 210px; } }
  .children-category-container .child-cats-button {
    position: absolute;
    top: 40%;
    width: 15px;
    height: 25px;
    background-position: center;
    background-color: transparent;
    background-repeat: no-repeat;
    background-image: url("../images/right.svg");
    border: none; }
    .children-category-container .child-cats-button.cats-left {
      transform-origin: 7.5px 12.5px;
      transform: rotate(180deg);
      left: -5px; }
      @media (max-width: 575.98px) {
        .children-category-container .child-cats-button.cats-left {
          left: 5px; } }
    .children-category-container .child-cats-button.cats-right {
      right: -5px; }
      @media (max-width: 575.98px) {
        .children-category-container .child-cats-button.cats-right {
          right: 5px; } }
  .children-category-container a.slide {
    border: solid 2px transparent;
    border-radius: 2px;
    width: 125px;
    padding-bottom: 14px;
    margin-right: 10px;
    margin-left: 10px;
    flex-shrink: 0; }
    .children-category-container a.slide:hover {
      border-color: #fcd900; }
    .children-category-container a.slide .category-item {
      border-color: transparent;
      padding: 0 !important; }
      .children-category-container a.slide .category-item:hover {
        box-shadow: none;
        border-color: transparent; }
      .children-category-container a.slide .category-item .category-item-title {
        font-size: 1.2rem;
        line-height: 1.6rem;
        max-width: 115px !important; }
      .children-category-container a.slide .category-item.category-item-label img {
        margin-bottom: 0; }

.toolbar-products .pages {
  display: none !important; }

.ias-noneleft + .ias-noneleft {
  display: none; }

@media (min-width: 1240px) {
  .category-default-category.ambrand-index-index .columns .column.main,
  .catalog-category-view .columns .column.main {
    flex-basis: 844px; } }

body .products-wrapper {
  position: relative;
  flex-direction: row;
  flex-wrap: wrap; }
  body .products-wrapper .simple-product-wrapper .product-item-wrap {
    height: 100%;
    display: flex;
    flex-direction: column; }
    body .products-wrapper .simple-product-wrapper .product-item-wrap .info-wrapper {
      position: relative; }
      body .products-wrapper .simple-product-wrapper .product-item-wrap .info-wrapper .product-image .product-tag {
        top: 3px;
        position: absolute; }
      body .products-wrapper .simple-product-wrapper .product-item-wrap .info-wrapper .product-image .klevuImgWrap,
      body .products-wrapper .simple-product-wrapper .product-item-wrap .info-wrapper .product-image .image-container {
        margin-top: 3.2rem;
        width: 100%;
        text-align: center; }
        @media (max-width: 575.98px) {
          body .products-wrapper .simple-product-wrapper .product-item-wrap .info-wrapper .product-image .klevuImgWrap,
          body .products-wrapper .simple-product-wrapper .product-item-wrap .info-wrapper .product-image .image-container {
            display: inline-block;
            margin-top: 4rem; } }
        body .products-wrapper .simple-product-wrapper .product-item-wrap .info-wrapper .product-image .klevuImgWrap .kuProdImg,
        body .products-wrapper .simple-product-wrapper .product-item-wrap .info-wrapper .product-image .klevuImgWrap .product-image-container,
        body .products-wrapper .simple-product-wrapper .product-item-wrap .info-wrapper .product-image .image-container .kuProdImg,
        body .products-wrapper .simple-product-wrapper .product-item-wrap .info-wrapper .product-image .image-container .product-image-container {
          width: 145px !important;
          max-width: 100%;
          display: block;
          margin: auto; }
          @media (max-width: 575.98px) {
            body .products-wrapper .simple-product-wrapper .product-item-wrap .info-wrapper .product-image .klevuImgWrap .kuProdImg,
            body .products-wrapper .simple-product-wrapper .product-item-wrap .info-wrapper .product-image .klevuImgWrap .product-image-container,
            body .products-wrapper .simple-product-wrapper .product-item-wrap .info-wrapper .product-image .image-container .kuProdImg,
            body .products-wrapper .simple-product-wrapper .product-item-wrap .info-wrapper .product-image .image-container .product-image-container {
              margin-top: 0; } }
      body .products-wrapper .simple-product-wrapper .product-item-wrap .info-wrapper .product-info {
        position: relative; }
        body .products-wrapper .simple-product-wrapper .product-item-wrap .info-wrapper .product-info .brand {
          position: relative;
          height: 100%;
          min-height: 20px;
          margin-bottom: 3px; }
          body .products-wrapper .simple-product-wrapper .product-item-wrap .info-wrapper .product-info .brand img.brand-image {
            max-width: 100px;
            max-height: 25px; }
        body .products-wrapper .simple-product-wrapper .product-item-wrap .info-wrapper .product-info .title {
          font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
          font-size: 1.3rem;
          font-weight: normal; }
          @media (max-width: 575.98px) {
            body .products-wrapper .simple-product-wrapper .product-item-wrap .info-wrapper .product-info .title {
              font-size: 1.4rem; } }
          body .products-wrapper .simple-product-wrapper .product-item-wrap .info-wrapper .product-info .title a.title-link {
            text-decoration: none; }
        body .products-wrapper .simple-product-wrapper .product-item-wrap .info-wrapper .product-info > .pricing .price-box.price-final_price .pricing {
          width: 100%; }
          body .products-wrapper .simple-product-wrapper .product-item-wrap .info-wrapper .product-info > .pricing .price-box.price-final_price .pricing.standard-price .price {
            font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
            font-size: 16px; }
          body .products-wrapper .simple-product-wrapper .product-item-wrap .info-wrapper .product-info > .pricing .price-box.price-final_price .pricing.standard-price.strikethrough-price .price {
            font-size: 13px; }
          body .products-wrapper .simple-product-wrapper .product-item-wrap .info-wrapper .product-info > .pricing .price-box.price-final_price .pricing.special-price .price {
            font-size: 16px !important;
            font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
            color: #eb5050;
            font-weight: 700; }
          body .products-wrapper .simple-product-wrapper .product-item-wrap .info-wrapper .product-info > .pricing .price-box.price-final_price .pricing .price-label {
            display: none; }
          body .products-wrapper .simple-product-wrapper .product-item-wrap .info-wrapper .product-info > .pricing .price-box.price-final_price .pricing .price-wrapper {
            text-align: center; }
          body .products-wrapper .simple-product-wrapper .product-item-wrap .info-wrapper .product-info > .pricing .price-box.price-final_price .pricing.config-pricing:not(.strikethrough-price) .price-container, body .products-wrapper .simple-product-wrapper .product-item-wrap .info-wrapper .product-info > .pricing .price-box.price-final_price .pricing.bundle-pricing:not(.strikethrough-price) .price-container {
            display: flex;
            justify-content: center;
            align-items: center; }
          body .products-wrapper .simple-product-wrapper .product-item-wrap .info-wrapper .product-info > .pricing .price-box.price-final_price .pricing.config-pricing:not(.strikethrough-price) .price-label, body .products-wrapper .simple-product-wrapper .product-item-wrap .info-wrapper .product-info > .pricing .price-box.price-final_price .pricing.bundle-pricing:not(.strikethrough-price) .price-label {
            margin-right: 0.8rem;
            display: block; }
        body .products-wrapper .simple-product-wrapper .product-item-wrap .info-wrapper .product-info .reviews {
          height: 30px; }
          body .products-wrapper .simple-product-wrapper .product-item-wrap .info-wrapper .product-info .reviews .product-reviews-summary.short {
            margin: 0 auto; }
            body .products-wrapper .simple-product-wrapper .product-item-wrap .info-wrapper .product-info .reviews .product-reviews-summary.short .rating-summary {
              margin: 0 auto;
              left: 0; }
            body .products-wrapper .simple-product-wrapper .product-item-wrap .info-wrapper .product-info .reviews .product-reviews-summary.short .reviews-actions {
              display: none; }
      body .products-wrapper .simple-product-wrapper .product-item-wrap .info-wrapper .product-actions {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        z-index: 1; }
        body .products-wrapper .simple-product-wrapper .product-item-wrap .info-wrapper .product-actions.in-wishlist .action.wishlist a {
          background: #eb5050; }
          body .products-wrapper .simple-product-wrapper .product-item-wrap .info-wrapper .product-actions.in-wishlist .action.wishlist a svg path {
            stroke: #fff; }
        body .products-wrapper .simple-product-wrapper .product-item-wrap .info-wrapper .product-actions .action {
          display: inline-block; }
          body .products-wrapper .simple-product-wrapper .product-item-wrap .info-wrapper .product-actions .action a.btn-action-secondary.btn-action-secondary.btn-action-secondary {
            height: 30px;
            width: 30px; }
            body .products-wrapper .simple-product-wrapper .product-item-wrap .info-wrapper .product-actions .action a.btn-action-secondary.btn-action-secondary.btn-action-secondary > span {
              display: none; }
        body .products-wrapper .simple-product-wrapper .product-item-wrap .info-wrapper .product-actions .wishlist {
          order: 1; }
          @media (min-width: 1240px) {
            body .products-wrapper .simple-product-wrapper .product-item-wrap .info-wrapper .product-actions .wishlist {
              margin-left: 0.5rem; } }
        @media (max-width: 575.98px) {
          body .products-wrapper .simple-product-wrapper .product-item-wrap .info-wrapper .product-actions .action.compare {
            margin-right: 1rem; } }
    body .products-wrapper .simple-product-wrapper .product-item-wrap .actions .product-specs {
      display: none; }
    body .products-wrapper .simple-product-wrapper .product-item-wrap .actions .rented {
      height: 23px;
      margin-top: 0.5rem;
      font-size: 1.1rem;
      vertical-align: middle; }
      @media (max-width: 575.98px) {
        body .products-wrapper .simple-product-wrapper .product-item-wrap .actions .rented {
          margin-top: 2.2rem;
          font-size: 1.4rem; } }
      body .products-wrapper .simple-product-wrapper .product-item-wrap .actions .rented img {
        max-width: 64px; }
      body .products-wrapper .simple-product-wrapper .product-item-wrap .actions .rented span,
      body .products-wrapper .simple-product-wrapper .product-item-wrap .actions .rented b {
        font-size: 1.1rem; }

body .products-wrapper.products-grid .simple-product-wrapper {
  padding: 0 2.3rem 1.4rem 2.3rem; }

body .products-list.products-wrapper,
body .productList[data-result-view='productList'] .products-wrapper,
body .productList[data-result-view='grid'] .products-wrapper,
body .productList[data-result-view='list'] .products-wrapper {
  position: relative;
  flex-direction: row;
  flex-wrap: wrap; }
  @media (min-width: 768px) and (max-width: 1023.98px) {
    body .products-list.products-wrapper,
    body .productList[data-result-view='productList'] .products-wrapper,
    body .productList[data-result-view='grid'] .products-wrapper,
    body .productList[data-result-view='list'] .products-wrapper {
      width: auto !important;
      margin-left: -20px;
      margin-right: -20px; } }
  body .products-list.products-wrapper .simple-product-wrapper,
  body .productList[data-result-view='productList'] .products-wrapper .simple-product-wrapper,
  body .productList[data-result-view='grid'] .products-wrapper .simple-product-wrapper,
  body .productList[data-result-view='list'] .products-wrapper .simple-product-wrapper {
    border-bottom: 1px solid #ddd; }
    @media (min-width: 768px) {
      body .products-list.products-wrapper .simple-product-wrapper,
      body .productList[data-result-view='productList'] .products-wrapper .simple-product-wrapper,
      body .productList[data-result-view='grid'] .products-wrapper .simple-product-wrapper,
      body .productList[data-result-view='list'] .products-wrapper .simple-product-wrapper {
        width: 50%; } }
    @media (max-width: 767.98px) {
      body .products-list.products-wrapper .simple-product-wrapper,
      body .productList[data-result-view='productList'] .products-wrapper .simple-product-wrapper,
      body .productList[data-result-view='grid'] .products-wrapper .simple-product-wrapper,
      body .productList[data-result-view='list'] .products-wrapper .simple-product-wrapper {
        width: 100%; } }
    body .products-list.products-wrapper .simple-product-wrapper:nth-last-child(-n + 2):nth-child(2n + 1),
    body .products-list.products-wrapper .simple-product-wrapper:nth-last-child(-n + 2):nth-child(2n + 1) ~ div,
    body .productList[data-result-view='productList'] .products-wrapper .simple-product-wrapper:nth-last-child(-n + 2):nth-child(2n + 1),
    body .productList[data-result-view='productList'] .products-wrapper .simple-product-wrapper:nth-last-child(-n + 2):nth-child(2n + 1) ~ div,
    body .productList[data-result-view='grid'] .products-wrapper .simple-product-wrapper:nth-last-child(-n + 2):nth-child(2n + 1),
    body .productList[data-result-view='grid'] .products-wrapper .simple-product-wrapper:nth-last-child(-n + 2):nth-child(2n + 1) ~ div,
    body .productList[data-result-view='list'] .products-wrapper .simple-product-wrapper:nth-last-child(-n + 2):nth-child(2n + 1),
    body .productList[data-result-view='list'] .products-wrapper .simple-product-wrapper:nth-last-child(-n + 2):nth-child(2n + 1) ~ div {
      border-bottom: none; }
    body .products-list.products-wrapper .simple-product-wrapper .product-item-wrap .product-content,
    body .productList[data-result-view='productList'] .products-wrapper .simple-product-wrapper .product-item-wrap .product-content,
    body .productList[data-result-view='grid'] .products-wrapper .simple-product-wrapper .product-item-wrap .product-content,
    body .productList[data-result-view='list'] .products-wrapper .simple-product-wrapper .product-item-wrap .product-content {
      padding: 2rem;
      height: 100%;
      display: flex;
      flex-direction: column; }
      @media (max-width: 767.98px) {
        body .products-list.products-wrapper .simple-product-wrapper .product-item-wrap .product-content,
        body .productList[data-result-view='productList'] .products-wrapper .simple-product-wrapper .product-item-wrap .product-content,
        body .productList[data-result-view='grid'] .products-wrapper .simple-product-wrapper .product-item-wrap .product-content,
        body .productList[data-result-view='list'] .products-wrapper .simple-product-wrapper .product-item-wrap .product-content {
          padding-left: 0;
          padding-right: 0; } }
    body .products-list.products-wrapper .simple-product-wrapper .info-wrapper,
    body .productList[data-result-view='productList'] .products-wrapper .simple-product-wrapper .info-wrapper,
    body .productList[data-result-view='grid'] .products-wrapper .simple-product-wrapper .info-wrapper,
    body .productList[data-result-view='list'] .products-wrapper .simple-product-wrapper .info-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      text-align: left; }
      body .products-list.products-wrapper .simple-product-wrapper .info-wrapper .product-image,
      body .productList[data-result-view='productList'] .products-wrapper .simple-product-wrapper .info-wrapper .product-image,
      body .productList[data-result-view='grid'] .products-wrapper .simple-product-wrapper .info-wrapper .product-image,
      body .productList[data-result-view='list'] .products-wrapper .simple-product-wrapper .info-wrapper .product-image {
        flex: 0 0 90px; }
        body .products-list.products-wrapper .simple-product-wrapper .info-wrapper .product-image .image-container .product-image-container,
        body .productList[data-result-view='productList'] .products-wrapper .simple-product-wrapper .info-wrapper .product-image .image-container .product-image-container,
        body .productList[data-result-view='grid'] .products-wrapper .simple-product-wrapper .info-wrapper .product-image .image-container .product-image-container,
        body .productList[data-result-view='list'] .products-wrapper .simple-product-wrapper .info-wrapper .product-image .image-container .product-image-container {
          max-width: 95px; }
          body .products-list.products-wrapper .simple-product-wrapper .info-wrapper .product-image .image-container .product-image-container .product-image-wrapper img,
          body .productList[data-result-view='productList'] .products-wrapper .simple-product-wrapper .info-wrapper .product-image .image-container .product-image-container .product-image-wrapper img,
          body .productList[data-result-view='grid'] .products-wrapper .simple-product-wrapper .info-wrapper .product-image .image-container .product-image-container .product-image-wrapper img,
          body .productList[data-result-view='list'] .products-wrapper .simple-product-wrapper .info-wrapper .product-image .image-container .product-image-container .product-image-wrapper img {
            width: 100%;
            height: 100%; }
      body .products-list.products-wrapper .simple-product-wrapper .info-wrapper .product-info,
      body .productList[data-result-view='productList'] .products-wrapper .simple-product-wrapper .info-wrapper .product-info,
      body .productList[data-result-view='grid'] .products-wrapper .simple-product-wrapper .info-wrapper .product-info,
      body .productList[data-result-view='list'] .products-wrapper .simple-product-wrapper .info-wrapper .product-info {
        flex: 1 1 auto;
        padding-left: 1rem; }
        body .products-list.products-wrapper .simple-product-wrapper .info-wrapper .product-info .brand,
        body .productList[data-result-view='productList'] .products-wrapper .simple-product-wrapper .info-wrapper .product-info .brand,
        body .productList[data-result-view='grid'] .products-wrapper .simple-product-wrapper .info-wrapper .product-info .brand,
        body .productList[data-result-view='list'] .products-wrapper .simple-product-wrapper .info-wrapper .product-info .brand {
          max-height: 20px; }
        body .products-list.products-wrapper .simple-product-wrapper .info-wrapper .product-info .title,
        body .productList[data-result-view='productList'] .products-wrapper .simple-product-wrapper .info-wrapper .product-info .title,
        body .productList[data-result-view='grid'] .products-wrapper .simple-product-wrapper .info-wrapper .product-info .title,
        body .productList[data-result-view='list'] .products-wrapper .simple-product-wrapper .info-wrapper .product-info .title {
          padding-right: 1.5rem; }
        body .products-list.products-wrapper .simple-product-wrapper .info-wrapper .product-info > .pricing,
        body .productList[data-result-view='productList'] .products-wrapper .simple-product-wrapper .info-wrapper .product-info > .pricing,
        body .productList[data-result-view='grid'] .products-wrapper .simple-product-wrapper .info-wrapper .product-info > .pricing,
        body .productList[data-result-view='list'] .products-wrapper .simple-product-wrapper .info-wrapper .product-info > .pricing {
          margin-top: 0.3rem; }
          body .products-list.products-wrapper .simple-product-wrapper .info-wrapper .product-info > .pricing .price-box.price-final_price .pricing,
          body .productList[data-result-view='productList'] .products-wrapper .simple-product-wrapper .info-wrapper .product-info > .pricing .price-box.price-final_price .pricing,
          body .productList[data-result-view='grid'] .products-wrapper .simple-product-wrapper .info-wrapper .product-info > .pricing .price-box.price-final_price .pricing,
          body .productList[data-result-view='list'] .products-wrapper .simple-product-wrapper .info-wrapper .product-info > .pricing .price-box.price-final_price .pricing {
            width: auto; }
        body .products-list.products-wrapper .simple-product-wrapper .info-wrapper .product-info .reviews .product-reviews-summary.short,
        body .productList[data-result-view='productList'] .products-wrapper .simple-product-wrapper .info-wrapper .product-info .reviews .product-reviews-summary.short,
        body .productList[data-result-view='grid'] .products-wrapper .simple-product-wrapper .info-wrapper .product-info .reviews .product-reviews-summary.short,
        body .productList[data-result-view='list'] .products-wrapper .simple-product-wrapper .info-wrapper .product-info .reviews .product-reviews-summary.short {
          margin: 0; }
      body .products-list.products-wrapper .simple-product-wrapper .info-wrapper .product-actions,
      body .productList[data-result-view='productList'] .products-wrapper .simple-product-wrapper .info-wrapper .product-actions,
      body .productList[data-result-view='grid'] .products-wrapper .simple-product-wrapper .info-wrapper .product-actions,
      body .productList[data-result-view='list'] .products-wrapper .simple-product-wrapper .info-wrapper .product-actions {
        position: relative;
        flex: 0 0 42px;
        flex-wrap: wrap;
        align-self: start;
        margin: 2px auto; }
        @media (max-width: 575.98px) {
          body .products-list.products-wrapper .simple-product-wrapper .info-wrapper .product-actions,
          body .productList[data-result-view='productList'] .products-wrapper .simple-product-wrapper .info-wrapper .product-actions,
          body .productList[data-result-view='grid'] .products-wrapper .simple-product-wrapper .info-wrapper .product-actions,
          body .productList[data-result-view='list'] .products-wrapper .simple-product-wrapper .info-wrapper .product-actions {
            flex-basis: 34px; } }
        body .products-list.products-wrapper .simple-product-wrapper .info-wrapper .product-actions .action,
        body .productList[data-result-view='productList'] .products-wrapper .simple-product-wrapper .info-wrapper .product-actions .action,
        body .productList[data-result-view='grid'] .products-wrapper .simple-product-wrapper .info-wrapper .product-actions .action,
        body .productList[data-result-view='list'] .products-wrapper .simple-product-wrapper .info-wrapper .product-actions .action {
          margin-bottom: 2rem;
          display: block; }
          body .products-list.products-wrapper .simple-product-wrapper .info-wrapper .product-actions .action a.btn-action-secondary.btn-action-secondary.btn-action-secondary,
          body .productList[data-result-view='productList'] .products-wrapper .simple-product-wrapper .info-wrapper .product-actions .action a.btn-action-secondary.btn-action-secondary.btn-action-secondary,
          body .productList[data-result-view='grid'] .products-wrapper .simple-product-wrapper .info-wrapper .product-actions .action a.btn-action-secondary.btn-action-secondary.btn-action-secondary,
          body .productList[data-result-view='list'] .products-wrapper .simple-product-wrapper .info-wrapper .product-actions .action a.btn-action-secondary.btn-action-secondary.btn-action-secondary {
            margin: 0 auto; }
            body .products-list.products-wrapper .simple-product-wrapper .info-wrapper .product-actions .action a.btn-action-secondary.btn-action-secondary.btn-action-secondary > span,
            body .productList[data-result-view='productList'] .products-wrapper .simple-product-wrapper .info-wrapper .product-actions .action a.btn-action-secondary.btn-action-secondary.btn-action-secondary > span,
            body .productList[data-result-view='grid'] .products-wrapper .simple-product-wrapper .info-wrapper .product-actions .action a.btn-action-secondary.btn-action-secondary.btn-action-secondary > span,
            body .productList[data-result-view='list'] .products-wrapper .simple-product-wrapper .info-wrapper .product-actions .action a.btn-action-secondary.btn-action-secondary.btn-action-secondary > span {
              display: inline-block;
              left: -3px; }
              @media (max-width: 575.98px) {
                body .products-list.products-wrapper .simple-product-wrapper .info-wrapper .product-actions .action a.btn-action-secondary.btn-action-secondary.btn-action-secondary > span,
                body .productList[data-result-view='productList'] .products-wrapper .simple-product-wrapper .info-wrapper .product-actions .action a.btn-action-secondary.btn-action-secondary.btn-action-secondary > span,
                body .productList[data-result-view='grid'] .products-wrapper .simple-product-wrapper .info-wrapper .product-actions .action a.btn-action-secondary.btn-action-secondary.btn-action-secondary > span,
                body .productList[data-result-view='list'] .products-wrapper .simple-product-wrapper .info-wrapper .product-actions .action a.btn-action-secondary.btn-action-secondary.btn-action-secondary > span {
                  right: auto;
                  left: 50%;
                  transform: translateX(-50%);
                  font-size: 1rem; } }
    body .products-list.products-wrapper .simple-product-wrapper .actions .product-specs,
    body .productList[data-result-view='productList'] .products-wrapper .simple-product-wrapper .actions .product-specs,
    body .productList[data-result-view='grid'] .products-wrapper .simple-product-wrapper .actions .product-specs,
    body .productList[data-result-view='list'] .products-wrapper .simple-product-wrapper .actions .product-specs {
      display: block;
      margin: 0.5rem 0; }
    body .products-list.products-wrapper .simple-product-wrapper .actions .kuLandingAddToCartBtn,
    body .products-list.products-wrapper .simple-product-wrapper .actions form button[type=submit],
    body .productList[data-result-view='productList'] .products-wrapper .simple-product-wrapper .actions .kuLandingAddToCartBtn,
    body .productList[data-result-view='productList'] .products-wrapper .simple-product-wrapper .actions form button[type=submit],
    body .productList[data-result-view='grid'] .products-wrapper .simple-product-wrapper .actions .kuLandingAddToCartBtn,
    body .productList[data-result-view='grid'] .products-wrapper .simple-product-wrapper .actions form button[type=submit],
    body .productList[data-result-view='list'] .products-wrapper .simple-product-wrapper .actions .kuLandingAddToCartBtn,
    body .productList[data-result-view='list'] .products-wrapper .simple-product-wrapper .actions form button[type=submit] {
      height: 48px;
      font-size: 1.2rem;
      font-weight: 600;
      line-height: 2.6; }
      @media (max-width: 575.98px) {
        body .products-list.products-wrapper .simple-product-wrapper .actions .kuLandingAddToCartBtn,
        body .products-list.products-wrapper .simple-product-wrapper .actions form button[type=submit],
        body .productList[data-result-view='productList'] .products-wrapper .simple-product-wrapper .actions .kuLandingAddToCartBtn,
        body .productList[data-result-view='productList'] .products-wrapper .simple-product-wrapper .actions form button[type=submit],
        body .productList[data-result-view='grid'] .products-wrapper .simple-product-wrapper .actions .kuLandingAddToCartBtn,
        body .productList[data-result-view='grid'] .products-wrapper .simple-product-wrapper .actions form button[type=submit],
        body .productList[data-result-view='list'] .products-wrapper .simple-product-wrapper .actions .kuLandingAddToCartBtn,
        body .productList[data-result-view='list'] .products-wrapper .simple-product-wrapper .actions form button[type=submit] {
          font-size: 1.4rem;
          line-height: 2; } }
    body .products-list.products-wrapper .simple-product-wrapper .rented,
    body .productList[data-result-view='productList'] .products-wrapper .simple-product-wrapper .rented,
    body .productList[data-result-view='grid'] .products-wrapper .simple-product-wrapper .rented,
    body .productList[data-result-view='list'] .products-wrapper .simple-product-wrapper .rented {
      text-align: center; }
      body .products-list.products-wrapper .simple-product-wrapper .rented img,
      body .productList[data-result-view='productList'] .products-wrapper .simple-product-wrapper .rented img,
      body .productList[data-result-view='grid'] .products-wrapper .simple-product-wrapper .rented img,
      body .productList[data-result-view='list'] .products-wrapper .simple-product-wrapper .rented img {
        max-width: 84px !important; }

@media (min-width: 1240px) {
  body .products-grid.products-wrapper .simple-product-wrapper,
  body .productList[data-result-view='productList'] .products-wrapper.products-wrapper .simple-product-wrapper,
  body .productList[data-result-view='grid'] .products-wrapper.products-wrapper .simple-product-wrapper {
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    text-align: center;
    padding: 0 2.3rem 1.4rem 2.3rem;
    width: 25%; }
    body .products-grid.products-wrapper .simple-product-wrapper:nth-child(4) ~ .simple-product-wrapper,
    body .productList[data-result-view='productList'] .products-wrapper.products-wrapper .simple-product-wrapper:nth-child(4) ~ .simple-product-wrapper,
    body .productList[data-result-view='grid'] .products-wrapper.products-wrapper .simple-product-wrapper:nth-child(4) ~ .simple-product-wrapper {
      padding-top: 2.6rem; }
    body .products-grid.products-wrapper .simple-product-wrapper:nth-child(4n+0), body .products-grid.products-wrapper .simple-product-wrapper:last-child,
    body .productList[data-result-view='productList'] .products-wrapper.products-wrapper .simple-product-wrapper:nth-child(4n+0),
    body .productList[data-result-view='productList'] .products-wrapper.products-wrapper .simple-product-wrapper:last-child,
    body .productList[data-result-view='grid'] .products-wrapper.products-wrapper .simple-product-wrapper:nth-child(4n+0),
    body .productList[data-result-view='grid'] .products-wrapper.products-wrapper .simple-product-wrapper:last-child {
      border-right: none; }
    body .products-grid.products-wrapper .simple-product-wrapper:nth-last-child(-n + 4):nth-child(4n + 1),
    body .products-grid.products-wrapper .simple-product-wrapper:nth-last-child(-n + 4):nth-child(4n + 1) ~ div,
    body .productList[data-result-view='productList'] .products-wrapper.products-wrapper .simple-product-wrapper:nth-last-child(-n + 4):nth-child(4n + 1),
    body .productList[data-result-view='productList'] .products-wrapper.products-wrapper .simple-product-wrapper:nth-last-child(-n + 4):nth-child(4n + 1) ~ div,
    body .productList[data-result-view='grid'] .products-wrapper.products-wrapper .simple-product-wrapper:nth-last-child(-n + 4):nth-child(4n + 1),
    body .productList[data-result-view='grid'] .products-wrapper.products-wrapper .simple-product-wrapper:nth-last-child(-n + 4):nth-child(4n + 1) ~ div {
      border-bottom: none; }
    body .products-grid.products-wrapper .simple-product-wrapper .product-item-wrap,
    body .productList[data-result-view='productList'] .products-wrapper.products-wrapper .simple-product-wrapper .product-item-wrap,
    body .productList[data-result-view='grid'] .products-wrapper.products-wrapper .simple-product-wrapper .product-item-wrap {
      height: 100%;
      display: flex;
      flex-direction: column; }
      body .products-grid.products-wrapper .simple-product-wrapper .product-item-wrap .product-content,
      body .productList[data-result-view='productList'] .products-wrapper.products-wrapper .simple-product-wrapper .product-item-wrap .product-content,
      body .productList[data-result-view='grid'] .products-wrapper.products-wrapper .simple-product-wrapper .product-item-wrap .product-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 0; }
        body .products-grid.products-wrapper .simple-product-wrapper .product-item-wrap .product-content .actions .product-specs,
        body .productList[data-result-view='productList'] .products-wrapper.products-wrapper .simple-product-wrapper .product-item-wrap .product-content .actions .product-specs,
        body .productList[data-result-view='grid'] .products-wrapper.products-wrapper .simple-product-wrapper .product-item-wrap .product-content .actions .product-specs {
          display: none; }
        body .products-grid.products-wrapper .simple-product-wrapper .product-item-wrap .product-content .actions form button[type=submit],
        body .productList[data-result-view='productList'] .products-wrapper.products-wrapper .simple-product-wrapper .product-item-wrap .product-content .actions form button[type=submit],
        body .productList[data-result-view='grid'] .products-wrapper.products-wrapper .simple-product-wrapper .product-item-wrap .product-content .actions form button[type=submit] {
          height: 34px;
          line-height: inherit; }
        body .products-grid.products-wrapper .simple-product-wrapper .product-item-wrap .product-content .actions .kuLandingAddToCartBtn,
        body .productList[data-result-view='productList'] .products-wrapper.products-wrapper .simple-product-wrapper .product-item-wrap .product-content .actions .kuLandingAddToCartBtn,
        body .productList[data-result-view='grid'] .products-wrapper.products-wrapper .simple-product-wrapper .product-item-wrap .product-content .actions .kuLandingAddToCartBtn {
          height: auto;
          line-height: 1.3;
          margin-top: 3rem; }
        body .products-grid.products-wrapper .simple-product-wrapper .product-item-wrap .product-content .info-wrapper,
        body .productList[data-result-view='productList'] .products-wrapper.products-wrapper .simple-product-wrapper .product-item-wrap .product-content .info-wrapper,
        body .productList[data-result-view='grid'] .products-wrapper.products-wrapper .simple-product-wrapper .product-item-wrap .product-content .info-wrapper {
          display: block; }
          body .products-grid.products-wrapper .simple-product-wrapper .product-item-wrap .product-content .info-wrapper .btn-action-secondary .icon-label.icon-label,
          body .productList[data-result-view='productList'] .products-wrapper.products-wrapper .simple-product-wrapper .product-item-wrap .product-content .info-wrapper .btn-action-secondary .icon-label.icon-label,
          body .productList[data-result-view='grid'] .products-wrapper.products-wrapper .simple-product-wrapper .product-item-wrap .product-content .info-wrapper .btn-action-secondary .icon-label.icon-label {
            display: none; }
          body .products-grid.products-wrapper .simple-product-wrapper .product-item-wrap .product-content .info-wrapper .klevuImgWrap a,
          body .productList[data-result-view='productList'] .products-wrapper.products-wrapper .simple-product-wrapper .product-item-wrap .product-content .info-wrapper .klevuImgWrap a,
          body .productList[data-result-view='grid'] .products-wrapper.products-wrapper .simple-product-wrapper .product-item-wrap .product-content .info-wrapper .klevuImgWrap a {
            height: 145px;
            display: flex;
            justify-content: center; }
          body .products-grid.products-wrapper .simple-product-wrapper .product-item-wrap .product-content .info-wrapper .product-info,
          body .productList[data-result-view='productList'] .products-wrapper.products-wrapper .simple-product-wrapper .product-item-wrap .product-content .info-wrapper .product-info,
          body .productList[data-result-view='grid'] .products-wrapper.products-wrapper .simple-product-wrapper .product-item-wrap .product-content .info-wrapper .product-info {
            text-align: center;
            padding: 0; }
            body .products-grid.products-wrapper .simple-product-wrapper .product-item-wrap .product-content .info-wrapper .product-info .title,
            body .productList[data-result-view='productList'] .products-wrapper.products-wrapper .simple-product-wrapper .product-item-wrap .product-content .info-wrapper .product-info .title,
            body .productList[data-result-view='grid'] .products-wrapper.products-wrapper .simple-product-wrapper .product-item-wrap .product-content .info-wrapper .product-info .title {
              padding: 0; }
            body .products-grid.products-wrapper .simple-product-wrapper .product-item-wrap .product-content .info-wrapper .product-info .kuPrice,
            body .productList[data-result-view='productList'] .products-wrapper.products-wrapper .simple-product-wrapper .product-item-wrap .product-content .info-wrapper .product-info .kuPrice,
            body .productList[data-result-view='grid'] .products-wrapper.products-wrapper .simple-product-wrapper .product-item-wrap .product-content .info-wrapper .product-info .kuPrice {
              align-items: center; }
          body .products-grid.products-wrapper .simple-product-wrapper .product-item-wrap .product-content .info-wrapper .product-actions,
          body .productList[data-result-view='productList'] .products-wrapper.products-wrapper .simple-product-wrapper .product-item-wrap .product-content .info-wrapper .product-actions,
          body .productList[data-result-view='grid'] .products-wrapper.products-wrapper .simple-product-wrapper .product-item-wrap .product-content .info-wrapper .product-actions {
            position: absolute; }
  body .products-grid.products-wrapper + .products-grid .simple-product-wrapper,
  body .productList[data-result-view='productList'] .products-wrapper.products-wrapper + .products-grid .simple-product-wrapper,
  body .productList[data-result-view='grid'] .products-wrapper.products-wrapper + .products-grid .simple-product-wrapper {
    border-top: 1px solid #ddd;
    padding-top: 2.6rem; } }

.ias-trigger.ias-trigger-next {
  margin-top: 5.5rem; }

[data-content-type='products'][data-appearance='carousel'] .slick-dots {
  margin-top: 2rem; }

[data-content-type='products'][data-appearance='carousel'] .slick-slide > div {
  flex-grow: 1; }

[data-content-type='products'][data-appearance='carousel'] .brand img,
[data-content-type='products'][data-appearance='carousel'] .rented img {
  display: inline-block; }

@media (max-width: 1239.98px) {
  body .products-wrapper.products-grid.widget-product-grid .simple-product-wrapper {
    width: 50%; } }

@media (max-width: 767.98px) {
  body .products-wrapper.products-grid.widget-product-grid .simple-product-wrapper {
    width: 100%; } }

.checkout-index-index .page-header {
  border: none; }
  .checkout-index-index .page-header::before, .checkout-index-index .page-header::after {
    display: none !important; }

.checkout-index-index .page-wrapper {
  overflow-x: hidden; }

.checkout-index-index .page-main {
  max-width: 980px; }

.checkout-index-index .cart-pre-actions .container, .checkout-index-index .cart-pre-actions .navigation, .checkout-index-index .cart-pre-actions .breadcrumb, .checkout-index-index .cart-pre-actions .page-header .header.panel, .page-header .checkout-index-index .cart-pre-actions .header.panel, .checkout-index-index .cart-pre-actions .header.content, .checkout-index-index .cart-pre-actions .footer-container, .checkout-index-index .cart-pre-actions .page-wrapper > .widget, .checkout-index-index .cart-pre-actions .page-wrapper > .page-bottom, .checkout-index-index .cart-pre-actions .block.category.event, .checkout-index-index .cart-pre-actions .top-container, .checkout-index-index .cart-pre-actions .page-main, .checkout-index-index .cart-pre-actions .cms-home .page-main .page.messages, .cms-home .page-main .checkout-index-index .cart-pre-actions .page.messages {
  max-width: 1004px;
  height: inherit; }

.checkout-index-index .page-title-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  margin: auto;
  height: 40px; }
  .checkout-index-index .page-title-wrapper .page-title {
    position: relative;
    z-index: 1;
    margin-bottom: 0; }
    @media (max-width: 1023.98px) {
      .checkout-index-index .page-title-wrapper .page-title {
        font-size: 1.6rem; } }
  @media (min-width: 1024px) {
    .checkout-index-index .page-title-wrapper {
      height: 80px; } }

.checkout-container {
  max-width: 1013px;
  margin: 15px auto 0; }
  @media (min-width: 1024px) {
    .checkout-container {
      display: flex;
      flex-wrap: wrap; } }
  .checkout-container .authentication-wrapper {
    order: 1; }
  .checkout-container .messages {
    max-width: 100%;
    width: 100%; }
  .checkout-container > div {
    float: none;
    width: auto;
    flex-grow: 1;
    max-width: 541px;
    flex-basis: 541px;
    padding-left: 0; }
    @media (min-width: 768px) {
      .checkout-container > div {
        min-width: 370px; } }
    @media (max-width: 1023.98px) {
      .checkout-container > div {
        margin-left: auto;
        margin-right: auto; } }
  .checkout-container .addresses {
    margin: 1rem 0; }
  .checkout-container .address {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start; }
    .checkout-container .address .field {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      width: 100%;
      margin-bottom: 1.3rem; }
      .checkout-container .address .field[name='shippingAddress.country_id'] {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border: 0;
        /* stylelint-disable-line */ }
      @media (min-width: 576px) {
        .checkout-container .address .field[name='shippingAddress.firstname'], .checkout-container .address .field[name='shippingAddress.lastname'] {
          width: 50%; }
        .checkout-container .address .field[name='shippingAddress.firstname'] {
          padding-right: 2%; }
        .checkout-container .address .field[name='shippingAddress.lastname'] {
          padding-left: 2%; } }
      .checkout-container .address .field[name='shippingAddress.region_id'], .checkout-container .address .field[name='shippingAddress.postcode'] {
        width: 50%; }
      .checkout-container .address .field[name='shippingAddress.region_id'] {
        padding-right: 2%; }
      .checkout-container .address .field[name='shippingAddress.postcode'] {
        padding-left: 2%; }
      .checkout-container .address .field.street .field .label {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border: 0;
        /* stylelint-disable-line */ }
      .checkout-container .address .field .field {
        margin-bottom: 0; }
      .checkout-container .address .field .label {
        position: relative;
        z-index: 1;
        display: block;
        width: auto;
        margin: 0 0.9rem;
        padding: 0 0.9rem;
        background: #fff;
        font-family: "Montserrat", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-weight: 600; }
      .checkout-container .address .field legend.label {
        line-height: 2.3; }
      .checkout-container .address .field._required .label::after {
        content: '*';
        font-size: 1.2rem;
        margin: 0 0 0 0.3rem; }
      .checkout-container .address .field .form-control,
      .checkout-container .address .field .input-text,
      .checkout-container .address .field .select,
      .checkout-container .address .field .control {
        width: 100%;
        margin-top: -0.6rem;
        border-radius: 0.3rem; }
      .checkout-container .address .field .select {
        padding-right: 3rem; }
  .checkout-container .field-error {
    margin-top: 0;
    font-size: 1.1rem;
    color: #d60000; }
  .checkout-container .form.form-giftcard-account .action-check,
  .checkout-container .form.form-giftcard-account .giftcard-account-info {
    margin-top: 2rem; }

.mark {
  background: none; }

.table .price-including-tax,
.table .price-excluding-tax {
  font-size: inherit; }

.opc-sidebar.opc-sidebar {
  flex-basis: 380px;
  float: none;
  width: auto;
  margin-top: 0;
  margin-left: auto; }
  .opc-sidebar.opc-sidebar .minicart-items-wrapper {
    margin: 0;
    padding: 25px 25px 15px;
    border: none; }
  .opc-sidebar.opc-sidebar .minicart-items .product-item {
    padding-bottom: 0; }
    .opc-sidebar.opc-sidebar .minicart-items .product-item:not(:first-child) {
      border-top-color: #ddd; }
    .opc-sidebar.opc-sidebar .minicart-items .product-item:last-child {
      border-bottom: 1px solid #ddd;
      padding-bottom: 10px; }
  .opc-sidebar.opc-sidebar .minicart-items .product-item-details .details-qty {
    margin-top: 0; }
  .opc-sidebar.opc-sidebar .minicart-items .label {
    font-weight: 700;
    font-size: 1.3rem; }
  .opc-sidebar.opc-sidebar .table-totals-container {
    padding: 0 25px; }
    .opc-sidebar.opc-sidebar .table-totals-container .cart-totals.cart-totals {
      margin-bottom: 0.4rem;
      /* padding on third last row's children, as per weird design */ }
      .opc-sidebar.opc-sidebar .table-totals-container .cart-totals.cart-totals tr:nth-last-of-type(2) td,
      .opc-sidebar.opc-sidebar .table-totals-container .cart-totals.cart-totals tr:nth-last-of-type(2) th {
        padding-bottom: 30px; }
  @media (min-width: 576px) and (max-width: 1023.98px) {
    .opc-sidebar.opc-sidebar .cart-summary,
    .opc-sidebar.opc-sidebar .checkout-placeorder {
      max-width: 374px;
      width: 100%;
      margin-right: auto;
      margin-left: 0;
      float: left; } }
  @media (max-width: 1023.98px) {
    .opc-sidebar.opc-sidebar .cart-summary {
      margin-top: 0; }
    .opc-sidebar.opc-sidebar .checkout-placeorder .btn,
    .opc-sidebar.opc-sidebar .checkout-placeorder .pagebuilder-button-primary,
    .opc-sidebar.opc-sidebar .checkout-placeorder .pagebuilder-button-secondary, .opc-sidebar.opc-sidebar .checkout-placeorder .account-nav .item.item a[href*='logout'], .account-nav .item.item .opc-sidebar.opc-sidebar .checkout-placeorder a[href*='logout'] {
      width: 100%; } }
  @media (min-width: 1024px) {
    .opc-sidebar.opc-sidebar .checkout-placeorder .btn,
    .opc-sidebar.opc-sidebar .checkout-placeorder .pagebuilder-button-primary,
    .opc-sidebar.opc-sidebar .checkout-placeorder .pagebuilder-button-secondary, .opc-sidebar.opc-sidebar .checkout-placeorder .account-nav .item.item a[href*='logout'], .account-nav .item.item .opc-sidebar.opc-sidebar .checkout-placeorder a[href*='logout'] {
      margin-left: 2.9rem;
      width: calc(100% - 2.9rem); } }

.checkout-discount {
  clear: both; }
  @media (min-width: 576px) and (max-width: 1239.98px) {
    .checkout-discount {
      max-width: 374px; } }
  @media (min-width: 1024px) {
    .checkout-discount {
      margin-left: 2.9rem;
      width: calc(100% - 2.9rem); } }

.shippit-options {
  margin-top: 2rem; }
  .shippit-options .control {
    margin-top: 0.5rem;
    margin-bottom: 2rem; }
  .shippit-options .label {
    font-weight: 700; }
  .shippit-options .choice {
    position: relative;
    z-index: 1;
    display: block;
    min-height: 1.5rem;
    padding-left: 2.9rem;
    color-adjust: exact; }
    .shippit-options .choice .label {
      position: relative;
      margin-bottom: 0;
      vertical-align: top; }
      .shippit-options .choice .label::before {
        position: absolute;
        top: -0.45rem;
        left: -2.9rem;
        display: block;
        width: 2.4rem;
        height: 2.4rem;
        pointer-events: none;
        content: '';
        background-color: #fff;
        border: #ddd solid 2px; }
      .shippit-options .choice .label::after {
        position: absolute;
        top: -0.45rem;
        left: -2.9rem;
        display: block;
        width: 2.4rem;
        height: 2.4rem;
        content: '';
        background: 50% / 50% 50% no-repeat; }
    .shippit-options .choice .checkbox {
      position: absolute;
      left: 0;
      z-index: -1;
      width: 2.4rem;
      height: 1.95rem;
      opacity: 0; }
      .shippit-options .choice .checkbox:checked ~ .label::before {
        color: #000;
        border-color: #000;
        background-color: #fff; }
      .shippit-options .choice .checkbox:checked ~ .label::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }
      .shippit-options .choice .checkbox:focus ~ .label::before {
        box-shadow: 0 3px 10px #00000026; }
      .shippit-options .choice .checkbox:focus:not(:checked) ~ .label::before {
        border-color: #000; }
      .shippit-options .choice .checkbox:not(:disabled):active ~ .label::before {
        color: #fff;
        background-color: #fff;
        border-color: #000; }
      .shippit-options .choice .checkbox[disabled] ~ .label, .shippit-options .choice .checkbox:disabled ~ .label {
        color: #ddd; }
        .shippit-options .choice .checkbox[disabled] ~ .label::before, .shippit-options .choice .checkbox:disabled ~ .label::before {
          background-color: #fff; }
  .shippit-options .admin__control-textarea {
    display: block;
    width: 100%;
    padding: 1.1rem 1.7rem;
    font-size: 1.3rem;
    /* stylelint-disable-line */
    font-weight: 400;
    /* stylelint-disable-line */
    line-height: 1.5;
    color: #59595a;
    background-color: #fff;
    background-clip: padding-box;
    border: 2px solid #aaa;
    border-radius: 0.3rem;
    /* stylelint-disable-line */
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    /* stylelint-disable-line */ }
    @media (prefers-reduced-motion: reduce) {
      .shippit-options .admin__control-textarea {
        transition: none; } }
    .shippit-options .admin__control-textarea::-ms-expand {
      background-color: transparent;
      border: 0; }
    .shippit-options .admin__control-textarea:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #59595a; }
    .shippit-options .admin__control-textarea:focus {
      color: #59595a;
      background-color: #fff;
      border-color: #fcd900;
      outline: 0;
      box-shadow: 0 3px 10px #00000026; }
    .shippit-options .admin__control-textarea::placeholder {
      color: #aaa;
      opacity: 1; }
    .shippit-options .admin__control-textarea:disabled, .shippit-options .admin__control-textarea[readonly] {
      background-color: #e5e5e5;
      opacity: 1; }

@media (max-width: 1023.98px) {
  .opc-wrapper {
    padding-right: 0; } }

.opc-wrapper .shipping-address-item {
  line-height: 1.5; }
  .opc-wrapper .shipping-address-item.selected-item {
    border-color: #fcd900; }
    .opc-wrapper .shipping-address-item.selected-item::after {
      content: '✓';
      background-color: #fcd900;
      font-family: inherit;
      font-size: 1.9rem; }
  .opc-wrapper .shipping-address-item a {
    text-decoration: none; }
    .opc-wrapper .shipping-address-item a:hover {
      text-decoration: hover; }

.opc-wrapper .accordion {
  counter-reset: section; }
  .opc-wrapper .accordion .btn-collapse span::before {
    counter-increment: section;
    content: counter(section) ". "; }

.opc-wrapper .form-login {
  padding-bottom: 0;
  border: none; }
  .opc-wrapper .form-login,
  .opc-wrapper .form-login .fieldset {
    margin-bottom: 0; }

.opc-wrapper .form-shipping-address {
  margin-top: 0; }
  @media (max-width: 767.98px) {
    .opc-wrapper .form-shipping-address .btn.continue,
    .opc-wrapper .form-shipping-address .continue.pagebuilder-button-primary,
    .opc-wrapper .form-shipping-address .continue.pagebuilder-button-secondary, .opc-wrapper .form-shipping-address .account-nav .item.item a.continue[href*='logout'], .account-nav .item.item .opc-wrapper .form-shipping-address a.continue[href*='logout'] {
      width: 100%; } }

@media (min-width: 576px) {
  .opc-wrapper .tab-holder {
    font-size: 1.5rem; } }

.opc-wrapper .tab-holder .btn,
.opc-wrapper .tab-holder .pagebuilder-button-primary,
.opc-wrapper .tab-holder .pagebuilder-button-secondary, .opc-wrapper .tab-holder .account-nav .item.item a[href*='logout'], .account-nav .item.item .opc-wrapper .tab-holder a[href*='logout'] {
  font-size: 1.4rem; }

.opc-wrapper .tab-holder .tab-headings {
  border-bottom: solid 4px #000; }
  .opc-wrapper .tab-holder .tab-headings .tab-item {
    background-color: #ddd;
    color: #000;
    display: block;
    flex: 1 1 50%;
    margin: 0 3px;
    font-family: "Montserrat", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1.3rem;
    line-height: 1.9rem;
    font-weight: 700;
    padding: 20px 10px;
    border: none;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    outline: none !important; }
    @media (min-width: 768px) {
      .opc-wrapper .tab-holder .tab-headings .tab-item {
        font-size: 1.6rem; } }
    .opc-wrapper .tab-holder .tab-headings .tab-item:hover, .opc-wrapper .tab-holder .tab-headings .tab-item.active {
      background-color: #000;
      color: #fff; }
    .opc-wrapper .tab-holder .tab-headings .tab-item:first-child {
      margin-left: 0; }
    .opc-wrapper .tab-holder .tab-headings .tab-item:last-child {
      margin-right: 0; }

@media (max-width: 575.98px) {
  .opc-wrapper .tab-holder .social-login-buttons {
    flex-wrap: wrap; } }

.opc-wrapper .tab-holder .social-login-buttons .btn-social {
  flex: 0 0 100%;
  padding-left: 45px;
  padding-right: 8px;
  line-height: 1.6; }
  @media (min-width: 576px) {
    .opc-wrapper .tab-holder .social-login-buttons .btn-social {
      flex: 1 1 50%;
      margin: 0 3px; } }
  .opc-wrapper .tab-holder .social-login-buttons .btn-social:first-child {
    margin-left: 0; }
  .opc-wrapper .tab-holder .social-login-buttons .btn-social:last-child {
    margin-right: 0; }
    @media (max-width: 575.98px) {
      .opc-wrapper .tab-holder .social-login-buttons .btn-social:last-child {
        margin-top: 0.5rem; } }

.opc-progress-bar {
  position: relative;
  width: 100%;
  text-align: center;
  margin-top: 1.5rem;
  margin-bottom: 4.6rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #ddd; }
  @media (max-width: 575.98px) {
    .opc-progress-bar {
      width: auto;
      margin-left: -1.5rem;
      margin-right: -1.5rem; } }
  .opc-progress-bar::before, .opc-progress-bar::after {
    content: '';
    position: absolute;
    top: 100%;
    border-bottom: 1px solid #ddd;
    width: 100vw; }
  .opc-progress-bar::before {
    left: 100%; }
  .opc-progress-bar::after {
    right: 100%; }
  @media (max-width: 767.98px) {
    .opc-progress-bar .opc-progress-bar-item {
      width: 33.33%; } }
  .opc-progress-bar .opc-progress-bar-item::before {
    left: 50%;
    top: 10px;
    height: 4px;
    background-color: #ddd; }
  .opc-progress-bar .opc-progress-bar-item.show-complete::before {
    background-color: #fcd900; }
  .opc-progress-bar .opc-progress-bar-item:last-child::before {
    display: none; }
  .opc-progress-bar .opc-progress-bar-item span {
    font-size: 1.1rem;
    font-weight: 400;
    padding-top: 33px;
    color: #000; }
    .opc-progress-bar .opc-progress-bar-item span::before {
      width: 24px;
      height: 24px;
      margin-left: -12px;
      background-color: #ddd; }
    .opc-progress-bar .opc-progress-bar-item span::after {
      content: counter(i);
      font-family: "Montserrat", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 1.5rem;
      line-height: 1.4rem;
      font-weight: 700;
      color: #fff;
      background-color: transparent; }
  .opc-progress-bar .opc-progress-bar-item.show-complete span::before {
    background-color: #fcd900; }
  .opc-progress-bar .opc-progress-bar-item.show-complete span::after {
    color: #000; }

.table-checkout-shipping-method td span {
  font-family: "Montserrat", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }

.shipping-method.shipping-method td {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  border: none !important; }

.shipping-method.shipping-method .col-method {
  padding-top: 0.6rem; }

.payment-title {
  font-size: 1.5rem; }
  @media (min-width: 576px) {
    .payment-title {
      padding-left: 1.5rem; } }
  @media (max-width: 575.98px) {
    .payment-title {
      margin: -1rem 0 1.5rem; } }
  .methods-shipping .payment-title {
    margin-top: 2rem;
    padding-left: 0; }

@media (max-width: 767.98px) {
  .methods-shipping .actions-toolbar .btn,
  .methods-shipping .actions-toolbar .pagebuilder-button-primary,
  .methods-shipping .actions-toolbar .pagebuilder-button-secondary, .methods-shipping .actions-toolbar .account-nav .item.item a[href*='logout'], .account-nav .item.item .methods-shipping .actions-toolbar a[href*='logout'] {
    width: 100%; } }

.ampickup-store-container.ampickup-store-container.ampickup-store-container {
  margin-bottom: 1rem; }
  .ampickup-store-container.ampickup-store-container.ampickup-store-container .label {
    float: none;
    width: 100%;
    margin-bottom: 0.5rem;
    text-align: left; }
  .ampickup-store-container.ampickup-store-container.ampickup-store-container .ampickup-store {
    height: calc(calc(1.5em + 2.2rem + 4px) + 2.5px);
    padding: 1.1rem 1.7rem 1.1rem 1.8rem;
    border-radius: 0.3rem; }
  .ampickup-store-container.ampickup-store-container.ampickup-store-container .ampickup-separator,
  .ampickup-store-container.ampickup-store-container.ampickup-store-container .ampickup-details-container {
    display: none; }

.ampickup-map-popup.ampickup-map-popup {
  flex: 0 0 414px;
  max-width: 414px;
  width: 100%;
  left: auto;
  right: 0;
  height: 100%; }
  .ampickup-map-popup.ampickup-map-popup .ampickup-overlay {
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%; }
    .ampickup-map-popup.ampickup-map-popup .ampickup-overlay:hover {
      cursor: pointer; }
  .ampickup-map-popup.ampickup-map-popup .ampickup-content {
    width: 100%;
    height: 100%;
    max-height: 100%;
    border-radius: 0;
    padding: 0; }
  .ampickup-map-popup.ampickup-map-popup .ampickup-title {
    flex-shrink: 0;
    height: 40px;
    margin-bottom: 0;
    background-color: #000;
    color: #fff;
    font-family: "Montserrat", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1.6rem;
    text-align: center;
    font-weight: 700;
    line-height: 40px; }
  .ampickup-map-popup.ampickup-map-popup .ampickup-close {
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    opacity: 1; }
    .ampickup-map-popup.ampickup-map-popup .ampickup-close::before, .ampickup-map-popup.ampickup-map-popup .ampickup-close::after {
      top: 10px;
      left: 14px;
      height: 21px;
      background-color: #fff; }
  .ampickup-map-popup.ampickup-map-popup .amlocator-main-container {
    min-width: 0; }
    .ampickup-map-popup.ampickup-map-popup .amlocator-main-container .amlocator-store-desc {
      cursor: default;
      overflow: revert !important; }
      .ampickup-map-popup.ampickup-map-popup .amlocator-main-container .amlocator-store-desc:not(:last-child) {
        border-bottom-color: #ddd; }
  .ampickup-map-popup.ampickup-map-popup .amlocator-map-container {
    flex-direction: column;
    min-height: 0;
    position: absolute;
    top: auto;
    right: 0;
    width: 100%;
    height: 100%; }
    .ampickup-map-popup.ampickup-map-popup .amlocator-map-container .amlocator-search-results {
      padding: 2rem 1.5rem 0; }
      @media (min-width: 576px) {
        .ampickup-map-popup.ampickup-map-popup .amlocator-map-container .amlocator-search-results {
          padding-left: 2rem;
          padding-right: 2rem; } }
    .ampickup-map-popup.ampickup-map-popup .amlocator-map-container .amlocator-block.-storelist {
      flex: 1 1 0;
      width: 100%;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      overflow-y: auto; }
      @media (min-width: 576px) {
        .ampickup-map-popup.ampickup-map-popup .amlocator-map-container .amlocator-block.-storelist {
          padding-left: 2.2rem;
          padding-right: 2.2rem; } }
    @media (max-width: 575.98px) {
      .ampickup-map-popup.ampickup-map-popup .amlocator-map-container .btn-sm, .ampickup-map-popup.ampickup-map-popup .amlocator-map-container .account-nav .item.item a[href*='logout'], .account-nav .item.item .ampickup-map-popup.ampickup-map-popup .amlocator-map-container a[href*='logout'] {
        padding: 0.8rem 1.9rem; }
        .ampickup-map-popup.ampickup-map-popup .amlocator-map-container .btn-sm:hover, .ampickup-map-popup.ampickup-map-popup .amlocator-map-container .account-nav .item.item a:hover[href*='logout'], .account-nav .item.item .ampickup-map-popup.ampickup-map-popup .amlocator-map-container a:hover[href*='logout'] {
          padding: 0.6rem 1.7rem; } }
  .ampickup-map-popup.ampickup-map-popup .amlocator-store-information {
    flex: 1 0 auto;
    line-height: 1.5;
    font-size: 1.2rem; }
    .ampickup-map-popup.ampickup-map-popup .amlocator-store-information .amlocator-title {
      margin: 0.5rem 0; }
  .ampickup-map-popup.ampickup-map-popup .ampickup-locations {
    height: calc(100% - 40px);
    padding: 2rem;
    padding-top: 40px; }
    .ampickup-map-popup.ampickup-map-popup .ampickup-locations::before {
      content: 'Click & Collect';
      top: 0;
      left: 0;
      right: 0;
      background: #000;
      color: #fff;
      position: absolute;
      z-index: -1;
      height: 40px;
      line-height: 40px;
      padding: 0 20px;
      font-size: 1.6rem;
      font-weight: bold;
      text-align: center; }
    .ampickup-map-popup.ampickup-map-popup .ampickup-locations h1 {
      display: none; }
      .ampickup-map-popup.ampickup-map-popup .ampickup-locations h1 + hr {
        display: none; }

.checkout-payment-method .accordion-payment {
  margin-bottom: 2rem; }

.checkout-payment-method .billing-address-same-as-shipping-block {
  margin-bottom: 0; }

.checkout-payment-method .payment-methods {
  margin: 0; }

.checkout-payment-method .payment-method .braintree-credit-card-selected {
  height: 31px; }

.checkout-payment-method .payment-method #braintree_expirationDate {
  width: 120px; }

.checkout-payment-method .payment-method [id='braintree_cc_type_cvv_div'] {
  max-width: 100%; }

.checkout-payment-method .payment-method [id='braintree_cc_cid'] {
  width: 100px; }

@media (min-width: 768px) {
  .checkout-payment-method .payment-method [for='braintree_cc_number'] {
    min-height: 58px; } }

.checkout-payment-method .payment-method .hosted-control {
  padding-top: 7px;
  padding-bottom: 6px;
  border: 2px solid #aaa;
  border-radius: 0.3rem; }
  .checkout-payment-method .payment-method .hosted-control:focus-within {
    border-color: #000;
    box-shadow: 0 3px 10px #00000026; }

@media (min-width: 576px) {
  .checkout-payment-method .payment-method {
    max-width: 374px; } }

.checkout-payment-method .payment-method-title {
  border: none !important;
  padding: 8px 0 0; }
  .checkout-payment-method .payment-method-title .label {
    position: relative;
    display: flex;
    align-items: center;
    height: 60px;
    width: 100%;
    padding: 0 2.3rem 0 6rem;
    border: 2px solid #ddd;
    border-radius: 0.3rem; }
    @media (max-width: 575.98px) {
      .checkout-payment-method .payment-method-title .label {
        padding: 0 2rem 0 5.8rem; } }
    .checkout-payment-method .payment-method-title .label:hover {
      cursor: pointer; }
    .checkout-payment-method .payment-method-title .label::before, .checkout-payment-method .payment-method-title .label::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      border-radius: 50%; }
    .checkout-payment-method .payment-method-title .label::before {
      left: 2.3rem;
      height: 24px;
      width: 24px;
      border-color: inherit;
      border-width: 2px;
      border-style: solid; }
      @media (max-width: 575.98px) {
        .checkout-payment-method .payment-method-title .label::before {
          left: 2rem; } }
    .checkout-payment-method .payment-method-title .label span {
      margin-right: auto;
      line-height: 1;
      font-family: "Montserrat", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 700;
      font-size: 1.5rem; }
    .checkout-payment-method .payment-method-title .label .payment-icon {
      order: 1;
      flex-shrink: 0;
      margin-left: auto;
      margin-right: 0; }
    .checkout-payment-method .payment-method-title .label svg {
      flex-shrink: 0;
      margin-left: 1rem; }
      @media (max-width: 575.98px) {
        .checkout-payment-method .payment-method-title .label svg {
          margin-left: 0.4rem; } }
  .checkout-payment-method .payment-method-title .custom-control-input:checked ~ .label {
    border-color: #000;
    box-shadow: 0 3px 10px #00000026; }
    .checkout-payment-method .payment-method-title .custom-control-input:checked ~ .label::after {
      left: 3rem;
      width: 10px;
      height: 10px;
      background-color: #000; }
      @media (max-width: 575.98px) {
        .checkout-payment-method .payment-method-title .custom-control-input:checked ~ .label::after {
          left: 2.7rem; } }

.checkout-payment-method .payment-method-content {
  padding-top: 20px;
  padding-left: 0;
  padding-right: 0; }
  .checkout-payment-method .payment-method-content iframe#checkout-iframe {
    width: 100%;
    height: 268px !important; }

.checkout-payment-method .actions-toolbar.actions-toolbar.actions-toolbar {
  margin-left: 0; }
  .checkout-payment-method .actions-toolbar.actions-toolbar.actions-toolbar .primary {
    float: none;
    width: 100%; }
    .checkout-payment-method .actions-toolbar.actions-toolbar.actions-toolbar .primary .btn,
    .checkout-payment-method .actions-toolbar.actions-toolbar.actions-toolbar .primary .pagebuilder-button-primary,
    .checkout-payment-method .actions-toolbar.actions-toolbar.actions-toolbar .primary .pagebuilder-button-secondary, .checkout-payment-method .actions-toolbar.actions-toolbar.actions-toolbar .primary .account-nav .item.item a[href*='logout'], .account-nav .item.item .checkout-payment-method .actions-toolbar.actions-toolbar.actions-toolbar .primary a[href*='logout'] {
      width: 100%;
      margin: 0; }

.checkout-payment-method .billing-address-details.billing-address-details.billing-address-details {
  margin-bottom: 1.5rem;
  padding-left: 3rem;
  line-height: 2; }

.checkout-payment-method .field-tooltip-action {
  display: none; }

@media (max-width: 575.98px) {
  .form-shipping-address.form-shipping-address ~ .d-flex .btn-primary,
  .form-shipping-address.form-shipping-address ~ .d-flex .pagebuilder-button-primary {
    width: 100%; } }

.form-shipping-address.form-shipping-address .field {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 1.3rem; }
  .form-shipping-address.form-shipping-address .field .field {
    margin-bottom: 0; }
  .form-shipping-address.form-shipping-address .field .control {
    float: none;
    width: 100%;
    margin-top: -0.8rem; }
  .form-shipping-address.form-shipping-address .field .label {
    position: relative;
    z-index: 1;
    display: block;
    margin: 0 0.9rem;
    padding: 0 0.9rem;
    background: #fff;
    font-family: "Montserrat", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 600;
    width: auto;
    float: none;
    text-align: left; }
  .form-shipping-address.form-shipping-address .field.street label.label {
    height: 0; }
  .form-shipping-address.form-shipping-address .field.street .input-text {
    margin-top: 0.5rem; }
  .form-shipping-address.form-shipping-address .field.choice::before {
    width: 0;
    padding-right: 2rem; }

.checkout-onepage-success .page-title-wrapper {
  text-align: center;
  margin-top: 40px !important; }

.checkout-onepage-success .action.print {
  display: none; }

.checkout-onepage-success .checkout-success {
  max-width: 500px;
  margin: 30px auto 0 auto !important;
  text-align: center; }
  .checkout-onepage-success .checkout-success .success-card {
    position: relative;
    top: 20px;
    border: 10px solid #fcd900;
    border-radius: 8px;
    padding: 25px;
    text-align: center;
    margin-bottom: 70px; }
    .checkout-onepage-success .checkout-success .success-card hr {
      margin-top: 0;
      margin-bottom: 3rem; }
    .checkout-onepage-success .checkout-success .success-card p:first-child {
      font-weight: bold;
      font-size: 1.8rem; }
      .checkout-onepage-success .checkout-success .success-card p:first-child a {
        color: #fcd900;
        text-decoration: none !important; }
    .checkout-onepage-success .checkout-success .success-card hr + p,
    .checkout-onepage-success .checkout-success .success-card hr + p + p {
      font-size: 1.5rem;
      margin-bottom: 5px; }

.table-comparison {
  min-width: 100%; }
  .table-comparison.table-striped tbody tr:nth-of-type(even) {
    background: none; }
    .table-comparison.table-striped tbody tr:nth-of-type(even) td {
      background-color: rgba(221, 221, 221, 0.46); }
  .table-comparison thead + tbody,
  .table-comparison thead + tbody ~ tbody {
    border: none; }
  .table-comparison thead + tbody tr {
    position: sticky;
    top: 0;
    z-index: 1;
    box-shadow: 0 3px 0 rgba(0, 0, 0, 0.16); }
  .table-comparison thead + tbody .cell {
    background: #fff;
    font-size: 1.2rem; }
    .table-comparison thead + tbody .cell .product-item-name a,
    .table-comparison thead + tbody .cell .price {
      font-size: inherit; }
    .table-comparison thead + tbody .cell .product-item-name {
      margin-right: -1rem; }
      .table-comparison thead + tbody .cell .product-item-name a {
        text-decoration: none; }
        .table-comparison thead + tbody .cell .product-item-name a:hover {
          text-decoration: underline; }
    .table-comparison thead + tbody .cell .price {
      font-weight: 700; }
  .table-comparison tr {
    display: flex;
    justify-content: stretch; }
  .table-comparison td.cell {
    width: 33.33%;
    max-width: 33.33%; }
    @media (min-width: 576px) {
      .table-comparison td.cell {
        width: calc(414px / 3); } }
  .table-comparison th + td.cell:last-child {
    width: 100%;
    max-width: 100%; }
  .table-comparison th + td + td.cell:last-child {
    width: 66.66%;
    max-width: 66.66%; }
  .table-comparison.two-compares td {
    width: 50% !important;
    max-width: 50% !important; }
  .table-comparison .product-item-photo {
    margin-bottom: auto;
    margin-left: 0;
    padding-bottom: 15px; }
  .table-comparison .attribute.label {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1rem; }
  .table-comparison .cell {
    border: none !important;
    padding-left: 2rem;
    vertical-align: bottom; }
  .table-comparison .price-box {
    margin: 0; }
  .table-comparison .pricing .price-label {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
    /* stylelint-disable-line */ }
  .table-comparison .pricing:not(:last-child) {
    display: none; }
  .table-comparison .product-image-container {
    max-width: 80px; }
  .table-comparison .compare-product-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    position: relative;
    width: 100%;
    height: 100%; }
  .table-comparison .btn-remove.btn-remove {
    position: absolute;
    top: 0.2rem;
    right: 0.7rem;
    width: 26px;
    height: 26px;
    z-index: 2; }

.table-wrapper.comparison {
  flex-grow: 1;
  margin: 0;
  padding: 0;
  overflow: auto;
  height: calc(100vh - 60px); }

body[class*=cms-event-detail] .columns,
body[class*=cms-course-detail] .columns {
  display: none; }

.event-detail .event-thumbnail {
  height: 187px;
  border-radius: 3px;
  overflow: hidden; }
  @media (min-width: 1024px) {
    .event-detail .event-thumbnail {
      height: 274px; } }

.event-detail .event-title {
  font-weight: 700;
  font-size: 2.2rem; }
  @media (min-width: 768px) {
    .event-detail .event-title {
      font-size: 2.4rem; } }

.event-detail .event-location {
  align-items: flex-start;
  margin-bottom: 2rem; }
  .event-detail .event-location a {
    text-decoration: underline; }
    .event-detail .event-location a:hover {
      text-decoration: none; }

.event-detail .step-content {
  padding: 2rem 0;
  line-height: 1.75; }
  @media (max-width: 767.98px) {
    .event-detail .step-content {
      font-size: 1.5rem; } }

.event-detail .event-buy-container {
  display: flex;
  align-items: center;
  max-width: 434px; }
  @media (max-width: 1023.98px) {
    .event-detail .event-buy-container {
      flex-wrap: wrap;
      justify-content: center;
      margin-left: auto;
      margin-right: auto; } }

.event-detail .event-price {
  font-weight: 700;
  font-size: 2.2rem; }
  @media (min-width: 768px) {
    .event-detail .event-price {
      font-size: 2.4rem; } }

body[class*=cms-events] .column.main {
  padding-bottom: 0; }
  body[class*=cms-events] .column.main [data-content-type=row][data-appearance=contained] {
    padding-left: 15px !important;
    padding-right: 15px !important; }

.events-banner,
.academy-banner {
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center !important;
  max-width: none;
  height: 250px;
  background-position: center !important; }
  @media (max-width: 575.98px) {
    .events-banner,
    .academy-banner {
      flex-basis: calc(100% + 3.5rem);
      height: 220px;
      margin: -3.3rem -1.5rem 0 !important; }
      .events-banner .btn, .events-banner a.pagebuilder-button-primary,
      .events-banner .pagebuilder-button-primary, .events-banner a.pagebuilder-button-secondary,
      .events-banner .pagebuilder-button-secondary, .events-banner .account-nav .item.item a[href*='logout'], .account-nav .item.item .events-banner a[href*='logout'],
      .academy-banner .btn,
      .academy-banner a.pagebuilder-button-primary,
      .academy-banner .pagebuilder-button-primary,
      .academy-banner a.pagebuilder-button-secondary,
      .academy-banner .pagebuilder-button-secondary,
      .academy-banner .account-nav .item.item a[href*='logout'],
      .account-nav .item.item .academy-banner a[href*='logout'] {
        display: none; } }
  .events-banner::before,
  .academy-banner::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: -1; }
  .events-banner [data-content-type='html'],
  .academy-banner [data-content-type='html'] {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .events-banner .btn, .events-banner a.pagebuilder-button-primary,
  .events-banner .pagebuilder-button-primary, .events-banner a.pagebuilder-button-secondary,
  .events-banner .pagebuilder-button-secondary, .events-banner .account-nav .item.item a[href*='logout'], .account-nav .item.item .events-banner a[href*='logout'],
  .academy-banner .btn,
  .academy-banner a.pagebuilder-button-primary,
  .academy-banner .pagebuilder-button-primary,
  .academy-banner a.pagebuilder-button-secondary,
  .academy-banner .pagebuilder-button-secondary,
  .academy-banner .account-nav .item.item a[href*='logout'],
  .account-nav .item.item .academy-banner a[href*='logout'] {
    margin-top: 3.4rem;
    width: 225px; }

.events-intro,
.academy-intro {
  padding: 34px 15px !important;
  text-align: center; }
  @media (max-width: 575.98px) {
    .events-intro,
    .academy-intro {
      position: relative;
      padding-left: 0 !important;
      padding-right: 0 !important;
      font-size: 1.5rem;
      text-align: left;
      max-height: 233px;
      overflow: hidden;
      margin-bottom: 5rem !important; }
      .events-intro .read-more-holder,
      .academy-intro .read-more-holder {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2; }
      .events-intro.show-more,
      .academy-intro.show-more {
        max-height: none; }
        .events-intro.show-more .read-more-holder .more,
        .academy-intro.show-more .read-more-holder .more {
          display: none; }
      .events-intro:not(.show-more)::after,
      .academy-intro:not(.show-more)::after {
        content: ' ';
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        right: 0;
        height: 120px;
        background: transparent linear-gradient(180deg, #eee0 0%, #fff 100%) 0% 0% no-repeat padding-box; }
      .events-intro:not(.show-more) .read-more-holder .less,
      .academy-intro:not(.show-more) .read-more-holder .less {
        display: none; } }
  .events-intro h1,
  .academy-intro h1 {
    margin-bottom: 1.7rem;
    font-size: 2.4rem;
    font-weight: 700; }
  .events-intro .lead,
  .academy-intro .lead {
    font-family: "Montserrat", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 400; }
  .events-intro p,
  .academy-intro p {
    max-width: 980px;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.7; }

.inner-prefooter.inner-prefooter.inner-prefooter {
  margin-bottom: 4rem !important; }
  @media (min-width: 768px) {
    .inner-prefooter.inner-prefooter.inner-prefooter {
      margin: 0 -0.4rem 6.4rem !important; } }

.inner-prefooter-text {
  margin: 3.8rem 1.9rem !important;
  padding: 34px 20px !important;
  border-style: solid !important;
  border-width: 10px !important;
  border-radius: 8px !important;
  text-align: center;
  font-family: "Montserrat", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  font-size: 2.2rem; }
  @media (max-width: 767.98px) {
    .inner-prefooter-text {
      margin-bottom: 3rem !important;
      line-height: 1.25; } }
  .inner-prefooter-text p {
    max-width: 830px;
    margin: 0 auto; }

.prefooter-block {
  position: relative;
  z-index: 0;
  justify-content: center !important;
  align-items: center;
  height: 345px;
  box-sizing: content-box;
  padding: 19px !important;
  text-align: center;
  color: #fff; }
  .prefooter-block figure {
    position: absolute;
    top: 19px;
    left: 19px;
    right: 19px;
    bottom: 19px;
    z-index: -1;
    border-radius: 2px;
    overflow: hidden; }
    @media (max-width: 575.98px) {
      .prefooter-block figure {
        top: 9px;
        bottom: 9px; } }
    .prefooter-block figure::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.66);
      z-index: 0; }
    .prefooter-block figure img {
      width: 100%;
      height: 100% !important;
      object-fit: cover; }
  .prefooter-block > div {
    max-width: 271px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px; }
  .prefooter-block p:first-child {
    line-height: 1.2;
    font-family: "Montserrat", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 700;
    font-size: 2.2rem;
    color: #fcd900; }
  .prefooter-block p:not(:last-child) {
    margin-bottom: 2.8rem; }
  .prefooter-block p:last-child {
    margin-bottom: 0; }
  .prefooter-block a {
    display: inline-block;
    color: #000;
    text-align: center;
    text-transform: none;
    text-decoration: none;
    line-height: 1.5;
    font-family: "Montserrat", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 600;
    font-size: 1.2rem;
    border-radius: 0.3rem;
    /* stylelint-disable-line */ }
  @media (max-width: 767.98px) {
    .prefooter-block {
      height: 345px;
      max-width: 408px;
      margin-left: auto !important;
      margin-right: auto !important; } }

.prefooter-masters a,
.prefooter-academy a {
  padding: 0.8rem 2.6rem;
  background: linear-gradient(180deg, #fcd900 50%, #ffba00 100%); }
  .prefooter-masters a:hover,
  .prefooter-academy a:hover {
    padding: 0.6rem 2.4rem;
    border: 2px solid #000;
    color: #000;
    background: #fff; }

.prefooter-masters p:first-child,
.prefooter-academy p:first-child {
  text-transform: uppercase; }

.prefooter-masters svg {
  margin-bottom: 3rem; }

.prefooter-twi svg {
  margin-bottom: 3.5rem; }

.prefooter-twi a {
  padding: 0.6rem 2.4rem;
  background: #fff;
  border: 2px solid #000; }
  .prefooter-twi a:hover {
    padding: 0.8rem 2.6rem;
    border: none;
    background: linear-gradient(180deg, #fcd900 50%, #ffba00 100%); }

.prefooter-academy svg {
  margin-bottom: 2rem; }

.prefooter-academy p:first-child {
  margin-bottom: 3.3rem; }

.event-search {
  margin-bottom: 4.7rem;
  padding: 2.8rem 2.6rem 2.6rem;
  background-color: #fcd900;
  text-align: center; }
  @media (max-width: 575.98px) {
    .event-search {
      margin: 0 -1.5rem 3.5rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem; } }
  .event-search h2 {
    margin-bottom: 1.5rem;
    font-weight: 700;
    font-size: 2.2rem; }
  .event-search .form-inline {
    justify-content: center; }
  @media (max-width: 767.98px) {
    .event-search .form-group {
      width: 100%;
      margin-bottom: 1.2rem; } }
  @media (max-width: 767.98px) {
    .event-search .form-control {
      width: 100%; } }
  @media (min-width: 768px) {
    .event-search .form-control {
      width: 220px;
      margin-right: 1.4rem; } }
  @media (min-width: 1024px) {
    .event-search .form-control {
      width: 330px; } }
  @media (max-width: 767.98px) {
    .event-search .btn-secondary, .event-search a.pagebuilder-button-secondary,
    .event-search .pagebuilder-button-secondary {
      width: 100%; } }
  @media (min-width: 768px) {
    .event-search .btn-secondary, .event-search a.pagebuilder-button-secondary,
    .event-search .pagebuilder-button-secondary {
      padding: 1.3rem 2.7rem; }
      .event-search .btn-secondary:hover,
      .event-search .pagebuilder-button-secondary:hover {
        padding: 1.1rem 2.5rem; } }

.event-list {
  list-style: none;
  padding-left: 0; }
  .event-list .event-list-item {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #fff;
    box-shadow: 0 3px 10px #00000026;
    border-radius: 3px;
    overflow: hidden; }
  .event-list .event-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
    padding: 2rem; }
  .event-list .event-title {
    margin-bottom: 1.5rem;
    text-transform: none;
    font-weight: 700;
    color: inherit;
    min-height: 67px; }
  .event-list .event-headline.event-headline {
    display: block;
    text-decoration: none;
    line-height: 1.25;
    font-size: 1.8rem; }
    .event-list .event-headline.event-headline:hover {
      text-decoration: underline;
      color: inherit; }
  .event-list .event-date {
    order: -1;
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    font-size: 1.2rem;
    color: #000; }
  .event-list .event-price {
    position: absolute;
    top: 2.4rem;
    right: 2.1rem;
    font-weight: 700;
    color: #fff;
    pointer-events: none; }

.amlocator-location-main .event-list li.col-md-6 {
  max-width: 100% !important; }

.event-thumbnail {
  position: relative;
  display: block;
  height: 187px;
  margin-bottom: 0;
  background-image: url("../images/noimage-placeholder.png");
  background-size: 150px 150px;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #f8f8f8; }
  .event-thumbnail .event-image {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.event-tag {
  position: absolute;
  top: 2.1rem;
  left: 2.1rem;
  z-index: 1;
  padding: 0.4rem 1rem;
  background-color: #fcd900;
  color: #000;
  border-radius: 0.3rem;
  font-weight: 700;
  font-size: 1.1rem;
  text-decoration: none !important; }
  .event-tag:hover {
    background-color: #000;
    color: #fff; }

.event-location {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: auto;
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
  color: #000; }
  .event-location .event-location-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    margin-right: 1.2rem;
    border-radius: 99px;
    border: solid 4px rgba(252, 217, 0, 0.4);
    background-color: #fcd900;
    background-clip: content-box; }
  .event-location p {
    margin-bottom: 0; }

@media (max-width: 767.98px) {
  .event-detail .event-buy-container {
    position: sticky;
    top: 0;
    background-color: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    max-width: none !important; } }

.cms-masters .breadcrumbs,
.page-layout-masters-page .breadcrumbs {
  margin-bottom: 0; }
  @media (min-width: 768px) {
    .cms-masters .breadcrumbs,
    .page-layout-masters-page .breadcrumbs {
      margin-bottom: 1rem; } }

.cms-masters .column.main,
.page-layout-masters-page .column.main {
  overflow-x: hidden; }
  .cms-masters .column.main [data-content-type='row'][data-appearance='contained'],
  .page-layout-masters-page .column.main [data-content-type='row'][data-appearance='contained'] {
    padding-left: 15px !important;
    padding-right: 15px !important; }
    @media (min-width: 768px) {
      .cms-masters .column.main [data-content-type='row'][data-appearance='contained']:last-child,
      .page-layout-masters-page .column.main [data-content-type='row'][data-appearance='contained']:last-child {
        margin-bottom: 1.5rem; } }
    @media (max-width: 767.98px) {
      .cms-masters .column.main [data-content-type='row'][data-appearance='contained']:last-child,
      .page-layout-masters-page .column.main [data-content-type='row'][data-appearance='contained']:last-child {
        padding-left: 0 !important;
        padding-right: 0 !important; } }

.cms-masters .pagebuilder-column,
.page-layout-masters-page .pagebuilder-column {
  padding: 15px !important;
  text-align: center; }
  .cms-masters .pagebuilder-column div,
  .page-layout-masters-page .pagebuilder-column div {
    height: 100%; }

.cms-masters .pagebuilder-banner-wrapper,
.page-layout-masters-page .pagebuilder-banner-wrapper {
  position: relative;
  z-index: 0;
  max-width: 378px;
  margin-left: auto;
  margin-right: auto;
  background-position: 100% 100% !important;
  background-size: 0 !important;
  background-color: #fff;
  border-radius: 0.3rem !important;
  overflow: hidden;
  text-align: center;
  font-family: "Montserrat", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  font-size: 1.8rem;
  box-shadow: 0 3px 10px #00000026; }
  .cms-masters .pagebuilder-banner-wrapper::before,
  .page-layout-masters-page .pagebuilder-banner-wrapper::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    height: 187px;
    background-image: inherit;
    background-position: center;
    background-size: 100% 100%;
    transition: background-size 0.3s; }
  .cms-masters .pagebuilder-banner-wrapper:hover::before,
  .page-layout-masters-page .pagebuilder-banner-wrapper:hover::before {
    background-size: 125% 125%; }
  .cms-masters .pagebuilder-banner-wrapper .pagebuilder-poster-overlay,
  .page-layout-masters-page .pagebuilder-banner-wrapper .pagebuilder-poster-overlay {
    padding-bottom: 28px !important; }
  .cms-masters .pagebuilder-banner-wrapper img,
  .page-layout-masters-page .pagebuilder-banner-wrapper img {
    width: 118px !important;
    height: 118px !important;
    margin-top: 8.5rem;
    margin-bottom: 1.3rem;
    background-color: #fcd900;
    border: 6px solid #fcd900;
    border-radius: 50%;
    object-fit: cover; }
  .cms-masters .pagebuilder-banner-wrapper p:not(:last-child),
  .page-layout-masters-page .pagebuilder-banner-wrapper p:not(:last-child) {
    margin-bottom: 0.6rem; }
  .cms-masters .pagebuilder-banner-wrapper p:last-child,
  .page-layout-masters-page .pagebuilder-banner-wrapper p:last-child {
    margin-bottom: 0; }
  .cms-masters .pagebuilder-banner-wrapper a,
  .page-layout-masters-page .pagebuilder-banner-wrapper a {
    display: inline-block;
    padding: 0.8rem 2.6rem;
    color: #fff;
    text-align: center;
    background: linear-gradient(180deg, #59595a 0%, black 100%);
    text-decoration: none !important;
    font-weight: 600;
    font-size: 1.2rem;
    border-radius: 0.3rem;
    /* stylelint-disable-line */ }
    .cms-masters .pagebuilder-banner-wrapper a:hover,
    .page-layout-masters-page .pagebuilder-banner-wrapper a:hover {
      padding: 0.6rem 2.4rem;
      border: 2px solid #000;
      color: #000;
      background: #fff; }
  .cms-masters .pagebuilder-banner-wrapper button,
  .page-layout-masters-page .pagebuilder-banner-wrapper button {
    position: absolute;
    top: 2rem;
    left: 2.4rem;
    margin: 0;
    background-color: #fcd900;
    color: #000;
    border: none;
    padding: 1px 10px;
    font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1.1rem; }

.masters-banner {
  position: relative;
  display: flex !important;
  align-items: center;
  justify-content: center !important;
  z-index: 0;
  height: 307px;
  margin-bottom: 3.5rem !important;
  background-position: center !important; }
  .masters-banner::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.4); }
  .masters-banner div {
    height: auto !important; }
  @media (max-width: 767.98px) {
    .masters-banner {
      height: 220px;
      margin-left: -15px !important;
      margin-right: -15px !important;
      width: calc(100% + 30px) !important;
      max-width: none;
      flex-basis: auto; } }

.masters-intro {
  margin: 0 auto 4rem !important;
  line-height: 1.6;
  font-size: 1.5rem; }
  .masters-intro h1 {
    margin-bottom: 2.5rem;
    font-weight: 700;
    font-size: 2.4rem; }
  .masters-intro p {
    margin-bottom: 0; }
  .masters-intro ~ .pagebuilder-column-group {
    margin-left: -15px;
    margin-right: -15px; }
  @media (min-width: 768px) {
    .masters-intro {
      max-width: 771px;
      text-align: center;
      margin-bottom: 6rem !important; } }

.masters-midbar.masters-midbar.masters-midbar {
  margin: 3.7rem 0 !important;
  padding: 0 !important; }
  @media (max-width: 767.98px) {
    .masters-midbar.masters-midbar.masters-midbar {
      margin-left: -15px !important;
      margin-right: -15px !important; } }
  .masters-midbar.masters-midbar.masters-midbar .pagebuilder-column {
    padding: 4rem 15px !important; }
    @media (min-width: 768px) {
      .masters-midbar.masters-midbar.masters-midbar .pagebuilder-column {
        padding: 6rem 15px !important; } }
    .masters-midbar.masters-midbar.masters-midbar .pagebuilder-column:first-child, .masters-midbar.masters-midbar.masters-midbar .pagebuilder-column:last-child {
      position: relative; }
      .masters-midbar.masters-midbar.masters-midbar .pagebuilder-column:first-child::before, .masters-midbar.masters-midbar.masters-midbar .pagebuilder-column:last-child::before {
        content: '';
        position: absolute;
        top: 0;
        background-color: inherit;
        width: 100vw;
        height: 100%; }
    .masters-midbar.masters-midbar.masters-midbar .pagebuilder-column:first-child {
      background-color: #fcd900; }
      .masters-midbar.masters-midbar.masters-midbar .pagebuilder-column:first-child::before {
        right: 100%; }
    .masters-midbar.masters-midbar.masters-midbar .pagebuilder-column:last-child {
      background-color: #000; }
      .masters-midbar.masters-midbar.masters-midbar .pagebuilder-column:last-child::before {
        left: 100%; }
    .masters-midbar.masters-midbar.masters-midbar .pagebuilder-column div {
      height: auto; }
  .masters-midbar.masters-midbar.masters-midbar .pagebuilder-banner-wrapper {
    margin-top: 3.6rem; }
    @media (min-width: 768px) {
      .masters-midbar.masters-midbar.masters-midbar .pagebuilder-banner-wrapper {
        margin-top: 4.9rem; } }
  .masters-midbar.masters-midbar.masters-midbar ~ div[data-content-type='row'] .pagebuilder-column-group {
    margin-left: -15px;
    margin-right: -15px; }

.masters-get-in-touch.masters-get-in-touch.masters-get-in-touch {
  position: relative;
  z-index: 0;
  justify-content: center !important;
  align-items: center;
  text-transform: uppercase;
  line-height: 1.2;
  font-family: "Montserrat", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  font-size: 2.2rem;
  color: #fcd900; }
  .masters-get-in-touch.masters-get-in-touch.masters-get-in-touch::before,
  .masters-get-in-touch.masters-get-in-touch.masters-get-in-touch figure {
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 0.3rem;
    overflow: hidden; }
  .masters-get-in-touch.masters-get-in-touch.masters-get-in-touch::before {
    content: '';
    z-index: -1;
    background: rgba(0, 0, 0, 0.64); }
  .masters-get-in-touch.masters-get-in-touch.masters-get-in-touch > div {
    max-width: 271px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px; }
  .masters-get-in-touch.masters-get-in-touch.masters-get-in-touch p:not(:last-child) {
    margin-bottom: 2.8rem; }
  .masters-get-in-touch.masters-get-in-touch.masters-get-in-touch p:last-child {
    margin-bottom: 0; }
  .masters-get-in-touch.masters-get-in-touch.masters-get-in-touch svg {
    margin-bottom: 3rem; }
  .masters-get-in-touch.masters-get-in-touch.masters-get-in-touch figure {
    z-index: -2; }
  .masters-get-in-touch.masters-get-in-touch.masters-get-in-touch img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover; }
  .masters-get-in-touch.masters-get-in-touch.masters-get-in-touch a {
    display: inline-block;
    padding: 0.8rem 2.6rem;
    color: #000;
    text-align: center;
    background: linear-gradient(180deg, #fcd900 50%, #ffba00 100%);
    text-transform: none;
    text-decoration: none !important;
    line-height: 1.5;
    font-weight: 600;
    font-size: 1.2rem;
    border-radius: 0.3rem;
    /* stylelint-disable-line */ }
    .masters-get-in-touch.masters-get-in-touch.masters-get-in-touch a:hover {
      padding: 0.6rem 2.4rem;
      border: 2px solid #000;
      color: #000;
      background: #fff; }
  @media (max-width: 767.98px) {
    .masters-get-in-touch.masters-get-in-touch.masters-get-in-touch {
      height: 345px;
      max-width: 408px;
      margin-left: auto !important;
      margin-right: auto !important; } }

.profile-banner.profile-banner.profile-banner {
  position: relative;
  height: 220px;
  margin-bottom: 5.7rem !important;
  padding-top: 8.6rem !important;
  background-position: center !important;
  z-index: 0;
  color: #fff; }
  @media (min-width: 768px) {
    .profile-banner.profile-banner.profile-banner {
      height: 307px;
      padding-top: 16rem !important; } }
  .profile-banner.profile-banner.profile-banner::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.4); }
  .profile-banner.profile-banner.profile-banner h1 {
    font-family: "Montserrat", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 700;
    font-size: 2.4rem;
    text-align: center; }
  .profile-banner.profile-banner.profile-banner figure {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    line-height: 0;
    width: 118px;
    height: 118px;
    margin: -56px auto 0 !important;
    background-color: #fcd900;
    border: 6px solid #fcd900 !important;
    border-radius: 50%; }
  .profile-banner.profile-banner.profile-banner img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover; }

.profile-content {
  max-width: 565px;
  margin: 0 auto 2.7rem !important;
  text-align: center; }
  @media (max-width: 767.98px) {
    .profile-content {
      margin-bottom: 0 !important;
      text-align: left !important; } }

.profile-social {
  margin-bottom: 0.5rem !important; }
  .profile-social ul {
    display: flex;
    justify-content: center;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0; }
  .profile-social li {
    display: inline-block;
    width: 33px;
    height: 33px;
    margin: 0 1rem 1rem; }
  .profile-social a {
    display: block;
    width: 100%;
    height: 100%;
    font-size: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain; }
    .profile-social a[href*='facebook'] {
      background-image: url("../images/facebook-dark.svg"); }
    .profile-social a[href*='instagram'] {
      background-image: url("../images/instagram-dark.svg"); }
    .profile-social a[href*='youtube'] {
      background-image: url("../images/youtube-dark.svg"); }

.profile-link.profile-link {
  margin-bottom: 2.5rem !important;
  text-align: center; }
  .profile-link.profile-link a {
    display: inline-block;
    padding: 0.8rem 2.6rem;
    color: #fff;
    text-align: center;
    background: linear-gradient(180deg, #59595a 0%, black 100%);
    text-decoration: none !important;
    font-weight: 600;
    font-size: 1.2rem;
    border-radius: 0.3rem;
    /* stylelint-disable-line */ }
    .profile-link.profile-link a:hover {
      padding: 0.6rem 2.4rem;
      border: 2px solid #000;
      color: #000;
      background: #fff; }

.profile-slider {
  width: 100%;
  max-width: 965px;
  margin: 0 auto 4.3rem !important; }
  @media (max-width: 767.98px) {
    .profile-slider {
      padding-left: 15px !important;
      padding-right: 15px !important; } }
  .profile-slider div {
    height: auto !important; }
  .profile-slider h2 {
    margin-bottom: 4.4rem;
    font-weight: 700;
    font-size: 2.2rem; }
    @media (max-width: 575.98px) {
      .profile-slider h2 {
        margin-bottom: 2rem;
        text-align: left; } }
  .profile-slider .pagebuilder-poster-overlay {
    height: 303px !important; }
    @media (min-width: 768px) {
      .profile-slider .pagebuilder-poster-overlay {
        height: 603px !important; } }
  .profile-slider .pagebuilder-slide-wrapper {
    background-position: center !important; }
  .profile-slider .slick-list {
    margin-bottom: 3.3rem; }
  .profile-slider .slick-dots {
    position: relative;
    overflow: visible;
    counter-reset: section; }
    .profile-slider .slick-dots li {
      position: static;
      counter-increment: section; }
      .profile-slider .slick-dots li.slick-active::after {
        content: counter(section) " / ";
        position: absolute;
        top: 100%;
        right: 50%;
        margin-top: 1rem;
        margin-right: 0.4rem; }
    .profile-slider .slick-dots button {
      position: relative; }
    .profile-slider .slick-dots::after {
      content: counter(section);
      position: absolute;
      top: 100%;
      left: 50%;
      margin-top: 1rem; }

.profile-blog {
  position: relative;
  margin-bottom: 3.3rem !important;
  padding: 0 1.5rem 0 !important; }
  @media (min-width: 576px) {
    .profile-blog {
      padding: 5.3rem 1.5rem 1rem !important; }
      .profile-blog, .profile-blog::before, .profile-blog::after {
        background-color: #f0f0f0; }
      .profile-blog::before, .profile-blog::after {
        content: '';
        position: absolute;
        top: 0;
        height: 100%;
        width: 100vw; }
      .profile-blog::before {
        left: 100%; }
      .profile-blog::after {
        right: 100%; } }
  @media (min-width: 576px) and (max-width: 1023.98px) {
    .profile-blog {
      padding-left: 0 !important;
      padding-right: 0 !important; } }
  .profile-blog h2 {
    margin-bottom: 4.4rem;
    font-weight: 700;
    font-size: 2.2rem;
    text-align: center; }
    @media (max-width: 575.98px) {
      .profile-blog h2 {
        margin-bottom: 2rem;
        text-align: left; } }
  .profile-blog .amblog-post-item {
    background-color: #fff; }
  .profile-blog .amblog-element-block {
    border: none; }
  .profile-blog .amblog-post-container {
    flex: 1 1 auto;
    max-width: 100%;
    height: 100%; }
  .profile-blog .blog-post .amblog-title {
    margin-bottom: 0;
    padding-left: 0; }
  .profile-blog .slick-list {
    margin-bottom: 2rem; }

.profile-products h2 {
  margin-bottom: 4.4rem;
  font-weight: 700;
  font-size: 2.2rem; }

.profile-products .slick-list {
  margin-bottom: 2rem; }

@media (max-width: 575.98px) {
  .profile-products h2 {
    margin-bottom: 2rem;
    text-align: left; }
  .profile-products .product-items {
    display: flex;
    flex-wrap: wrap; }
  .profile-products .product-item {
    visibility: visible !important;
    display: block !important; } }

.masters-form-intro {
  max-width: 535px;
  margin: 3rem auto 0 !important; }
  @media (min-width: 768px) {
    .masters-form-intro {
      margin-bottom: 3rem !important;
      font-family: "Montserrat", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 1.6rem;
      text-align: center; } }
  @media (max-width: 767.98px) {
    .masters-form-intro {
      padding: 0 1.5rem !important; }
      .masters-form-intro + div .teds-border {
        border: none !important; }
      .masters-form-intro + div button {
        width: 100%; } }
  .masters-form-intro h1 {
    margin-bottom: 2.4rem;
    font-weight: 700;
    font-size: 2.4rem; }
  .masters-form-intro p {
    margin-bottom: 0; }

.modal-popup.modal-slide .modal-inner-wrap[class] {
  background-color: #fff;
  max-height: 100%; }

@media (min-width: 768px) {
  .amblog-container-list .slick-list {
    min-width: 100%;
    margin-left: auto;
    margin-right: auto; }
  .amblog-container-list .slick-track {
    min-width: 100% !important;
    width: max-content !important;
    justify-content: center; } }

.master-pager a.action {
  text-decoration: none !important;
  position: relative;
  padding: 5px 30px;
  min-width: 30%; }
  .master-pager a.action:hover {
    text-decoration: underline !important; }

.master-pager .master-name {
  font-size: 1.5rem; }

.master-pager .action.next::after,
.master-pager .action.previous::after {
  content: ' ';
  position: absolute;
  right: 5px;
  display: block;
  top: 10px;
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center; }

.master-pager .action.next::after {
  right: 5px;
  background-image: url(../images/right.svg); }

.master-pager .action.previous::after {
  left: 5px;
  background-image: url(../images/right.svg);
  transform: rotate(180deg); }

.product.data.items {
  z-index: auto; }
  .product.data.items::before, .product.data.items::after {
    display: none; }
  .product.data.items p {
    width: 100% !important; }
  .product.data.items iframe {
    max-width: 100%; }
  @media (min-width: 768px) {
    .product.data.items > .item.title {
      width: 20%;
      margin: 0;
      padding: 0 2px; }
      .product.data.items > .item.title > .switch,
      .product.data.items > .item.title > .switch:visited {
        height: 60px;
        line-height: 44px;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        color: #000;
        background-color: #ddd;
        width: 100%;
        border: none;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        padding: 8px 20px;
        z-index: auto;
        text-decoration: none !important; }
      .product.data.items > .item.title.active > .switch,
      .product.data.items > .item.title:not(.disabled) > .switch:hover,
      .product.data.items > .item.title:not(.disabled) > .switch:focus {
        background-color: #000;
        color: #fff; }
      .product.data.items > .item.title:first-child {
        padding-left: 0; }
      .product.data.items > .item.title:nth-last-child(2) {
        padding-right: 0; }
    .product.data.items > .item.content {
      margin-top: 60px;
      border-top: 3px solid #000; } }
  @media (max-width: 767.98px) {
    .product.data.items > .item.title {
      margin-left: -15px;
      margin-right: -15px;
      width: auto; }
      .product.data.items > .item.title > .switch,
      .product.data.items > .item.title > .switch:visited {
        background: #fff;
        border: none;
        border-bottom: solid 1px #ddd;
        color: #000;
        padding: 8px 20px;
        position: relative;
        height: auto;
        font-size: 1.5rem;
        line-height: 2rem;
        text-decoration: none !important; }
        .product.data.items > .item.title > .switch:hover,
        .product.data.items > .item.title > .switch:visited:hover {
          text-decoration: underline !important; }
        .product.data.items > .item.title > .switch::after,
        .product.data.items > .item.title > .switch:visited::after {
          position: absolute;
          content: ' ';
          right: 30px;
          top: 9px;
          background-image: url("../images/plus.svg");
          width: 10px;
          height: 10px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain; }
      .product.data.items > .item.title.active > .switch,
      .product.data.items > .item.title:not(.disabled) > .switch:hover,
      .product.data.items > .item.title:not(.disabled) > .switch:focus {
        padding: 8px 20px;
        color: #000; }
        .product.data.items > .item.title.active > .switch::after,
        .product.data.items > .item.title:not(.disabled) > .switch:hover::after,
        .product.data.items > .item.title:not(.disabled) > .switch:focus::after {
          background-image: url("../images/minus.svg"); }
    .product.data.items > .item.content {
      padding: 15px 5px 15px;
      border: none; }
      .product.data.items > .item.content[id='reviews'] {
        overflow-x: hidden;
        margin-left: -1.5rem;
        margin-right: -1.5rem;
        padding-left: 2rem;
        padding-right: 2rem; } }

@media (max-width: 767.98px) {
  .product-info-main {
    margin-top: 1rem; } }

.product-info-main .page-title {
  font-size: 1.8rem; }
  @media (min-width: 1024px) {
    .product-info-main .page-title {
      font-size: 3.4rem; } }

@media (max-width: 575.98px) {
  .product-info-main .price-box {
    margin-top: 0; } }

@media (max-width: 575.98px) {
  .product-info-main .product-reviews-summary {
    position: relative;
    z-index: 1;
    display: block;
    margin: 0 -1.5rem 0;
    padding: 0 1.8rem;
    background: #fff; } }

.product-info-main .box-tocart {
  display: block;
  margin-top: 0; }
  .product-info-main .box-tocart .fieldset {
    margin: 0; }
    .product-info-main .box-tocart .fieldset,
    .product-info-main .box-tocart .fieldset .product-addto-links {
      display: flex; }
    .product-info-main .box-tocart .fieldset .product-addto-links {
      margin-top: 0;
      margin-bottom: 0; }
      @media (min-width: 1024px) {
        .product-info-main .box-tocart .fieldset .product-addto-links {
          margin-right: 1.8rem; } }
  .product-info-main .box-tocart .learn-more {
    margin-top: 1rem; }
  .product-info-main .box-tocart .actions {
    flex-grow: 1;
    padding-top: 0; }
  @media (max-width: 575.98px) {
    .product-info-main .box-tocart .btn-primary,
    .product-info-main .box-tocart .pagebuilder-button-primary {
      padding-left: 1.3rem;
      padding-right: 1.3rem; } }
  .product-info-main .box-tocart .btn-action-secondary {
    margin-left: 1.5rem !important; }
    @media (min-width: 576px) {
      .product-info-main .box-tocart .btn-action-secondary {
        margin-left: 1.8rem !important; } }
    @media (min-width: 1024px) {
      .product-info-main .box-tocart .btn-action-secondary {
        margin-left: 2.5rem !important; } }

.product-info-main .special-price {
  margin: 0; }

@media (max-width: 575.98px) {
  .product-info-main .product-info-price {
    position: sticky;
    top: 0;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    padding: 0 1.5rem 0.1rem;
    background: #fff;
    box-shadow: 0 7px 7px rgba(0, 0, 0, 0.12);
    z-index: 30; } }

.product-info-main .product-payment-methods {
  display: flex;
  flex-wrap: wrap; }
  @media (max-width: 575.98px) {
    .product-info-main .product-payment-methods {
      margin: 0 -1.5rem; } }
  @media (min-width: 576px) {
    .product-info-main .product-payment-methods {
      margin: 1rem 0 0;
      padding: 1rem 0;
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      text-align: center; } }
  .product-info-main .product-payment-methods .product-payment-method {
    flex: 0 0 100%;
    display: flex; }
    .product-info-main .product-payment-methods .product-payment-method p button {
      display: inline;
      font-size: inherit;
      font-family: inherit;
      text-decoration: underline; }
      .product-info-main .product-payment-methods .product-payment-method p button:hover {
        text-decoration: none; }
    .product-info-main .product-payment-methods .product-payment-method:empty {
      display: none; }
    @media (max-width: 575.98px) {
      .product-info-main .product-payment-methods .product-payment-method {
        align-items: center;
        justify-content: center;
        padding: 8px;
        border-top: 1px solid #ddd; }
        .product-info-main .product-payment-methods .product-payment-method:last-child {
          border-bottom: 1px solid #ddd; }
        .product-info-main .product-payment-methods .product-payment-method svg {
          flex-shrink: 0;
          margin-right: 1rem; }
        .product-info-main .product-payment-methods .product-payment-method p {
          margin-bottom: 0; }
          .product-info-main .product-payment-methods .product-payment-method p ~ a {
            margin-left: 0.5rem; } }
    @media (min-width: 576px) {
      .product-info-main .product-payment-methods .product-payment-method {
        flex: 0 0 50%;
        flex-direction: column;
        align-items: center; }
        .product-info-main .product-payment-methods .product-payment-method:first-child {
          border-right: 1px solid #ddd; }
        .product-info-main .product-payment-methods .product-payment-method p {
          max-width: 180px;
          margin: auto auto 0;
          padding-top: 10px; } }
    .product-info-main .product-payment-methods .product-payment-method span span:first-child {
      font-weight: 700; }

@media (max-width: 575.98px) {
  .product.info.detailed::before {
    content: 'Product information';
    display: block;
    text-align: center;
    background: #000;
    color: #fff;
    height: 40px;
    line-height: 40px;
    font-family: "Montserrat", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 700;
    font-size: 16px;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    margin-bottom: 0.5rem; } }

.product.info.detailed::after {
  content: '';
  display: table;
  clear: both; }

.product.info.detailed .additional-attributes {
  width: 100%; }
  .product.info.detailed .additional-attributes > tbody > tr > th,
  .product.info.detailed .additional-attributes > tbody > tr > td {
    border: solid 1px #e5e5e5;
    font-size: 13px;
    padding: 8px 15px; }
  .product.info.detailed .additional-attributes > tbody > tr > th {
    border-right: none; }
  .product.info.detailed .additional-attributes > tbody > tr > td {
    border-left: none; }
  .product.info.detailed .additional-attributes tr:nth-child(odd) th,
  .product.info.detailed .additional-attributes tr:nth-child(odd) td {
    background-color: rgba(221, 221, 221, 0.2); }

@media (max-width: 767.98px) {
  .catalog-product-view .product.short-description {
    order: 0; } }

.header-after.product-advertlist {
  border-bottom: none; }
  @media (max-width: 1023.98px) {
    .header-after.product-advertlist {
      margin: 1.5rem 0;
      padding: 1.5rem;
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd; } }
  @media (max-width: 575.98px) {
    .header-after.product-advertlist {
      margin-left: -1.5rem;
      margin-right: -1.5rem; } }
  @media (max-width: 1239.98px) {
    .header-after.product-advertlist ul {
      flex-wrap: wrap; } }
  .header-after.product-advertlist ul li {
    display: block;
    text-align: center; }
    @media (min-width: 1240px) {
      .header-after.product-advertlist ul li {
        flex: 0 0 25%; } }
    @media (max-width: 1239.98px) {
      .header-after.product-advertlist ul li {
        width: 50%;
        margin-top: 10px;
        margin-bottom: 10px;
        padding-left: 20px;
        padding-right: 20px;
        line-height: 1.45;
        font-size: 1.5rem; } }
    .header-after.product-advertlist ul li a::before {
      display: block;
      margin: 5px auto; }
    .header-after.product-advertlist ul li a {
      text-decoration: none !important;
      flex-direction: column; }
      .header-after.product-advertlist ul li a:hover {
        text-decoration: underline !important; }

.catalog-product-view .block.related {
  clear: both; }

.request-price-match-holder a:hover {
  cursor: pointer; }

@media (max-width: 767.98px) {
  .request-price-match-holder {
    border-bottom: 1px solid #ddd; } }

@media (max-width: 575.98px) {
  .request-price-match-holder {
    margin-left: -1.5rem;
    margin-right: -1.5rem; } }

@media (max-width: 767.98px) {
  .grid-relateds {
    margin-top: 3rem;
    margin-bottom: 2rem; } }

.grid-relateds .block-title {
  background-color: #000;
  color: #fff;
  text-align: center; }
  @media (max-width: 575.98px) {
    .grid-relateds .block-title {
      margin-left: -1.5rem;
      margin-right: -1.5rem; } }
  .grid-relateds .block-title h2 {
    padding: 1.05rem;
    font-weight: 700;
    font-size: 1.6rem; }

.grid-relateds .products {
  max-width: 780px;
  margin-left: auto;
  margin-right: auto; }
  @media (max-width: 575.98px) {
    .grid-relateds .products {
      margin-bottom: 1.5rem; }
      .grid-relateds .products .products {
        margin-bottom: 0; } }

.grid-relateds .product-item-info {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%; }

.grid-relateds .product-item-details {
  flex-grow: 1;
  display: flex;
  flex-direction: column; }

.grid-relateds .product-item-actions {
  margin-top: auto;
  margin-bottom: 2rem; }
  @media (max-width: 575.98px) {
    .grid-relateds .product-item-actions {
      margin-bottom: 1rem; } }

.grid-relateds .price-box {
  margin-bottom: 0; }

.grid-relateds .price {
  display: block;
  text-align: center; }

.grid-relateds .pricing.pricing {
  flex-grow: 1;
  padding-right: 0; }

.grid-relateds .actions-secondary {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1; }

.grid-relateds .product-item,
.grid-relateds .product-item-info {
  height: 100%; }

@media (max-width: 575.98px) {
  .grid-relateds .product-item-photo {
    margin-top: 5rem; } }

.bundle-options-container .product-add-form {
  display: block; }
  .bundle-options-container .product-add-form .bundle-options-wrapper {
    float: none;
    width: 100%; }
    .bundle-options-container .product-add-form .bundle-options-wrapper .fieldset {
      outline: none !important;
      margin-bottom: 0; }
    .bundle-options-container .product-add-form .bundle-options-wrapper .custom-control.custom-radio .custom-control-label {
      font-weight: 700; }
    .bundle-options-container .product-add-form .bundle-options-wrapper .custom-control.custom-radio .custom-control-label::after {
      content: '';
      margin: 0; }
    .bundle-options-container .product-add-form .bundle-options-wrapper .custom-control.custom-radio .custom-control-label::before,
    .bundle-options-container .product-add-form .bundle-options-wrapper .custom-control.custom-radio .custom-control-label::after {
      top: 10px; }
  .bundle-options-container .product-add-form .product-options-wrapper {
    float: none;
    width: 100%;
    outline: none !important;
    margin-bottom: 0; }
    .bundle-options-container .product-add-form .product-options-wrapper .field.option .qty-holder {
      display: none; }
    .bundle-options-container .product-add-form .product-options-wrapper p.required {
      display: none; }
    .bundle-options-container .product-add-form .product-options-wrapper .custom-control-label::after, .bundle-options-container .product-add-form .product-options-wrapper .custom-control-label::before {
      top: 0; }
  .bundle-options-container .product-add-form .block-bundle-summary {
    float: none;
    width: 100%;
    top: 0 !important;
    background-color: transparent;
    padding: 0; }
    .bundle-options-container .product-add-form .block-bundle-summary > .title {
      display: none; }
    .bundle-options-container .product-add-form .block-bundle-summary .product-image-container {
      display: none; }
    .bundle-options-container .product-add-form .block-bundle-summary .product-details .product.name {
      display: none; }
    .bundle-options-container .product-add-form .block-bundle-summary .bundle-summary {
      display: none; }
    .bundle-options-container .product-add-form .block-bundle-summary .available.stock {
      display: none; }

.bundle-actions {
  display: none; }
  .bundle-actions #bundle-slide {
    display: none; }

.product-options-wrapper .field.configurable label.label {
  margin: 0 0.9rem !important;
  padding: 0 0.9rem !important; }

.product-options-wrapper .field.configurable .control {
  width: 100% !important; }

.catalog-product-view .products-wrapper.products-grid .simple-product-wrapper {
  width: 100%; }

.product-promo-container .bluefoot-row.row.bluefoot-entity,
.product-promo-container .bluefoot-wrapper.container.row,
.product-promo-container .bluefoot-wrapper.row.navigation,
.product-promo-container .bluefoot-wrapper.row.breadcrumb,
.product-promo-container .page-header .bluefoot-wrapper.row.header.panel,
.page-header .product-promo-container .bluefoot-wrapper.row.header.panel,
.product-promo-container .bluefoot-wrapper.row.header.content,
.product-promo-container .bluefoot-wrapper.row.footer-container,
.product-promo-container .page-wrapper > .bluefoot-wrapper.row.widget,
.product-promo-container .page-wrapper > .bluefoot-wrapper.row.page-bottom,
.product-promo-container .bluefoot-wrapper.row.block.category.event,
.product-promo-container .bluefoot-wrapper.row.top-container,
.product-promo-container .bluefoot-wrapper.row.page-main,
.product-promo-container .cms-home .page-main .bluefoot-wrapper.row.page.messages,
.cms-home .page-main .product-promo-container .bluefoot-wrapper.row.page.messages,
.product.attribute.overview .bluefoot-row.row.bluefoot-entity,
.product.attribute.overview .bluefoot-wrapper.container.row,
.product.attribute.overview .bluefoot-wrapper.row.navigation,
.product.attribute.overview .bluefoot-wrapper.row.breadcrumb,
.product.attribute.overview .page-header .bluefoot-wrapper.row.header.panel,
.page-header .product.attribute.overview .bluefoot-wrapper.row.header.panel,
.product.attribute.overview .bluefoot-wrapper.row.header.content,
.product.attribute.overview .bluefoot-wrapper.row.footer-container,
.product.attribute.overview .page-wrapper > .bluefoot-wrapper.row.widget,
.product.attribute.overview .page-wrapper > .bluefoot-wrapper.row.page-bottom,
.product.attribute.overview .bluefoot-wrapper.row.block.category.event,
.product.attribute.overview .bluefoot-wrapper.row.top-container,
.product.attribute.overview .bluefoot-wrapper.row.page-main,
.product.attribute.overview .cms-home .page-main .bluefoot-wrapper.row.page.messages,
.cms-home .page-main .product.attribute.overview .bluefoot-wrapper.row.page.messages {
  margin-left: 0;
  margin-right: 0; }

.product-promo-container .bluefoot-wrapper,
.product.attribute.overview .bluefoot-wrapper {
  margin-bottom: 1.5rem; }

.cash-back-offer-wrapper img {
  display: block; }

.ampickupmsi-main-container {
  background: #fff !important;
  border: 10px solid #fcd900;
  border-radius: 8px;
  padding-left: 0;
  padding-right: 0; }
  .ampickupmsi-main-container .ampickupmsi-location {
    border-bottom: 1px solid #ddd; }
    .ampickupmsi-main-container .ampickupmsi-location:hover {
      background: #aaa !important; }
    .ampickupmsi-main-container .ampickupmsi-location:last-child {
      border-bottom: 0; }

@media (min-width: 1240px) {
  .product-info-main.product-sticky .page-title-wrapper.product,
  .product-info-main.product-sticky .product-info-price {
    position: fixed;
    top: 0;
    z-index: 99;
    min-height: 52px; }
  .product-info-main.product-sticky .page-title-wrapper.product::before {
    content: ' ';
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    position: absolute;
    background-color: #fff;
    border-bottom: 1px solid #00000029; }
  .product-info-main.product-sticky .page-title-wrapper.product {
    left: 0;
    width: 50vw;
    padding-left: calc((100vw - 1190px) / 2); }
    .product-info-main.product-sticky .page-title-wrapper.product .page-title {
      margin: 2px 0;
      font-size: 3rem;
      position: relative;
      z-index: 1; }
  .product-info-main.product-sticky .product-info-price {
    right: 0;
    width: 50vw;
    display: flex;
    justify-content: flex-end;
    padding-right: calc((100vw - 1190px) / 2); }
    body.page-product-bundle .product-info-main.product-sticky .product-info-price {
      justify-content: flex-start; }
    .product-info-main.product-sticky .product-info-price .price-box {
      margin: 2px 0; }
      .product-info-main.product-sticky .product-info-price .price-box .price-label {
        display: none; }
      .product-info-main.product-sticky .product-info-price .price-box [data-price-type='oldPrice'] {
        display: none; }
      .product-info-main.product-sticky .product-info-price .price-box .standard-price:not(.strikethrough-price) [data-price-type='oldPrice'] {
        display: inline; }
      .product-info-main.product-sticky .product-info-price .price-box .price-from,
      .product-info-main.product-sticky .product-info-price .price-box .price-to {
        margin: 3px 0; }
        .product-info-main.product-sticky .product-info-price .price-box .price-from .price-label,
        .product-info-main.product-sticky .product-info-price .price-box .price-to .price-label {
          display: inline; }
    .product-info-main.product-sticky .product-info-price .product-add-form .box-tocart {
      display: flex;
      flex-direction: row;
      margin: 2px 0; }
      .product-info-main.product-sticky .product-info-price .product-add-form .box-tocart .input-text.qty {
        height: auto; }
      .product-info-main.product-sticky .product-info-price .product-add-form .box-tocart .field.qty {
        display: flex; }
      .product-info-main.product-sticky .product-info-price .product-add-form .box-tocart .learn-more {
        display: none; }
      .product-info-main.product-sticky .product-info-price .product-add-form .box-tocart .action-buttons {
        display: flex; }
        .product-info-main.product-sticky .product-info-price .product-add-form .box-tocart .action-buttons .product-addto-links {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 0; }
          .product-info-main.product-sticky .product-info-price .product-add-form .box-tocart .action-buttons .product-addto-links .action span {
            display: none; }
  body.page-product-bundle .product-info-main.product-sticky .product-info-price .price-box {
    display: none; }
  body.page-product-bundle .product-info-main.product-sticky .price-box.price-configured_price {
    position: fixed;
    top: 0;
    z-index: 99;
    min-height: 52px;
    right: 0;
    width: 50vw;
    display: flex;
    justify-content: flex-start;
    padding-right: calc((100vw - 1190px) / 2);
    margin: 0; }
    body.page-product-bundle .product-info-main.product-sticky .price-box.price-configured_price .price {
      font-size: 3rem;
      line-height: 1.5;
      vertical-align: middle; }
  body.page-product-bundle .product-info-main.product-sticky .box-tocart {
    position: fixed;
    top: 0;
    z-index: 99;
    min-height: 52px;
    right: 0;
    width: 30vw;
    display: flex;
    justify-content: flex-end;
    padding-right: calc((100vw - 1190px) / 2);
    padding-top: 2px;
    padding-bottom: 2px;
    margin: 0; }
    body.page-product-bundle .product-info-main.product-sticky .box-tocart .field.qty .input-text {
      height: 48px; }
    body.page-product-bundle .product-info-main.product-sticky .box-tocart .actions {
      margin-bottom: 0; }
    body.page-product-bundle .product-info-main.product-sticky .box-tocart .action-buttons {
      display: flex; }
      body.page-product-bundle .product-info-main.product-sticky .box-tocart .action-buttons .product-addto-links {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0; }
        body.page-product-bundle .product-info-main.product-sticky .box-tocart .action-buttons .product-addto-links .action span {
          display: none; }
  body.page-product-configurable .product-info-main.product-sticky .product-info-price .price-box .price-label {
    display: none !important; }
  body.page-product-configurable .product-info-main.product-sticky .product-info-price .price-box .price {
    margin-right: 20px; }
  body.page-product-configurable .product-info-main.product-sticky .product-options-wrapper {
    height: 0;
    overflow: hidden; } }

@media (min-width: 1240px) {
  body.page-product-bundle .product-info-main:not(.product-sticky) .price-box.price-configured_price {
    display: none !important; } }

@media (max-width: 1239.98px) {
  body.page-product-bundle .price-box.price-configured_price {
    display: none !important; } }

@media (max-width: 575.98px) {
  body.page-product-bundle .product-info-price {
    position: static;
    box-shadow: none; } }

.discontinued-product-holder .disc-title {
  font-weight: bold;
  font-size: 1.4rem;
  margin-left: auto;
  padding: 1rem;
  border-radius: 15px;
  background-color: #ddd;
  z-index: 1;
  position: relative; }
  @media (min-width: 1024px) {
    .discontinued-product-holder .disc-title {
      text-align: right;
      margin-top: -7rem;
      width: 65%;
      font-size: 1.8rem; } }

.discontinued-product-holder .img-holder {
  text-align: center; }
  .discontinued-product-holder .img-holder img {
    max-width: 300px; }

.discontinued-product-holder .rep-title {
  font-weight: bold; }
  .discontinued-product-holder .rep-title a {
    text-decoration: none; }

.discontinued-product-holder .pricing {
  font-weight: bold;
  font-size: 1.8rem; }
  @media (min-width: 1024px) {
    .discontinued-product-holder .pricing {
      font-size: 2.4rem; } }

@media (min-width: 1240px) {
  .stick-tab-title-holder {
    position: fixed;
    top: 52px;
    z-index: 300;
    padding-top: 10px;
    left: 0;
    right: 0;
    background-color: #fff;
    box-shadow: 0 3px 6px #00000029; }
    .stick-tab-title-holder a.tab-link {
      font-size: 1.6rem;
      line-height: 40px;
      font-weight: 700;
      display: inline-block;
      width: 232px;
      height: 40px;
      border-radius: 3px 3px 0 0;
      margin-right: 5px;
      text-decoration: none !important;
      text-align: center; }
      .stick-tab-title-holder a.tab-link:hover, .stick-tab-title-holder a.tab-link.active {
        background-color: #000;
        color: #fff; } }

@media (max-width: 1239.98px) {
  .stick-tab-title-holder {
    display: none !important; } }

.product_recommendations_below-main-content {
  display: block; }
  .product_recommendations_below-main-content .grid-relateds {
    display: block; }
    .product_recommendations_below-main-content .grid-relateds .products {
      justify-content: center !important;
      max-width: 1000px; }
      .product_recommendations_below-main-content .grid-relateds .products > div {
        width: 25%; }
        @media (max-width: 1239.98px) {
          .product_recommendations_below-main-content .grid-relateds .products > div {
            width: 50%; } }
        @media (max-width: 575.98px) {
          .product_recommendations_below-main-content .grid-relateds .products > div {
            width: 70%; } }
        .product_recommendations_below-main-content .grid-relateds .products > div .simple-product-wrapper {
          width: 100%; }

@media (max-width: 575.98px) {
  .humm-price-info-widget .humm-description .humm-more-info.humm-more-info {
    font-size: 1.3rem; } }

.studio19-wrapper div {
  font-size: inherit !important; }

.box-tocart .qty .control {
  display: flex;
  align-items: center; }
  @media (max-width: 575.98px) {
    .box-tocart .qty .control {
      margin-bottom: 1rem;
      margin-top: 1rem; } }

input.qty.qty.qty.qty {
  height: auto;
  width: 30px;
  margin: 0 0.3rem;
  padding-left: 0;
  padding-right: 0;
  border: none;
  text-align: center;
  font-weight: 700;
  font-size: 1.3rem;
  color: #000; }
  @media (max-width: 575.98px) {
    input.qty.qty.qty.qty {
      margin: 0 1rem; } }
  input.qty.qty.qty.qty:focus {
    box-shadow: 0 0.1875rem 0.375rem rgba(89, 89, 90, 0.16); }

.block-minicart.block-minicart,
.block-minicart > div,
.block-minicart .block-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1; }
  .block-minicart.block-minicart[style='display: block;']:not([hidden]),
  .block-minicart > div[style='display: block;']:not([hidden]),
  .block-minicart .block-content[style='display: block;']:not([hidden]) {
    display: flex !important; }

.block-minicart .minicart-items-wrapper {
  flex-grow: 1;
  margin: 0;
  padding: 1.5rem 0;
  border: none; }

.block-minicart .minicart-items {
  padding: 0 2rem; }
  .block-minicart .minicart-items .product-item {
    flex-wrap: wrap; }
    .block-minicart .minicart-items .product-item > .product {
      width: 100%; }
    .block-minicart .minicart-items .product-item:not(:first-child) {
      border-top-color: #ddd; }
    .block-minicart .minicart-items .product-item .product-image-photo {
      margin-left: 0; }
    .block-minicart .minicart-items .product-item .message {
      width: 100%;
      margin-bottom: 0; }
  .block-minicart .minicart-items .product-item-details {
    position: relative;
    padding-left: 78px; }
    .block-minicart .minicart-items .product-item-details .minicart-price:hover {
      cursor: default; }
    .block-minicart .minicart-items .product-item-details .price {
      font-size: 1.3rem;
      white-space: nowrap; }
    .block-minicart .minicart-items .product-item-details .details-qty {
      display: flex; }
    .block-minicart .minicart-items .product-item-details .product.options .content {
      display: block !important; }

.block-minicart .minicart-footer {
  padding: 0 2rem 2rem;
  line-height: 1.6; }
  .block-minicart .minicart-footer a:not(.btn):not(a.pagebuilder-button-primary):not(
  .pagebuilder-button-primary):not(a.pagebuilder-button-secondary):not(
  .pagebuilder-button-secondary) {
    text-transform: capitalize;
    text-decoration: underline; }
    .block-minicart .minicart-footer a:not(.btn):not(a.pagebuilder-button-primary):not(
    .pagebuilder-button-primary):not(a.pagebuilder-button-secondary):not(
    .pagebuilder-button-secondary):hover {
      text-decoration: none; }

.block-minicart .subtotal {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 2rem 1rem;
  padding: 3rem 0 0;
  border-top: 1px solid #ddd;
  font-family: "Montserrat", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  font-size: 2.2rem; }
  .block-minicart .subtotal .label,
  .block-minicart .subtotal .price.price.price {
    font-size: inherit; }
  .block-minicart .subtotal .label::after {
    display: none; }
  .block-minicart .subtotal .price {
    margin-left: 0.5rem; }

.block-minicart .action.delete {
  position: absolute;
  top: 54px;
  right: 0; }
  @media (max-width: 575.98px) {
    .block-minicart .action.delete {
      top: 55px; } }

.product-item-name.product-item-name {
  margin-top: 0.5rem;
  margin-bottom: 0; }
  .product-item-name.product-item-name a {
    padding-left: 0;
    color: inherit;
    font-size: 1.3rem;
    text-decoration: none !important; }
    .product-item-name.product-item-name a:hover {
      text-decoration: underline !important; }

.checkout-cart-index .page-header {
  border: none; }
  .checkout-cart-index .page-header::before, .checkout-cart-index .page-header::after {
    display: none !important; }

.checkout-cart-index .page-title-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  margin-bottom: 4.3rem; }
  .checkout-cart-index .page-title-wrapper h1 {
    position: relative;
    z-index: 1;
    font-weight: 700;
    font-size: 2.4rem; }
  @media (max-width: 1023.98px) {
    .checkout-cart-index .page-title-wrapper {
      height: 40px;
      margin: 0.4rem auto 2rem; }
      .checkout-cart-index .page-title-wrapper h1 {
        font-size: 1.6rem; } }

.checkout-cart-index .cart-summary,
.checkout-cart-index .form-cart {
  position: static;
  float: none;
  width: 100%; }

.checkout-cart-index .message.message {
  margin-bottom: 2rem; }

.cart-pre-actions {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  max-width: 1920px;
  width: 100%;
  height: 80px;
  margin: 0 auto;
  padding: 0 2rem;
  align-items: center;
  background-color: #fcd900; }
  @media (max-width: 1023.98px) {
    .cart-pre-actions {
      height: 40px; }
      .cart-pre-actions svg {
        margin-top: -0.1rem;
        margin-left: -1.5rem;
        width: 28px;
        height: 28px; } }
  .cart-pre-actions .btn-link {
    display: flex;
    align-items: center;
    color: inherit;
    font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1.3rem; }
    .cart-pre-actions .btn-link:hover {
      color: #000; }
  .cart-pre-actions .btn-primary, .cart-pre-actions a.pagebuilder-button-primary,
  .cart-pre-actions .pagebuilder-button-primary {
    width: 100%;
    max-width: 221px; }

.cart-container {
  display: flex; }
  @media (max-width: 1023.98px) {
    .cart-container {
      flex-wrap: wrap; } }
  @media (min-width: 768px) {
    .cart-container .form-cart {
      width: auto;
      flex-grow: 1; } }
  .cart-container .cart.item.item.item {
    border: none; }
  @media (max-width: 767.98px) {
    .cart-container .cart tbody tr {
      border-bottom: 1px solid #ddd; } }
  .cart-container .cart thead + tbody td {
    border-top: none; }
  @media (max-width: 767.98px) {
    .cart-container .cart tr:first-child td {
      min-height: 0 !important; } }
  .cart-container .cart .product-item-photo {
    max-width: 60px; }
    @media (min-width: 768px) {
      .cart-container .cart .product-item-photo {
        max-width: 80px; } }
  @media (max-width: 767.98px) {
    .cart-container .cart .product-item-name {
      display: block;
      margin-top: 0.5rem; } }
  .cart-container .cart .item-options.item-options {
    margin-top: 0;
    margin-bottom: 0; }
  @media (max-width: 767.98px) {
    .cart-container .cart .col {
      border: none; } }
  .cart-container .cart .col[data-th]::before {
    display: none !important; }
  .cart-container .cart .col:not(.item) {
    width: 1px;
    padding-left: 3rem; }
  @media (max-width: 767.98px) {
    .cart-container .cart .col.qty {
      padding-top: 1rem;
      padding-bottom: 1.5rem;
      padding-left: 7.5rem; } }
  @media (min-width: 768px) {
    .cart-container .cart .col.qty .control {
      padding-top: 2.5rem; } }
  @media (max-width: 767.98px) {
    .cart-container .cart .col.price {
      padding: 0; } }
  @media (max-width: 767.98px) {
    .cart-container .cart .col.item-actions {
      float: right;
      display: flex;
      margin-top: 1rem;
      padding-top: 0;
      padding-right: 0;
      padding-left: 0 !important;
      width: auto;
      align-items: center; }
      .cart-container .cart .col.item-actions .price-excluding-tax {
        padding-top: 1rem;
        padding-right: 3.5rem; } }
  @media (min-width: 768px) {
    .cart-container .cart .product-item-details {
      padding-top: 2rem; } }
  @media (min-width: 768px) {
    .cart-container .cart .price-excluding-tax {
      padding-top: 3.1rem; } }
  @media (min-width: 768px) {
    .cart-container .cart .action-delete {
      margin: 2.5rem auto 0; } }
  @media (max-width: 767.98px) {
    .cart-container .cart .action-delete {
      margin: 0 auto;
      float: right; } }
  .cart-container .cart .price .price,
  .cart-container .cart .subtotal .price {
    font-size: 1.3rem; }
  .cart-container .cart .price .price {
    font-weight: normal; }
  .cart-container .cart .qty.qty.input-text {
    height: 24px;
    width: 30px;
    margin-top: 0; }
  @media (min-width: 768px) {
    .cart-container .cart .price-including-tax,
    .cart-container .cart .price-excluding-tax {
      padding-top: 3.1rem; } }

.cart-summary.cart-summary {
  order: 1;
  width: 100%;
  background: none;
  padding-right: 1rem; }
  @media (max-width: 1023.98px) {
    .cart-summary.cart-summary {
      margin-top: 3rem;
      padding-left: 1rem; } }
  @media (min-width: 1024px) {
    .cart-summary.cart-summary {
      width: 380px;
      margin-left: 2.4rem; } }
  .opc-sidebar .cart-summary.cart-summary {
    padding: 0; }
  .cart-summary.cart-summary .block .title {
    padding: 0; }
    .cart-summary.cart-summary .block .title::after {
      display: none; }
  .cart-summary.cart-summary .block.shipping {
    display: none; }
  .cart-summary.cart-summary .summary.title,
  .cart-summary.cart-summary .cart-totals {
    position: relative;
    margin: 0; }
    .cart-summary.cart-summary .summary.title::before,
    .cart-summary.cart-summary .cart-totals::before {
      content: '';
      position: absolute;
      background-color: #fcd900;
      left: -10px;
      right: -10px;
      z-index: -1; }
      .opc-sidebar .cart-summary.cart-summary .summary.title::before, .opc-sidebar
      .cart-summary.cart-summary .cart-totals::before {
        display: none; }
  .cart-summary.cart-summary .summary.title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    background-color: #000;
    color: #fff;
    font-weight: 700;
    font-size: 1.3rem; }
    .cart-summary.cart-summary .summary.title::before {
      top: -10px;
      bottom: 0;
      border-radius: 8px 8px 0 0; }
  .cart-summary.cart-summary .cart-totals {
    margin-bottom: 2.4rem;
    border-top: none;
    background-color: #fff; }
    .cart-summary.cart-summary .cart-totals::before {
      top: 0;
      bottom: -10px;
      border-radius: 0 0 8px 8px; }
    .cart-summary.cart-summary .cart-totals .table {
      width: calc(100% - 40px);
      margin: 0 20px; }
    .cart-summary.cart-summary .cart-totals .mark {
      background: none; }
    .cart-summary.cart-summary .cart-totals tr:nth-last-of-type(2) td,
    .cart-summary.cart-summary .cart-totals tr:nth-last-of-type(2) th {
      padding-bottom: 20px; }
    .cart-summary.cart-summary .cart-totals th,
    .cart-summary.cart-summary .cart-totals td {
      border: none;
      padding: 0.65rem 0;
      font-size: 1.5rem; }
    .cart-summary.cart-summary .cart-totals .sub th,
    .cart-summary.cart-summary .cart-totals .sub td {
      padding-bottom: 0; }
    .cart-summary.cart-summary .cart-totals .totals-tax th,
    .cart-summary.cart-summary .cart-totals .totals-tax td {
      padding-bottom: 1.6rem; }
    .cart-summary.cart-summary .cart-totals .totals.discount {
      color: #eb5050; }
    .cart-summary.cart-summary .cart-totals .grand {
      font-family: "Montserrat", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }
      .cart-summary.cart-summary .cart-totals .grand th,
      .cart-summary.cart-summary .cart-totals .grand td {
        border-top: 1px solid #ddd;
        padding-bottom: 1.6rem;
        padding-top: 1.6rem;
        font-size: 1.8rem; }
      .cart-summary.cart-summary .cart-totals .grand strong {
        font-weight: 700; }
      .cart-summary.cart-summary .cart-totals .grand small {
        display: block;
        line-height: 1;
        font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-size: 1.2rem;
        color: #59595a; }

.cart-summary-container {
  list-style: none;
  padding-left: 0; }
  .cart-summary-container li {
    margin-bottom: 0; }
  .cart-summary-container .content {
    display: block !important; }
  .cart-summary-container .btn-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.3rem; }
    .cart-summary-container .btn-link svg:last-child {
      display: none; }
    .cart-summary-container .btn-link:not(.collapsed) svg {
      display: none; }
      .cart-summary-container .btn-link:not(.collapsed) svg:last-child {
        display: block; }
    @media (max-width: 1023.98px) {
      .cart-summary-container .btn-link {
        font-size: 1.5rem; } }
    @media (max-width: 575.98px) {
      .cart-summary-container .btn-link {
        width: calc(100% + 3rem);
        margin-left: -1.5rem;
        margin-right: -1.5rem;
        padding-left: 1.8rem !important;
        padding-right: 1.8rem !important; } }

.cart-empty {
  text-align: center; }
  .cart-empty a {
    text-decoration: underline; }
    .cart-empty a:hover {
      text-decoration: none; }

.action.delete, .action.action-delete {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%; }
  .action.delete svg, .action.action-delete svg {
    transform: rotate(45deg); }

.coupon {
  margin-left: 0 !important; }
  .coupon .input-text {
    height: 47px; }
  .coupon div.mage-error {
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 0 !important; }
  .coupon .btn-secondary, .coupon a.pagebuilder-button-secondary,
  .coupon .pagebuilder-button-secondary {
    width: 134px; }

.giftcard .delete {
  width: auto;
  justify-content: flex-start; }

.add-to-cart-popup.add-to-cart-popup .modal-header {
  padding: 0; }

.add-to-cart-popup.add-to-cart-popup .action-close {
  position: absolute;
  top: 0.4rem;
  right: 0.4rem;
  z-index: 1;
  outline: 0; }
  .add-to-cart-popup.add-to-cart-popup .action-close::before {
    width: 28.28px;
    height: 28.28px;
    background-size: contain;
    transform: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28.284' height='28.284'%3E%3Cg fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'%3E%3Cpath d='M7.072 21.213 21.214 7.071M7.071 7.07l14.142 14.142'/%3E%3C/g%3E%3C/svg%3E"); }

.add-to-cart-popup.add-to-cart-popup .modal-inner-wrap {
  border-radius: 1rem;
  height: auto;
  max-height: calc(100% - 10rem);
  max-width: 1150px;
  width: 90%;
  transform: translateY(-50%);
  top: 50%;
  margin: auto; }

.add-to-cart-popup.add-to-cart-popup .modal-content {
  position: relative;
  padding: 0;
  overflow-y: auto;
  border-radius: 1rem; }

.recommended-items {
  padding: 0 1rem; }
  @media (max-width: 767.98px) {
    .recommended-items {
      margin-bottom: 14rem; } }
  .recommended-items .block.upsell {
    margin-bottom: 2rem; }
  .recommended-items .grid-relateds .products {
    max-width: 1024px;
    margin-top: 2rem; }
  .recommended-items .products-upsell {
    width: 100%; }
    .recommended-items .products-upsell .product-item {
      padding: 1rem;
      background: #eee;
      margin-bottom: 0; }
    .recommended-items .products-upsell .product-item-info {
      width: 100%; }
    .recommended-items .products-upsell .product-item-details {
      padding: 1rem 0; }
    .recommended-items .products-upsell .product-item-actions {
      margin-bottom: 0; }
    .recommended-items .products-upsell .product-item-name.product-item-name {
      min-height: 38px; }

.recommended-items .grid-relateds .slick-slider {
  padding: 0 8rem;
  max-width: 1024px; }
  @media (max-width: 767.98px) {
    .recommended-items .grid-relateds .slick-slider {
      padding: 0 6rem; } }
  .recommended-items .grid-relateds .slick-slider .slick-track .slick-slide {
    padding: 0 1rem;
    flex: 0 0 25%; }
    @media (max-width: 1023.98px) {
      .recommended-items .grid-relateds .slick-slider .slick-track .slick-slide {
        flex: 0 0 33.33%; } }
    @media (max-width: 767.98px) {
      .recommended-items .grid-relateds .slick-slider .slick-track .slick-slide {
        flex: 0 0 50%; } }
    @media (max-width: 575.98px) {
      .recommended-items .grid-relateds .slick-slider .slick-track .slick-slide {
        flex: 0 0 100%; } }
    .recommended-items .grid-relateds .slick-slider .slick-track .slick-slide > div {
      max-width: 160px; }
  .recommended-items .grid-relateds .slick-slider .slick-arrow.slick-prev {
    left: 1rem; }
  .recommended-items .grid-relateds .slick-slider .slick-arrow.slick-next {
    right: 1rem; }
  .recommended-items .grid-relateds .slick-slider .slick-arrow::before {
    background-size: 30px; }

.recommended-popup .slick-track {
  margin-left: 0;
  margin-right: 0;
  width: 100% !important; }

.grid-relateds .product-image-container {
  display: block;
  max-width: 100%;
  width: 140px !important;
  margin: 0 auto !important; }

div.recommended-popup {
  font-family: "Montserrat", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }
  div.recommended-popup div.block-title.title {
    margin: 0; }
    div.recommended-popup div.block-title.title h2#block-upsell-heading {
      display: none; }
  div.recommended-popup .header-panel {
    text-align: center;
    background: #E5EFE6;
    color: #317C3A;
    padding: 1.5rem 4rem;
    font-size: 1.4rem; }
    div.recommended-popup .header-panel svg {
      margin-right: 1rem;
      margin-top: -0.3rem; }
    div.recommended-popup .header-panel .added-to-cart-header {
      font-weight: 700; }
    @media (max-width: 1023.98px) {
      div.recommended-popup .header-panel {
        font-size: 1.4rem;
        padding: 1rem 4rem;
        line-height: 1.2; }
        div.recommended-popup .header-panel .dash {
          display: none; }
        div.recommended-popup .header-panel .added-to-cart-header {
          display: block; }
        div.recommended-popup .header-panel svg {
          display: none; } }
  div.recommended-popup .proceed-to-checkout {
    position: absolute;
    top: 2.7rem;
    right: 4.6rem; }
    @media (max-width: 1023.98px) {
      div.recommended-popup .proceed-to-checkout {
        position: static;
        order: 1;
        margin-top: 1.6rem; } }
  div.recommended-popup .proceed-to-checkout-link-wrapper {
    width: 100%; }
    div.recommended-popup .proceed-to-checkout-link-wrapper .proceed-to-checkout-link {
      color: #fff;
      border-color: #fff;
      width: 100%;
      padding: 1rem;
      margin-top: 1rem;
      font-size: 1.6rem; }
    @media (max-width: 767.98px) {
      div.recommended-popup .proceed-to-checkout-link-wrapper {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 99;
        width: 100%;
        background: #fff;
        padding: 1rem; }
        div.recommended-popup .proceed-to-checkout-link-wrapper .proceed-to-checkout-link {
          color: #000;
          border-color: #000; } }
  div.recommended-popup .product-thumbnail {
    margin-right: 6rem; }
    div.recommended-popup .product-thumbnail img {
      width: auto;
      height: auto;
      object-fit: contain;
      font-size: 0; }
  div.recommended-popup .product-panel {
    display: flex;
    align-items: center;
    justify-content: center; }
  div.recommended-popup .package-panel {
    background: #000;
    color: #fff;
    font-weight: bold;
    text-align: center;
    padding: 3rem;
    gap: 0 3rem;
    display: flex;
    justify-content: space-between; }
    @media (max-width: 1023.98px) {
      div.recommended-popup .package-panel {
        flex-direction: column;
        padding: 2rem; } }
  div.recommended-popup .package-ticks {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    flex: 1 0 28%;
    padding-top: 1.5rem; }
    @media (max-width: 767.98px) {
      div.recommended-popup .package-ticks {
        border-top: 2px solid #808080; } }
    div.recommended-popup .package-ticks .tick-item {
      flex: 0 1 115px;
      margin-bottom: 1rem; }
      div.recommended-popup .package-ticks .tick-item .tick-icon {
        margin: auto;
        width: 37px;
        height: 37px;
        background-image: url(../images/icon-tick.svg);
        background-size: cover; }
      div.recommended-popup .package-ticks .tick-item .tick-text {
        padding-top: 1rem;
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 1.25; }
      @media (max-width: 767.98px) {
        div.recommended-popup .package-ticks .tick-item {
          flex-basis: 100%;
          display: flex;
          align-items: center;
          padding-left: 1rem; }
          div.recommended-popup .package-ticks .tick-item .tick-icon {
            margin: 1rem;
            width: 24px;
            height: 24px; }
          div.recommended-popup .package-ticks .tick-item .tick-text {
            padding-top: 0; } }
  div.recommended-popup .package-header {
    padding-top: 3.3rem;
    margin-left: auto;
    margin-right: auto;
    font-size: 2.5rem;
    font-weight: bold;
    max-width: 43.7rem;
    text-align: center; }
    @media (max-width: 1023.98px) {
      div.recommended-popup .package-header {
        font-size: 2.4rem; }
        div.recommended-popup .package-header br {
          display: none; } }
  div.recommended-popup .package-highlight {
    color: #fcd900; }
  div.recommended-popup .package-product {
    border: 0.5rem solid #fcd900;
    border-radius: 2rem;
    background-color: #fff;
    flex: 1 1 68%;
    padding: 2rem;
    color: #000;
    display: flex;
    gap: 2rem;
    flex-wrap: wrap; }
    @media (max-width: 767.98px) {
      div.recommended-popup .package-product {
        border: 0;
        padding: 0;
        flex-direction: column;
        background: #000;
        color: #fff;
        text-align: left; } }
  div.recommended-popup .package-thumbnail {
    flex: 1 1 40%;
    text-align: center; }
  div.recommended-popup .package-title {
    flex: 1 1 40%;
    font-size: 1.8rem;
    font-weight: normal;
    text-align: left; }
    div.recommended-popup .package-title strong {
      text-transform: uppercase; }
    div.recommended-popup .package-title .package-product-name {
      padding: 1rem 0; }
  div.recommended-popup .package-add-to-cart-button {
    display: flex;
    flex-grow: 1; }
    @media (max-width: 767.98px) {
      div.recommended-popup .package-add-to-cart-button {
        position: fixed;
        bottom: 57px;
        left: 0;
        z-index: 100;
        width: 100%;
        background: #fff;
        padding: 1rem;
        font-size: 1.6rem;
        box-shadow: 0 -7px 7px rgba(0, 0, 0, 0.12); }
        div.recommended-popup .package-add-to-cart-button .button-margin {
          margin: 0; } }
  div.recommended-popup .button-margin {
    margin-top: 2rem;
    flex-grow: 1; }
  div.recommended-popup .package-price {
    font-size: 2rem;
    font-weight: bold; }
  div.recommended-popup .recommended-title {
    padding-top: 3.8rem;
    padding-left: 11rem;
    font-size: 1.8rem;
    max-width: 1024px;
    margin: auto; }
    @media (max-width: 1023.98px) {
      div.recommended-popup .recommended-title {
        padding-left: 3rem; } }
    @media (max-width: 767.98px) {
      div.recommended-popup .recommended-title {
        padding-left: 2rem; } }
  div.recommended-popup .package-add-to-cart-form {
    width: 100%; }
  div.recommended-popup .package-thumbnail-text {
    line-height: 1; }

.page-products .products-grid .product-item .photo {
  max-width: 140px;
  margin-left: auto;
  margin-right: auto; }

.product.price.product-item-details {
  font-weight: bold;
  font-size: 16px; }

.form.giftcard .action-check,
.form.giftcard .giftcard.info {
  margin-top: 2rem; }

@media (max-width: 767.98px) {
  .amlocator-index-index .breadcrumbs,
  .amlocator-location-view .breadcrumbs {
    margin-bottom: 0; } }

@media (min-width: 768px) {
  .amlocator-index-index .breadcrumbs,
  .amlocator-location-view .breadcrumbs {
    margin-bottom: 1.2rem; } }

.amlocator-location-view .page-wrapper {
  overflow-x: hidden; }

.amlocator-main-container .amlocator-map-container {
  flex-direction: row;
  max-height: none;
  height: auto; }

.amlocator-main-container .amlocator-search-container {
  width: 100%;
  background-color: #fcd900;
  text-align: center;
  border-radius: 0.3rem; }
  @media (max-width: 575.98px) {
    .amlocator-main-container .amlocator-search-container {
      width: auto;
      margin-left: -1.5rem;
      margin-right: -1.5rem;
      border-radius: 0; } }
  .amlocator-main-container .amlocator-search-container h1 {
    font-weight: 700;
    font-size: 2.4rem; }

.amlocator-main-container .amlocator-search-block {
  display: flex;
  align-items: center;
  max-width: 752px; }
  @media (max-width: 575.98px) {
    .amlocator-main-container .amlocator-search-block {
      flex-wrap: wrap; }
      .amlocator-main-container .amlocator-search-block .amlocator-block,
      .amlocator-main-container .amlocator-search-block .btn,
      .amlocator-main-container .amlocator-search-block .pagebuilder-button-primary,
      .amlocator-main-container .amlocator-search-block .pagebuilder-button-secondary,
      .amlocator-main-container .amlocator-search-block .account-nav .item.item a[href*='logout'],
      .account-nav .item.item .amlocator-main-container .amlocator-search-block a[href*='logout'] {
        flex: 0 0 100%; }
      .amlocator-main-container .amlocator-search-block .amlocator-block {
        margin-bottom: 1rem; } }
  @media (min-width: 576px) {
    .amlocator-main-container .amlocator-search-block .amlocator-block {
      margin-bottom: 0; }
    
    .amlocator-main-container .amlocator-search-block .btn,
    .amlocator-main-container .amlocator-search-block .pagebuilder-button-primary,
    .amlocator-main-container .amlocator-search-block .pagebuilder-button-secondary,
    .amlocator-main-container .amlocator-search-block .account-nav .item.item a[href*='logout'],
    .account-nav .item.item .amlocator-main-container .amlocator-search-block a[href*='logout'] {
      flex: 0 0 118px;
      padding-left: 0;
      padding-right: 0; } }
  .amlocator-main-container .amlocator-search-block .search-icon {
    top: 50%;
    right: 1rem;
    transform: translateY(-50%); }
    @media (min-width: 768px) {
      .amlocator-main-container .amlocator-search-block .search-icon {
        right: 2rem; } }
  @media (max-width: 767.98px) {
    .amlocator-main-container .amlocator-search-block select.form-control {
      background-position: right 1rem center; } }

.amlocator-main-container .amlocator-wrapper {
  margin-top: 0; }
  @media (max-width: 767.98px) {
    .amlocator-main-container .amlocator-wrapper {
      max-height: none; } }

.amlocator-main-container .amlocator-results {
  display: flex;
  width: 100%;
  height: 840px; }
  @media (max-width: 767.98px) {
    .amlocator-main-container .amlocator-results {
      flex-wrap: wrap;
      height: auto; } }
  .amlocator-main-container .amlocator-results .amlocator-block.-map {
    max-height: none;
    height: 100%;
    padding-left: 0; }
    @media (min-width: 768px) {
      .amlocator-main-container .amlocator-results .amlocator-block.-map {
        width: 60%; } }
    @media (max-width: 767.98px) {
      .amlocator-main-container .amlocator-results .amlocator-block.-map {
        width: 100%;
        order: 2;
        margin-top: 1rem; } }
  @media (min-width: 768px) {
    .amlocator-main-container .amlocator-results .amlocator-block.-storelist {
      padding-left: 2.8rem; } }
  @media (max-width: 767.98px) {
    .amlocator-main-container .amlocator-results .amlocator-block.-storelist {
      width: 100%; } }

.amlocator-main-container .amlocator-store-desc {
  background: none;
  color: inherit; }
  .amlocator-main-container .amlocator-store-desc:not(:last-child) {
    border-bottom: 1px solid #ddd; }
  .amlocator-main-container .amlocator-store-desc .amlocator-block {
    margin: 0.2rem 0 2rem; }
  .amlocator-main-container .amlocator-store-desc .amlocator-link.amlocator-link {
    text-decoration: none !important;
    color: inherit; }
    .amlocator-main-container .amlocator-store-desc .amlocator-link.amlocator-link:hover {
      text-decoration: underline !important; }

.amlocator-main-container .store-marker {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 99px;
  border: solid 4px rgba(252, 217, 0, 0.4);
  background-color: #fcd900;
  background-clip: content-box; }

.amlocator-location-main .amlocator-block.-map {
  height: 333px !important; }

.amlocator-location-main .amlocator-location-info {
  display: flex;
  align-items: flex-start;
  max-width: 805px;
  margin: 0 auto 3rem; }

.amlocator-location-main .amlocator-info-container {
  padding-top: 5.7rem; }
  .amlocator-location-main .amlocator-info-container .page-title-wrapper {
    margin-bottom: 3rem; }
  .amlocator-location-main .amlocator-info-container .amlocator-block {
    display: flex;
    align-items: center; }
    .amlocator-location-main .amlocator-info-container .amlocator-block .icon-container {
      margin: 0 1rem 0 0;
      width: 38px;
      height: 38px;
      background-color: #fcd900;
      background-clip: content-box;
      border: solid 3px rgba(252, 217, 0, 0.4);
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center; }
    .amlocator-location-main .amlocator-info-container .amlocator-block .amlocator-text {
      display: block;
      line-height: 1; }
  .amlocator-location-main .amlocator-info-container .am-phone a,
  .amlocator-location-main .amlocator-info-container .am-email a {
    text-decoration: none; }
    .amlocator-location-main .amlocator-info-container .am-phone a:hover,
    .amlocator-location-main .amlocator-info-container .am-email a:hover {
      text-decoration: underline; }

.amlocator-location-main .amlocator-store-hours {
  position: relative;
  z-index: 1;
  flex: 0 0 375px;
  margin-top: -7rem;
  margin-left: auto; }
  .amlocator-location-main .amlocator-store-hours .amlocator-store-hours-inner {
    padding: 3rem 2rem;
    background: #fff; }
  @media (max-width: 767.98px) {
    .amlocator-location-main .amlocator-store-hours {
      margin-top: 1.5rem;
      flex: 0 0 100%;
      max-width: 100%; } }
  .amlocator-location-main .amlocator-store-hours h2 {
    margin-bottom: 1.5rem; }
  .amlocator-location-main .amlocator-store-hours .amlocator-schedule-table .amlocator-row {
    margin: 0;
    padding: 1rem 0;
    border-bottom: 1px solid #ddd; }
    .amlocator-location-main .amlocator-store-hours .amlocator-schedule-table .amlocator-row:last-child {
      border-bottom: none; }
    .amlocator-location-main .amlocator-store-hours .amlocator-schedule-table .amlocator-row .-day {
      text-align: left;
      font-weight: 700; }

.amlocator-location-main .amlocator-location-gallery {
  flex-grow: 1;
  flex-basis: calc(100% - 375px);
  max-width: calc(100% - 375px); }
  .amlocator-location-main .amlocator-location-gallery .amlocator-wrapper {
    height: auto;
    padding: 0; }
    @media (max-width: 767.98px) {
      .amlocator-location-main .amlocator-location-gallery .amlocator-wrapper {
        max-height: none; } }
  .amlocator-location-main .amlocator-location-gallery .slick-list {
    margin-bottom: 1.5rem;
    border-radius: 2px; }
  .amlocator-location-main .amlocator-location-gallery .slick-dots {
    max-width: 319px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto; }
  .amlocator-location-main .amlocator-location-gallery .amlocator-image {
    height: auto; }

@media (min-width: 768px) {
  .amlocator-location-main .amlocator-collapse-title {
    padding-top: 1.6rem; } }

.amlocator-location-main .amlocator-location-reviews {
  overflow: visible;
  display: block !important;
  height: auto !important; }

.amlocator-location-main .amlocator-reviews-wrapper {
  flex: 0 0 50%;
  margin-left: auto;
  text-align: center;
  max-width: 375px; }
  .amlocator-location-main .amlocator-reviews-wrapper .rating-summary {
    margin-bottom: 1.7rem; }
  .amlocator-location-main .amlocator-reviews-wrapper .amlocator-name {
    margin-bottom: 1rem;
    font-size: 1.8rem; }
  .amlocator-location-main .amlocator-reviews-wrapper .slick-prev {
    left: -2rem; }
  .amlocator-location-main .amlocator-reviews-wrapper .slick-next {
    right: -2rem; }

.amlocator-location-main .amlocator-location-review .amlocator-wrapper {
  padding: 0 30px; }

.amlocator-location-main .amlocator-location-review h3 {
  margin-bottom: 1.1rem;
  font-weight: 700;
  font-size: 1.8rem; }

.amlocator-location-main .amlocator-location-review .amlocator-message {
  font-size: 1.5rem; }

.amlocator-location-main .amlocator-description {
  position: relative;
  max-width: 600px;
  margin: 5rem auto;
  padding: 6rem 1rem;
  text-align: center;
  line-height: 1.6;
  font-size: 1.5rem; }
  .amlocator-location-main .amlocator-description p:last-child {
    margin-bottom: 0; }
  @media (min-width: 768px) {
    .amlocator-location-main .amlocator-description, .amlocator-location-main .amlocator-description::before, .amlocator-location-main .amlocator-description::after {
      background-color: #f0f0f0; }
    .amlocator-location-main .amlocator-description::before, .amlocator-location-main .amlocator-description::after {
      content: '';
      position: absolute;
      top: 0;
      width: 100vw;
      height: 100%; }
    .amlocator-location-main .amlocator-description::before {
      right: 100%; }
    .amlocator-location-main .amlocator-description::after {
      left: 100%; }
    .amlocator-location-main .amlocator-description h2 {
      margin-bottom: 3.2rem; } }
  @media (max-width: 767.98px) {
    .amlocator-location-main .amlocator-description {
      margin: 0 auto 5rem;
      padding: 0;
      text-align: left; } }

@media (max-width: 1023.98px) {
  .amlocator-location-main .amlocator-location-info {
    flex-wrap: wrap;
    max-width: 100%;
    margin-bottom: 1.5rem;
    padding-top: 2.1rem; }
  .amlocator-location-main .amlocator-location-gallery,
  .amlocator-location-main .amlocator-reviews-wrapper {
    flex: 0 0 100%;
    max-width: 100%; }
  .amlocator-location-main .amlocator-reviews-wrapper {
    margin-bottom: 3rem; }
  .amlocator-location-main .amlocator-location-gallery {
    order: 1;
    padding-left: 0;
    padding-right: 0; } }

@media (max-width: 767.98px) {
  .amlocator-location-main {
    display: flex;
    flex-direction: column; }
    .amlocator-location-main .amlocator-info-container {
      padding-top: 3.5rem; } }

.teds-events-banner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 250px;
  margin-bottom: 1rem;
  border-radius: 3px;
  overflow: hidden;
  text-align: center;
  font-family: "Montserrat", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  font-size: 2.4rem;
  color: #fcd900; }
  .teds-events-banner img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover; }
  .teds-events-banner a::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    z-index: -1;
    opacity: 0.4; }
  .teds-events-banner p {
    margin-bottom: 1.2rem; }
    .teds-events-banner p:first-child::before {
      content: '';
      display: block;
      margin: 0 auto 1.7rem;
      background-image: url("../images/teds-events.svg");
      background-size: 157.07px 80.99px;
      background-repeat: no-repeat;
      background-position: center;
      width: 157.07px;
      height: 80.99px; }
  @media (max-width: 767.98px) {
    .teds-events-banner {
      height: 333px !important; }
      .teds-events-banner p {
        margin-bottom: 2.2rem;
        max-width: 280px;
        line-height: 1.3; }
        .teds-events-banner p:first-child::before {
          margin-bottom: 2.7rem; } }

.am-mobbanner {
  order: -2; }

@media (max-width: 767.98px) {
  .am-content {
    order: -1; } }

.amlocator-info-popup .amlocator-name {
  margin-bottom: 1.5rem;
  font-weight: 700; }
  .amlocator-info-popup .amlocator-name .amlocator-link {
    text-decoration: none !important; }
    .amlocator-info-popup .amlocator-name .amlocator-link:hover {
      text-decoration: underline !important; }

.amlocator-info-popup .amlocator-image {
  display: none; }

.holiday-hours {
  padding-top: 2.5rem;
  border-top: 1px solid #ddd;
  font-weight: 700;
  color: #eb5050; }
  .holiday-hours h3 {
    margin-bottom: 1rem;
    color: #000; }

.holiday-link {
  margin-top: 2.9rem; }
  @media (min-width: 768px) {
    .holiday-link {
      margin-top: 2rem; } }
  .holiday-link a {
    display: block; }
  .holiday-link img {
    width: 100%; }

body.tracking-index-index .page-title-wrapper {
  display: none; }

.tracking-wrapper h2 {
  font-size: 2.4rem;
  font-weight: 700; }

.tracking-wrapper .tracking-number-display .table-striped {
  border-collapse: separate; }
  .tracking-wrapper .tracking-number-display .table-striped tbody tr:nth-of-type(even) {
    background-color: rgba(221, 221, 221, 0.2); }
  .tracking-wrapper .tracking-number-display .table-striped td,
  .tracking-wrapper .tracking-number-display .table-striped th {
    padding: 8px 15px;
    border: solid 1px #f0f0f0;
    height: 47px;
    vertical-align: middle; }
    .tracking-wrapper .tracking-number-display .table-striped td svg,
    .tracking-wrapper .tracking-number-display .table-striped th svg {
      max-width: 30px;
      max-height: 30px;
      margin-right: 15px; }
      @media (max-width: 1023.98px) {
        .tracking-wrapper .tracking-number-display .table-striped td svg,
        .tracking-wrapper .tracking-number-display .table-striped th svg {
          display: block;
          margin: 0 auto 5px auto; } }
  .tracking-wrapper .tracking-number-display .table-striped tr.completed svg {
    color: #28a745; }
  .tracking-wrapper .tracking-number-display .table-striped tr:first-child th {
    border-top-left-radius: 5px; }
  .tracking-wrapper .tracking-number-display .table-striped tr:first-child td {
    border-top-right-radius: 5px; }
  .tracking-wrapper .tracking-number-display .table-striped tr:not(:first-child) th,
  .tracking-wrapper .tracking-number-display .table-striped tr:not(:first-child) td {
    border-top: none; }
  .tracking-wrapper .tracking-number-display .table-striped td {
    border-left: none; }
  .tracking-wrapper .tracking-number-display .table-striped th {
    border-right: none;
    width: 20%; }
    @media (max-width: 1023.98px) {
      .tracking-wrapper .tracking-number-display .table-striped th {
        text-align: center; } }

@media (min-width: 768px) {
  .tracking-wrapper .tracking-form {
    border: solid 10px #fcd900;
    border-radius: 8px;
    padding: 45px 65px; } }

.tracking-wrapper .tracking-form .fieldset {
  margin-bottom: 0; }

.twi-banner.twi-banner.twi-banner {
  position: relative; }
  @media (max-width: 575.98px) {
    .twi-banner.twi-banner.twi-banner {
      margin-left: -1.5rem !important;
      margin-right: -1.5rem !important; } }
  .twi-banner.twi-banner.twi-banner::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.66);
    transition: opacity 0.3s; }
  .twi-banner.twi-banner.twi-banner:hover {
    cursor: pointer; }
    .twi-banner.twi-banner.twi-banner:hover::before {
      opacity: 0.8; }
  .twi-banner.twi-banner.twi-banner [data-content-type='html'] svg {
    position: absolute;
    top: 50%;
    left: 10%;
    z-index: 2;
    transform: translateY(-50%);
    pointer-events: none; }
    @media (max-width: 1023.98px) {
      .twi-banner.twi-banner.twi-banner [data-content-type='html'] svg {
        top: 3rem;
        transform: none;
        left: 0;
        right: 0;
        margin: auto;
        width: 150px;
        height: auto; } }
    @media (max-width: 575.98px) {
      .twi-banner.twi-banner.twi-banner [data-content-type='html'] svg {
        top: 2rem;
        width: 115px; } }
  .twi-banner.twi-banner.twi-banner [data-content-type='video'] {
    position: relative; }
    .twi-banner.twi-banner.twi-banner [data-content-type='video']::before, .twi-banner.twi-banner.twi-banner [data-content-type='video']::after {
      position: absolute;
      left: 0;
      right: 0;
      z-index: 2;
      margin: auto;
      pointer-events: none; }
      @media (min-width: 1024px) {
        .twi-banner.twi-banner.twi-banner [data-content-type='video']::before, .twi-banner.twi-banner.twi-banner [data-content-type='video']::after {
          transform: translateY(4.7rem); } }
    .twi-banner.twi-banner.twi-banner [data-content-type='video']::before {
      content: '';
      bottom: 50%;
      width: 67px;
      height: 67px;
      background-image: url(../images/play.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      border-radius: 99px; }
      @media (max-width: 1023.98px) {
        .twi-banner.twi-banner.twi-banner [data-content-type='video']::before {
          width: 50px;
          height: 50px;
          bottom: 20%; } }
      @media (max-width: 575.98px) {
        .twi-banner.twi-banner.twi-banner [data-content-type='video']::before {
          width: 35px;
          height: 35px; } }
    .twi-banner.twi-banner.twi-banner [data-content-type='video']::after {
      content: 'Watch video';
      top: 50%;
      padding-top: 1rem;
      font-family: "Montserrat", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 600;
      font-size: 1.3rem;
      color: #fff;
      text-shadow: 0 3px 6px #00000040;
      text-align: center; }
      @media (max-width: 1023.98px) {
        .twi-banner.twi-banner.twi-banner [data-content-type='video']::after {
          top: auto;
          bottom: 16%; } }
      @media (max-width: 575.98px) {
        .twi-banner.twi-banner.twi-banner [data-content-type='video']::after {
          bottom: 9%; } }
  .twi-banner.twi-banner.twi-banner.clicked::before,
  .twi-banner.twi-banner.twi-banner.clicked [data-content-type='html'] svg,
  .twi-banner.twi-banner.twi-banner.clicked [data-content-type='video']::before,
  .twi-banner.twi-banner.twi-banner.clicked [data-content-type='video']::after {
    display: none; }

.twi-anchors.twi-anchors.twi-anchors {
  margin: 1.4rem -0.7rem !important; }
  @media (max-width: 767.98px) {
    .twi-anchors.twi-anchors.twi-anchors {
      margin-bottom: 0.7rem !important; } }
  .twi-anchors.twi-anchors.twi-anchors .pagebuilder-column {
    padding: 0.7rem !important; }
  .twi-anchors.twi-anchors.twi-anchors p {
    margin: 0; }
  .twi-anchors.twi-anchors.twi-anchors [data-content-type='text'] a {
    position: relative;
    display: block;
    height: 100px;
    width: 100%;
    padding: 2.2rem 5.2rem 2.2rem 2.2rem;
    border-radius: 0.3rem;
    font-family: "Montserrat", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 700;
    font-size: 1.6rem;
    text-decoration: none;
    color: #fff;
    overflow: hidden; }
    .twi-anchors.twi-anchors.twi-anchors [data-content-type='text'] a::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.66);
      pointer-events: none;
      transition: opacity 0.3s;
      opacity: 0; }
    .twi-anchors.twi-anchors.twi-anchors [data-content-type='text'] a::after {
      content: '';
      position: absolute;
      top: 2.1rem;
      right: 2.4rem;
      z-index: 1;
      width: 26px;
      height: 26px;
      background-image: url(../images/arrow-right-white.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center; }
    .twi-anchors.twi-anchors.twi-anchors [data-content-type='text'] a:hover::before {
      opacity: 0.3; }
  .twi-anchors.twi-anchors.twi-anchors .twi-black a {
    background-color: #000; }
  .twi-anchors.twi-anchors.twi-anchors .twi-yellow a {
    background: transparent linear-gradient(250deg, #fcd900 0%, #fcd900 47%, #ff8002 100%); }
  .twi-anchors.twi-anchors.twi-anchors .twi-red a {
    background: transparent linear-gradient(250deg, #eb5050 0%, #bf2828 100%); }
  .twi-anchors.twi-anchors.twi-anchors .twi-grey a {
    background: transparent linear-gradient(97deg, #807f7f 0%, #aaa 100%); }

.twi-description-inner.twi-description-inner.twi-description-inner {
  position: relative;
  max-width: 641px;
  margin: 0 auto !important;
  padding: 8rem 0 33rem !important;
  line-height: 1.75;
  text-align: center;
  color: #fff; }
  @media (max-width: 1023.98px) {
    .twi-description-inner.twi-description-inner.twi-description-inner {
      padding: 5rem 0 13rem !important; } }
  .twi-description-inner.twi-description-inner.twi-description-inner, .twi-description-inner.twi-description-inner.twi-description-inner::before, .twi-description-inner.twi-description-inner.twi-description-inner::after {
    background-color: #000; }
  .twi-description-inner.twi-description-inner.twi-description-inner::before, .twi-description-inner.twi-description-inner.twi-description-inner::after {
    content: '';
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100%; }
  .twi-description-inner.twi-description-inner.twi-description-inner::before {
    left: 100%; }
  .twi-description-inner.twi-description-inner.twi-description-inner::after {
    right: 100%; }
  .twi-description-inner.twi-description-inner.twi-description-inner h2 {
    max-width: 412px;
    margin: 0 auto 5rem;
    font-weight: 700;
    font-size: 3.5rem;
    color: #fcd900; }

.twi-events-intro.twi-events-intro.twi-events-intro {
  margin: 9rem auto !important;
  position: relative;
  z-index: 1; }
  @media (max-width: 1023.98px) {
    .twi-events-intro.twi-events-intro.twi-events-intro {
      margin: 5rem auto !important; } }
  .twi-events-intro.twi-events-intro.twi-events-intro.twi-events-intro-first {
    margin-top: -26rem !important; }
    @media (max-width: 1023.98px) {
      .twi-events-intro.twi-events-intro.twi-events-intro.twi-events-intro-first {
        margin-top: -9rem !important; }
        .twi-events-intro.twi-events-intro.twi-events-intro.twi-events-intro-first .twi-block-image {
          display: none !important; } }
  @media (max-width: 1023.98px) {
    .twi-events-intro.twi-events-intro.twi-events-intro .pagebuilder-column-group {
      flex-wrap: wrap; } }
  .twi-events-intro.twi-events-intro.twi-events-intro .pagebuilder-column {
    align-self: center !important; }
    @media (max-width: 1023.98px) {
      .twi-events-intro.twi-events-intro.twi-events-intro .pagebuilder-column {
        width: 100% !important; } }
    @media (min-width: 1024px) {
      .twi-events-intro.twi-events-intro.twi-events-intro .pagebuilder-column:first-child {
        margin-right: 5rem !important; }
      .twi-events-intro.twi-events-intro.twi-events-intro .pagebuilder-column:last-child {
        margin-left: -8.5rem !important; } }
    .twi-events-intro.twi-events-intro.twi-events-intro .pagebuilder-column.twi-block-image {
      padding: 2.4rem 0 !important;
      background-color: #fcd900; }
      @media (max-width: 1023.98px) {
        .twi-events-intro.twi-events-intro.twi-events-intro .pagebuilder-column.twi-block-image {
          order: -1;
          position: relative;
          z-index: 1;
          margin-bottom: 1.3rem !important;
          padding: 1.2rem 0 !important; }
          .twi-events-intro.twi-events-intro.twi-events-intro .pagebuilder-column.twi-block-image::before {
            content: '';
            background: #fff;
            position: absolute;
            top: 0;
            right: 0;
            width: 48px;
            height: 100%;
            z-index: -1; } }
      @media (min-width: 1024px) {
        .twi-events-intro.twi-events-intro.twi-events-intro .pagebuilder-column.twi-block-image {
          flex-basis: 620px;
          width: 645px !important; } }
      @media (max-width: 1023.98px) {
        .twi-events-intro.twi-events-intro.twi-events-intro .pagebuilder-column.twi-block-image:last-child::before {
          right: auto;
          left: 0; } }
      .twi-events-intro.twi-events-intro.twi-events-intro .pagebuilder-column.twi-block-image:last-child figure {
        display: flex;
        justify-content: flex-end; }
      @media (min-width: 1024px) {
        .twi-events-intro.twi-events-intro.twi-events-intro .pagebuilder-column.twi-block-image img {
          max-width: 120% !important; } }
    .twi-events-intro.twi-events-intro.twi-events-intro .pagebuilder-column.twi-block-text {
      position: relative;
      padding: 2.6rem !important;
      border: 10px solid #fcd900 !important;
      border-radius: 8px !important;
      background-color: #fff;
      line-height: 1.75; }
      @media (min-width: 1024px) {
        .twi-events-intro.twi-events-intro.twi-events-intro .pagebuilder-column.twi-block-text {
          flex-basis: 588px;
          padding: 3.5rem !important; }
          .twi-events-intro.twi-events-intro.twi-events-intro .pagebuilder-column.twi-block-text h3 {
            margin-bottom: 1.5rem; } }
      .twi-events-intro.twi-events-intro.twi-events-intro .pagebuilder-column.twi-block-text h3 {
        font-weight: 700;
        font-size: 2.2rem; }

.twi-upcoming.twi-upcoming.twi-upcoming {
  margin: 0 -1.5rem 4rem !important; }
  .twi-upcoming.twi-upcoming.twi-upcoming h2 {
    margin-bottom: 4rem;
    text-align: center;
    font-weight: 700;
    font-size: 2.4rem; }
  .twi-upcoming.twi-upcoming.twi-upcoming li {
    max-width: 100% !important; }

.amlocator-location-main .event-list.row.slick-initialized .slick-list {
  min-width: 100%; }

.wishlist.products-grid .product-item-info {
  position: relative; }

.wishlist.products-grid .product-item-actions {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  margin-top: 0; }

.wishlist.products-grid .product-item-name,
.wishlist.products-grid .price-box {
  text-align: center; }

.wishlist.products-grid .product-item-photo {
  margin-bottom: 0 !important; }
  @media (max-width: 1023.98px) {
    .wishlist.products-grid .product-item-photo {
      margin-bottom: 30px !important; } }

.wishlist.products-grid .product-image-wrapper {
  padding-bottom: 100% !important; }

.wishlist-toolbar .pager {
  width: 100%;
  align-content: space-between;
  display: flex; }
  .wishlist-toolbar .pager .toolbar-amount {
    margin-bottom: 0;
    text-align: left; }
  .wishlist-toolbar .pager .limiter {
    margin-bottom: 0; }

@media (max-width: 1023.98px) {
  .cms-home .main {
    padding-bottom: 0 !important; } }

.cms-home .page-main {
  padding: 0;
  max-width: 100%; }
  .cms-home .page-main .page.messages {
    padding-left: 1rem;
    padding-right: 1rem; }
    @media (max-width: 1239.98px) {
      .cms-home .page-main .page.messages {
        max-width: 100%; } }
    .cms-home .page-main .page.messages .message {
      margin-top: 1rem; }
      @media (min-width: 768px) {
        .cms-home .page-main .page.messages .message {
          margin-bottom: 0; } }
  .cms-home .page-main .column.main {
    overflow-x: hidden;
    padding-bottom: 2.3rem; }
  @media (max-width: 767.98px) {
    .cms-home .page-main .column.main {
      display: flex;
      flex-direction: column; }
    .cms-home .page-main [data-content-type='row'] {
      width: 100%; }
      .cms-home .page-main [data-content-type='row']:nth-of-type(2) {
        order: -2; }
      .cms-home .page-main [data-content-type='row']:nth-of-type(5), .cms-home .page-main [data-content-type='row']:nth-of-type(6) {
        order: -1; } }

@media (min-width: 768px) {
  .home-banner .pagebuilder-column-group {
    width: 100%;
    margin-left: auto;
    margin-right: auto; } }

.home-banner .pagebuilder-column [data-content-type='slider'] {
  min-height: 0 !important; }

.home-banner .pagebuilder-column .slick-track {
  height: 100%; }

.home-banner .pagebuilder-column .slick-list,
.home-banner .pagebuilder-column .pagebuilder-mobile-hidden {
  position: absolute;
  width: 100%;
  height: 100% !important; }

.home-banner .pagebuilder-column [data-content-type='slide'],
.home-banner .pagebuilder-column [data-content-type='slide'] > div,
.home-banner .pagebuilder-column [data-content-type='slide'] > a > div {
  height: 100%; }

@media (max-width: 767.98px) {
  .home-banner .pagebuilder-column {
    padding: 0 !important; }
    .home-banner .pagebuilder-column:first-child [data-content-type='slider'] {
      margin-bottom: 5.5rem !important;
      padding-bottom: 97% !important; }
    .home-banner .pagebuilder-column:last-child {
      display: none !important; }
      .home-banner .pagebuilder-column:last-child div {
        background: none !important; }
  .home-banner .pagebuilder-poster-overlay {
    min-height: 0 !important; } }

@media (min-width: 768px) {
  .home-banner .pagebuilder-column .slick-slide {
    width: 100% !important; }
  .home-banner .pagebuilder-column:first-child {
    width: 66.555% !important; }
    .home-banner .pagebuilder-column:first-child [data-content-type='slider'] {
      padding-bottom: 46.633% !important; }
  .home-banner .pagebuilder-column:last-child {
    width: 33.445% !important;
    padding-left: 0 !important; }
    .home-banner .pagebuilder-column:last-child > * {
      position: relative;
      padding-bottom: 45.1% !important; }
      .home-banner .pagebuilder-column:last-child > *:first-child {
        margin-bottom: 10px !important; }
    .home-banner .pagebuilder-column:last-child [data-content-type='slider'] {
      padding-bottom: 45.1% !important; } }

.home-banner div[data-content-type='banner'] {
  height: 50%;
  display: flex;
  align-items: stretch; }
  .home-banner div[data-content-type='banner'] div,
  .home-banner div[data-content-type='banner'] a {
    width: 100%;
    display: flex; }

.home-banner div[data-content-type='slide'] > div {
  display: flex;
  align-items: stretch; }

.home-banner .pagebuilder-slide-wrapper {
  width: 100%;
  min-height: inherit !important;
  background-position: center !important; }

@media (min-width: 1024px) {
  .home-banner .slick-slider .slick-dots {
    display: none !important; } }

@media (max-width: 1023.98px) {
  .home-banner .slick-slider .slick-dots {
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 2.5rem; }
  .home-banner .slick-slider .slick-prev {
    left: 1.7rem; }
  .home-banner .slick-slider .slick-next {
    right: 1.7rem; } }

@media (min-width: 1024px) {
  .home-banner .pagebuilder-column:first-child .slick-slider .slick-dots {
    display: flex !important;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin: 0.25rem auto 0 auto; } }

.home-banner-blocks.home-banner-blocks.home-banner-blocks {
  margin-bottom: 3rem !important; }
  .home-banner-blocks.home-banner-blocks.home-banner-blocks .pagebuilder-column-group {
    margin: 0 3px; }
  .home-banner-blocks.home-banner-blocks.home-banner-blocks .pagebuilder-column {
    padding: 2px 6px !important;
    font-family: "Montserrat", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 700;
    font-size: 1.6rem;
    color: #fff; }
    .home-banner-blocks.home-banner-blocks.home-banner-blocks .pagebuilder-column:nth-child(1) [data-content-type='text'] {
      background-color: #000; }
    .home-banner-blocks.home-banner-blocks.home-banner-blocks .pagebuilder-column:nth-child(2) [data-content-type='text'] {
      background: transparent linear-gradient(250deg, #fcd900 0%, #fcd900 47%, #ff8002 100%); }
    .home-banner-blocks.home-banner-blocks.home-banner-blocks .pagebuilder-column:nth-child(3) [data-content-type='text'] {
      background: transparent linear-gradient(250deg, #eb5050 0%, #bf2828 100%); }
    .home-banner-blocks.home-banner-blocks.home-banner-blocks .pagebuilder-column:nth-child(4) [data-content-type='text'] {
      background: transparent linear-gradient(97deg, #807f7f 0%, #aaa 100%); }
  .home-banner-blocks.home-banner-blocks.home-banner-blocks [data-content-type='text'] {
    position: relative;
    z-index: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100px;
    padding: 22px 78px 22px 22px !important;
    overflow: hidden;
    border-radius: 0.3rem !important; }
    .home-banner-blocks.home-banner-blocks.home-banner-blocks [data-content-type='text']::after {
      content: '';
      position: absolute;
      top: 2.6rem;
      right: 2.4rem;
      z-index: -1;
      width: 26px;
      height: 26px;
      background-image: url(../images/arrow-right-white.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center; }
  .home-banner-blocks.home-banner-blocks.home-banner-blocks p {
    margin-bottom: 0; }
  .home-banner-blocks.home-banner-blocks.home-banner-blocks a {
    color: inherit;
    text-decoration: none !important; }
    .home-banner-blocks.home-banner-blocks.home-banner-blocks a::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1; }
    .home-banner-blocks.home-banner-blocks.home-banner-blocks a:hover {
      text-decoration: underline !important; }
  .home-banner-blocks.home-banner-blocks.home-banner-blocks img {
    position: absolute;
    top: 0;
    right: 0;
    width: 155px !important;
    height: 155px !important;
    object-fit: cover;
    transform: translateX(50%); }
  @media (min-width: 768px) and (max-width: 1239.98px) {
    .home-banner-blocks.home-banner-blocks.home-banner-blocks .pagebuilder-column-group {
      flex-wrap: wrap; }
    .home-banner-blocks.home-banner-blocks.home-banner-blocks .pagebuilder-column {
      width: 50% !important; }
      .home-banner-blocks.home-banner-blocks.home-banner-blocks .pagebuilder-column:nth-child(odd) {
        padding-right: 0 !important; }
    .home-banner-blocks.home-banner-blocks.home-banner-blocks [data-content-type='text']::after {
      top: 50%;
      transform: translateY(-50%); } }

.home-brands.home-brands.home-brands {
  margin-bottom: 2.6rem !important;
  text-align: center; }
  .home-brands.home-brands.home-brands ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none; }
    .home-brands.home-brands.home-brands ul ~ p a {
      display: inline-block;
      margin: 2rem 0 0;
      color: #000;
      padding: 0.6rem 2.4rem;
      border: 2px solid #000;
      background: #fff;
      font-weight: 600;
      font-size: 1.2rem;
      border-radius: 0.3rem;
      text-decoration: none !important; }
      .home-brands.home-brands.home-brands ul ~ p a:hover {
        padding: 0.8rem 2.6rem;
        background: linear-gradient(180deg, #59595a 0%, black 100%);
        color: #fff;
        border: none; }
  .home-brands.home-brands.home-brands li {
    margin: 1rem 1.3rem; }
    @media (min-width: 768px) {
      .home-brands.home-brands.home-brands li {
        margin-left: 2.2rem;
        margin-right: 2.2rem; } }
  .home-brands.home-brands.home-brands img {
    max-width: 128px;
    height: auto !important; }
    @media (min-width: 768px) {
      .home-brands.home-brands.home-brands img {
        max-width: 128px; } }

.home-categories.home-categories.home-categories {
  margin-bottom: 3.9rem !important;
  text-align: center;
  font-weight: 700; }
  .home-categories.home-categories.home-categories h2 {
    margin-bottom: 2.5rem;
    font-weight: 700;
    font-size: 1.3rem; }
  .home-categories.home-categories.home-categories .pagebuilder-column {
    flex-direction: row !important;
    flex-wrap: wrap;
    justify-content: center !important; }
    .home-categories.home-categories.home-categories .pagebuilder-column [data-content-type='text'] {
      position: relative;
      z-index: 0;
      flex: 0 0 150px !important;
      margin: 0 1.1rem 1rem !important;
      line-height: 1.2;
      font-size: 1.6rem; }
      @media (max-width: 575.98px) {
        .home-categories.home-categories.home-categories .pagebuilder-column [data-content-type='text'] {
          flex-basis: 100px !important; } }
    .home-categories.home-categories.home-categories .pagebuilder-column img {
      height: auto !important; }
    .home-categories.home-categories.home-categories .pagebuilder-column a {
      font-family: "Montserrat", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      text-decoration: none !important; }
      .home-categories.home-categories.home-categories .pagebuilder-column a:hover {
        text-decoration: underline !important; }
      .home-categories.home-categories.home-categories .pagebuilder-column a::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1; }

.home-tabs.home-tabs.home-tabs .tabs-navigation li {
  z-index: 0 !important; }

@media (max-width: 767.98px) {
  .home-tabs.home-tabs.home-tabs .tabs-navigation {
    display: none !important; } }

@media (min-width: 768px) {
  .home-tabs.home-tabs.home-tabs .tabs-navigation {
    display: flex;
    justify-content: center;
    margin-left: 1rem;
    margin-right: 1rem;
    border-bottom: 3px solid #000; }
    .home-tabs.home-tabs.home-tabs .tabs-navigation li {
      border: none !important; }
    .home-tabs.home-tabs.home-tabs .tabs-navigation a.tab-title {
      max-width: 31.2vw;
      width: 241px;
      margin-right: 0.3rem;
      padding: 1.8rem 1rem;
      border-radius: 0.3rem 0.3rem 0 0;
      text-align: center;
      font-family: "Montserrat", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 700;
      font-size: 1.6rem;
      background-color: rgba(221, 221, 221, 0.5);
      color: #000;
      text-decoration: none !important; }
      .home-tabs.home-tabs.home-tabs .tabs-navigation a.tab-title:hover {
        text-decoration: underline !important; }
    .home-tabs.home-tabs.home-tabs .tabs-navigation .ui-state-active a.tab-title {
      background-color: #000;
      color: #fff; } }

.home-tabs.home-tabs.home-tabs .tabs-content {
  border: none;
  z-index: 0 !important; }
  @media (max-width: 767.98px) {
    .home-tabs.home-tabs.home-tabs .tabs-content [data-content-type='tab-item'] {
      display: block !important;
      border-top: 1px solid #ddd; }
    .home-tabs.home-tabs.home-tabs .tabs-content h2 {
      margin-bottom: 3rem !important;
      font-weight: 700;
      font-size: 2.2rem;
      text-align: center; }
    .home-tabs.home-tabs.home-tabs .tabs-content [data-content-type='html'] {
      margin-top: 4rem !important;
      text-align: center; } }
  .home-tabs.home-tabs.home-tabs .tabs-content .product-item-wrap .info-wrapper {
    min-height: 320px; }

.home-tabs.home-tabs.home-tabs .slick-dots {
  margin-top: 1rem; }
  @media (max-width: 575.98px) {
    .home-tabs.home-tabs.home-tabs .slick-dots {
      display: none !important; } }

@media (max-width: 575.98px) {
  .home-blog .slick-dots {
    display: none !important; }
  .home-banner .slick-slider .slick-dots {
    display: none !important; } }

.home-blocks-1.home-blocks-1.home-blocks-1 {
  margin-bottom: 6rem !important; }

.home-clubted,
.home-twi {
  position: relative;
  z-index: 0;
  width: calc(50% - 2rem) !important;
  margin: 10px !important;
  padding: 0 !important;
  text-align: center;
  overflow: hidden;
  border-radius: 0.3rem !important; }

.home-clubted {
  display: flex;
  background-color: #fcd900;
  border-radius: 0.3rem; }
  .home-clubted svg {
    position: absolute;
    top: -2.5rem;
    left: 0;
    right: 0;
    z-index: -1;
    margin: auto;
    border-radius: 50%;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.57); }
  .home-clubted h2 {
    margin-bottom: 0.8rem;
    font-weight: 700;
    font-size: 2.8rem; }
  .home-clubted [data-content-type='text'] {
    margin: 15rem 1rem 1rem !important;
    padding: 3rem 2rem !important;
    background-color: #fff;
    text-align: center; }
  .home-clubted ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    padding-left: 0;
    margin-bottom: 8rem; }
    .home-clubted ul li {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 97px;
      height: 97px;
      margin-right: 2rem;
      padding: 10px;
      border-radius: 50%;
      border: 2px dashed #000;
      font-family: "Montserrat", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 700;
      text-align: center; }
  .home-clubted p {
    margin: 0; }
  .home-clubted .home-clubted-form {
    position: absolute;
    bottom: 8rem;
    left: 3rem;
    right: 3rem;
    z-index: 1; }
    @media (max-width: 575.98px) {
      .home-clubted .home-clubted-form {
        bottom: 8rem; } }
  .home-clubted .control::before {
    top: 1.2rem; }
  @media (min-width: 1024px) {
    .home-clubted .btn, .home-clubted a.pagebuilder-button-primary,
    .home-clubted .pagebuilder-button-primary, .home-clubted a.pagebuilder-button-secondary,
    .home-clubted .pagebuilder-button-secondary, .home-clubted .account-nav .item.item a[href*='logout'], .account-nav .item.item .home-clubted a[href*='logout'] {
      margin-left: 1rem; } }
  @media (min-width: 768px) and (max-width: 1023.98px) {
    .home-clubted .form-group {
      margin-bottom: 0.5rem; }
    .home-clubted ul {
      margin-bottom: 13rem; }
    .home-clubted .control, .home-clubted .btn, .home-clubted a.pagebuilder-button-primary,
    .home-clubted .pagebuilder-button-primary, .home-clubted a.pagebuilder-button-secondary,
    .home-clubted .pagebuilder-button-secondary, .home-clubted .account-nav .item.item a[href*='logout'], .account-nav .item.item .home-clubted a[href*='logout'] {
      width: 100%; } }
  @media (min-width: 576px) and (max-width: 767.98px) {
    .home-clubted .form-group {
      margin-bottom: 0; }
    .home-clubted .btn, .home-clubted a.pagebuilder-button-primary,
    .home-clubted .pagebuilder-button-primary, .home-clubted a.pagebuilder-button-secondary,
    .home-clubted .pagebuilder-button-secondary, .home-clubted .account-nav .item.item a[href*='logout'], .account-nav .item.item .home-clubted a[href*='logout'] {
      margin-left: 1rem; } }
  @media (max-width: 575.98px) {
    .home-clubted svg {
      top: -3.5rem; }
    .home-clubted h2 {
      margin-bottom: 1.5rem;
      font-size: 2.2rem; }
    .home-clubted .form-group {
      margin-bottom: 0.5rem; }
    .home-clubted ul {
      margin-bottom: 13rem; }
    .home-clubted li {
      margin-left: 1rem;
      margin-right: 1rem; }
    .home-clubted .control, .home-clubted .btn, .home-clubted a.pagebuilder-button-primary,
    .home-clubted .pagebuilder-button-primary, .home-clubted a.pagebuilder-button-secondary,
    .home-clubted .pagebuilder-button-secondary, .home-clubted .account-nav .item.item a[href*='logout'], .account-nav .item.item .home-clubted a[href*='logout'] {
      width: 100%; } }

.home-twi.home-twi {
  display: flex;
  align-items: center;
  justify-content: center !important;
  min-height: 453px;
  color: #fff; }
  .home-twi.home-twi::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.76); }
  .home-twi.home-twi h2 {
    max-width: 269px;
    margin-bottom: 3rem;
    font-weight: 700;
    font-size: 2.2rem;
    color: #fcd900; }
  .home-twi.home-twi ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 2rem;
    font-family: "Montserrat", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1.6rem;
    font-weight: 700; }
  .home-twi.home-twi li {
    margin-bottom: 0.1rem; }
  .home-twi.home-twi svg {
    margin-bottom: 4rem; }
  .home-twi.home-twi p {
    margin-bottom: 0; }
  .home-twi.home-twi a {
    display: inline-block;
    padding: 1.2rem 3.4rem;
    border: 2px solid #000;
    color: #000;
    text-align: center;
    background: #fff;
    text-transform: none;
    text-decoration: none !important;
    line-height: 1.5;
    font-family: "Montserrat", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 700;
    font-size: 1.4rem;
    border-radius: 0.3rem;
    /* stylelint-disable-line */ }
    .home-twi.home-twi a::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1; }

.home-blog.home-blog.home-blog {
  position: relative;
  padding: 4.9rem 0 2.7rem !important; }
  .home-blog.home-blog.home-blog, .home-blog.home-blog.home-blog::before, .home-blog.home-blog.home-blog::after {
    background-color: rgba(221, 221, 221, 0.5); }
  .home-blog.home-blog.home-blog::before, .home-blog.home-blog.home-blog::after {
    content: '';
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100%; }
  .home-blog.home-blog.home-blog::before {
    left: 100%; }
  .home-blog.home-blog.home-blog::after {
    right: 100%; }
  .home-blog.home-blog.home-blog h2 {
    margin-bottom: 1rem;
    text-align: center;
    font-weight: 700;
    font-size: 2.4rem; }
    .home-blog.home-blog.home-blog h2 ~ p {
      text-align: center; }
      .home-blog.home-blog.home-blog h2 ~ p a:only-child {
        display: inline-block;
        margin-bottom: 1.5rem;
        padding: 0.8rem 2.6rem;
        background: linear-gradient(180deg, #59595a 0%, black 100%);
        color: #fff;
        font-weight: 600;
        font-size: 12px;
        border-radius: 0.3rem;
        text-decoration: none !important; }
        .home-blog.home-blog.home-blog h2 ~ p a:only-child:focus, .home-blog.home-blog.home-blog h2 ~ p a:only-child:active {
          border: none; }
        .home-blog.home-blog.home-blog h2 ~ p a:only-child:hover {
          padding: 0.6rem 2.4rem;
          border: 2px solid #000;
          background: #fff;
          color: #000; }
  .home-blog.home-blog.home-blog .amblog-element-block {
    border: none;
    padding: 0 10px; }
  .home-blog.home-blog.home-blog .amblog-post-container {
    flex: 0 0 100%;
    max-width: 100%; }
  .home-blog.home-blog.home-blog .slick-slide > div {
    display: flex;
    width: 100%;
    height: auto;
    align-items: stretch;
    justify-content: center; }

.home-blocks-2.home-blocks-2.home-blocks-2 {
  margin: 4.3rem 0 0 !important; }
  .home-blocks-2.home-blocks-2.home-blocks-2 .pagebuilder-column {
    position: relative;
    width: calc(33.33% - 10px) !important;
    margin-left: 5px !important;
    margin-right: 5px !important;
    margin-bottom: 17px !important;
    padding: 3.5rem 1.8rem 1.5rem !important;
    border: 1rem solid #fcd900 !important;
    border-radius: 0.8rem !important; }
    @media (min-width: 1024px) and (max-width: 1239.98px) {
      .home-blocks-2.home-blocks-2.home-blocks-2 .pagebuilder-column {
        padding-left: 3.8rem !important;
        padding-right: 3.8rem !important; } }
    @media (min-width: 1240px) {
      .home-blocks-2.home-blocks-2.home-blocks-2 .pagebuilder-column {
        width: calc(33.33% - 20px) !important;
        margin-left: 10px !important;
        margin-right: 10px !important;
        padding: 4.5rem 4.6rem 2.5rem !important; } }
  .home-blocks-2.home-blocks-2.home-blocks-2 h2 {
    font-family: "Montserrat", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 700;
    font-size: 2.2rem; }
  .home-blocks-2.home-blocks-2.home-blocks-2 [data-content-type='text'] ~ [data-content-type='text'] {
    margin-top: auto !important; }
  .home-blocks-2.home-blocks-2.home-blocks-2 p {
    font-family: "Montserrat", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1.6rem; }
  .home-blocks-2.home-blocks-2.home-blocks-2 ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 2.4rem; }
    .home-blocks-2.home-blocks-2.home-blocks-2 ul li {
      display: flex;
      margin-bottom: 0.4rem; }
      .home-blocks-2.home-blocks-2.home-blocks-2 ul li::before {
        content: '';
        display: inline-block;
        width: 22.52px;
        height: 22.52px;
        margin-right: 2rem;
        background-image: url("../images/tick.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center; }
  .home-blocks-2.home-blocks-2.home-blocks-2 a {
    display: inline-block;
    color: #000;
    padding: 0.6rem 2.4rem;
    border: 2px solid #000;
    background: #fff;
    font-weight: 600;
    font-size: 1.2rem;
    border-radius: 0.3rem;
    text-decoration: none !important; }
    .home-blocks-2.home-blocks-2.home-blocks-2 a:hover {
      padding: 0.8rem 2.6rem;
      background: linear-gradient(180deg, #59595a 0%, black 100%);
      color: #fff;
      border: none; }
    @media (max-width: 1239.98px) {
      .home-blocks-2.home-blocks-2.home-blocks-2 a {
        margin-left: -2px; } }
  .home-blocks-2.home-blocks-2.home-blocks-2 svg,
  .home-blocks-2.home-blocks-2.home-blocks-2 img {
    position: absolute;
    right: 0.8rem;
    bottom: 2.7rem;
    max-width: 40% !important; }
    @media (min-width: 1024px) and (max-width: 1239.98px) {
      .home-blocks-2.home-blocks-2.home-blocks-2 svg,
      .home-blocks-2.home-blocks-2.home-blocks-2 img {
        right: 1.2rem; } }
    @media (min-width: 1240px) {
      .home-blocks-2.home-blocks-2.home-blocks-2 svg,
      .home-blocks-2.home-blocks-2.home-blocks-2 img {
        right: 2.6rem;
        bottom: 3.6rem; } }

@media (min-width: 1024px) {
  .home-usps {
    display: none !important; } }

@media (max-width: 1023.98px) {
  .home-usps .header-after.product-advertlist {
    margin-top: 0;
    padding-top: 0;
    border: none; } }
