.message.message {
  margin-bottom: 1.3rem;
  padding: $alert-padding-y $alert-padding-x;
  border-radius: $alert-border-radius;
  color: $white;
  text-align: left;
  font-size: 1.2rem;

  span,
  div {

    &::before {
      display: none !important;
    }
  }

  .product-info-main & {

    div {
      width: 100%;
    }
  }

  a {
    text-decoration: underline;
    color: inherit !important;

    &:hover {
      text-decoration: none;
    }
  }

  @each $color, $value in $theme-colors {

    &.#{$color} {

      @include alert-variant(theme-color-level($color, $alert-bg-level), theme-color-level($color, $alert-border-level), theme-color-level($color, $alert-color-level));
    }
  }

  &.message-primary {
    background-color: $primary-light;
    color: $black;
  }
}

.alert {
  font-size: 1.2rem;

  &:empty {
    display: none;
  }

  &.help-text {
    position: relative;
    text-align: center;

    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      width: 0;
      height: 0;
      transform: translateX(-50%);
      border-top-width: 8px;
      border-top-style: solid;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
    }

    &.arrow-top::after {
      border-bottom-width: 8px;
      border-bottom-style: solid;
      border-top-width: 0;
      bottom: 100%;
      top: auto;
    }

    &.arrow-bottom::after {
      border-top-width: 8px;
      border-top-style: solid;
      border-bottom-width: 0;
      top: 100%;
      bottom: auto;
    }
  }

  &.alert-primary {
    background-color: $primary-light;
    color: $black;

    &.help-text::after {
      border-top-color: $primary-light;
    }

    &.arrow-top::after {
      border-top-color: transparent;
      border-bottom-color: $primary-light;
    }

    &.arrow-bottom::after {
      border-bottom-color: transparent;
      border-top-color: $primary-light;
    }
  }

  &.alert-secondary,
  &.alert-dark {
    background-color: $secondary;
    color: $white;

    &.help-text::after {
      border-top-color: $secondary;
    }

    &.arrow-top::after {
      border-top-color: transparent;
      border-bottom-color: $secondary;
    }

    &.arrow-bottom::after {
      border-bottom-color: transparent;
      border-top-color: $secondary;
    }
  }

  p {
    margin-bottom: 0;
  }
}
