.page-layout-2columns-left {

  .columns {
    display: flex;
  }

  .page-title-wrapper {
    border-bottom: 1px solid $light;
    margin-bottom: 3rem;
  }

  [data-content-type='faq'] {

    h3,
    [data-element='answer'] {
      padding-left: 1.8rem !important;
    }

    h3 {
      font-size: 1.3rem;
    }
  }

  .column.main {
    flex: 0 1 100%;
    width: auto;
    padding-left: 0;

    @include media-breakpoint-up(lg) {
      flex-basis: 650px;
      margin-left: auto;
    }

    @include media-breakpoint-up(xl) {
      flex-basis: 681px;
      margin-right: auto;
    }
  }

  .sidebar-main {

    @include media-breakpoint-up(lg) {
      order: -1;
      flex: 0 1 275.38px;
      margin-right: 2rem;
      padding-right: 0;
    }

    @include media-breakpoint-up(xl) {
      margin-right: 4rem;
    }
  }
}
