.price-box.price-final_price {

  .pricing {
    text-align: center;
    position: relative;
    margin-top: 0;
    padding-left: 0;
    padding-right: 1rem;

    &.strikethrough-price .price-wrapper .price {
      text-decoration: line-through;

      .product-info-main & {
        text-decoration: none;

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          width: 100%;
          transform: translateY(-50%);
          height: 3px;
          margin-top: -1px;
          background-color: $black;
        }
      }
    }

    &.cashback-price .price-wrapper .price {
      color: $orange;
    }

    &.tradein-price {

      .price-wrapper {

        .price {
          color: $orange;
        }
      }
    }

    .price-label {
      display: block;
      font-size: 1.1rem;
      line-height: 1.5;

      @include media-breakpoint-up(md) {
        text-align: left;
      }

      @include media-breakpoint-only(xs) {
        line-height: 1.4;
        font-size: 1.3rem;
      }

      &::after {
        content: '';
      }
    }

    .price-wrapper {
      display: block;

      @include media-breakpoint-up(md) {
        text-align: left;
      }

      .price {
        position: relative;
        line-height: 1.5em;
        font-family: $headings-font-family;
        font-weight: 700;

        .product-info-main & {
          font-size: 2.2rem;

          @include media-breakpoint-up(lg) {
            font-size: 3rem;
          }
        }
      }
    }
  }
}
