.table-comparison {
  min-width: 100%;

  &.table-striped tbody tr:nth-of-type(even) {
    background: none;

    td {
      background-color: $table-accent-bg;
    }
  }

  thead + tbody {

    &,
    & ~ tbody {
      border: none;
    }

    tr {
      position: sticky;
      top: 0;
      z-index: 1;
      box-shadow: 0 3px 0 rgba($black, 0.16);
    }

    .cell {
      background: $white;
      font-size: 1.2rem;

      .product-item-name a,
      .price {
        font-size: inherit;
      }

      .product-item-name {
        margin-right: -1rem;

        a {
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .price {
        font-weight: 700;
      }
    }
  }

  tr {
    display: flex;
    justify-content: stretch;
  }

  td {

    &.cell {
      width: 33.33%;
      max-width: 33.33%;

      @include media-breakpoint-up(sm) {
        width: calc(414px / 3);
      }
    }
  }

  th {

    + td.cell:last-child {
      width: 100%;
      max-width: 100%;
    }

    + td + td.cell:last-child {
      width: 66.66%;
      max-width: 66.66%;
    }
  }

  &.two-compares {

    td {
      width: 50% !important;
      max-width: 50% !important;
    }
  }

  .product-item-photo {
    margin-bottom: auto;
    margin-left: 0;
    padding-bottom: 15px;
  }

  .attribute.label {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1rem;
  }

  .cell {
    border: none !important;
    padding-left: 2rem;
    vertical-align: bottom;
  }

  .price-box {
    margin: 0;
  }

  .pricing {

    .price-label {
      @include sr-only; /* stylelint-disable-line */
    }

    &:not(:last-child) {
      display: none;
    }
  }

  .product-image-container {
    max-width: 80px;
  }

  .compare-product-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    position: relative;
    width: 100%;
    height: 100%;
  }

  .btn-remove.btn-remove {
    position: absolute;
    top: 0.2rem;
    right: 0.7rem;
    width: 26px;
    height: 26px;
    z-index: 2;
  }
}

.table-wrapper.comparison {
  flex-grow: 1;
  margin: 0;
  padding: 0;
  overflow: auto;
  height: calc(100vh - 60px); // required to deal with position-sticky bug where it doesn't like being in an overflowed ancestor
}
