.twi-banner.twi-banner.twi-banner {
  position: relative;

  @include media-breakpoint-down(xs) {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba($black, 0.66);
    transition: opacity 0.3s;
  }

  &:hover {
    cursor: pointer;

    &::before {
      opacity: 0.8;
    }
  }

  [data-content-type='html'] svg {
    position: absolute;
    top: 50%;
    left: 10%;
    z-index: 2;
    transform: translateY(-50%);
    pointer-events: none;

    @include media-breakpoint-down(md) {
      top: 3rem;
      transform: none;
      left: 0;
      right: 0;
      margin: auto;
      width: 150px;
      height: auto;
    }

    @include media-breakpoint-down(xs) {
      top: 2rem;
      width: 115px;
    }
  }

  [data-content-type='video'] {
    position: relative;

    &::before,
    &::after {
      position: absolute;
      left: 0;
      right: 0;
      z-index: 2;
      margin: auto;
      pointer-events: none;

      @include media-breakpoint-up(lg) {
        transform: translateY(4.7rem);
      }
    }

    &::before {
      content: '';
      bottom: 50%;
      width: 67px;
      height: 67px;
      background-image: url(../images/play.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      border-radius: 99px;

      @include media-breakpoint-down(md) {
        width: 50px;
        height: 50px;
        bottom: 20%;
      }

      @include media-breakpoint-down(xs) {
        width: 35px;
        height: 35px;
      }
    }

    &::after {
      content: 'Watch video';
      top: 50%;
      padding-top: 1rem;
      font-family: $headings-font-family;
      font-weight: 600;
      font-size: 1.3rem;
      color: $white;
      text-shadow: 0 3px 6px #00000040;
      text-align: center;

      @include media-breakpoint-down(md) {
        top: auto;
        bottom: 16%;
      }

      @include media-breakpoint-down(xs) {
        bottom: 9%;
      }
    }
  }

  &.clicked {

    &::before,
    [data-content-type='html'] svg,
    [data-content-type='video']::before,
    [data-content-type='video']::after {
      display: none;
    }
  }
}

.twi-anchors.twi-anchors.twi-anchors {
  margin: 1.4rem -0.7rem !important;

  @include media-breakpoint-down(sm) {
    margin-bottom: 0.7rem !important;
  }

  .pagebuilder-column {
    padding: 0.7rem !important;
  }

  p {
    margin: 0;
  }

  [data-content-type='text'] a {
    position: relative;
    display: block;
    height: 100px;
    width: 100%;
    padding: 2.2rem 5.2rem 2.2rem 2.2rem;
    border-radius: $border-radius;
    font-family: $headings-font-family;
    font-weight: 700;
    font-size: 1.6rem;
    text-decoration: none;
    color: $white;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      background-color: rgba($black, 0.66);
      pointer-events: none;
      transition: opacity 0.3s;
      opacity: 0;
    }

    &::after {
      content: '';
      position: absolute;
      top: 2.1rem;
      right: 2.4rem;
      z-index: 1;
      width: 26px;
      height: 26px;
      background-image: url(../images/arrow-right-white.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }

    &:hover::before {
      opacity: 0.3;
    }
  }

  .twi-black a {
    background-color: $secondary;
  }

  .twi-yellow a {
    background: transparent linear-gradient(250deg, $primary 0%, $primary 47%, #ff8002 100%);
  }

  .twi-red a {
    background: transparent linear-gradient(250deg, $orange 0%, #bf2828 100%);
  }

  .twi-grey a {
    background: transparent linear-gradient(97deg, #807f7f 0%, #aaa 100%);
  }
}

.twi-description-inner.twi-description-inner.twi-description-inner {
  position: relative;
  max-width: 641px;
  margin: 0 auto !important;
  padding: 8rem 0 33rem !important;
  line-height: 1.75;
  text-align: center;
  color: $white;

  @include media-breakpoint-down(md) {
    padding: 5rem 0 13rem !important;
  }

  &,
  &::before,
  &::after {
    background-color: $secondary;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100%;
  }

  &::before {
    left: 100%;
  }

  &::after {
    right: 100%;
  }

  h2 {
    max-width: 412px;
    margin: 0 auto 5rem;
    font-weight: 700;
    font-size: 3.5rem;
    color: $primary;
  }
}

.twi-events-intro.twi-events-intro.twi-events-intro {
  margin: 9rem auto !important;
  position: relative;
  z-index: 1;

  @include media-breakpoint-down(md) {
    margin: 5rem auto !important;
  }

  &.twi-events-intro-first {
    margin-top: -26rem !important;

    @include media-breakpoint-down(md) {
      margin-top: -9rem !important;

      .twi-block-image {
        display: none !important;
      }
    }
  }

  .pagebuilder-column-group {

    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
    }
  }

  .pagebuilder-column {
    align-self: center !important;

    @include media-breakpoint-down(md) {
      width: 100% !important;
    }

    @include media-breakpoint-up(lg) {

      &:first-child {
        margin-right: 5rem !important;
      }

      &:last-child {
        margin-left: -8.5rem !important;
      }
    }

    &.twi-block-image {
      padding: 2.4rem 0 !important;
      background-color: $primary;

      @include media-breakpoint-down(md) {
        order: -1;
        position: relative;
        z-index: 1;
        margin-bottom: 1.3rem !important;
        padding: 1.2rem 0 !important;

        &::before {
          content: '';
          background: $white;
          position: absolute;
          top: 0;
          right: 0;
          width: 48px;
          height: 100%;
          z-index: -1;
        }
      }

      @include media-breakpoint-up(lg) {
        flex-basis: 620px;
        width: 645px !important;
      }

      &:last-child {

        @include media-breakpoint-down(md) {

          &::before {
            right: auto;
            left: 0;
          }
        }

        figure {
          display: flex;
          justify-content: flex-end;
        }
      }

      img {

        @include media-breakpoint-up(lg) {
          max-width: 120% !important;
        }
      }
    }

    &.twi-block-text {
      position: relative;
      padding: 2.6rem !important;
      border: 10px solid $primary !important;
      border-radius: 8px !important;
      background-color: $white;
      line-height: 1.75;

      @include media-breakpoint-up(lg) {
        flex-basis: 588px;
        padding: 3.5rem !important;

        h3 {
          margin-bottom: 1.5rem;
        }
      }

      h3 {
        font-weight: 700;
        font-size: 2.2rem;
      }
    }
  }
}

.twi-upcoming.twi-upcoming.twi-upcoming {
  margin: 0 -1.5rem 4rem !important;

  h2 {
    margin-bottom: 4rem;
    text-align: center;
    font-weight: 700;
    font-size: 2.4rem;
  }

  li {
    max-width: 100% !important;
  }
}

.amlocator-location-main {

  .event-list.row.slick-initialized {

    .slick-list {
      min-width: 100%;
    }
  }
}
