body {
  font-size: 1.3rem;
}

.navigation, .breadcrumb, .page-header .header.panel, .header.content, .footer-container, .page-wrapper > .widget, .page-wrapper > .page-bottom, .block.category.event, .top-container, .page-main {  /* stylelint-disable-line */
  @extend .container;
}

.small-payment-logos {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-around;
  align-items: baseline;
  justify-content: space-around !important;
  max-width: 400px;

  @include media-breakpoint-down(md) {
    align-items: center;
    justify-content: center !important;
    margin: auto;

    .logo {
      margin: 0.5rem 1rem !important;
    }
  }
}

[data-content-type='row'][data-appearance='contained'] {
  max-width: map-get($container-max-widths, 'xl');
  width: 100%;
}

.page-wrapper {
  left: auto !important;
}

[data-content-type='row'][data-appearance='contained'] [data-element='inner'] {
  margin: 0 !important;
  padding: 0 !important;
}

.main {

  a:not(.btn) {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.teds-border {
  border: 10px solid $primary;
  border-radius: 8px;
}

.loading-mask {
  background: none;
}

.loader-container {
  bottom: 0;
  left: 0;
  margin: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba($black, 0.3);
}
