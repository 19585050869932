.modal-popup {

  .action-close {

    &::before {
      content: '';
      width: 24px;
      height: 24px;
      background-image: url('../images/plus.svg');
      background-size: 14px 14px;
      background-position: center;
      background-repeat: no-repeat;
      transform: rotate(45deg);
    }
  }

  &.confirm .modal-inner-wrap {
    height: auto;
    margin: 0 auto;
    top: 50%;
    transform: translateY(-50%);
  }

  .modal-title {
    font-weight: 700;
    font-size: 2.4rem;
    text-align: center;
  }

  .modal-footer {
    display: flex;
    justify-content: flex-end;

    .btn {
      margin-left: 1rem;
    }

    .primary {
      background-color: $primary;
      border-color: $primary;
      color: $black;
      font-family: $headings-font-family;
      font-size: 1.2rem;

      &:hover {
        background-color: $black;
        color: $white;
      }
    }
  }

  .modal-inner-wrap {
    height: calc(100% - 10rem);
  }
}

.modals-overlay {
  cursor: pointer;
}

.subscribe-modal.subscribe-modal.subscribe-modal {
  left: 0;
  overflow: hidden;
  contain: content;

  .modal-inner-wrap {
    overflow: visible;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    max-width: 360px;
    width: 100%;
    margin: auto;
    transform: translate(-50%, -50%);
    border: 10px solid $primary;
    border-radius: 8px;

    @include media-breakpoint-down(xs) {
      max-width: 330px;
    }

    @media (max-width: 359px) {
      max-width: 264px;
    }

    &::before {
      content: '';
      position: absolute;
      bottom: -60px;
      left: -75px;
      z-index: 1;
      width: 156px;
      height: 274px;
      background-image: url('../images/ted.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      pointer-events: none;

      @include media-breakpoint-down(xs) {
        bottom: -53px;
        left: -56px;
        width: 130px;
        height: 250px;
      }

      @media (max-width: 359px) {
        bottom: -60px;
        left: -66px;
      }
    }

    .modal-content {
      padding: 2.9rem 3.5rem 1.5rem;

      @include media-breakpoint-down(xs) {
        padding: 2.4rem 3rem 1.3rem;
      }

      @media (max-width: 359px) {
        padding: 1.9rem 2.5rem 0.5rem;
      }
    }

    header {
      padding: 0;
    }

    footer {
      display: none;
    }

    .insert-container {
      padding: 0 !important;
    }

    .amform {
      margin-top: 3rem;
    }
  }

  h3 {
    line-height: 1.1;
    font-weight: 800;
    font-size: 5.9rem;

    small {
      vertical-align: text-top;
      line-height: 1.5;
      font-weight: 700;
      font-size: 1.8rem;
    }

    @include media-breakpoint-down(xs) {
      font-size: 5.3rem;
    }

    @media (max-width: 359px) {
      font-size: 4.1rem;

      small {
        line-height: 1.25;
      }
    }
  }

  .h4 {
    display: block;
    font-weight: 700;
    font-size: 1.6rem;

    @include media-breakpoint-down(xs) {
      font-size: 1.45rem;
    }

    @media (max-width: 359px) {
      font-size: 1.13rem;
    }
  }

  .teds-border {
    border: none;
    border-radius: 0;
    padding: 0 !important;
  }

  .btn {
    width: 100%;
  }

  .action-close {
    position: absolute;
    top: -56px;
    right: 0;
    z-index: 1;
    padding: 1rem;
    margin-right: -1.5rem;

    &::before {
      width: 28.28px;
      height: 28.28px;
      background-image: url('../images/close-thin.svg');
      background-size: contain;
      transform: none;
    }
  }

  p {
    margin: 0;
  }
}
