.page-header {
  position: relative;
  z-index: 100;
  margin-bottom: 0;
  background-color: $primary;
  border-bottom: 1px solid $white;
  color: $black;

  @include media-breakpoint-down(md) {
    border-bottom: none;
  }

  @include media-breakpoint-down(xs) {
    z-index: 101;
    background: none;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 108px;
      background-color: $primary;
    }

    &::after {
      content: '';
      position: absolute;
      top: 108px;
      right: 0;
      z-index: 1;
      width: 100vw;
      background-image: url(../images/nav-curve.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-color: $secondary;
      transition: background-color 0s 0.3s;
    }

    .nav-open &::after {
      height: 10px;
      background-color: $black;
    }
  }

  a:not(.btn) {
    color: inherit;
  }

  .panel.wrapper {

    @include media-breakpoint-down(md) {
      display: none;
    }

    @include media-breakpoint-up(lg) {
      border-bottom: 1px solid $white;
    }
  }

  .panel.header {
    padding: 0 map-get($spacers, 3) 0 0;
  }

  .header-content-container {
    background-color: $primary;

    @include media-breakpoint-up(lg) {
      background: $gradient-primary;
    }

    @include media-breakpoint-down(md) {
      position: relative;
      padding-top: 4rem;
    }

    @include media-breakpoint-down(sm) {
      background: none;
    }
  }

  .action.showcart {

    &::before {
      display: none !important;
    }

    @include media-breakpoint-down(md) {
      margin-left: 0;
      margin-right: 2.5rem;

      .header-links-label {
        display: none;
      }
    }
  }

  .header.content {
    position: static;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $navbar-height;
    padding-top: 0;

    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
      max-width: 100%;
      height: auto;
      padding: 0;
    }

    &::before,
    &::after {
      display: none;
    }
  }
}

.top-bar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top-bar {
  font-family: $headings-font-family;

  ul {
    display: flex;
    align-items: center;
    list-style: none;
    margin-bottom: 0;
    padding: 0;
  }

  li {
    position: relative;
    margin-bottom: 0;

    ul {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 4;
      min-width: 100%;
      width: max-content;
      padding: 1rem 0;
      background-color: $white;
      box-shadow: $box-shadow-lg;

      a {
        padding: 1rem 1.8rem;

        &:hover {
          text-decoration: underline;
        }
      }

      ul {
        top: 0;
        left: 100%;
        box-shadow: 5px 3px 6px #00000029;
      }
    }

    &:hover,
    &:focus-within {

      > ul {
        display: block;
      }
    }
  }

  a {
    display: block;
    padding: 1.5rem 1.2rem;
    font-weight: 700;
    font-size: 1.3rem;

    &:not(:only-child)::after {
      content: '';
      display: inline-block;
      margin-left: 5px;
      width: 14px;
      height: 7px;
      background-image: url('../images/down.svg');
      background-size: 8.03px 5px;
      background-position: center;
      background-repeat: no-repeat;
    }

    @include media-breakpoint-up(xl) {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  ul ul a:not(:only-child)::after {
    transform: rotate(270deg);
  }
}

.top-left {

  li {

    &:hover,
    &:focus-within {

      a {
        text-decoration: none;
        background-color: $white;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    margin-left: -0.6rem;
  }
}

.top-right {

  li {

    &:not(:last-child) {

      a {
        padding-left: 1.3rem;
        padding-right: 1.3rem;
      }
    }

    &:last-child() {
      margin-left: 1rem;
    }
  }

  .widget.block {
    margin: 0;
  }

  .pagebuilder-button-link.pagebuilder-button-link {
    margin: 0;
    background: $gradient-dark;
    color: $white !important;
    font-weight: 600;
    @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-line-height-sm, $btn-border-radius-sm); /* stylelint-disable-line */

    &:active {
      color: $white;
    }

    &:hover,
    &:focus {
      text-decoration: none;
      border: 2px solid $secondary;
      color: $black !important;
      background: none;
      @include button-size($btn-padding-y-sm - $btn-border-width, $btn-padding-x-sm - $btn-border-width, $btn-font-size-sm, $btn-line-height-sm, $btn-border-radius-sm); /* stylelint-disable-line */
    }
  }
}

.logo {
  z-index: 0;
  margin-bottom: 0;

  @include media-breakpoint-down(md) {
    flex-shrink: 1;
    max-width: 178px;
    width: auto;
    margin: 0.8rem auto;
    z-index: 2;
    padding-bottom: 2px;
  }
}

@include media-breakpoint-up(md) {

  .header .logo img {
    width: auto;
    height: auto;
  }
}

.nav-toggle {
  display: none;

  @include media-breakpoint-down(md) {
    position: static;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 60px;
    margin-left: 0.5rem;
    padding: 0;
    border-radius: 0;

    &::before {
      display: none;
    }

    svg:last-child {
      display: none;
    }

    .nav-open & svg {
      display: none;

      &:last-child {
        display: block;
      }
    }
  }
}

.header-links {

  &,
  .links,
  li {
    display: flex !important;
    align-items: center;
    height: 100%;
  }

  .header-link {
    display: block;
    margin-left: 3.4rem;
    font-family: $headings-font-family;
    font-weight: 700;
    font-size: 1.3rem;
    text-align: center;

    @include media-breakpoint-only(lg) {
      margin-left: 2.5rem;
    }

    .expert-advice {

      ul {
        list-style: none;
        font-weight: 600;
        font-size: 1rem;
        align-items: center;
      }

      li.online {
        padding-right: 3.7rem;
      }

      li.online::before {
        content: '';
        border-radius: 0.375rem;
        height: 0.75rem;
        width: 0.75rem;
        margin-right: 0.5rem;
        background-color: $green;
      }
    }

    .svg-container {
      width: 40px;
      height: 40px;
      margin: 0 auto 0.5rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      text-decoration: none;

      .svg-container {
        background-color: $primary-light;
      }
    }
  }

  .links {
    list-style: none;
    margin: 0;
    padding: 0;

    .header-link-chat.online {
      padding-top: 1.5rem;
    }

    li {
      position: relative;
      margin: 0;

      &:first-child .header-link {
        margin-left: 0;
      }
    }

    .wishlist {

      .counter.qty.qty.qty {
        right: -0.5rem;
      }
    }

    @include media-breakpoint-down(md) {

      .header-link-account,
      .header-link-store {
        position: absolute;
        top: 0;
        width: 50%;
        height: 40px;
        border-bottom: 1px solid $white;
        text-align: center;

        .header-link {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          margin: 0;
          font-size: 1.1rem;

          .svg-container {
            width: auto;
            height: auto;
            margin: 0 1rem 0 0;
            background: none;
          }
        }
      }

      .header-link-store {
        left: 0;

        &:only-child {
          width: 100%;
        }

        svg {
          width: 18px;
          height: 18px;
          stroke-width: 2px;
        }
      }

      .header-link-account {
        right: 0;
        border-left: 1px solid $white;

        .header-link > .svg-container > svg {
          width: 16.08px;
          height: 15px;
        }
      }
    }
  }

  .counter.qty.qty.qty:not(:empty) {
    position: absolute;
    top: 1rem;
    right: 1.8rem;
    width: 21px;
    height: 21px;
    margin: -8px 0 0;
    padding: 0;
    border-radius: 50%;
    background-color: $red;
    color: $white;
    line-height: 22px;
    text-align: center;
    font-weight: 600;
    font-size: 1.2rem;

    @include media-breakpoint-up(lg) {
      right: -0.9rem;
    }
  }

  .wishlist {

    @include media-breakpoint-down(md) {
      display: none !important;
    }
  }

  .header-link-chat {

    @include media-breakpoint-down(md) {
      display: none !important;
    }
  }
}

.header-after {
  border-bottom: 1px solid $light;

  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;

    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-evenly;
    }

    li {
      display: flex;
      align-items: center;
      margin: 0.7rem 0;
      font-weight: 700;
      font-size: 1.3rem;

      @include media-breakpoint-down(sm) {
        width: 50%;
      }

      a {
        display: flex;
        align-items: center;

        @include media-breakpoint-down(md) {
          max-width: 145px;
          flex-direction: column;
          margin: 0 auto;
          text-align: center;
          text-decoration: none !important;
        }
      }

      a::before {
        content: '';
        flex-shrink: 0;
        width: 37px;
        height: 37px;
        margin-right: 1.1rem;
        background-image: url('../images/header-after-2.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;

        @include media-breakpoint-down(md) {
          margin-right: 0;
          margin-bottom: 1.1rem;
        }
      }

      &:nth-child(1) a::before {
        background-image: url('../images/header-after-1.svg');
      }

      &:nth-child(3) a::before {
        background-image: url('../images/header-after-3.svg');
      }

      &:nth-child(4) a::before {
        background-image: url('../images/header-after-4.svg');
      }
    }
  }
}
