.account-nav {

  .content {
    background: none;
    padding: 0;
  }

  .item.item {
    width: 100%;

    ~ .item {
      margin-top: 1.5rem;
    }

    strong,
    a {
      color: $secondary;
      border: none;
      padding: 0;
      font-weight: 400;
    }

    a:not([href*='logout']) {
      color: inherit;

      &:hover {
        background: none;
        color: $secondary;
        text-decoration: underline;
      }
    }

    a[href*='logout'] {
      @extend .btn;
      @extend .btn-sm;
      @extend .btn-outline-secondary;
    }
  }
}

.account {

  .page-main {

    @include media-breakpoint-down(sm) {
      padding-top: 0;
    }
  }

  .table {

    th {
      border-bottom: none !important;
    }
  }

  .limiter-options {
    min-width: 80px;
  }

  .additional-addresses {

    th,
    td {

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }

    td {
      font-size: 1.2rem;
    }

    .delete {
      display: inline-block;
    }
  }
}

.page-title-wrapper.dashboard-title {
  border: none;
  margin-bottom: 0;
}

.form-address-edit {

  .country select {
    height: $input-height;
    padding: $input-padding-y $input-padding-x;
    font-family: $input-font-family;
    color: $input-color;
    border: $input-border-width solid $input-border-color;

    @include border-radius($input-border-radius, 0);
    @include box-shadow($input-box-shadow);
    @include transition($input-transition);

    &::-ms-expand {
      background-color: transparent;
      border: 0;
    }

    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 $input-color;
    }

    @include form-control-focus($ignore-warning: true);

    &:disabled,
    &[readonly] {
      background-color: $input-disabled-bg;
      opacity: 1;
    }
  }
}

.customer-account-login {

  .block-minicart .block-customer-login .block-content {
    margin-top: 0;
  }

  .block-customer-login {
    width: 100%;

    .block-content {
      width: 100%;

      form.form-login .register {
        max-width: 350px;
        margin: 0 auto;

        @include media-breakpoint-down(xs) {
          padding-left: 0;
          padding-right: 0;
        }
      }

      .social-login-wrapper > div {
        max-width: 300px;

        @include media-breakpoint-down(md) {
          margin-left: auto;
          margin-right: auto;
        }

        .svg-container {
          bottom: auto;
          border: none;
        }
      }
    }
  }
}

.customer-account-edit,
.customer-address-form {

  .fieldset {

    > .field {
      margin-bottom: 0;
    }

    .floating-label {
      margin-bottom: 1.5rem;
    }
  }
}

.customer-account-logoutsuccess .page-main {
  text-align: center;
  margin-top: 3rem;
}

.sales-order-history {

  .pager {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .toolbar-amount {
      float: none;
      width: auto;
      flex: none;
    }

    .pages {
      position: relative;
      width: auto;

      .action.next,
      .action.previous {
        background-image: url('../images/arrow-right.svg');
        background-position: right 1.2rem center;
        background-repeat: no-repeat;
        background-size: 24px 24px;
        border: none;

        &.previous {
          transform: rotate(180deg);
        }

        &::before {
          content: ' ';
        }
      }
    }
  }
}
