
.kuSearchResultsPageContainer .filter-sidebar.filter-sidebar,
.layered-filter-block {

  &.filter-no-options {
    display: none;
  }

  .filter-clear {
    text-decoration: underline;
    font-size: 1.2rem;

    &:hover {
      text-decoration: none;
    }
  }

  .filter-title.filter-title {
    display: flex;
    align-items: center;
    background-color: $black;
    font-weight: 700;
    color: $white;
    padding: 8px;

    svg {

      @include media-breakpoint-up(lg) {
        margin-right: 0.8rem;
      }
    }

    strong {
      font-size: 1.3rem;
      line-height: 24px;
      display: inline-block;
      outline: none;

      @include media-breakpoint-down(md) {
        margin: auto;
        text-align: center;
        font-family: $headings-font-family;
        font-size: 1.6rem;
      }
    }
  }

  .active .am-show-more.-active {
    padding: 0.5rem 1.5rem;
    margin-right: 2.28rem;
  }

  .filter-options-item {

    .am-labels-folding .item {
      align-items: normal;
    }

    .filter-options-title {
      position: relative;
      padding: 8.5px 13px;
      background: none;
      border: none;
      border-bottom: solid 1px $light;
      box-shadow: none;
      font-size: 1.3rem;
      line-height: 22px;
      margin: 0;
      outline: none;
      text-align: left;
      width: 100%;
      color: $black;

      &::after {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 15px;
        background-image: url('../images/plus.svg');
        display: block;
        width: 8px;
        height: 8px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        content: ' ';
      }

      @include media-breakpoint-down(md) {
        padding-left: 28px;

        &::after {
          padding-right: 28px;
        }
      }
    }

    &.active {

      .filter-options-title::after {
        background-image: url('../images/minus.svg');
      }
    }

    .filter-options-content {
      margin-bottom: 8px;
      padding-left: 13px;

      .am-filter-price {
        max-width: 65px;
        padding-left: 1.1rem;
        padding-right: 1.1rem;
      }

      ul.items.items,
      ol.items.items {
        margin: 0;
        padding-top: 12px;
      }

      .am_shopby_link_selected {
        font-weight: 400;
      }

      .count {
        color: $black;
      }

      .item.item {
        display: flex;
        margin-left: 0 !important;
        padding-left: 2.9rem;
        font-size: 1.2rem;

        @include media-breakpoint-down(md) {
          padding-left: 5.4rem;

          .custom-control-label {
            margin-top: 0.8rem;
          }
        }

        a {
          margin-left: 8px;
          color: inherit;

          &,
          .count {
            color: inherit;
          }
        }
      }
    }
  }
}

.am_shopby_apply_filters {

  .am-show-button {
    position: static;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-shadow: 14px 8px 16px rgba($black, 0.16);
    padding: 10px;

    &,
    &::before {
      background-color: #fff;
    }

    &::before {
      position: absolute;
      top: 8px;
      left: -20px;
      z-index: -1;
      width: 40px;
      height: 40px;
      border: 1px solid #ccc;
      border-radius: 0 0 0 3px;
      box-shadow: 7px 6px 16px rgba($black, 0.16);
      transform: rotate(45deg);
      transform-origin: 50%;
    }
  }

  &:not(.-fixed) .am-show-button {
    border-left: none;
    border-radius: 0 3px 3px 0;
  }

  &.-fixed .am-show-button {
    justify-content: center;

    .am-items {
      margin: 0;
    }
  }
}

@include media-breakpoint-down(lg) {

  #layered-filter-block {
    display: block !important;
    margin-bottom: 0;
  }

  .toolbar.toolbar-products:first-child {
    padding: 0;
    background-color: $white;
    border: solid 1px $black;
    justify-content: space-between;

    @include media-breakpoint-down(md) {
      display: flex !important;
    }

    @include media-breakpoint-down(xs) {
      box-sizing: content-box;
      margin: 0;
    }

    .filter-mobile-controls {
      flex: 1 1 50%;
      background-color: $black;

      button.refine-control {
        background-color: $black;
        color: $white;
        border: none;
        width: 100%;
        height: 40px;
        outline: none;
        font-size: 1.3rem;
        display: flex;
        align-items: center;
        justify-content: center;

        &.active::before {
          content: '';
          background: rgba($black, 0.5);
          display: block;
          height: 100%;
          position: fixed;
          right: 0;
          top: 0;
          width: 100%;
          z-index: 100;
        }

        svg {
          margin-right: 0.5rem;
        }
      }
    }

    .sorter {
      flex: 1 1 50%;
      padding: 4px;
      height: 40px;
      font-size: 1.2rem;
      text-align: center;
      justify-content: center !important;
      margin: 0 0 0 6px;

      @include media-breakpoint-down(lg) {
        margin-top: -1px;
        margin-bottom: -1px;
      }

      .sorter-options {
        background-color: $white;
        margin: 0;
        padding: 0 1.8rem 0 1rem;
      }
    }
  }

  .toolbar.toolbar-products:last-child {

    .filter-mobile-controls {
      display: none !important;
    }
  }
}
