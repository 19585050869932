body {

  .amasty-catalog-topnav {
    display: block;

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  .toolbar {
    position: relative;
    background: $gray-100;
    // padding: 4px 20px 10px 20px;
    padding: 4px 20px;
    display: flex !important;
    flex-direction: row;
    height: 100%;
    max-height: 40px;

    .toolbar-sorter {
      display: flex;
      justify-content: left;
      align-items: center;

      .sorter-label {
        margin: 0;
      }

      .sorter-action {
        top: 0;
        text-decoration: none !important;

        &:hover {
          text-decoration: underline !important;
          cursor: pointer;
        }

        &::before { display: none; }

        & > span {
          width: auto;
          position: relative;
          height: auto;
          margin: 0;
        }
      }
    }

    &.toolbar-products {

      @include media-breakpoint-down(lg) {

        &:last-child {
          display: block;
          background: none;

          .sorter {
            display: none;
          }

          .toolbar-amount {
            display: block;
          }
        }
      }

      ~ &.toolbar-products {

        .filter-mobile-controls {
          display: none !important;
        }
      }
    }

    .modes,
    .sorter,
    .toolbar-amount {
      position: relative;
      float: none;
      flex: 1 1 33%;
      margin: 0;
      padding: 0;
      height: auto;
      vertical-align: middle;
    }

    .sorter {
      text-align: left;

      .sorter-label {
        font-weight: 700;
        text-transform: capitalize;

        @include media-breakpoint-down(sm) {
          margin-right: -0.3rem;
        }
      }

      .sorter-options {
        background-color: $gray-100;
        border: none;
        outline: none;
        padding-right: 40px;

        @include media-breakpoint-down(sm) {
          max-width: 70%;
        }
      }
    }

    .toolbar-amount {
      font-size: 12px;
      line-height: 3.4rem;

      @include media-breakpoint-down(lg) {
        display: none;
      }
    }

    .modes {
      width: 30px;
      height: 30px;
      display: inline-block;
      text-align: right;

      @include media-breakpoint-down(lg) {
        display: none;
      }

      .modes-mode {
        border: none;
        text-align: center;
        float: none;
        cursor: pointer;

        &::before {
          content: none;
          display: none;
        }

        svg {
          height: 20px;

          path {
            fill: $gray-500;
          }
        }

        &.kuCurrent,
        &.active {
          cursor: default;

          svg {

            path {
              fill: $black;
            }
          }
        }
      }
    }
  }
}

.page-products .columns,
#amasty-shopby-product-list {
  z-index: initial;
}

#amasty-shopby-product-list {
  display: flex;
  flex-direction: column;

  .toolbar.toolbar-products:first-child {
    order: 0;

    .m-auto.w-100,
    .percentage-wrapper {
      display: none;
    }
  }

  .products-wrapper {
    order: 1;
  }

  .ias-spinner {
    order: 9998;
    display: inline-block;
    width: 3rem;
    height: 3rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: 0.75s linear infinite spinner-border;
    margin: auto;
    color: $secondary;

    img {
      display: none !important;
    }

    em {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border: 0;
    }

    + .toolbar-products .percentage-wrapper {
      display: none;
    }
  }

  .ias-noneleft,
  .ias-trigger-next {
    order: 9999;
  }

  .toolbar.toolbar-products ~ .toolbar-products {
    order: 2;
    background: none;
    margin: 0;

    .filter-mobile-controls {
      display: none;
    }

    .sorter,
    .modes {
      display: none;
    }
  }
}

.kuSearchResultsPageContainer .filter-sidebar.filter-sidebar {

  @include media-breakpoint-up(lg) {
    display: table-cell;
    width: 315px;
    max-width: 315px;
    padding-right: 4rem;

    .amshopby-items {
      padding-left: 0;
    }
  }

  @include media-breakpoint-down(md) {
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    margin: 0;
    max-width: 414px;
    min-width: 0;
    overflow-y: auto;
    transition: transform 0.3s;
    transform: translateX(100%);
    z-index: 101;
    background: $white;

    &.active {
      transform: translateX(0);
      box-shadow: $box-shadow-xl;
      width: 100%;
    }

    .filter-action-holder,
    .amshopby-items {
      padding-left: 1.9rem;
      padding-right: 1.9rem;
    }
  }
}
