body[class*=cms-academy] {

  .page-wrapper {
    overflow-x: hidden;
  }

  .column.main {
    padding-bottom: 0;

    [data-content-type=row][data-appearance=contained] {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
  }

  .home-blog.home-blog.home-blog {
    background: none;

    @include media-breakpoint-down(sm) {
      margin-bottom: 0.5rem;
      text-align: left;
    }

    &::before,
    &::after {
      display: none;
    }

    .amblog-element-block {
      padding: 0;
    }

    h2 {
      margin-bottom: 2rem;

      @include media-breakpoint-up(md) {
        margin-bottom: 3.2rem;
      }

      @include media-breakpoint-down(sm) {
        text-align: left;
      }
    }
  }

  .event-list li {
    width: 100%;
    max-width: 100%;
  }

  .slick-list {
    width: 100%;
  }

  h2 {

    @include media-breakpoint-up(lg) {
      margin-bottom: 3.2rem;
    }
  }
}

.academy-banner.academy-banner {
  height: 200px;
}

.academy-events.academy-events.academy-events {
  position: relative;
  padding: 3rem 0 !important;

  @include media-breakpoint-up(lg) {
    padding: 4rem 0 !important;
  }

  h2 {
    font-size: 2.4rem;
  }

  &,
  &::before,
  &::after {
    background-color: rgba($light, 0.51);
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    z-index: -1;
    width: 100vw;
    height: 100%;
  }

  &::before {
    left: 100%;
  }

  &::after {
    right: 100%;
  }

  .pagebuilder-column {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  [data-content-type='text'] {
    max-width: 506px;
    margin-bottom: 1.3rem !important;

    @include media-breakpoint-up(lg) {
      margin: 0 auto 1.3rem !important;
      text-align: center;
    }

    h2 {
      font-weight: 700;
    }
  }

  .container {
    padding-left: 0;
    padding-right: 0;
    max-width: 100% !important;
  }
}

.academy-courses.academy-courses.academy-courses {
  background: none;

  &::before,
  &::after {
    display: none;
  }
}
