.aa-Panel {
  flex-direction: row;
  left: 0 !important;
  right: 0 !important;
  width: 100% !important;
  box-shadow: $box-shadow-lg;
  border: none;
  background: $white;

  @include media-breakpoint-up(md) {
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($light, 0.3);
      z-index: -1;
    }
  }

  .aa-PanelLayout {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    padding-bottom: 0;

    @include media-breakpoint-down(sm) {
      display: block;
      padding-top: 1rem;
    }

    @include media-breakpoint-up(sm) {
      max-width: 540px;
    }

    @include media-breakpoint-up(md) {
      max-width: 100%;

      section:nth-child(1) {
        position: relative;
        max-width: 669px;
        background: $white;
        border: none !important;
        padding: 3rem 1.5rem 8.6rem 7rem;

        @include media-breakpoint-only(md) {
          padding-left: 3rem;
        }

        &::before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 100%;
          width: 100vw;
          background: inherit;
        }
      }
    }

    @include media-breakpoint-up(lg) {
      max-width: 976px;
    }

    @include media-breakpoint-up(xl) {
      max-width: 1190px;
      grid-template-columns: 1fr 782px;
    }

    section:nth-child(1) {

      .aa-List li {
        background: none;

        a:hover {
          text-decoration: none;
        }
      }
    }

    .autocomplete-products-footer {
      position: relative;
      width: auto;
      left: auto;
    }

    .aa-SourceHeader {
      background-color: $primary;
      border-radius: 4px;
      color: $black !important;
      font: normal normal normal 1.1rem $font-family-base !important;
      text-transform: capitalize !important;
      display: inline-block;
      padding: 0.5rem 1rem !important;
    }

    .aa-Source.aa-Source[data-autocomplete-source-id='categories'],
    .aa-Source.aa-Source[data-autocomplete-source-id='pages'] {

      @include media-breakpoint-only(md) {
        max-width: 190px;
      }

      .aa-List {
        padding-top: 0.6rem;
      }

      @include media-breakpoint-up(md) {
        width: 100% !important;
        padding-top: 2.9rem;
        padding-right: 0.6rem;
      }

      .algoliasearch-autocomplete-hit.algoliasearch-autocomplete-hit {
        padding: 1rem 0;
        background: none;
        border-bottom: 1px solid $light;

        &:hover .info-title {
          text-decoration: underline;
        }

        em {
          font-weight: normal;
        }
      }

      .aa-no-results.aa-no-results {
        padding: 1rem 0;
      }
    }

    .aa-Source.aa-Source[data-autocomplete-source-id='pages'] {
      padding-top: 2rem;

      @include media-breakpoint-down(md) {
        padding-bottom: 1.5rem;
      }
    }

    .info {
      position: relative;
      padding-top: 2.2rem;

      .algoliasearch-autocomplete-category {
        display: none;
      }

      .after_special.promotion {
        padding-right: 0.4rem;
      }

      .algoliasearch-autocomplete-price {
        position: absolute;
        top: 0;
        left: 0;
        font-weight: 700;
        color: $black;

        @include media-breakpoint-down(md) {
          left: 1.5rem;
        }
      }
    }

    .aa-Item {

      &:hover {
        background: none !important;
      }

      a {
        padding-left: 0 !important;
        padding-right: 0 !important;

        &:hover {
          background: none !important;
          text-decoration: underline !important;
        }
      }
    }

    #autocomplete-products-footer {
      left: 7rem;
      right: 1rem;
      width: auto;
      bottom: 3rem;
      border-style: solid;
      border-width: 2px;
      font-family: $btn-font-family;
      font-weight: $btn-font-weight;

      @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-line-height-sm, $btn-border-radius-sm);
      @include button-outline-variant($secondary);

      @include media-breakpoint-only(md) {
        left: 3rem;
      }

      span {
        font-weight: inherit;
        color: inherit;
      }

      a {
        color: inherit;
        text-decoration: none;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }
    }
  }
}

.block-search {
  float: none;
  z-index: 1;
  flex: 0 1 470px;
  margin: -3px auto 0;
  padding: 0 20px;

  @include media-breakpoint-down(md) {
    margin-bottom: 0 !important;
  }

  @include media-breakpoint-up(md) {

    &,
    .aa-Autocomplete,
    .aa-Form {
      height: 100%;
    }
  }

  .aa-Label {
    display: inline;
    margin-bottom: 0;
  }

  .aa-InputWrapper {
    align-self: center;
    width: 100%;
  }

  .aa-Form {
    display: flex;
    position: relative;

    @include media-breakpoint-down(md) {
      padding: 1rem 1.5rem 1.5rem;
    }

    .aa-Input {
      height: 44px;
      padding: $input-padding-y 3.5rem $input-padding-y 1.7rem;
      border-radius: 0.7rem;
      border: $input-btn-border-width $secondary solid;
      font-family: $headings-font-family;
      font-weight: 700;
      font-size: 1.3rem;
      width: 100%;

      @include transition($input-transition);

      &:focus {
        outline: 0;
        border-color: $input-focus-border-color;
        color: $black;
        box-shadow: $box-shadow-lg;
      }
    }

    .aa-SubmitButton {
      right: 0.7rem !important;
      top: 50%;
      transform: translateY(-50%);
      background-image: url('../images/search.svg') !important;
      background-repeat: no-repeat !important;
      background-position: center !important;
      background-size: 24px 24px !important;

      @include media-breakpoint-down(md) {
        right: 2.2rem !important;
        margin-top: -0.25rem;
      }

      &:focus {
        outline: none;
      }

      svg {
        display: none;
      }
    }
  }

  @include media-breakpoint-down(md) {
    padding: 0;
  }

  .search-curve {
    display: none;
  }

  @include media-breakpoint-down(md) {
    position: relative;
    flex-basis: 100%;
    order: 4;
    background-color: $darker;

    .search-curve {
      display: block;
      position: absolute;
      bottom: 100%;
      left: 0;
      right: 0;
      color: $primary;
      background-color: $darker;
    }
  }

  @include media-breakpoint-down(xs) {

    .nav-open & {
      height: 0;
      padding: 0;
      overflow: hidden;
    }
  }

  .input-group {
    width: 100%;
  }

  .form-control {
    left: auto;
    height: 44px;
    padding-right: 3.5rem;
    border-radius: 0.7rem;
    border-color: $secondary;
    font-family: $headings-font-family;
    font-weight: 700;
    font-size: 1.3rem;

    &:focus {
      border-width: $input-btn-border-width !important;
      border-color: $input-focus-border-color !important;
      background-color: $white !important;
      color: $black !important;
      box-shadow: $box-shadow-lg !important;
    }

    @include media-breakpoint-down(md) {
      margin: 0;
      border-radius: 0.5rem;
      border-width: 3px;
    }
  }

  .magnifying-glass {
    background: none !important;
  }
}

.form-search {

  .btn-search-close,
  .search-spinner {
    display: none;
  }

  .btn-search-close-overlay {
    position: absolute;
    border: none;
  }

  &.hide {

    .search-autocomplete {
      transform: translateY(-100%);
    }
  }

  .algolia-search-input {

    @include media-breakpoint-down(sm) {
      font-size: 1.6rem;

      &::placeholder {
        font-size: 1.3rem;
      }
    }
  }
}

.search-wrapper {

  .search-icon {
    display: block;
    right: 1rem;
    top: 50%;
    z-index: 99;
    transform: translateY(-50%);

    @include media-breakpoint-down(md) {
      right: 1.5rem;
    }
  }
}

#algolia-autocomplete-container .other-sections {
  max-width: 600px;
  margin: 0;
  padding-top: 25px;
  opacity: 1;

  @include media-breakpoint-up(sm) {
    padding-top: 38px;
  }
}

.aa-dataset-products {
  width: 100%;
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(sm) {
    padding-bottom: 20px;
  }

  @include media-breakpoint-down(xs) {
    min-height: 0 !important;
  }
}

#algolia-autocomplete-container {

  .section-sidebar {
    position: relative;

    @include media-breakpoint-up(md) {
      padding-right: 4.5rem;
    }

    @include media-breakpoint-up(sm) {

      &::before {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 100vw;
        background-color: rgba($light, 0.3);
        z-index: -1;
      }
    }

    @include media-breakpoint-down(xs) {
      order: 1;
      margin-bottom: 2rem;
    }
  }

  .section-content {

    @include media-breakpoint-up(md) {
      padding-left: 7rem;
    }
  }

  .aa-dataset-0 {

    ~ div:not(:empty) {
      padding-top: 1rem;
    }
  }

  #autocomplete-products-footer {
    position: static;
    margin-top: auto;
    background: none;
    width: 100%;

    span {
      font-weight: inherit;
      color: inherit;
    }

    @include media-breakpoint-down(sm) {

      &#autocomplete-products-footer {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: auto;
        left: auto;
        height: auto;
        bottom: auto;
      }

      .btn-seeall {
        display: inline-block;
        border: none;
        background: none;
        width: auto;
        font-size: 0;
        padding: 0;
        position: absolute;
        top: 4rem;
        right: 0;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
          color: inherit;
        }

        .autocomplete-seeall {
          font-family: $font-family-base;
          font-weight: normal;
          font-size: 1.3rem;
        }
      }
    }

    @include media-breakpoint-down(xs) {

      .btn-seeall {
        top: 3rem;
        right: 1.5rem;
      }
    }
  }

  .algoliasearch-autocomplete-price {
    text-align: left;
    font: normal normal bold 13px/22px $font-family-base;
    letter-spacing: 0;
    color: $black !important;
    opacity: 1;
    font-size: 1em !important;
  }

  .aa-dropdown-menu {
    top: 100% !important;
    left: 0 !important;
    right: 0 !important;
    z-index: 99 !important;
    width: auto;
    margin-top: 0;
    border: none;
    box-shadow: $box-shadow-lg;

    .category {
      background-color: $primary;
      border-radius: 4px;
      color: $black !important;
      text-align: left;
      font: normal normal normal 11px $font-family-base;
      opacity: 1;
      text-transform: none;
      display: inline-block;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 10px;
      padding-right: 10px;
    }

    .algoliasearch-autocomplete-hit.algoliasearch-autocomplete-hit {
      padding: 1rem 0;
      background: none;
      border-bottom: 1px solid $light;

      &:hover .info-title {
        text-decoration: underline;
      }

      em {
        font-weight: normal;
      }
    }

    .aa-no-results.aa-no-results {
      padding: 1rem 0;
    }
  }

  .other-sections .aa-suggestions {
    margin: 0;

    @include media-breakpoint-down(xs) {
      margin-bottom: 0 !important;
    }
  }

  .aa-dropdown-menu .algoliasearch-autocomplete-hit .info {
    margin: 0;
    padding-left: 20px;
    padding-bottom: 17px;
    white-space: normal;
  }

  .aa-suggestions {
    margin-bottom: 20px !important;
    padding-top: 23px;
  }

  .aa-dropdown-menu .other-sections .aa-dataset-products .aa-suggestions .aa-suggestion {
    padding: 0;

    &:nth-child(8) ~ .aa-suggestion {
      display: none;
    }

    .algoliasearch-autocomplete-hit {
      border: none;
      padding: 0;
    }
  }
}

.search-see-all {
  float: right;

  @include media-breakpoint-down(xs) {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
