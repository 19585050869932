[data-content-type='faq'] {
  border-bottom: none !important;
  margin-bottom: 3rem !important;

  &,
  [data-content-type='faq-item'] {
    border-color: $light;
  }

  [data-content-type='faq-item'] {
    border-left: none !important;
    border-right: none !important;
    padding-left: 0 !important;

    &:first-child {
      border-top: none !important;
    }
  }

  h3 {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $light !important;
    padding: 0 !important;
    height: 40px;

    &::before,
    &::after {
      content: '';
      position: absolute;
      right: 2.5rem;
      width: 10px;
      height: 10px;
      background-image: url('../images/minus.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    &::after {
      transform: rotate(90deg);
      transform-origin: center;
    }

    &:hover {
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }

    &[aria-expanded='true'] {
      background-color: $white;

      &::after {
        display: none;
      }
    }
  }

  [data-element='answer'] {
    padding: 2rem 0 2rem !important;
  }
}
