.navigation {
  font-family: $headings-font-family;
  z-index: 0;

  .category-item {
    margin-right: 0;
    padding-top: 0;
    padding-bottom: 0;

    &:hover,
    &:focus-within {
      background-color: $white;
      color: $black;

      > a {
        background-color: $white;
      }
    }
  }

  @include media-breakpoint-up(lg) {

    ul {
      display: flex;
      justify-content: space-between;
      margin: 0 -0.5rem 0 -1.5rem;
      padding: 0;
    }

    a.nav-link {
      padding: 1.3rem 0.5rem;
      font-size: 1.4rem;
    }
  }

  @include media-breakpoint-up(xl) {

    ul {
      margin: 0 -1rem 0 -2rem;
    }

    a.nav-link {
      padding-left: 1rem;
      padding-right: 1rem;
      font-size: 1.6rem;
    }
  }
}

.nav-sections-item-title {
  display: none;
}

.nav-sections-item-content {
  margin-top: 0;
  padding: 0;

  @include media-breakpoint-down(md) {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-grow: 1;
    margin-left: 0;
  }

  @include media-breakpoint-up(lg) {
    position: relative;
  }
}

.nav-sections {
  background-color: $white;
  color: $black;

  a:not(.btn) {
    color: inherit !important;
  }

  .navigation {
    background: none;

    @include media-breakpoint-down(md) {
      padding: 0;
    }
  }

  @include media-breakpoint-down(md) {
    background-color: $primary;

    &::before {
      content: '';
      background: rgba($black, 0.5);
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 10px;
      z-index: 2;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      width: 100vw;
      height: 10px;
      background-image: url('../images/nav-curve.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-color: $black;
    }
  }

  @include media-breakpoint-down(xs) {

    &::before {
      display: none;
    }
  }

  @include media-breakpoint-up(lg) {
    background-color: $black;
    color: $white;
    transform: none !important;
  }
}

.category-item {
  display: block;
  padding: 0;

  @include media-breakpoint-up(lg) {
    position: static !important;
  }

  &.level0::after {
    width: auto;
  }

  &.level0 {

    @include media-breakpoint-up(lg) {

      &:not(.parent)::after {
        display: none;
      }

      &.parent {

        &::after {
          content: '';
          display: none;
          background: rgba($black, 0.5);
          height: 100%;
          position: fixed;
          right: 0;
          top: 0;
          width: 100%;
          z-index: -1;
          pointer-events: none;
          left: auto;
        }

        &.active,
        &:hover,
        &:focus-within {

          &::after {
            display: block;
          }

          .submenu-wrapper {
            display: block;
            color: $black;
          }

          > .nav-link {

            &::after {
              content: ' ';
              width: 0;
              height: 0;
              border-left: 12px solid transparent;
              border-right: 12px solid transparent;
              border-bottom: 10px solid $white;
              position: absolute;
              bottom: 0;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }

        > .nav-link {
          position: relative;
          height: 100%;
          background-color: $black;

          span {
            color: $white;
          }

          &::before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: -1rem;
            right: -1rem;
          }
        }
      }

      @include media-breakpoint-up(xl) {

        > .nav-link::before {
          left: -1.25rem;
          right: -1.25rem;
        }
      }

      @include media-breakpoint-up(xxl) {

        > .nav-link::before {
          left: -1.5rem;
          right: -1.5rem;
        }
      }

      @include media-breakpoint-up(xxxl) {

        > .nav-link::before {
          left: -3.5rem;
          right: -3.5rem;
        }
      }
    }
  }
}

.nav-sections-items .navigation {

  @include media-breakpoint-up(lg) {
    position: static;

    ul {
      position: static;
      display: flex;
      justify-content: space-between;
      margin: 0 -1.3rem;
      padding: 0;
    }
  }

  @include media-breakpoint-down(md) {
    padding-left: 0;
    padding-right: 0;

    ul,
    li {
      border: none !important;
      padding: 0;
    }

    li {

      > a {
        position: relative;
        margin-bottom: 0;
        padding: 0.8rem 2rem;
        border-bottom: 1px solid $gray-100;
        border-left: none !important;
        line-height: 1.75;
        font-size: 1.6rem;
        background-color: $white;
        font-weight: 700;

        span {
          margin-left: 0 !important;
        }
      }

      &:first-child > a {
        border-top: none;
        padding-top: 1.6rem !important;
      }

      &:last-child a {
        padding-bottom: 1.6rem !important;
      }
    }

    .level0.parent > a {
      background-image: url('../images/arrow-right.svg');
      background-position: right 1.2rem center;
      background-repeat: no-repeat;
      background-size: 32px 32px;
    }

    .parent {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 50px;
        height: 100%;
        background-image: url(../img/caret.svg);
        background-position: right 8px center;
        background-repeat: no-repeat;
        background-size: 6px 12px;
      }
    }

    &.inner {

      .parent::before {
        display: none;
      }

      .level0 > .nav-link {
        display: none;

        &.show + .submenu-wrapper {
          display: flex !important;

          .submenu {
            display: block !important;
          }
        }
      }
    }
  }
}

.submenu-wrapper {
  display: none;
  background: $white;

  .submenu.submenu {
    position: static;
    box-shadow: none;
    border: none;
    min-width: 0;
    margin-top: 0;
    font-weight: 400;
    font-size: 1.5rem;

    &::before {
      display: none !important;
    }
  }

  @include media-breakpoint-up(lg) {
    position: absolute;
    z-index: 0;
    top: 100%;
    left: 0;
    right: 0;
    max-width: none !important;
    box-shadow: $box-shadow-xl;

    .submenu-wrapper-inner {
      display: flex;
      max-width: 1010px;
      width: 100%;
      margin: auto;
      flex-wrap: wrap;
    }

    .submenu-items.submenu-items {

      .submenu-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .submenu {
        margin: 17px -1px -2px -1px !important;

        .category-item.active > a {
          border-color: $primary;
          border-width: 2px;
        }

        a {
          padding: 0;
          background: none !important;
          color: $primary;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .brands-wrapper,
    .submenu-items {
      padding: 1.5rem 2.5rem 2.5rem 2.6rem;

      h3 {
        font-size: 1.3rem;
        font-weight: 700;
      }

      .btn-outline-secondary:hover {
        background-color: $black;
      }
    }

    .brands-wrapper {
      flex: 0 0 100%;
      padding-top: 2.5rem;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $gray-100;
      margin-bottom: 1rem;

      .brands-inner {
        display: flex;
        width: calc(100% - 300px);
        align-items: center;

        > div:nth-child(n+6) {
          display: none;
        }

        a {
          display: block;
          padding: 0 2rem;

          img {
            transition: all 0.3s ease;
            width: 100%;
            max-width: 130px;
            max-height: 40px;
            margin-bottom: 0;
          }

          span {
            display: none;
          }

          &:hover {

            img {
              transform: scale(1.05);
            }
          }
        }
      }
    }

    .submenu-items {
      position: relative;
      flex: 0 0 100%;

      &::before {
        content: '';
        position: absolute;
        top: 1.5rem;
        right: 0;
        bottom: 3rem;
      }

      h3 {
        margin-right: auto;
      }
    }

    .submenu {
      display: flex !important;
      justify-content: flex-start !important;
      flex-wrap: wrap;
      margin-left: -5px !important;
      margin-right: -5px !important;

      li {
        width: 25%;
        margin: 0;
        font-size: 1.2rem;

        a {
          text-align: center;
          display: block;
          border: solid 2px transparent;
          border-radius: 2px;
          min-height: 120px;
          padding: 5px;
          text-align: left;
          min-height: initial;
          margin-bottom: 10px;
          border: none;
          transition: all 0.3s ease;

          &:hover {
            border-color: $primary;
            text-decoration: none !important;
            font-weight: 600;
          }
        }
      }
    }

    .nav-back {
      display: none;
    }
  }

  @include media-breakpoint-up(xl) {

    .submenu-wrapper-inner {
      max-width: 1220px;
    }
  }

  @include media-breakpoint-down(md) {
    flex-wrap: wrap;

    .submenu-wrapper-inner {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .brands-wrapper,
    .submenu-items {
      width: 100%;

      h3 {
        position: relative;
        margin-bottom: 0;
        padding: 1rem;
        background-color: $black;
        color: $white;
        text-align: center;
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 1.3;

        button.nav-back {
          position: absolute;
          left: 0;
          top: 0;
          padding-left: 2.6rem;
          padding-right: 2.6rem;
        }

        .see-all {
          padding: 0 !important;
          border: none !important;
          text-decoration: underline;
          position: absolute;
          right: 1.9rem;
          top: 1.2rem;
          font-family: $font-family-base;
          font-weight: normal;
          font-size: 1.3rem;
        }
      }

      .btn-outline-secondary {
        display: none;
      }

      a img {
        display: none;
      }
    }

    .brands-wrapper .brands-inner a,
    .submenu-items .submenu li a {
      background-color: $primary;
      font-size: 1.5rem;
      font-weight: 700;
      padding: 0.8rem 2rem;
      border-bottom: 1px solid $white;
    }

    .brands-wrapper {
      order: 0;

      .brands-inner {

        div {

          &:first-child > a {
            border-top: none;
            padding-top: 1.6rem;
          }

          &:last-child a {
            padding-bottom: 1.6rem;
          }
        }
      }
    }
  }

  @include media-breakpoint-only(lg) {
    max-width: map-get($grid-breakpoints, 'lg');
  }

  @include media-breakpoint-only(xl) {
    max-width: map-get($grid-breakpoints, 'xl');
  }
}

.submenu-extra {

  @include media-breakpoint-down(md) {

    ul {
      padding-left: 0;
      margin-bottom: 0;
    }

    a {
      display: block;
      font-size: 1.5rem;
      font-weight: 700;
      padding: 0.8rem 2rem;
      border: none !important;
    }

    li {
      margin-bottom: 0;

      a {
        border-bottom: 1px solid $gray-100 !important;
      }

      &:first-child > a {
        border-top: none;
        padding-top: 1.6rem;
      }

      &:last-child a {
        padding-bottom: 1.6rem;
      }
    }
  }
}
