.wishlist.products-grid {

  .product-item-info {
    position: relative;
  }

  .product-item-actions {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    margin-top: 0;
  }

  .product-item-name,
  .price-box {
    text-align: center;
  }

  .product-item-photo {
    margin-bottom: 0 !important;

    @include media-breakpoint-down(md) {
      margin-bottom: 30px !important;
    }
  }

  .product-image-wrapper {
    padding-bottom: 100% !important;
  }
}

.wishlist-toolbar {

  .pager {
    width: 100%;
    align-content: space-between;
    display: flex;

    .toolbar-amount {
      margin-bottom: 0;
      text-align: left;
    }

    .limiter {
      margin-bottom: 0;
    }
  }
}
