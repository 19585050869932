.kuFilters.kuFilters.kuFilters[role='navigation'] {

  .block-title.filter-title.sidebar-heading {
    display: flex;
    align-items: center;
    padding: 0 0.5rem;
    margin-bottom: 2rem;
    font-weight: 700;

    svg {
      margin-right: 0.5rem;
    }
  }

  .kuFilterBox {

    .kuFilterHead {
      font-size: 1.3rem;
      font-weight: 700;
      text-transform: capitalize;

      &::after {
        position: absolute;
        top: 30%;
        border: none;
        transform: none;
        display: block;
        width: 8px;
        height: 8px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
      }

      &.kuExpand::after {
        background-image: url('../images/plus.svg');
      }

      &.kuCollapse::after {
        background-image: url('../images/minus.svg');
      }
    }

    .kuFilterNames {

      a.klevuFilterOption {
        display: flex;
        align-items: center;
        color: $black;

        .kuFilterCancel,
        .kuFilterTotal {
          margin-left: auto;
          margin-right: 0.8rem;
        }
      }

      .kufacet-text {
        font-size: 1.2rem;
      }

      ul {

        li {

          span.kuFilterIcon {

            &::before {
              width: 2.4rem;
              height: 2.4rem;
              border: 2px solid $gray-300;
            }

            &::after {
              width: 12px;
              height: 7px;
              top: 6px;
              left: 6px;
              border-width: 3px;
            }
          }

          &:hover,
          &.kuSelected {

            a {
              font-weight: 700;
            }

            span.kuFilterIcon {

              &::before {
                border-color: $black;
              }

              &::after {
                border-color: $black;
              }
            }
          }

          &:not(.quick) {

            &.level-0 > a {
              font-weight: 700;
            }

            &.level-1 {
              padding-left: 3rem;
            }
          }
        }
      }
    }
  }

  .kuPriceSlider {

    .noUi-connect {
      background: $black;
    }

    .noUi-tooltip {
      border: 0;
      font-weight: 700;
    }
  }
}
