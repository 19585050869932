.cms-home {

  .main {

    @include media-breakpoint-down(md) {
      padding-bottom: 0 !important;
    }
  }

  .page-main {
    padding: 0;
    max-width: 100%;

    .page.messages {
      @extend .container;

      padding-left: 1rem;
      padding-right: 1rem;

      @include media-breakpoint-down(lg) {
        max-width: 100%;
      }

      .message {
        margin-top: 1rem;

        @include media-breakpoint-up(md) {
          margin-bottom: 0;
        }
      }
    }

    .column.main {
      overflow-x: hidden;
      padding-bottom: 2.3rem;
    }

    @include media-breakpoint-down(sm) {

      .column.main {
        display: flex;
        flex-direction: column;
      }

      [data-content-type='row'] {
        width: 100%;

        &:nth-of-type(2) {
          order: -2;
        }

        &:nth-of-type(5),
        &:nth-of-type(6) {
          order: -1;
        }
      }
    }
  }
}

.home-banner {

  .pagebuilder-column-group {

    @include media-breakpoint-between(md, xxxl) {
      width: 100%;
      max-width: map-get($grid-breakpoints, 'xxxl');
      margin-left: auto;
      margin-right: auto;
    }
  }

  .pagebuilder-column {

    [data-content-type='slider'] {
      min-height: 0 !important;
    }

    .slick-track {
      height: 100%;
    }

    .slick-list,
    .pagebuilder-mobile-hidden {
      position: absolute;
      width: 100%;
      height: 100% !important;
    }

    [data-content-type='slide'] {

      &,
      > div,
      > a > div {
        height: 100%;
      }
    }
  }

  @include media-breakpoint-down(sm) {

    .pagebuilder-column {
      padding: 0 !important;

      &:first-child {

        [data-content-type='slider'] {
          margin-bottom: 5.5rem !important;
          padding-bottom: 97% !important;
        }
      }

      &:last-child {
        display: none !important;

        div {
          background: none !important;
        }
      }
    }

    .pagebuilder-poster-overlay {
      min-height: 0 !important;
    }
  }

  @include media-breakpoint-up(md) {

    .pagebuilder-column {

      .slick-slide {
        width: 100% !important;
      }

      &:first-child {
        width: 66.555% !important;

        [data-content-type='slider'] {
          padding-bottom: 46.633% !important;
        }
      }

      &:last-child {
        width: 33.445% !important;
        padding-left: 0 !important;

        > * {
          position: relative;
          padding-bottom: 45.1% !important;

          &:first-child {
            margin-bottom: 10px !important;
          }
        }

        [data-content-type='slider'] {
          padding-bottom: 45.1% !important;
        }
      }
    }
  }

  div[data-content-type='banner'] {
    height: 50%;
    display: flex;
    align-items: stretch;

    div,
    a {
      width: 100%;
      display: flex;
    }
  }

  div[data-content-type='slide'] > div {
    display: flex;
    align-items: stretch;
  }

  .pagebuilder-slide-wrapper {
    width: 100%;
    min-height: inherit !important;
    background-position: center !important;
  }

  .slick-slider {

    @include media-breakpoint-up(lg) {

      .slick-dots {
        display: none !important;
      }
    }

    @include media-breakpoint-down(md) {

      .slick-dots {
        position: absolute;
        top: 100%;
        left: 0;
        margin-top: 2.5rem;
      }

      .slick-prev {
        left: 1.7rem;
      }

      .slick-next {
        right: 1.7rem;
      }
    }
  }

  .pagebuilder-column:first-child {

    @include media-breakpoint-up(lg) {

      .slick-slider {

        .slick-dots {
          display: flex !important;
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          margin: 0.25rem auto 0 auto;
        }
      }
    }
  }
}

.home-banner-blocks.home-banner-blocks.home-banner-blocks {
  margin-bottom: 3rem !important;

  .pagebuilder-column-group {
    margin: 0 3px;
  }

  .pagebuilder-column {
    padding: 2px 6px !important;
    font-family: $headings-font-family;
    font-weight: 700;
    font-size: 1.6rem;
    color: $white;

    &:nth-child(1) [data-content-type='text'] {
      background-color: $black;
    }

    &:nth-child(2) [data-content-type='text'] {
      background: transparent linear-gradient(250deg, $primary 0%, $primary 47%, #ff8002 100%);
    }

    &:nth-child(3) [data-content-type='text'] {
      background: transparent linear-gradient(250deg, $orange 0%, #bf2828 100%);
    }

    &:nth-child(4) [data-content-type='text'] {
      background: transparent linear-gradient(97deg, #807f7f 0%, #aaa 100%);
    }
  }

  [data-content-type='text'] {
    position: relative;
    z-index: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100px;
    padding: 22px 78px 22px 22px !important;
    overflow: hidden;
    border-radius: $border-radius !important;

    &::after {
      content: '';
      position: absolute;
      top: 2.6rem;
      right: 2.4rem;
      z-index: -1;
      width: 26px;
      height: 26px;
      background-image: url(../images/arrow-right-white.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  }

  p {
    margin-bottom: 0;
  }

  a {
    color: inherit;
    text-decoration: none !important;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }

    &:hover {
      text-decoration: underline !important;
    }
  }

  img {
    position: absolute;
    top: 0;
    right: 0;
    width: 155px !important;
    height: 155px !important;
    object-fit: cover;
    transform: translateX(50%);
  }

  @include media-breakpoint-between(md, lg) {

    .pagebuilder-column-group {
      flex-wrap: wrap;
    }

    .pagebuilder-column {
      width: 50% !important;

      &:nth-child(odd) {
        padding-right: 0 !important;
      }
    }

    [data-content-type=text]::after {
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.home-brands.home-brands.home-brands {
  margin-bottom: 2.6rem !important;
  text-align: center;

  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;

    ~ p a {
      display: inline-block;
      margin: 2rem 0 0;
      color: $black;
      padding: $btn-padding-y-sm $btn-padding-x-sm;
      border: 2px solid $black;
      background: $white;
      font-weight: 600;
      font-size: 1.2rem;
      border-radius: $border-radius;
      text-decoration: none !important;

      &:hover {
        padding: #{$btn-padding-y-sm + $btn-border-width} #{$btn-padding-x-sm + $btn-border-width};
        background: $gradient-dark;
        color: $white;
        border: none;
      }
    }
  }

  li {
    margin: 1rem 1.3rem;

    @include media-breakpoint-up(md) {
      margin-left: 2.2rem;
      margin-right: 2.2rem;
    }
  }

  img {
    max-width: 128px;
    height: auto !important;

    @include media-breakpoint-up(md) {
      max-width: 128px;
    }
  }
}

.home-categories.home-categories.home-categories {
  margin-bottom: 3.9rem !important;
  text-align: center;
  font-weight: 700;

  h2 {
    margin-bottom: 2.5rem;
    font-weight: 700;
    font-size: 1.3rem;
  }

  .pagebuilder-column {
    flex-direction: row !important;
    flex-wrap: wrap;
    justify-content: center !important;

    [data-content-type='text'] {
      position: relative;
      z-index: 0;
      flex: 0 0 150px !important;
      margin: 0 1.1rem 1rem !important;
      line-height: 1.2;
      font-size: 1.6rem;

      @include media-breakpoint-down(xs) {
        flex-basis: 100px !important;
      }
    }

    img {
      height: auto !important;
    }

    a {
      font-family: $headings-font-family;
      text-decoration: none !important;

      &:hover {
        text-decoration: underline !important;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
      }
    }
  }
}

.home-tabs.home-tabs.home-tabs {

  .tabs-navigation {

    li {
      z-index: 0 !important;
    }

    @include media-breakpoint-down(sm) {
      display: none !important;
    }

    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: center;
      margin-left: 1rem;
      margin-right: 1rem;
      border-bottom: 3px solid $black;

      li {
        border: none !important;
      }

      a.tab-title {
        max-width: 31.2vw;
        width: 241px;
        margin-right: 0.3rem;
        padding: 1.8rem 1rem;
        border-radius: $border-radius $border-radius 0 0;
        text-align: center;
        font-family: $headings-font-family;
        font-weight: 700;
        font-size: 1.6rem;
        background-color: rgba($light, 0.5);
        color: $black;
        text-decoration: none !important;

        &:hover {
          text-decoration: underline !important;
        }
      }

      .ui-state-active a.tab-title {
        background-color: $black;
        color: $white;
      }
    }
  }

  .tabs-content {
    border: none;
    z-index: 0 !important;

    @include media-breakpoint-down(sm) {

      [data-content-type='tab-item'] {
        display: block !important;
        border-top: 1px solid $light;
      }

      h2 {
        margin-bottom: 3rem !important;
        font-weight: 700;
        font-size: 2.2rem;
        text-align: center;
      }

      [data-content-type='html'] {
        margin-top: 4rem !important;
        text-align: center;
      }
    }

    .product-item-wrap .info-wrapper {
      min-height: 320px;
    }
  }

  .slick-dots {
    margin-top: 1rem;

    @include media-breakpoint-down(xs) {
      display: none !important;
    }
  }
}

@include media-breakpoint-down(xs) {

  .home-blog .slick-dots {
    display: none !important;
  }

  .home-banner .slick-slider .slick-dots {
    display: none !important;
  }
}

.home-blocks-1.home-blocks-1.home-blocks-1 {
  margin-bottom: 6rem !important;
}

.home-clubted,
.home-twi {
  position: relative;
  z-index: 0;
  width: calc(50% - 2rem) !important;
  margin: 10px !important;
  padding: 0 !important;
  text-align: center;
  overflow: hidden;
  border-radius: $border-radius !important;
}

.home-clubted {
  display: flex;
  background-color: $primary;
  border-radius: $border-radius;

  svg {
    position: absolute;
    top: -2.5rem;
    left: 0;
    right: 0;
    z-index: -1;
    margin: auto;
    border-radius: 50%;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.57);
  }

  h2 {
    margin-bottom: 0.8rem;
    font-weight: 700;
    font-size: 2.8rem;
  }

  [data-content-type='text'] {
    margin: 15rem 1rem 1rem !important;
    padding: 3rem 2rem !important;
    background-color: $white;
    text-align: center;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    padding-left: 0;
    margin-bottom: 8rem;

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 97px;
      height: 97px;
      margin-right: 2rem;
      padding: 10px;
      border-radius: 50%;
      border: 2px dashed $black;
      font-family: $headings-font-family;
      font-weight: 700;
      text-align: center;
    }
  }

  p {
    margin: 0;
  }

  .home-clubted-form {
    position: absolute;
    bottom: 8rem;
    left: 3rem;
    right: 3rem;
    z-index: 1;

    @include media-breakpoint-down(xs) {
      bottom: 8rem;
    }
  }

  .control::before {
    top: 1.2rem;
  }

  @include media-breakpoint-up(lg) {

    .btn {
      margin-left: 1rem;
    }
  }

  @include media-breakpoint-only(md) {

    .form-group {
      margin-bottom: 0.5rem;
    }

    ul {
      margin-bottom: 13rem;
    }

    .control,
    .btn {
      width: 100%;
    }
  }

  @include media-breakpoint-only(sm) {

    .form-group {
      margin-bottom: 0;
    }

    .btn {
      margin-left: 1rem;
    }
  }

  @include media-breakpoint-only(xs) {

    svg {
      top: -3.5rem;
    }

    h2 {
      margin-bottom: 1.5rem;
      font-size: 2.2rem;
    }

    .form-group {
      margin-bottom: 0.5rem;
    }

    ul {
      margin-bottom: 13rem;
    }

    li {
      margin-left: 1rem;
      margin-right: 1rem;
    }

    .control,
    .btn {
      width: 100%;
    }
  }
}

.home-twi.home-twi {
  display: flex;
  align-items: center;
  justify-content: center !important;
  min-height: 453px;
  color: $white;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background-color: rgba($black, 0.76);
  }

  h2 {
    max-width: 269px;
    margin-bottom: 3rem;
    font-weight: 700;
    font-size: 2.2rem;
    color: $primary;
  }

  ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 2rem;
    font-family: $headings-font-family;
    font-size: 1.6rem;
    font-weight: 700;
  }

  li {
    margin-bottom: 0.1rem;
  }

  svg {
    margin-bottom: 4rem;
  }

  p {
    margin-bottom: 0;
  }

  a {
    display: inline-block;
    padding: 1.2rem 3.4rem;
    border: 2px solid $black;
    color: $black;
    text-align: center;
    background: $white;
    text-transform: none;
    text-decoration: none !important;
    line-height: 1.5;
    font-family: $headings-font-family;
    font-weight: 700;
    font-size: 1.4rem;
    @include border-radius($btn-border-radius-sm); /* stylelint-disable-line */

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
    }
  }
}

.home-blog.home-blog.home-blog {
  position: relative;
  padding: 4.9rem 0 2.7rem !important;

  &,
  &::before,
  &::after {
    background-color: rgba($light, 0.5);
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100%;
  }

  &::before {
    left: 100%;
  }

  &::after {
    right: 100%;
  }

  h2 {
    margin-bottom: 1rem;
    text-align: center;
    font-weight: 700;
    font-size: 2.4rem;

    ~ p {
      text-align: center;

      a:only-child {
        display: inline-block;
        margin-bottom: 1.5rem;
        padding: #{$btn-padding-y-sm + $btn-border-width} #{$btn-padding-x-sm + $btn-border-width};
        background: $gradient-dark;
        color: $white;
        font-weight: 600;
        font-size: 12px;
        border-radius: 0.3rem;
        text-decoration: none !important;

        &:focus,
        &:active {
          border: none;
        }

        &:hover {
          padding: $btn-padding-y-sm $btn-padding-x-sm;
          border: 2px solid $black;
          background: $white;
          color: $black;
        }
      }
    }
  }

  .amblog-element-block {
    border: none;
    padding: 0 10px;
  }

  .amblog-post-container {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .slick-slide > div {
    display: flex;
    width: 100%;
    height: auto;
    align-items: stretch;
    justify-content: center;
  }
}

.home-blocks-2.home-blocks-2.home-blocks-2 {
  margin: 4.3rem 0 0 !important;

  .pagebuilder-column {
    position: relative;
    width: calc(33.33% - 10px) !important;
    margin-left: 5px !important;
    margin-right: 5px !important;
    margin-bottom: 17px !important;
    padding: 3.5rem 1.8rem 1.5rem !important;
    border: 1rem solid $primary !important;
    border-radius: 0.8rem !important;

    @include media-breakpoint-only(lg) {
      padding-left: 3.8rem !important;
      padding-right: 3.8rem !important;
    }

    @include media-breakpoint-up(xl) {
      width: calc(33.33% - 20px) !important;
      margin-left: 10px !important;
      margin-right: 10px !important;
      padding: 4.5rem 4.6rem 2.5rem !important;
    }
  }

  h2 {
    font-family: $headings-font-family;
    font-weight: 700;
    font-size: 2.2rem;
  }

  [data-content-type='text'] ~ [data-content-type='text'] {
    margin-top: auto !important;
  }

  p {
    font-family: $headings-font-family;
    font-size: 1.6rem;
  }

  ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 2.4rem;

    li {
      display: flex;
      margin-bottom: 0.4rem;

      &::before {
        content: '';
        display: inline-block;
        width: 22.52px;
        height: 22.52px;
        margin-right: 2rem;
        background-image: url('../images/tick.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }
    }
  }

  a {
    display: inline-block;
    color: $black;
    padding: $btn-padding-y-sm $btn-padding-x-sm;
    border: 2px solid $black;
    background: $white;
    font-weight: 600;
    font-size: 1.2rem;
    border-radius: $border-radius;
    text-decoration: none !important;

    &:hover {
      padding: #{$btn-padding-y-sm + $btn-border-width} #{$btn-padding-x-sm + $btn-border-width};
      background: $gradient-dark;
      color: $white;
      border: none;
    }

    @include media-breakpoint-down(lg) {
      margin-left: -2px;
    }
  }

  svg,
  img {
    position: absolute;
    right: 0.8rem;
    bottom: 2.7rem;
    max-width: 40% !important;

    @include media-breakpoint-only(lg) {
      right: 1.2rem;
    }

    @include media-breakpoint-up(xl) {
      right: 2.6rem;
      bottom: 3.6rem;
    }
  }
}

.home-usps {

  @include media-breakpoint-up(lg) {
    display: none !important;
  }

  @include media-breakpoint-down(md) {

    .header-after.product-advertlist {
      margin-top: 0;
      padding-top: 0;
      border: none;
    }
  }
}
