.page-footer {

  .footer.content {
    max-width: none;
    margin: 0;
    padding: 0;
  }

  .footer-bg {
    padding: 1.4rem 0 0;
    background: $gradient-darker;
    color: $white;

    @include media-breakpoint-up(lg) {
      padding: 6.4rem 0;
    }
  }

  .footer-container {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
  }

  .footer {
    border-top: none;
  }

  a:not(.btn) {
    color: inherit;
  }

  .footer-logo img {
    filter: brightness(0) invert(1);
  }

  .accordion {

    .btn-link {
      border: none;
      font-weight: 700;
      font-size: 1.3rem;

      &:hover,
      &:focus,
      &:active {
        background: none;
        border: none;
        box-shadow: none;
      }
    }

    @include media-breakpoint-up(lg) {

      .collapse,
      .collapsing {
        display: block !important;
        height: auto !important;
      }

      .btn-link {
        margin-bottom: 1rem;
        padding: 0.6rem;
        color: $primary;

        &:hover,
        &:focus,
        &:active {
          text-decoration: none;
          cursor: default;
          color: $primary;
        }

        svg {
          display: none;
        }
      }
    }

    @include media-breakpoint-down(md) {
      max-width: 283px;
      margin: 0 auto;

      .btn-link {
        padding-top: 0.6rem;
        padding-bottom: 0.6rem;

        &,
        &:hover,
        &:focus,
        &:active {
          color: $white;
        }
      }

      ul {
        padding-bottom: 1.5rem;
      }
    }
  }

  .phone {
    font-weight: 700;
    font-size: 2.4rem;
  }

  .footer-bordered {
    border-style: solid;
    border-color: rgba($white, 0.17);

    @include media-breakpoint-down(md) {
      border-width: 1px 0;

      .footer-bordered-inner {
        max-width: 208px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 3.2rem;
        padding-bottom: 6rem;
      }

      .btn {
        width: 166px;
      }
    }

    @include media-breakpoint-up(lg) {
      border-width: 0 1px;
    }
  }

  .footer-link > div:not(.collapse) {
    margin-bottom: 1rem;
  }

  .footer-link a {
    margin-bottom: 1rem;
    display: block;
  }

  .social-links.social-links {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    list-style: none;

    @include media-breakpoint-down(md) {
      justify-content: center;
      margin: -6.6rem 0 0;

      li {
        margin: 0 0.6rem;
      }
    }

    @include media-breakpoint-up(lg) {
      width: 150px;
      margin-top: -5rem;

      li {
        margin-right: 1rem;
      }
    }
  }
}
