.input-text,
.select {
  display: block;
  width: 100%;
  height: $input-height;
  padding: $input-padding-y $input-padding-x $input-padding-y 1.7rem;
  font-family: $input-font-family;
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;

  @include font-size($input-font-size);
  @include border-radius($input-border-radius, 0);
  @include box-shadow($input-box-shadow);
  @include transition($input-transition);

  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 $input-color;
  }

  @include form-control-focus($ignore-warning: true);

  &::placeholder {
    color: $input-placeholder-color;
    opacity: 1;
  }

  &:disabled,
  &[readonly] {
    background-color: $input-disabled-bg;
    opacity: 1;
  }

  &:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    label + & {
      border-top-left-radius: $input-border-radius;
      border-bottom-left-radius: $input-border-radius;
    }
  }

  &:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  @include media-breakpoint-down(xs) {
    background-position: right 1.5rem center;
  }
}

textarea.input-text {
  height: auto;
}

.label {
  font-size: 1.2rem;
}

select {
  appearance: none;
  background-image: url('../images/down.svg');
  background-size: 14px 7px;
  background-position: right 2rem center;
  background-repeat: no-repeat;
}

.floating-label {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  label {
    position: relative;
    z-index: 1;
    display: block;
    margin: 0 0.9rem;
    padding: 0 0.9rem;
    background: $white;
    font-family: $headings-font-family;
    font-weight: 600;
  }

  .form-control,
  .input-text,
  .control {
    width: 100%;
    margin-top: -0.8rem;
  }

  .input-group {

    .form-control,
    .input-text {
      flex-grow: 1;
      width: auto;
      min-width: 0;

      ~ div {
        margin-top: -0.8rem;
      }
    }
  }

  .control {

    .form-control,
    .input-text {
      margin-top: 0;
    }
  }
}

input,
select,
textarea {

  &.mage-error {
    border-color: $danger;
  }
}

div.mage-error {
  width: 100%;
  margin-top: 0;
  padding-left: 0.2rem;
  font-size: 1.1rem;
}

.custom-control {
  min-height: 3rem;

  input {

    &[disabled],
    &:disabled {

      ~ .custom-control-label {

        &::before {
          border-color: $custom-control-label-disabled-color;
        }
      }
    }
  }
}

// Don't refactor the following into background images on inputs
// There's an edge-case bug where the lastpass browser extension can't set a background image if you've added one already
// and instead creates DOM elements, which will appear off the page when the sidebar is closed, creating a large horizontal scrollbar.
.email,
.password {

  .control {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 1rem;
      left: 1.7rem;
      width: 24px;
      height: 24px;
      background-position: center;
      background-repeat: no-repeat;
      pointer-events: none;
    }
  }

  .form-control,
  .input-text {
    padding-left: 5.5rem;
  }
}

.control::before {

  .email & {
    background-image: url('../images/email.svg');
    background-size: 21.95px 21.95px;
  }

  .password & {
    background-image: url('../images/password.svg');
    background-size: 24px 24px;
  }
}

.required .label.label.label::after {
  content: '*';
  font-size: 1.2rem;
  margin: 0 0 0 0.3rem;
  color: $danger;
}

.review-field-ratings .label.label.label:not(#Overall_rating_label)::after {
  display: none;
}

.amcform-toolbar {
  width: 100%;
}

.amcform-page-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .form-group {
    width: 100%;
    margin-bottom: 1.9rem;
  }

  .amform-layout-two.amform-layout-two.amform-layout-two {
    display: flex;
    margin-right: 0;
    max-width: 100%;

    @include media-breakpoint-up(md) {
      max-width: 48%;
    }
  }

  .amform-layout-three.amform-layout-three.amform-layout-three {
    display: flex;

    + .amform-layout-three + .amform-layout-three {
      margin-right: 0;
    }

    @include media-breakpoint-down(sm) {
      max-width: 100%;
      margin-right: 0;
    }
  }
}

.field._error .control {

  input,
  select,
  textarea {
    border-color: $red;
  }
}

.range-container {
  position: relative;
  line-height: 0;
}

.custom-range {

  &,
  &::-webkit-slider-runnable-track {
    background-color: transparent;
  }

  &,
  &::-webkit-slider-thumb,
  &::-webkit-slider-runnable-track {
    height: $custom-range-track-height;
  }
}

.range-value {
  position: absolute;
  bottom: 24px;
  display: block;
  width: 30px;
  height: 22px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-color: $primary;
  text-align: center;
  font-weight: 700;
  line-height: 2.1;
  transform: translateX(-10px);
  pointer-events: none;

  &::before {
    content: '';
    position: absolute;
    top: 100%;
    right: 0;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 10px solid $primary;
    margin-bottom: 2px;
  }
}

.custom-range-list {
  position: absolute;
  top: 100%;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 0;
  margin-top: 1rem;
  margin-bottom: 0;
  list-style: none;

  li {
    flex-shrink: 1;
    text-align: center;
    font-weight: 700;
    font-family: $headings-font-family;

    &:hover {
      cursor: pointer;
    }
  }

  @include media-breakpoint-down(xs) {
    margin-left: -1rem;
    margin-right: -1rem;
    width: calc(100% + 2rem);

    li {
      font-weight: normal;
      font-size: 1rem;
    }
  }
}

.range-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: $custom-range-track-height;
  background-color: $black;
  border-radius: $custom-range-track-border-radius;
  overflow: hidden;

  .range-active {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: $primary;
    border-radius: $custom-range-track-border-radius;
  }
}

.custom-file.custom-file {

  .custom-file-label {
    padding-left: calc(125px + #{$custom-file-padding-x});
    border: none;
    line-height: 1.9;
    font-family: $headings-font-family;
    font-size: 1.2rem;
    box-shadow: none !important;

    &::after {
      content: 'Choose File';
      right: auto;
      left: 0;
      width: 125px;
      height: 33px;
      margin: auto;
      text-align: center;
      border: 2px solid $black;
      border-radius: $border-radius;
      line-height: 0.7;
    }
  }

  .custom-file-input {

    &:hover {
      cursor: pointer;

      + .custom-file-label::after {
        background: $gradient-dark;
        color: $white;
      }
    }

    &:focus {

      + .custom-file-label::after {
        box-shadow: 0 0 0 $btn-focus-width rgba($black, 0.5);
      }
    }
  }
}

/* Used for Jobs at Teds form */
.cms-jobs-at-teds {

  .fb-file {

    label.custom-file-label {

      &::before {
        position: absolute;
        display: block;
        margin: 0 0.9rem;
        padding: 0 0.9rem;
        background: #fff;
        font-weight: 600;
        left: 0;
        z-index: 10;
        top: -15px;
      }

      &[for=cv] {

        &::before {
          text-transform: uppercase;
          content: attr(for);
        }
      }

      &[for=cover-letter] {

        &::before {
          text-transform: capitalize;
          content: 'Cover Letter';
        }
      }

      &::after {
        left: 3px !important;
        width: 102px;
        padding: 11px 0;
      }
    }
  }
}

/* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {

  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'] { font-size: 1.6rem !important; }
}

/* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
@media screen and (device-aspect-ratio: 375/667) {

  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='tel'],
  input[type='url'] { font-size: 1.6rem !important; }
}

/* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
@media screen and (device-aspect-ratio: 9/16) {

  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='tel'],
  input[type='url'] { font-size: 1.6rem !important; }
}

.custom-checkbox {

  .custom-control-label {
    cursor: pointer;
  }
}
