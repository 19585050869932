.tags-wrapper {
  position: absolute;
  text-align: center;
  width: 91px;
  height: 24px;
  z-index: 30;
  font-weight: bold;

  .tag {
    padding: 4px 3px;
    border-radius: 4px;
    font-size: 11px;
    background: $yellow-dark;
    color: $white;

    &.tag-cash-back {
      background: $orange;
    }

    &.tag-bonus {
      background: $green;
    }

    &.tag-clearance {
      background: $purple-light;
    }

    &.tag-online-only {
      background: $blue-light;
    }
  }
}

.product.media {
  position: relative;
}

.product.media .tags-wrapper {
  width: auto;
  height: auto;

  @include media-breakpoint-up(lg) {
    left: 100px;
  }

  .tag {
    padding: 8px 31px;
    font-size: 1.3rem;
  }
}
