.brand-brand-slider {

  .slick-track {
    align-items: center;
  }

  .am-image {
    max-width: 260px;
    max-height: 140px;
    padding: 1rem;
  }
}

.brands-filters {

  .letters {
    margin-top: 1.2rem;
  }

  .-letter-all {
    margin-right: 1rem;
  }

  .letter.letter {
    text-decoration: none;
    font-weight: 700;
    font-size: 1.2rem;
    background-color: $primary;
    border-color: $primary;
    color: $black;

    &:hover,
    &.-active {
      background-color: $black;
      border-color: $black;
      color: $white;
    }
  }
}

.brand-images.brand-images {

  @include media-breakpoint-up(md) {
    margin-right: -3.5rem;
  }

  .brands-letter {

    @include media-breakpoint-up(md) {
      width: 33.333%;
    }

    .title {
      padding: 0 4px 1px;
      background-color: $primary;
      border-color: $primary;
      color: $black;
      font-weight: 700;
    }

    .brand-item {

      .item {
        text-decoration: none;
      }

      .label {
        font-weight: 700;
      }
    }
  }
}

.ambrands-search-wrapper {

  #livesearch {
    border-top-color: $primary;

    a {
      font-weight: 700;
      text-decoration: none;

      &:hover {
        background-color: $black;
        color: $white;
      }
    }
  }
}
