.compare-container {
  position: sticky;
  bottom: 0;
  left: 0;
  z-index: 50;
}

.link.compare {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  background-color: $black;
  color: $white;

  @include media-breakpoint-up(lg) {
    justify-content: center;
  }

  .btn-link {
    font-family: $font-family-base;
    font-weight: normal;
    font-size: 1.1rem;
    color: inherit;
  }

  p {
    margin-bottom: 0;
  }

  small {
    font-size: 1.1rem;
  }

  .btn-primary:hover {
    background: none;
    border-color: $white;
    color: $white;
  }
}

.sidebar-compare {

  .block-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    width: 100%;
  }
}
