.klevuTarget.klevu-fluid.klevuTarget > div {
  left: 0 !important;
  right: 0 !important;
  width: 100% !important;
  max-width: none !important;
  top: 141px !important;

  @include media-breakpoint-only(lg) {
    top: 190px !important;
  }

  @include media-breakpoint-up(xl) {
    top: 169px !important;
  }
}

.klevuQuickSearchingArea.klevuQuickSearchingArea {
  box-shadow: $box-shadow-lg;

  [data-container-role='main'] {
    max-width: 1190px;
    width: 100%;

    @include media-breakpoint-up(md) {
      padding-right: 1.5rem;
      padding-left: 1.5rem;
    }
  }

  .kuQuickSearchFacetedLayout & {

    .kuContainer {
      display: none;
    }

    .kuQuickResultsListContainer.kuQuickResultsListContainer {
      height: 330px;
      overflow: visible;

      @include media-breakpoint-down(sm) {
        height: auto;
      }

      .klevu-desc-l2 {
        display: none !important;
      }
    }

    [data-block-id='ku_quick_left_facets'] {
      max-height: 370px !important;
      background: none !important;

      @include media-breakpoint-down(sm) {
        max-height: none !important;
        max-width: 540px;
        margin-right: auto;
        margin-left: auto;
      }
    }

    [ku-container][data-container-role='left'] {
      background-color: rgba($light, 0.3);
      position: relative;

      @include media-breakpoint-down(sm) {
        background-color: $white;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 100%;
        width: 100%;
        height: 100%;
        background-color: rgba($light, 0.3);
        z-index: -1;
      }

      .kuFilters {
        padding: 3rem 2.2rem 1rem;

        @include media-breakpoint-up(md) {
          padding: 3rem 1.5rem 1rem 1.5rem;
        }

        @include media-breakpoint-up(xl) {
          padding: 3rem 4.6rem 1rem 1rem;
        }
      }

      .kuFilterIcon {
        display: none;
      }

      .klevu-cms {

        @include media-breakpoint-down(sm) {
          padding: 0 2.2rem 3rem 2.2rem;
        }

        @include media-breakpoint-up(md) {
          padding: 0 1.5rem 0 1.5rem;
        }

        @include media-breakpoint-only(xl) {
          padding: 0 4.6rem 0 1rem;
        }

        .kuFilterNames.kuFilterNames .klevuFilterOption {
          padding-bottom: 0;
        }

        .kuFilters {
          padding: 0;

          .kuFilterBox > .kuFilterHead {
            display: none;
          }
        }

        [data-content='cmsCompressed'] {
          display: block !important;

          li {
            border-bottom: none;

            &:nth-child(2) ~ li {
              display: none;
            }
          }

          .klevuProductItemTop,
          [data-block-id='ku_quick_other_items'] {
            display: none;
          }

          .klevuQuickProductInnerBlock {
            padding: 1rem 0;
            border-bottom: 1px solid $light;

            &:hover {
              text-decoration: underline;
            }
          }

          .klevuQuickProductDescBlock {
            margin-left: 0;
            text-decoration: inherit;
          }

          .klevuProductItemBottom {
            min-height: 0;
            padding: 0;
            text-decoration: inherit;

            .klevuQuickProductName {
              font-weight: 400;
              color: $black;
            }

            .klevu-desc-l2 {
              font-size: 1rem;
            }
          }
        }
      }

      [data-container-role='left'] {
        background: none;
        width: 100%;
        max-width: 100%;
        display: block;

        &::before {
          display: none;
        }

        .kuFilterHead.kuCollapse {
          display: none;
        }

        .kuFilterIcon {
          display: none;
        }

        .kuMulticheck {
          margin: 0;
          padding: 0;
        }

        .kufacet-text {
          background-color: $primary;
          border-radius: 4px;
          color: $black !important;
          font: normal normal normal 1.1rem $font-family-base !important;
          text-transform: capitalize !important;
          display: inline-block;
          padding: 0.5rem 1rem !important;
          width: auto;
          text-decoration: none !important;
        }

        .kuFilterTotal,
        .kuFilterCancel {
          display: none;
        }
      }
    }

    [data-result-view='grid'] {

      &.klevuQuickSearchResults {

        ul {
          display: flex;
          flex-wrap: wrap;
        }

        .klevuQuickImgWrap {
          width: auto !important;
          height: 60px !important;
        }

        img {
          width: 50px;
          height: 50px;
          object-fit: contain;
        }

        .klevuQuickAddtoCart.klevuQuickAddtoCart {
          display: none;
        }

        .klevuProductItemBottom {
          position: static;
          width: auto !important;
          text-decoration: inherit;

          > a {
            position: absolute;
            top: 0;
            left: 0;
            padding: 0;
            right: 0;
            bottom: 0;
          }

          .klevuQuickProductName {
            font-weight: 400;
          }
        }

        .klevuProductItemTop {
          width: auto !important;
          flex-shrink: 0;
        }
      }

      .klevuProduct.klevuProduct {
        display: flex;
        align-items: flex-start;
        position: relative;
        min-height: 60px;
        padding: 0.5rem 1rem;
        border: none !important;
        float: none;
        width: 50% !important;
        background: none !important;

        &:hover {
          text-decoration: underline;
        }

        @include media-breakpoint-down(sm) {
          width: 100% !important;
          padding: 0.5rem 0;
        }

        &:nth-child(6) ~ .klevuProduct {
          display: none;
        }

        > a {
          display: flex;
          flex-shrink: 0;
        }

        .klevuQuickProductDescBlock {
          display: flex;
          flex-direction: column;
          text-decoration: inherit;
        }

        a.klevuQuickProductInnerBlock {
          max-width: 100%;
          padding: 0;

          &:hover {
            text-decoration: underline;
          }
        }

        .klevuQuickProductPrice.kuClippedOne {
          order: -1;
          margin-bottom: 0.3rem;
        }
      }
    }
  }

  .kuFilterBox {

    .kuFilterNames.kuFilterNames {
      height: auto !important;

      ul li {
        border-bottom: 1px solid $light;

        &:nth-child(2) ~ li {
          display: none !important;
        }

        > a {
          padding: 1rem 0;

          &:hover {
            text-decoration: underline;
          }
        }

        .kufacet-text.kufacet-text {
          width: auto;
          max-width: none;
          text-decoration: inherit;
        }

        .kuFilterTotal {
          float: none;
          padding-left: 5px;

          &::before {
            content: '(';
          }

          &::after {
            content: ')';
          }
        }
      }

      a {
        color: $black;
      }
    }

    .kuShowOpt {
      display: none;
    }

    &.klevuFilter {

      &.kuMulticheck:not([data-filter='category']),
      &[data-filter='klevu_price'] {
        display: none;
      }

      &.kuMulticheck {
        margin-bottom: 0;
      }
    }

    .kuFilterHead.kuFilterHead {
      background-color: $primary;
      border-radius: 4px;
      color: $black !important;
      font: normal normal normal 0 $font-family-base !important;
      text-transform: capitalize !important;
      display: inline-block;
      margin-bottom: 0 !important;
      padding: 0.5rem 1rem !important;

      &::before {
        content: 'Categories';
        font-size: 1.1rem;
      }

      &::after {
        display: none !important;
      }
    }
  }

  [data-container-id='ku_quick_main_content_container'] {

    @include media-breakpoint-down(sm) {
      display: flex !important;
      flex-direction: column;
    }
  }

  [data-container-id='ku_quick_main_content_center'] {

    .klevuResultsBlock .klevuQuickSearchResults.productList {
      padding: 2rem;

      @include media-breakpoint-up(xl) {
        padding: 2rem 5.6rem;
      }
    }

    @include media-breakpoint-down(sm) {
      order: -1;
      max-width: 540px !important;
      margin-right: auto !important;
      margin-left: auto !important;
      margin-bottom: 2rem !important;
    }
  }

  .klevu-all {
    position: absolute;
    bottom: 2rem;
    left: 2rem;
    right: 2rem;
    z-index: 1;

    @include media-breakpoint-down(sm) {
      bottom: -1rem;
    }

    @include media-breakpoint-up(xl) {
      bottom: 3rem;
      left: 7rem;
      right: 7rem;
    }

    a {
      font-weight: 700 !important;
    }
  }
}

.kuSearchPersonalizationsContainer {
  display: none;
}

.kuQuickResultsListHeader {
  display: none;
}

.searchRequestLoading .klevuQuickSearchingArea {
  display: none !important;
}

.klevuRestyle {

  > [data-container-role='main'] {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    position: relative;
    display: flex;
    width: 100%;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }

  [data-container-role='header'] {
    width: 33%;
    background-color: rgba($light, 0.3);
    position: relative;

    @include media-breakpoint-down(sm) {
      width: 100%;
      background-color: $white;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 100%;
      width: 100vw;
      height: 100%;
      background-color: rgba($light, 0.3);
      z-index: -1;
    }

    .kuFilters {
      padding: 3rem 2.2rem 1rem;

      @include media-breakpoint-up(md) {
        padding: 3rem 1.5rem 1rem 1.5rem;
      }

      @include media-breakpoint-up(xl) {
        padding: 3rem 4.6rem 1rem 1rem;
      }
    }

    .kuFilterIcon {
      display: none;
    }
  }

  .klevuAutoSuggestionsWrap {
    padding: 3rem 4.6rem 1rem 1rem;
    border: none;

    ul li {
      border-bottom: 1px solid $light;

      &:nth-child(2) ~ li {
        display: none !important;
      }

      a {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin: 0.2rem 0.5rem 0.2rem 0;
        padding: 1rem 0;

        * {
          vertical-align: middle;
        }
      }
    }
  }

  .klevuSuggestionHeading {
    background-color: $primary;
    border-radius: 4px;
    color: $black !important;
    font: normal normal normal 1.1rem $font-family-base !important;
    text-transform: capitalize !important;
    display: inline-block;
    padding: 0.5rem 1rem !important;
    width: auto;
    text-decoration: none !important;
  }

  .klevuQuickSearchResults {

    ul {
      margin: 0;
      padding-left: 0;
      text-align: left;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;

      li {
        margin-bottom: auto;
        display: flex;
        align-items: flex-start;
        position: relative;
        min-height: 60px;
        padding: 0.5rem 1rem;
        border: none !important;
        float: none;
        width: 50% !important;
        background: none !important;

        @include media-breakpoint-down(sm) {
          width: 100% !important;
          padding: 0.5rem 0;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .klevuQuickImgWrap.klevuQuickImgWrap {
      float: none;
      overflow: hidden;
      width: 100% !important;
      height: 50px !important;
      text-align: center;
    }
  }

  [ku-container] {
    text-decoration: inherit;
  }

  [data-container-id='ku_quick_main_content_center'] {
    position: static;

    .klevuResultsBlock .klevuQuickSearchResults.productList.productList {
      padding: 2rem 5.6rem 7rem;

      @include media-breakpoint-down(sm) {
        padding: 2rem;
      }
    }
  }

  [data-container-id='ku_quick_main_content_container'] {

    @include media-breakpoint-down(sm) {
      order: -1;
      max-width: 540px !important;
      margin-right: auto !important;
      margin-left: auto !important;
      margin-bottom: 2rem !important;
    }
  }
}
