.zendesk-button.zendesk-button {
  position: fixed;
  margin: 10px 25px 30px 25px;
  bottom: 0;
  right: 0;
  z-index: 88;
  width: 66px;
  height: 66px;
  background: $primary;
  background-clip: padding-box;
  border: solid 7px rgba($primary, 0.4);
  border-radius: 100%;
  text-align: center;
  vertical-align: middle;

  &:hover {
    background-clip: border-box;
  }

  &:focus {
    outline: 2px solid rgba($primary, 0.4);
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.zendesk-chat.zendesk-chat {

  &:hover {
    cursor: pointer;
  }
}
