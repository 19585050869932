
.attr-icons-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include media-breakpoint-down(sm) {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  svg.attr-icon {
    padding: 0;
  }

  .attr-icon {
    width: 44px;
    height: 44px;
    border-radius: 3px;
    background-color: $light;
    margin-right: 4px;
    margin-bottom: 4px;
    padding: 4px 0;
    text-align: center;
    overflow: hidden;

    .attr-value {
      font-size: 1.6rem;
      line-height: 1.9rem;
      text-align: center;
      font-weight: 800;
    }

    .attr-text {
      font-size: 0.7rem;
      line-height: 0.8rem;
      text-align: center;
      text-transform: uppercase;
      font-weight: 600;
    }

    &.small-val {

      .attr-value {
        font-size: 1.2rem;
        line-height: 1.4rem;
        min-height: 19px;
      }
    }

    &.extra-small-val {

      .attr-value {
        font-size: 0.9rem;
        line-height: 1.2rem;
        min-height: 19px;
      }
    }

    &.small-label {

      .attr-text {
        font-size: 0.6rem;
      }
    }

    &.lcd-size {

      .attr-value {
        border: solid 0.5px $black;
        padding: 3px 5px;
        margin-bottom: 1px;
        display: inline-block;
        border-radius: 2px;
      }
    }
  }
}

body.catalog-product-view {

  @include media-breakpoint-up(md) {

    .attr-icons-wrapper {

      svg.attr-icon {
        padding: 0;
      }

      .attr-icon {
        width: 64px;
        height: 64px;
        padding: 7px 0;

        .attr-value {
          font-size: 2.3rem;
          line-height: 2.7rem;
          text-align: center;
          font-weight: 800;
        }

        .attr-text {
          font-size: 1rem;
          line-height: 1.1rem;
          text-align: center;
          text-transform: uppercase;
        }

        &.small-val {

          .attr-value {
            font-size: 1.6rem;
            line-height: 1.8rem;
            min-height: 27px;
          }
        }

        &.extra-small-val {

          .attr-value {
            font-size: 1.2rem;
            line-height: 1.5rem;
            min-height: 27px;
          }
        }

        &.small-label {

          .attr-text {
            font-size: 0.9rem;
          }
        }
      }
    }
  }
}
