.cms-masters,
.page-layout-masters-page {

  .breadcrumbs {
    margin-bottom: 0;

    @include media-breakpoint-up(md) {
      margin-bottom: 1rem;
    }
  }

  .column.main {
    overflow-x: hidden;

    [data-content-type='row'][data-appearance='contained'] {
      padding-left: 15px !important;
      padding-right: 15px !important;

      &:last-child {

        @include media-breakpoint-up(md) {
          margin-bottom: 1.5rem;
        }

        @include media-breakpoint-down(sm) {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
      }
    }
  }

  .pagebuilder-column {
    padding: 15px !important;
    text-align: center;

    div {
      height: 100%;
    }
  }

  .pagebuilder-banner-wrapper {
    position: relative;
    z-index: 0;
    max-width: 378px;
    margin-left: auto;
    margin-right: auto;
    background-position: 100% 100% !important;
    background-size: 0 !important;
    background-color: $white;
    border-radius: $border-radius !important;
    overflow: hidden;
    text-align: center;
    font-family: $headings-font-family;
    font-weight: 700;
    font-size: 1.8rem;
    box-shadow: $box-shadow-xl;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: -1;
      height: 187px;
      background-image: inherit;
      background-position: center;
      background-size: 100% 100%;
      transition: background-size 0.3s;
    }

    &:hover::before {
      background-size: 125% 125%;
    }

    .pagebuilder-poster-overlay {
      padding-bottom: 28px !important;
    }

    img {
      width: 118px !important;
      height: 118px !important;
      margin-top: 8.5rem;
      margin-bottom: 1.3rem;
      background-color: $primary;
      border: 6px solid $primary;
      border-radius: 50%;
      object-fit: cover;
    }

    p {

      &:not(:last-child) {
        margin-bottom: 0.6rem;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      display: inline-block;
      padding: #{$btn-padding-y-sm + $btn-border-width} #{$btn-padding-x-sm + $btn-border-width};
      color: $white;
      text-align: center;
      background: $gradient-dark;
      text-decoration: none !important;
      font-weight: 600;
      font-size: 1.2rem;
      @include border-radius($btn-border-radius-sm); /* stylelint-disable-line */

      &:hover {
        padding: $btn-padding-y-sm $btn-padding-x-sm;
        border: 2px solid $black;
        color: $black;
        background: $white;
      }
    }

    button {
      position: absolute;
      top: 2rem;
      left: 2.4rem;
      margin: 0;
      background-color: $primary;
      color: $secondary;
      border: none;
      padding: 1px 10px;
      font-family: $font-family-base;
      font-size: 1.1rem;
    }
  }
}

.masters-banner {
  position: relative;
  display: flex !important;
  align-items: center;
  justify-content: center !important;
  z-index: 0;
  height: 307px;
  margin-bottom: 3.5rem !important;
  background-position: center !important;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: rgba($black, 0.4);
  }

  div {
    height: auto !important;
  }

  @include media-breakpoint-down(sm) {
    height: 220px;
    margin-left: -15px !important;
    margin-right: -15px !important;
    width: calc(100% + 30px) !important;
    max-width: none;
    flex-basis: auto;
  }
}

.masters-intro {
  margin: 0 auto 4rem !important;
  line-height: 1.6;
  font-size: 1.5rem;

  h1 {
    margin-bottom: 2.5rem;
    font-weight: 700;
    font-size: 2.4rem;
  }

  p {
    margin-bottom: 0;
  }

  ~ .pagebuilder-column-group {
    margin-left: -15px;
    margin-right: -15px;
  }

  @include media-breakpoint-up(md) {
    max-width: 771px;
    text-align: center;
    margin-bottom: 6rem !important;
  }
}

.masters-midbar.masters-midbar.masters-midbar {
  margin: 3.7rem 0 !important;
  padding: 0 !important;

  @include media-breakpoint-down(sm) {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }

  .pagebuilder-column {
    padding: 4rem 15px !important;

    @include media-breakpoint-up(md) {
      padding: 6rem 15px !important;
    }

    &:first-child,
    &:last-child {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        background-color: inherit;
        width: 100vw;
        height: 100%;
      }
    }

    &:first-child {
      background-color: $primary;

      &::before {
        right: 100%;
      }
    }

    &:last-child {
      background-color: $secondary;

      &::before {
        left: 100%;
      }
    }

    div {
      height: auto;
    }
  }

  .pagebuilder-banner-wrapper {
    margin-top: 3.6rem;

    @include media-breakpoint-up(md) {
      margin-top: 4.9rem;
    }
  }

  ~ div[data-content-type='row'] .pagebuilder-column-group {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.masters-get-in-touch.masters-get-in-touch.masters-get-in-touch {
  position: relative;
  z-index: 0;
  justify-content: center !important;
  align-items: center;
  text-transform: uppercase;
  line-height: 1.2;
  font-family: $headings-font-family;
  font-weight: 700;
  font-size: 2.2rem;
  color: $primary;

  &::before,
  figure {
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: $border-radius;
    overflow: hidden;
  }

  &::before {
    content: '';
    z-index: -1;
    background: rgba($black, 0.64);
  }

  > div {
    max-width: 271px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
  }

  p {

    &:not(:last-child) {
      margin-bottom: 2.8rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  svg {
    margin-bottom: 3rem;
  }

  figure {
    z-index: -2;
  }

  img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
  }

  a {
    display: inline-block;
    padding: #{$btn-padding-y-sm + $btn-border-width} #{$btn-padding-x-sm + $btn-border-width};
    color: $black;
    text-align: center;
    background: $gradient-primary;
    text-transform: none;
    text-decoration: none !important;
    line-height: 1.5;
    font-weight: 600;
    font-size: 1.2rem;
    @include border-radius($btn-border-radius-sm); /* stylelint-disable-line */

    &:hover {
      padding: $btn-padding-y-sm $btn-padding-x-sm;
      border: 2px solid $black;
      color: $black;
      background: $white;
    }
  }

  @include media-breakpoint-down(sm) {
    height: 345px;
    max-width: 408px;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.profile-banner.profile-banner.profile-banner {
  position: relative;
  height: 220px;
  margin-bottom: 5.7rem !important;
  padding-top: 8.6rem !important;
  background-position: center !important;
  z-index: 0;
  color: $white;

  @include media-breakpoint-up(md) {
    height: 307px;
    padding-top: 16rem !important;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: rgba($black, 0.4);
  }

  h1 {
    font-family: $headings-font-family;
    font-weight: 700;
    font-size: 2.4rem;
    text-align: center;
  }

  figure {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    line-height: 0;
    width: 118px;
    height: 118px;
    margin: -56px auto 0 !important;
    background-color: $primary;
    border: 6px solid $primary !important;
    border-radius: 50%;
  }

  img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
  }
}

.profile-content {
  max-width: 565px;
  margin: 0 auto 2.7rem !important;
  text-align: center;

  @include media-breakpoint-down(sm) {
    margin-bottom: 0 !important;
    text-align: left !important;
  }
}

.profile-social {
  margin-bottom: 0.5rem !important;

  ul {
    display: flex;
    justify-content: center;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
  }

  li {
    display: inline-block;
    width: 33px;
    height: 33px;
    margin: 0 1rem 1rem;
  }

  a {
    display: block;
    width: 100%;
    height: 100%;
    font-size: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    &[href*='facebook'] {
      background-image: url('../images/facebook-dark.svg');
    }

    &[href*='instagram'] {
      background-image: url('../images/instagram-dark.svg');
    }

    &[href*='youtube'] {
      background-image: url('../images/youtube-dark.svg');
    }
  }
}

.profile-link.profile-link {
  margin-bottom: 2.5rem !important;
  text-align: center;

  a {
    display: inline-block;
    padding: #{$btn-padding-y-sm + $btn-border-width} #{$btn-padding-x-sm + $btn-border-width};
    color: $white;
    text-align: center;
    background: $gradient-dark;
    text-decoration: none !important;
    font-weight: 600;
    font-size: 1.2rem;
    @include border-radius($btn-border-radius-sm); /* stylelint-disable-line */

    &:hover {
      padding: $btn-padding-y-sm $btn-padding-x-sm;
      border: 2px solid $black;
      color: $black;
      background: $white;
    }
  }
}

.profile-slider {
  width: 100%;
  max-width: 965px;
  margin: 0 auto 4.3rem !important;

  @include media-breakpoint-down(sm) {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  div {
    height: auto !important;
  }

  h2 {
    margin-bottom: 4.4rem;
    font-weight: 700;
    font-size: 2.2rem;

    @include media-breakpoint-down(xs) {
      margin-bottom: 2rem;
      text-align: left;
    }
  }

  .pagebuilder-poster-overlay {
    height: 303px !important;

    @include media-breakpoint-up(md) {
      height: 603px !important;
    }
  }

  .pagebuilder-slide-wrapper {
    background-position: center !important;
  }

  .slick-list {
    margin-bottom: 3.3rem;
  }

  .slick-dots {
    position: relative;
    overflow: visible;
    counter-reset: section;

    li {
      position: static;
      counter-increment: section;

      &.slick-active::after {
        content: counter(section) ' / ';
        position: absolute;
        top: 100%;
        right: 50%;
        margin-top: 1rem;
        margin-right: 0.4rem;
      }
    }

    button {
      position: relative;
    }

    &::after {
      content: counter(section);
      position: absolute;
      top: 100%;
      left: 50%;
      margin-top: 1rem;
    }
  }
}

.profile-blog {
  position: relative;
  margin-bottom: 3.3rem !important;
  padding: 0 1.5rem 0 !important;

  @include media-breakpoint-up(sm) {
    padding: 5.3rem 1.5rem 1rem !important;

    &,
    &::before,
    &::after {
      background-color: $lighter;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      height: 100%;
      width: 100vw;
    }

    &::before {
      left: 100%;
    }

    &::after {
      right: 100%;
    }
  }

  @include media-breakpoint-between(sm, md) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  h2 {
    margin-bottom: 4.4rem;
    font-weight: 700;
    font-size: 2.2rem;
    text-align: center;

    @include media-breakpoint-down(xs) {
      margin-bottom: 2rem;
      text-align: left;
    }
  }

  .amblog-post-item {
    background-color: $white;
  }

  .amblog-element-block {
    border: none;
  }

  .amblog-post-container {
    flex: 1 1 auto;
    max-width: 100%;
    height: 100%;
  }

  .blog-post .amblog-title {
    margin-bottom: 0;
    padding-left: 0;
  }

  .slick-list {
    margin-bottom: 2rem;
  }
}

.profile-products {

  h2 {
    margin-bottom: 4.4rem;
    font-weight: 700;
    font-size: 2.2rem;
  }

  .slick-list {
    margin-bottom: 2rem;
  }

  @include media-breakpoint-down(xs) {

    h2 {
      margin-bottom: 2rem;
      text-align: left;
    }

    .product-items {
      display: flex;
      flex-wrap: wrap;
    }

    .product-item {
      visibility: visible !important;
      display: block !important;
    }
  }
}

.masters-form-intro {
  max-width: 535px;
  margin: 3rem auto 0 !important;

  @include media-breakpoint-up(md) {
    margin-bottom: 3rem !important;
    font-family: $headings-font-family;
    font-size: 1.6rem;
    text-align: center;
  }

  @include media-breakpoint-down(sm) {
    padding: 0 1.5rem !important;

    + div {

      .teds-border {
        border: none !important;
      }

      button {
        width: 100%;
      }
    }
  }

  h1 {
    margin-bottom: 2.4rem;
    font-weight: 700;
    font-size: 2.4rem;
  }

  p {
    margin-bottom: 0;
  }
}

.modal-popup.modal-slide .modal-inner-wrap[class] {
  background-color: $white;
  max-height: 100%;
}

@include media-breakpoint-up(md) {

  .amblog-container-list {

    .slick-list {
      min-width: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    .slick-track {
      min-width: 100% !important;
      width: max-content !important;
      justify-content: center;
    }
  }
}

.master-pager {

  a.action {
    text-decoration: none !important;
    position: relative;
    padding: 5px 30px;
    min-width: 30%;

    &:hover {
      text-decoration: underline !important;
    }
  }

  .master-name {
    font-size: 1.5rem;
  }

  .action.next::after,
  .action.previous::after {
    content: ' ';
    position: absolute;
    right: 5px;
    display: block;
    top: 10px;
    width: 15px;
    height: 15px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  .action.next::after {
    right: 5px;
    background-image: url(../images/right.svg);
  }

  .action.previous::after {
    left: 5px;
    background-image: url(../images/right.svg);
    transform: rotate(180deg);
  }
}
