.slick-prev,
.slick-next {
  background: none !important;
  transition: $transition-base;
  z-index: 50;

  &::before {
    content: '';
    display: block;
    padding: 10px;
    background-image: url('../images/down.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 15px 15px;
    line-height: 1;
  }

  &:hover {
    opacity: 0.7;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 $btn-focus-width rgba(mix(color-yiq($white), $white, 15%), 0.5);
  }
}

.slick-prev::before {
  transform: rotate(90deg);
}

.slick-next::before {
  transform: rotate(-90deg);
}

.slick-dots {
  display: flex !important;
  max-width: 490px;
  margin: 0 auto;
  padding: 0;
  border-radius: 99px;
  overflow: hidden;

  li {
    margin: 0;
    height: 5px;
    flex-grow: 1;
    width: auto;
    display: block;

    button {
      border-radius: 0;
      width: 100%;
      height: 100%;
      border: none !important;
      padding: 0;
      background: $light;
      outline: none;

      &::before {
        content: '';
        position: absolute;
        top: -20px;
        bottom: -21px;
        left: 0;
        right: 0;
        z-index: 3;
      }
    }

    &.slick-active button {
      background: $black !important;
      border-radius: 99px;
    }
  }

  @include media-breakpoint-down(sm) {
    max-width: calc(100% - 6rem);
    margin: 2rem 3rem;
  }
}

.slick-initialized {

  .slick-track {
    display: flex;

    .slick-slide {
      display: flex;
      height: auto;
      align-items: stretch;
      justify-content: center;
    }
  }
}

.widget-product-carousel {

  .product-item {
    display: block !important;
    visibility: visible !important;
  }

  .product-item-wrap {

    .info-wrapper {

      .brand {
        min-height: 45px !important;
      }

      .title {
        min-height: 70px;
      }
    }
  }

  @include media-breakpoint-down(sm) {

    &.products-wrapper.products-grid .simple-product-wrapper {
      width: 100%;
      border-right: none;
    }
  }
}
