.btn-primary,
.btn.action-accept {
  color: $black;

  &,
  &:focus {
    background: $gradient-primary;
  }
}

.btn-secondary,
.btn-dark {
  color: $white;

  &,
  &:focus {
    background: $gradient-dark;
  }
}

.btn-white {
  color: $black;
}

.btn-fb,
.btn-goo {
  color: $white;

  &:focus {
    border-width: 2px;
  }
}

.btn-fb {
  background-color: #3b5998;
  border: 2px solid #3b5998;
}

.btn-goo {
  background-color: #dd4b39;
  border: 2px solid #dd4b39;
}

.btn-primary,
.btn-secondary,
.btn-dark,
.btn-white,
.btn.action-accept {
  border: none;
  padding: #{$btn-padding-y + $btn-border-width} #{$btn-padding-x + $btn-border-width};

  &:focus,
  &:active {
    border: none;
  }

  &:hover {
    padding: $btn-padding-y $btn-padding-x;
    border: 2px solid $black;
    background: $white;
    color: $black;

    .page-footer.page-footer & {
      background: none;
      border-color: $white;
      color: $white;
    }
  }

  &.btn-sm {
    padding: #{$btn-padding-y-sm + $btn-border-width} #{$btn-padding-x-sm + $btn-border-width};

    &:hover {
      padding: $btn-padding-y-sm $btn-padding-x-sm;
    }
  }

  &.btn-lg {
    padding: #{$btn-padding-y-lg + $btn-border-width} #{$btn-padding-x-lg + $btn-border-width};

    &:hover {
      padding: $btn-padding-y-lg $btn-padding-x-lg;
    }
  }
}

.btn-sm {
  font-weight: 600;
}

.btn-outline-secondary,
.btn.action-dismiss {
  position: relative;
  background-color: $white;

  &:hover,
  &:focus {
    border-width: 2px;
  }

  &.active {
    box-shadow: 0 1px 8px #0003;
  }
}

.btn.action-dismiss {

  @include button-outline-variant($secondary);
}

.btn-social {
  position: relative;
  text-align: left;
  padding-left: 22%;
  padding-right: 3rem;

  .svg-container {
    position: absolute;
    top: 50%;
    left: 0.75rem;
    bottom: auto;
    transform: translateY(-50%);
    width: 30px;
    text-align: center;
    border: none;

    svg {
      height: 23px;
    }
  }
}

.btn-action-secondary.btn-action-secondary.btn-action-secondary {
  position: relative;
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 37px;
  height: 37px;
  padding: 0;
  border: none !important;
  border-radius: 99px !important;
  background-color: $gray-100;
  color: $black;

  span:not(.sr-only) {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin: 3px auto 0;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.8rem;

    @include media-breakpoint-only(xs) {
      font-size: 0.9rem;
    }
  }

  &:hover {
    background-color: darken($gray-100, 10%);
  }

  &.btn-white {
    background-color: $white;
    box-shadow: $box-shadow-lg;

    &:hover {
      background-color: darken($white, 10%);
    }
  }
}

.btn {
  white-space: nowrap;

  &:disabled {
    background: $gray-200;
    border: none;
    color: $medium;
  }
}

.btn-primary-flat {
  background-color: $primary;
  border-color: $primary;

  &:hover {
    background-color: $black;
    color: $white;
  }
}

.btn-qty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 2.4rem;
  height: 2.4rem;
  padding: 0;
  border-radius: 50%;
  border-width: 1px;

  @include media-breakpoint-only(xs) {
    width: 3rem;
    height: 3rem;
  }
}

.btn-link {
  border: none;

  &:hover,
  &:focus,
  &:active {
    border: none;
    background: none;
    box-shadow: none;
  }
}

.btn-collapse {
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {

    &:last-child {
      display: none;
    }
  }

  &:not(.collapsed) svg {
    display: none;

    &:last-child {
      display: block;
    }
  }
}

a.pagebuilder-button-primary.pagebuilder-button-primary,
.pagebuilder-button-primary.pagebuilder-button-primary {
  @extend .btn;
  @extend .btn-primary;
}

a.pagebuilder-button-secondary.pagebuilder-button-secondary,
.pagebuilder-button-secondary.pagebuilder-button-secondary {
  @extend .btn;
  @extend .btn-secondary;
}
